import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { CircularProgress, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: "6px",
		backgroundColor: `${theme.palette.primary.mainLight} important`,
		color: "#fff",
		fontSize: "1rem",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		textTransform: "none",
		"&:hover": {
			backgroundColor: theme.palette.secondary.mainTwo
		},
		marginRight: "20px"
	}
}));
export default function MultiButtons({ options, loading }) {
	const [open, setOpen] = React.useState(false);
	const classes = useStyles();

	const anchorRef = React.useRef(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const handleClick = () => {
		console.info(`You clicked ${options[selectedIndex].text}`);
		options[selectedIndex].onClick();
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<div style={{ marginRight: 20 }}>
			<ButtonGroup
				variant="contained"
				ref={anchorRef}
				aria-label="Button group with a nested menu"
			>
				<Button
					sx={{
						borderRadius: "6px",
						backgroundColor: "#003A7B",
						color: "#fff",
						fontSize: "15px",
						fontFamily: "Rubik",
						fontWeight: "900",
						padding: 1,
						textTransform: "none",
						"&:hover": {
							backgroundColor: "#02A689"
						}
					}}
					onClick={handleClick}
					disabled={loading}
				>
					{loading && <CircularProgress />} {options[selectedIndex].text}
				</Button>
				<Button
					size="small"
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
					sx={{
						borderRadius: "6px",
						backgroundColor: "#003A7B",
						color: "#fff",
						fontSize: "1rem",
						fontFamily: "Rubik",
						fontWeight: "900",
						textTransform: "none",
						"&:hover": {
							backgroundColor: "#02A689"
						}
					}}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom"
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map((option, index) => (
										<MenuItem
											key={option}
											disabled={index === 2}
											selected={index === selectedIndex}
											onClick={(event) => handleMenuItemClick(event, index)}
										>
											{option.text}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
