import React, { useState, useEffect } from "react";
import { Button, Typography, useMediaQuery } from "@material-ui/core";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router";
import AskUsAQuestionDialog from "../AskUsAQuestionDialog/AskUsAQuestionDialog";
import askUsAQuestionIcon from "../../assets/AskUsAQuestion.svg";
import Chat from "../Chat/Chat";
import { createUserChat, getUserChatById } from "../../services/userChat";
import { useUserState } from "../../context/UserContext";

const heroStyles = makeStyles((theme) => {
	return {
		heroSection: {
			position: "relative",
			height: "35rem",
			backgroundImage: "linear-gradient(180deg, #0B478D 0%, #6EA554 100%)"
		},
		image: {
			height: "100%",
			objectFit: "cover",
			width: "100%",
			opacity: 0.85
		},
		contentContainer: {
			marginLeft: 10,
			position: "absolute",
			top: "20%"
		},
		heading: {
			fontSize: "2.5em",
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightBold,
			color: "#fff",
			width: "555px",
			lineHeight: 1.3,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.5rem",
				width: "100%"
			},
			[theme.breakpoints.up("md")]: {
				width: "auto"
			}
		},
		description: {
			fontSize: "1.5rem",
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightRegular,
			color: "#fff",
			width: "100%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem"
			},
			[theme.breakpoints.down("xs")]: {
				fontSize: "0.8rem"
			}
		},
		alreadyRegistered: {
			fontSize: "16px",
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightRegular,
			color: "#fff",
			marginTop: 8
		},
		descriptionBold: {
			fontWeight: theme.typography.fontWeightSemiBold
		},
		descriptionContainer: {
			paddingTop: 8
		},
		signUpButton: {
			background: "#003A7B",
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightSemiBold,
			marginTop: 15,
			fontSize: "1rem",
			color: "#FFF",
			textTransform: "none",

			[theme.breakpoints.down("xs")]: {
				fontSize: "0.875rem"
			},
			width: 240,
			transition: "transform 0.5s ease, border 0.5s ease",
			"&:hover": {
				color: "#fff",
				backgroundColor: "#003A7B",
				border: "1px solid #fff",
				transform: "scale(1.01)"
			}
		},
		signInButton: {
			background: "#FFF",
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightSemiBold,
			marginTop: 15,
			fontSize: "1rem",
			color: "#003A7B",
			textTransform: "none",
			"&:hover": {
				backgroundColor: "#E5E5E5"
			},
			[theme.breakpoints.down("xs")]: {
				fontSize: "0.875rem"
			},
			width: 240
		},
		AskQIconCont: {
			position: "absolute",
			bottom: 20,
			right: 100,
			[theme.breakpoints.down("sm")]: {
				right: 20,
				bottom: -52
			}
		}
	};
});
export const HeroSection = ({
	heroTextOne,
	heroTextTwo,
	heroDescriptionTextOne,
	heroDescriptionTextTwo,
	heroDescriptionTextThree,
	heroImage,
	isDashboard,
	moreText
}) => {
	const classes = heroStyles();
	const { loginWithRedirect } = useAuth0();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [askQuestion, setAskQuestion] = useState(false);
	const userState = useUserState();
	const [oldChat, setOldChat] = useState([]);
	const [chatForceOpen, setChatForceOpen] = useState(false);

	useEffect(() => {
		const getChats = async () => {
			try {
				const resp = await getUserChatById({
					userId: userState?.userProfile?.userId
				});

				setOldChat(resp.data.data.data.rows);
			} catch (e) {}
		};

		if (isDashboard) {
			getChats();
		}
	}, [userState?.userProfile?.userId]);

	return (
		<div className={classes.heroSection}>
			{heroImage ? (
				<img
					src={heroImage}
					className={classes.image}
					style={{
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						mixBlendMode: "soft-light"
					}}
					alt="hero-image"
					fetchpriority="high"
				/>
			) : (
				""
			)}
			<div className={classes.contentContainer}>
				<div>
					<Container maxWidth="lg" component="div">
						<Typography component="h1" className={classes.heading}>
							{heroTextOne}
						</Typography>
						<Typography component="h1" className={classes.heading}>
							{heroTextTwo}
						</Typography>
						<div className={classes.descriptionContainer}>
							<Typography component="h1" className={classes.description}>
								{heroDescriptionTextOne}
							</Typography>
							<Typography component="h1" className={classes.description}>
								{heroDescriptionTextTwo}
							</Typography>
							{moreText ? moreText : ""}
							<Typography
								component="h1"
								className={`${classes.description} ${classes.descriptionBold}`}
							>
								{heroDescriptionTextThree}
							</Typography>
							{isDashboard && (
								<Chat
									oldChat={oldChat}
									uniqueKey={userState?.userProfile?.userId}
									createChat={createUserChat}
									chatForceOpen={chatForceOpen}
									setChatForceOpen={setChatForceOpen}
								/>
							)}
							{!isDashboard && pathname.startsWith("/app") && (
								<AskUsAQuestionDialog
									state={askQuestion}
									stateHandler={setAskQuestion}
								/>
							)}

							{!isDashboard && !pathname.startsWith("/app") && (
								<>
									{/* <Typography
										component={"h1"}
										className={classes.alreadyRegistered}
									>
										Already registered?
									</Typography> */}
									<Grid
										container
										flexDirection={"row"}
										alignItems={"center"}
										justifyContent={"flex-start"}
										columnSpacing={10}
									>
										{/* <Grid item xs={12} md={5} sm={12} lg={5} xl={5}>
											<Button
												className={classes.signInButton}
												onClick={() => {
													navigate("/login");
												}}
											>
												Login here
											</Button>
										</Grid> */}
										<Grid item xs={12} md={5} sm={12} lg={5} xl={5}>
											<Button
												className={classes.signUpButton}
												onClick={async () => {
													navigate("/signup");
												}}
											>
												Sign Up Now
											</Button>
										</Grid>
									</Grid>
									<AskUsAQuestionDialog
										state={askQuestion}
										stateHandler={setAskQuestion}
									/>
								</>
							)}
						</div>
					</Container>
				</div>
			</div>
			<div className={classes.AskQIconCont}>
				<img
					src={askUsAQuestionIcon}
					alt="ask us a question icon"
					role="button"
					aria-haspopup="dialog"
					tabIndex="0"
					aria-label="Open chat dialog"
					onClick={() => {
						if (isDashboard) {
							setChatForceOpen(true);
						} else {
							setAskQuestion(true);
						}
					}}
					style={{ cursor: "pointer" }}
				></img>
			</div>
		</div>
	);
};
