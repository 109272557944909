import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Typography, Container } from "@material-ui/core";
import { Button, Toast } from "../components/core";
import { TextField } from "../components/core";
import { Checkbox } from "../components/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Divider from "../components/Divider/Divider";
import googleAuth, { signIn } from "../services/auth";
import { useUserDispatch } from "../context/UserContext";
import Cookies from "universal-cookie";
import auth0 from "auth0-js";
import LogoColor from "../assets/logoColor.png";
import GoogleIcon from "../assets/flat-color-icons_google.svg";
import loginImg from "../assets/matthew-lejune-8L_bURvgm0c-unsplash-1.png";
const cookies = new Cookies();
const initialValues = {
	email: "",
	password: ""
};

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.email("Must be a valid email")
		.max(255)
		.required("Email is required"),
	password: Yup.string().max(255).required("Password is required")
});
const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%"
	},
	socialIcons: {
		height: "32px",
		width: "32px"
	},
	forgotPass: {
		float: "right",
		cursor: "pointer",
		color: theme.palette.primary.mainLight,
		"&:hover": {
			color: theme.palette.primary.main
		},
		marginBottom: "40px"
	},
	signUp: {
		marginLeft: 5,
		cursor: "pointer",
		textDecoration: "underline",
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.primary.mainLight,
		"&:hover": {
			color: theme.palette.primary.main
		}
	},
	container: {
		minWidth: "100%",
		minHeight: "100%",
		position: "relative"
	},
	innerContainer: {
		paddingTop: "100px",
		paddingBottom: "130px",
		paddingLeft: "100px",
		width: "70%",
		[theme.breakpoints.down("md")]: {
			width: "75%"
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			paddingTop: "300px",
			paddingLeft: "24px"
		}
	},
	loginImageStyles: {
		position: "absolute",
		width: "30%",
		height: "100%",
		objectFit: "cover",
		[theme.breakpoints.down("md")]: {
			width: "25%"
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			height: "20%",
			top: 0
		}
	},
	loginContainer: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: "13px"
	},
	journeyHeading: {
		textAlign: "center",
		color: "#fff",
		fontSize: "32px",
		width: "100%",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		[theme.breakpoints.down("sm")]: {
			fontSize: "28px"
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "26px"
		}
	},
	journeySubHeading: {
		textAlign: "center",
		color: "#fff ",
		fontSize: "20px",
		width: "100%",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		[theme.breakpoints.down("sm")]: {
			fontSize: "17px"
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "17px"
		}
	},
	button: {
		backgroundColor: theme.palette.primary.mainLight,
		color: "#fff",
		borderRadius: "5px",
		textTransform: "none",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		"&:hover": {
			color: "#fff",
			backgroundColor: theme.palette.secondary.mainTwo
		},
		fontSize: "1rem"
	},
	logoStyle: {
		width: "279.906px",
		height: "50.532px",
		marginBottom: "60px"
	},
	largeTitle: {
		color: theme.palette.primary.mainLight,
		fontFamily: theme.typography.fontFamily,
		fontSize: "2.5rem",
		fontWeight: theme.typography.fontWeightBold,
		textAlign: "center"
	}
}));

const Login = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const auth0Client = new auth0.WebAuth({
		domain: process.env.REACT_APP_AUTH0_DOMAIN, // Replace with your Auth0 domain
		clientID: process.env.REACT_APP_AUTH0_CLIENT_ID, // Replace with your Auth0 client ID
		redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI, // Replace with your callback URL
		responseType: "token id_token",
		scope: "openid profile email" // Customize scopes as needed
	});
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const onSubmit = async (values) => {
		setLoading(true);
		auth0Client.login(
			{
				realm: "Username-Password-Authentication", // Replace with your Auth0 connection name
				username: values.email,
				password: values.password
			},
			(err) => {
				if (err) {
					setLoading(false);
					setToast((prev) => {
						return {
							state: "open",
							message: err.error_description,
							type: "error",
							hideDuration: 3000
						};
					});
				}
			}
		);
		// window.location.href = "https://www.alokeebeta.com/home";

		setTimeout(() => setLoading(false), 1500);
	};

	const handleGoogleLogin = () => {
		auth0Client.authorize({
			connection: "google-oauth2" // Specify the Google social connection
		});
		// window.location.href = "https://www.alokeebeta.com/home";
	};

	return (
		<div className={classes.container}>
			<Toast {...toast} setState={setToast} />
			<Helmet>
				<title>Login | Alokee</title>
			</Helmet>
			<img src={loginImg} alt="boulevard" className={classes.loginImageStyles}></img>
			<Container className={classes.innerContainer}>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
				>
					<Grid
						item
						direction="column"
						justifyContent="center"
						alignItems="center"
						lg={5}
						xl={4}
						md={5}
						sm={5}
						xs={12}
						className={classes.loginContainer}
					>
						<Grid
							item
							container
							direction="column"
							alignItems="center"
							justifyContent="center"
							style={{
								paddingTop: "30px"
							}}
						>
							<Grid item>
								<img
									src={LogoColor}
									alt="logo"
									className={classes.logoStyle}
								/>
							</Grid>
							<Grid item>
								<Typography className={classes.largeTitle} style={{ marginBottom: "40px" }}>
									Welcome Back!
								</Typography>
							</Grid>
						</Grid>
						<Grid item direction="column" alignItems="center">
							<Container maxWidth="xs">
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={onSubmit}
								>
									{(props) => (
										<Form>
											<TextField
												name="email"
												type="email"
												label="Email"
												tooltipRequired={false}
												InputLabelProps={{
													style: { fontSize: "1rem", color: "#686868" }
												}}
											/>
											<TextField
												name="password"
												type="password"
												label="Password"
												tooltipRequired={false}
												InputLabelProps={{
													style: { fontSize: "1rem", color: "#686868" }
												}}
												style={{ marginBottom: 0 }}
											/>
											<Typography
												variant="subtitle2"
												display="inline"
												color="textSecondary"
												className={classes.forgotPass}
												role="button"
												tabIndex={0}
												onClick={() => {
													auth0Client.changePassword(
														{
															email: props.values.email,
															connection: "Username-Password-Authentication"
														},
														(err) => {
															if (err) {
																setLoading(false);
																setToast((prev) => {
																	return {
																		state: "open",
																		message: err.error_description,
																		type: "error",
																		hideDuration: 3000
																	};
																});
															} else {
																setToast((prev) => {
																	return {
																		state: "open",
																		message:
																			"Please check your email to reset your password.",
																		type: "success",
																		hideDuration: 3000
																	};
																});
															}
														}
													);
												}}
											>
												Forgot password?
											</Typography>
											<Box py={2}>
												<Button
													loading={loading}
													variant="outlined"
													loadingPosition="start"
													className={classes.button}
													fullWidth={true}
												>
													Sign In
												</Button>
											</Box>
											<Box style={{ paddingBottom: "20px" }}>
												<Typography
													variant="subtitle2"
													display="inline"
													style={{ color: "#000" }}
												>
													Don't have an Account?
												</Typography>
												<Typography
													variant="subtitle2"
													display="inline"
													color="text"
													aria-label="Don't Have an Account? Sign Up Here."
													role="button"
													tabIndex={0}
													className={classes.signUp}
													onClick={() => navigate("/SignUp")}
												>
													Sign up here
												</Typography>
											</Box>
											<Box display="flex" justifyContent="center" alignItems="center" style={{ paddingBottom: "20px" }}>
												<Typography style={{ color: "#686868" }}>or</Typography>
											</Box>
											<Box pb={1}>
												<Button
													loading={loading}
													variant="outlined"
													loadingPosition="start"
													className={classes.button}
													style={{ background: "#FFF", color: "#003A7B" }}
													onClick={handleGoogleLogin}
													startIcon={
														<img
															src={GoogleIcon}
															alt="Google Logo"
															width="24"
															height="24"
														/>
													}
													fullWidth={true}
												>
													Sign in with Google
												</Button>
											</Box>
											{/* <Box py={1}>
												<Typography
													variant="subtitle2"
													display="inline"
													color="textSecondary"
												>
													Don't have an Account?
												</Typography>
												<Typography
													variant="subtitle2"
													display="inline"
													color="text"
													className={classes.signUp}
													onClick={() => navigate("/SignUp")}
												>
													Sign Up
												</Typography>
											</Box> */}

											<Box
												py={1}
												sx={{
													marginLeft: 10
												}}
											>
												<div id="signInDiv"></div>
											</Box>
											<Box
												py={2}
												sx={{
													marginLeft: 10
												}}
											></Box>
										</Form>
									)}
								</Formik>
							</Container>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Login;
