import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		textAlign: "center",
		background: "transparent",
		boxShadow: "none",
		padding: 10,
		backgroundColor: theme.palette.primary.mainLight
	},
	appBarSimple: {
		zIndex: theme.zIndex.drawer + 1,
		textAlign: "center",
		background: "transparent",
		boxShadow: "none",
		padding: 10
	},
	appBarAboutUs: {
		zIndex: theme.zIndex.drawer + 1,
		textAlign: "center",
		background: "transparent",
		boxShadow: "none",
		padding: 10,
		backgroundColor: "#D9FAF5"
	},
	navMenu: {
		fontWeight: theme.typography.fontWeightLight,
		fontFamily: theme.typography.fontFamily
	},
	menuItem: {
		fontWeight: "500",
		fontSize: "0.95em",
		lineHeight: "1",
		color: theme.palette.primary.main
	},
	typo: {
		flexGrow: 1,
		textAlign: "center"
	},
	menuButton: {
		marginRight: 36
	},
	img: {
		flex: 1,
		height: 700,
		alignSelf: "stretch",
		alignContent: "stretch",
		justifyContent: "stretch"
	},
	userIcon: {
		height: "35px",
		width: "35px",
		objectFit: "cover",
		marginRight: "20px"
	},

	profileMenu: {
		minWidth: 240,
		minHeight: 120,
		overflow: "auto"
	},
	profileMenuUser: {
		display: "flex",
		flexDirection: "column",
		padding: theme.spacing(2)
	},
	profileMenuItem: {
		color: theme.palette.text.hint
	},
	profileMenuIcon: {
		marginRight: theme.spacing(2),
		color: theme.palette.text.hint,
		"&:hover": {
			color: theme.palette.primary.main
		}
	},
	profileMenuLink: {
		fontSize: 16,
		textDecoration: "none",
		"&:hover": {
			cursor: "pointer"
		},
		marginTop: theme.spacing(0)
	},
	logo: {
		width: "139.953px",
		height: "25.266px",
		marginRight: "10px",
		"&:hover": {
			cursor: "pointer"
		}
	},
	heading: {
		fontSize: "1.7em",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		color: "#1C4180",
		[theme.breakpoints.down("xs")]: {
			display: "none"
		}
	},
	button: {
		padding: "10px 18px",
		background: "#003A7B",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		fontSize: "1rem",
		lineHeight: "normal",
		color: "#FFFFFF",
		textTransform: "none",
		width: 200,
		"&:hover": {
			color: "#000",
			backgroundColor: theme.palette.primary.white
		},
		[theme.breakpoints.down("sm")]: {
			width: 100,
			fontSize: 14,
			padding: "5px 7px"
		},
		[theme.breakpoints.down("md")]: {
			width: 120,
			fontSize: 16,
			padding: "5px 7px"
		},
		[theme.breakpoints.between("sm", "xs")]: {
			width: "100px !important",
			fontSize: "12px !important",
			padding: "5px 7px !important"
		}
	},
	loginButton: {
		padding: "10px 18px",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		fontSize: "1rem",
		lineHeight: "normal",
		width: 200,
		color: "#FFFFFF",
		textTransform: "none",
		border: "1px solid #fff",
		"&:hover": {
			color: "#003A7B",
			backgroundColor: theme.palette.primary.white
		},
		[theme.breakpoints.down("sm")]: {
			width: 100,
			fontSize: 14,
			padding: "5px 7px"
		},
		[theme.breakpoints.down("md")]: {
			width: 120,
			fontSize: 16,
			padding: "5px 7px"
		},
		[theme.breakpoints.down("xs")]: {
			width: "100px !important",
			fontSize: "12px !important",
			padding: "5px 7px !important"
		}
	},
	homeToolbar: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		[theme.breakpoints.up("lg")]: {
			marginTop: "52px",
			marginLeft: "75px",
			marginRight: "75px"
		}
	},
	menuIconwhite: {
		color: "#FFF"
	},
	menuIconBlue: {
		color: theme.palette.primary.mainLight
	}
}));
