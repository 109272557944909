import axiosInstance from ".";

export const createCounterOfferBySeller = async (params) => {
	return axiosInstance.post(
		`/userPropertyEnvelope/createCounterOfferBySellerAgent`,
		params,
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};
export const createCounterOfferByBuyer = async (params) => {
	return axiosInstance.post(
		`/userPropertyEnvelope/createCounterOfferByBuyer`,
		params,
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};
export const updateSignBySellerCounterOffer = async (params) => {
	return axiosInstance.post(
		`/userPropertyEnvelope/updateSignBySellerCounterOffer`,
		params,
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};
export const updateSignByBuyerCounterOffer = async (params) => {
	return axiosInstance.post(
		`/userPropertyEnvelope/updateSignByBuyerCounterOffer`,
		params,
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};
export const updateSellerAgentInfo = async (params) => {
	return axiosInstance.post(`/userPropertyEnvelope/updateSellerAgentInfo`, {
		...params
	});
};
