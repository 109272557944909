import {
	Avatar,
	Container,
	Grid,
	Paper,
	Typography,
	InputAdornment,
	Box,
	Chip
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import makeStyles from "./styles";
import Collapsable from "../Collapsable/Collapsable";
import { FieldArray, Form, Formik } from "formik";
import { Button, Checkbox, Loader, TextField, Toast } from "../core";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { getPropertyById } from "../../services/bridgeInteractive";
import { Stack } from "@mui/material";
import OfferSummary from "../Properties/Forms/OfferSummary";
import * as Yup from "yup";
import {
	getAllUserPropertyFormById,
	updateTermsAcceptedStatus
} from "../../services/propertyForm";
import { useNavigate } from "react-router-dom";
import FormSummary from "../Properties/FormSummary";
import doneIcon from "../../assets/done.png";
import TextDialog from "../core/TextDialog";
import CommentBox from "../Admin/comment/CommentBox";
import NumberFormat from "react-number-format";
import RevisedForm from "./Observers/RevisedForm";
import { useAuth0 } from "@auth0/auth0-react";
import NonExclusiveAgreement from "../../pages/NonExclusiveAgreement";
import FormSkeleton from "../core/FormSkeleton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

let initialValues = {
	purchasePrice: "0",
	deposit: 20,
	loan: 80,
	balanceDownPayment: 0,
	partners: [],
	buyer: "",
	closingDate: 45,
	buyerFundNeedClose: "",
	expiryDate: 3,
	increaseDeposit: 0,
	loanType: "Conventional",
	loanRate: "",
	loanRateType: "",
	occupancyType: "Primary",
	loanContingency: false,
	appraisalContingency: false,
	investigationContingency: false,
	sellerDocumentsReview: false,
	titleReportReview: false,
	removalOfAllContingencies: false,
	contingentOffer: false,
	liquidatedDamages: "Yes",
	arbitrationOfDisputes: "Yes",
	proofOfFunds: "",
	loanQualification: "",
	otherDocuments: ""
};

const schema = Yup.object().shape({
	purchasePrice: Yup.string().required("purchase price is required"),
	deposit: Yup.string().required("deposit is required"),
	loan: Yup.string().required("loan is required"),
	buyer: Yup.string().required("buyer name is required"),
	partners: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Partner's Name is Required"),
			email: Yup.string()
				.email("Must be a valid email")
				.max(255)
				.required("Partner's Email is required")
		})
	),
	increaseDeposit: Yup.string(),
	loanType: Yup.string(),
	loanRate: Yup.string(),
	loanRateType: Yup.string(),
	occupancyType: Yup.string(),
	loanContingency: Yup.boolean().required("required"),
	appraisalContingency: Yup.boolean().required("required"),
	investigationContingency: Yup.boolean().required("required"),
	sellerDocumentsReview: Yup.boolean().required("required"),
	titleReportReview: Yup.boolean().required("required"),
	removalOfAllContingencies: Yup.boolean().required("required"),
	contingentOffer: Yup.boolean().required("required"),
	liquidatedDamages: Yup.string().required("required"),
	arbitrationOfDisputes: Yup.string().required("required"),
	proofOfFunds: Yup.mixed().required("Please upload proof of funds")
});
let initialValuesRevision = {
	purchasePrice: "0",
	sellerName: "",
	sellerEmail: "",
	secondSellerName: "",
	secondSellerEmail: "",
	loanContingency: true,
	appraisalContingency: true,
	investigationContingency: true,
	sellerDocumentsReview: true,
	titleReportReview: true,
	removalOfAllContingencies: false,
	contingentOffer: false
};
const schemaRevision = Yup.object().shape({
	sellerName: Yup.string().required("name is required").nullable(),
	sellerEmail: Yup.string().required("email is required").nullable(),
	secondSellerName: Yup.mixed().when("secondSeller", (secondSeller) => {
		if (secondSeller && secondSeller.length > 0)
			return Yup.mixed().required("name is required");
	}),
	secondSellerEmail: Yup.mixed().when("secondSeller", (secondSeller) => {
		if (secondSeller && secondSeller.length > 0)
			return Yup.mixed().required("email is required");
	}),

	purchasePrice: Yup.string().required("purchase price is required"),
	loanContingency: Yup.boolean().required("required"),
	appraisalContingency: Yup.boolean().required("required"),
	investigationContingency: Yup.boolean().required("required"),
	sellerDocumentsReview: Yup.boolean().required("required"),
	titleReportReview: Yup.boolean().required("required"),
	removalOfAllContingencies: Yup.boolean().required("required"),
	contingentOffer: Yup.boolean().required("required")
});
let initialValuesSellerInfo = {
	sellerName: "",
	sellerEmail: "",
	secondSellerName: "",
	secondSellerEmail: ""
};

const schemaSellerInfo = Yup.object().shape({
	sellerName: Yup.string().required("name is required"),
	sellerEmail: Yup.string().required("email is required"),
	secondSellerName: Yup.mixed().when("secondSeller", (secondSeller) => {
		if (secondSeller && secondSeller.length > 0)
			return Yup.mixed().required("name is required");
	}),
	secondSellerEmail: Yup.mixed().when("secondSeller", (secondSeller) => {
		if (secondSeller && secondSeller.length > 0)
			return Yup.mixed().required("email is required");
	})
});

function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			// isNumericString
		/>
	);
}

function RecipientView({
	revisedState,
	status,
	propertyMlsId,
	userPropertyData,
	commentsProp,
	showCommentBox,
	hideStepper,
	onAdminReviewDone,
	setStatus,
	setShowConfirmStatusChange,
	showActions,
	signedBy,
	showRevised,
	setShowRevised,
	onSubmitRevision,
	loadingRecipient,
	onSubmitSellerInfo,
	askSellerInfo,
	setAskSellerInfo,
	loadingSellerInfo,
	showCommentForm,
	setShowCommentForm,
	alreadyGetSellerInfo,
	revisedFields
}) {
	const navigate = useNavigate();
	const classes = makeStyles();
	const [activeStep, setActiveStep] = useState(0);
	const { user } = useAuth0();
	const [comments, setComments] = useState(commentsProp || []);
	const [userPropertyDetails, setUserPropertyDetails] = useState({});
	const [offerSentToSeller, setOfferSentToSeller] = useState({
		message: "",
		state: false
	});
	const [showConfirm, setShowConfirm] = useState({
		message: "Please review all details you have filled.",
		state: false
	});
	const [showConfirmOfferSent, setShowConfirmOfferSent] = useState({
		message: "",
		state: false
	});
	const [showDocuSignProceed, setShowDocuSignProceed] = useState({
		message: "",
		state: false
	});
	const [loading, setLoading] = useState(false);
	const [stepper, setStepper] = useState({
		offerTerms: true,
		uploads: false,
		offerDetails: false,
		offerSummary: false
	});
	const { state, pathname } = useLocation();
	if (state) {
		initialValues.purchasePrice = state.data.listPrice;
	}
	if (user) {
		initialValues.buyer = user.firstName + " " + user.lastName;
	}
	const [formValues, setFormValues] = useState(
		state ? initialValues : userPropertyData
	);
	const [revisedFormValues, setRevisedFormValues] = useState(
		initialValuesRevision
	);
	const [agreementButtonEnabled, setAgreementButtonEnabled] = useState(true);

	const { mlsId, userId, id, propertyId, role, code, userPropertyId } =
		useParams();
	const [propertyData, setPropertyData] = useState(state ? state.data : []);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const formRef = useRef();
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD"
	});

	useEffect(() => {
		if (status == "awaiting-alokee-approval") {
			showCommentBox = true;
		}
		if (pathname == `/sellerForm/${mlsId}/${userId}/${id}/${propertyId}`) {
			if (status == "pulled-back") {
				setShowConfirmOfferSent((prevState) => {
					return {
						message: "This offer has been pulled back by Buyer.",
						state: true
					};
				});
			}
		}
	}, [status]);

	useEffect(() => {
		const getProperty = async () => {
			try {
				const resp = await getPropertyById({
					mlsId: mlsId || propertyMlsId,
					auth0Id: user?.sub
				});
				if (resp.data.data) {
					setPropertyData(resp.data.data);
				} else {
					try {
						const resp = await getAllUserPropertyFormById({
							id: id || userPropertyId,
							auth0Id: user?.sub
						});

						setPropertyData(
							JSON.parse(resp.data.data.data[0].property_form.MLSData)
						);
					} catch (e) {}
				}
				if (pathname == `/app/property-form/${mlsId}`) {
					setFormValues((prev) => {
						return {
							...prev,
							purchasePrice: resp.data.ListPrice
						};
					});
				}
			} catch (e) {}
		};
		if (!state) {
			getProperty();
		} else {
			return;
		}
	}, [mlsId, propertyMlsId]);
	useEffect(() => {
		const getPropertyForm = async () => {
			try {
				const resp = await getAllUserPropertyFormById({
					id: id || userPropertyId,
					auth0Id: user?.sub
				});

				const propertyData = resp.data.data.data[0].property_form;
				propertyData.userPropertyId = resp.data.data.data[0].id;
				propertyData.id = resp.data.data.data[0].property_form.id;
				propertyData.buyer =
					resp.data.data.data[0].user.firstName +
					" " +
					resp.data.data.data[0].user.lastName;

				if (propertyData.partners) {
					propertyData.partners = [JSON.parse(propertyData.partners)];
				}
				if (!propertyData.partners) {
					propertyData.partners = [];
				}
				if (propertyData.entity) {
					propertyData.entity = [JSON.parse(propertyData.entity)];
				}
				if (!propertyData.entity) {
					propertyData.entity = [];
				}
				if (resp.data.data.data[0].comments) {
					setComments(resp.data.data.data[0].comments);
				}
				initialValuesRevision.purchasePrice = propertyData.purchasePrice;
				setFormValues(propertyData);
				setRevisedFormValues(propertyData);

				setUserPropertyDetails({
					status: resp.data.data.data[0].status,
					signedBy: resp.data.data.data[0].user_property_envelopes[0].signedBy,
					signedByBuyer:
						resp.data.data.data[0].user_property_envelopes[0].signedByBuyer,
					signedBySecondBuyer:
						resp.data.data.data[0].user_property_envelopes[0].signedBySecondBuyer,
					signedByBuyerAgent:
						resp.data.data.data[0].user_property_envelopes[0].signedByBuyerAgent,
					signedBySellerAgent:
						resp.data.data.data[0].user_property_envelopes[0].signedBySellerAgent,
					signedBySeller:
						resp.data.data.data[0].user_property_envelopes[0].signedBySeller,
					signedBySecondSeller:
						resp.data.data.data[0].user_property_envelopes[0].signedBySecondSeller,
					lastOfferType:
						resp.data.data.data[0].user_property_envelopes[0].envelopeType
				});
				
			} catch (e) {
				
			}
		};
		if (
			pathname == `/sellerForm/${mlsId}/${userId}/${id}/${propertyId}` ||
			pathname ==
				`/app/buyer-property-offer-form/${mlsId}/${userId}/${id}/${propertyId}` ||
			pathname == `/recipientForm/${role}/${code}/${mlsId}/${userPropertyId}`
		) {
			getPropertyForm();
		} else {
			return;
		}
	}, [id]);

	const handleNext = () => {
		if (activeStep == 4) {
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const onFormSubmit = async (values, { resetForm }, errors) => {
		if (role == "sellerAgent" && !alreadyGetSellerInfo) {
			setAskSellerInfo((prevState) => {
				return {
					message: "Add Seller's Details",
					state: true,
					textDialog: true
				};
			});
			return;
		} else if (
			userPropertyDetails.lastOfferType == "seller-counter-offer" &&
			role == "buyerAgent"
		) {
			navigate(
				`/signingPageSellerCounterOffer/${role}/${code}/${userPropertyId}`,
				{
					replace: true
				}
			);
			return;
		} else if (role == "secondBuyer" && status == "awaiting-signatures") {
			setShowDocuSignProceed((prevState) => {
				return {
					message: "Please review the contract carefully.",
					state: true,
					description:
						"Contact Alokee if you have any questions or concerns you wish to discuss."
				};
			});
			return;
		}

		// else if (userPropertyDetails.lastOfferType == "buyer-counter-offer") {
		// 	navigate(
		// 		`/signingPageForBuyerCounterOffer/${role}/${code}/${userPropertyId}`,
		// 		{
		// 			replace: true
		// 		}
		// 	);
		// 	return;
		// }
		navigate(`/signingPageForRecipient/${role}/${code}/${userPropertyId}`, {
			replace: true
		});
		return;
	};
	const onAccordionClick = (stepperObj) => {
		setStepper((prevState) => {
			const key = `${stepperObj}`;
			return {
				...prevState,
				[key]: !prevState[stepperObj]
			};
		});
	};

	useEffect(() => {
		if (activeStep == 0) {
			setStepper((prevState) => {
				return {
					offerTerms: true,
					uploads: false,
					offerDetails: false,
					offerSummary: false
				};
			});
		}
		if (activeStep == 1) {
			setStepper((prevState) => {
				return {
					offerTerms: false,
					uploads: true,
					offerDetails: false,
					offerSummary: false
				};
			});
		}
		if (activeStep == 2) {
			setStepper((prevState) => {
				return {
					offerTerms: false,
					uploads: false,
					offerDetails: true,
					offerSummary: false
				};
			});
		}
		if (activeStep == 3) {
			setStepper((prevState) => {
				return {
					offerTerms: false,
					uploads: false,
					offerDetails: false,
					offerSummary: true
				};
			});
		}
	}, [activeStep]);

	const handleBack = () => {
		if (activeStep == 0) {
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<Container maxWidth="lg">
			<Toast {...toast} setState={setToast} />
			{
				<div>
					{role == "buyerAgent" && !!comments.length ? (
						<Typography
							style={{
								fontWeight: "bold",
								fontSize: "1.5em",
								color: "#003a7b",
								padding: 10,
								marginTop: 20
							}}
							variant="caption"
							color="textSecondary"
						>
							Comments
						</Typography>
					) : (
						""
					)}
					{(role == "buyerAgent" && !!comments.length) ||
					showCommentForm.state ? (
						<CommentBox
							userPropertyId={formValues && formValues.userPropertyId}
							commentsProp={comments}
							showCommentBox={role == "buyerAgent"}
							showCommentForm={showCommentForm}
							setShowCommentForm={setShowCommentForm}
						/>
					) : (
						""
					)}
				</div>
			}
			{propertyData.constructor === Object &&
			formValues &&
			Object.keys(formValues).length != 0 ? (
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="stretch"
					className={classes.root}
					spacing={2}
					flexGrow
				>
					<Grid
						item
						xs={12}
						sm={12}
						lg={4}
						xl={4}
						md={4}
						style={{
							position: "relative",
							border: "1px solid",
							borderColor: "#cacaca",
							padding: 0,
							margin: 3
						}}
					>
						<Avatar
							src={
								propertyData?.Media
									? propertyData?.Media?.map((data) => data.MediaURL)[0]
									: "https://aqareyonline.com/site_new_assets/assets/images/placeholder-listing.jpeg"
							}
							variant="square"
							className={classes.img}
						></Avatar>
						<Chip
							label={userPropertyDetails.status}
							className={classes.formStatusChip}
							style={{
								marginLeft: 4,
								color: "#fff",
								backgroundColor:
									userPropertyDetails.status == "Pending" ||
									userPropertyDetails.status == "under-review" ||
									userPropertyDetails.status == "awaiting-signatures" ||
									userPropertyDetails.status == "awaiting-alokee-approval" ||
									userPropertyDetails.status == "in-progress"
										? "#FFC107"
										: "" ||
										  userPropertyDetails.status == "Active" ||
										  userPropertyDetails.status == "accepted"
										? "#28A745"
										: userPropertyDetails.status == "rejected"
										? "#FF4842"
										: "#17A2B8"
							}}
						/>

						<Grid
							container
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							className={classes.journeyContainer}
						>
							<Grid
								item
								container
								direction="row"
								justifyContent="space-between"
								style={{
									marginBottom: 10,
									marginLeft: 20
								}}
							>
								<Grid item>
									<Typography className={classes.price}>
										{formatter.format(propertyData.ListPrice)}
									</Typography>
								</Grid>
							</Grid>

							<Grid
								item
								xs={12}
								lg={12}
								className={classes.address}
								style={{
									marginLeft: 20
								}}
							>
								<Typography className={classes.addressInContainer}>
									{propertyData.UnparsedAddress}
								</Typography>
							</Grid>
							<Grid
								container
								item
								xs={8}
								lg={8}
								md={8}
								className={classes.listedPriceText}
								direction="row"
								justifyContent="space-between"
							>
								<Grid item xs={12} lg={12} md={12}>
									<Typography
										style={{
											cursor: "pointer",
											fontSize: "16px"
										}}
										variant="caption"
										color="textSecondary"
									>
										Listed Price
									</Typography>
									<Typography
										style={{
											cursor: "pointer",
											fontSize: "20px"
										}}
									>
										{formatter.format(propertyData.ListPrice)}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={12} md={12}>
									<Typography
										style={{
											cursor: "pointer",
											fontSize: "16px"
										}}
										variant="caption"
										color="textSecondary"
									>
										Offer Price
									</Typography>
									<Typography
										style={{
											cursor: "pointer",
											overflowWrap: "break-word",
											maxWidth: "260px",
											fontSize: "20px"
										}}
									>
										{formatter.format(formValues.purchasePrice)}
									</Typography>
								</Grid>
							</Grid>
							{role != "seller" && role != "sellerAgent" && (
								<Grid
									item
									style={{ marginTop: 15, marginLeft: 20 }}
									xs={12}
									lg={12}
									md={12}
								>
									<Typography className={classes.cashBonus}>
										Alokee Cash Bonus
									</Typography>
									<Typography className={classes.cashBonusText}>
										{formRef.current?.values?.purchasePrice
											? formRef.current.values.purchasePrice * 0.025 - 9999 > 0
												? formatter.format(
														(
															formRef.current.values.purchasePrice * 0.025 -
															9999
														).toFixed(2)
												  )
												: "$0.00"
											: formatter.format(
													(formValues.purchasePrice * 0.025 - 9999).toFixed(2) >
														0
														? (formValues.purchasePrice * 0.025 - 9999).toFixed(
																2
														  )
														: "0"
											  )}
									</Typography>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						lg={7}
						md={7}
						xl={7}
						style={{
							margin: 3,
							border: "1px solid",
							borderColor: "#cacaca"
						}}
					>
						<Formik
							initialValues={formValues}
							validationSchema={schema}
							onSubmit={onFormSubmit}
							innerRef={formRef}
							render={({
								values,
								isSubmitting,
								submitForm,
								isValid,
								dirty,
								errors,
								setErrors,
								setSubmitting
							}) => {
								if (Object.keys(errors).length > 0 && isSubmitting) {
									for (var i in errors) {
										alert(errors[i]); //alerts key's value
									}
									setSubmitting(false);
									setStepper({
										offerTerms: true,
										uploads: true,
										offerDetails: true,
										offerSummary: true
									});
									window.scrollTo(0, 0);
								}
								return (
									<Form>
										<Grid
											style={{ paddingBottom: 15 }}
											item
											xs={12}
											sm={12}
											lg={12}
											xl={12}
										>
											<Collapsable
												expanded={true}
												showActions={showActions}
												setShowConfirm={setShowConfirmStatusChange}
												setStatus={setStatus}
												name={"Offer Summary"}
												setAskSellerInfo={setAskSellerInfo}
												askSellerInfo={askSellerInfo}
												handleBack={handleBack}
												loading={loading}
												status={status}
												signedBy={signedBy}
												showCommentForm={showCommentForm}
												setShowCommentForm={setShowCommentForm}
												userPropertyDetails={userPropertyDetails}
												onClick={() => {
													onAccordionClick("offerSummary");
												}}
												onAdminReviewDone={onAdminReviewDone}
												handleNext={() => {
													if (!onAdminReviewDone) {
														handleNext();
														setShowConfirm((prevState) => {
															return {
																...prevState,
																state: true
															};
														});
													} else {
														onAdminReviewDone(formValues.userPropertyId);
													}
												}}
												activeStep={activeStep}
												revisedState={revisedState}
												hideStepper={hideStepper}
											>
												{
													<OfferSummary
														revisedState={revisedState}
														values={values}
														propertyAddress={propertyData.UnparsedAddress}
														setShowConfirmOfferSent={setShowConfirmOfferSent}
													>
														<FormSummary
															hideCashBonus={
																role == "seller" ||
																role == "sellerAgent" ||
																role == "secondSeller"
															}
															hideDocuments={
																role == "seller" || role == "secondSeller"
															}
															values={values}
															cashBonus={values.purchasePrice * 0.025 - 9999}
															revisedFields={revisedFields}
															buyerFunds={formatter.format(
																values.purchasePrice * (values.deposit / 100) +
																	parseFloat(
																		(values.purchasePrice *
																			values.balanceDownPayment) /
																			100
																	)
															)}
															address={propertyData.UnparsedAddress}
														></FormSummary>
													</OfferSummary>
												}
											</Collapsable>
										</Grid>
										<TextDialog
											showActions={false}
											message={showRevised.message}
											state={showRevised.state}
											stateHandler={setShowRevised}
											submitHandler={() => {}}
											onClose={() => {}}
											showField={false}
											showAvatar={false}
											textDialog={true}
											agreementDialog={true}
										>
											<Formik
												initialValues={revisedFormValues}
												validationSchema={schemaRevision}
												onSubmit={onSubmitRevision}
												render={({ values }) => (
													<Form>
														<Grid container spacing={2}>
															<Grid item xs={12} md={4} sm={6}>
																<TextField
																	tooltipRequired={false}
																	name="sellerName"
																	label="Seller Name"
																	type="text"
																	required
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<TextField
																	tooltipRequired={false}
																	name="sellerEmail"
																	label="Seller Email"
																	type="email"
																	required
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<TextField
																	name="purchasePrice"
																	label="Purchase Price"
																	type="text"
																	defaultValue={values.purchasePrice}
																	InputProps={{
																		startAdornment: (
																			<InputAdornment position="start">
																				$
																			</InputAdornment>
																		),

																		inputComponent: NumberFormatCustom
																	}}
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Checkbox
																	size="small"
																	name="loanContingency"
																	label="Loan Contingency"
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Checkbox
																	size="small"
																	name="appraisalContingency"
																	label="Appraisal Contingency"
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Checkbox
																	size="small"
																	name="investigationContingency"
																	label="Investigation Contingency"
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Checkbox
																	size="small"
																	name="sellerDocumentsReview"
																	label="Seller Documents Review"
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Checkbox
																	size="small"
																	name="titleReportReview"
																	label="Title Report Review"
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Checkbox
																	size="small"
																	name="removalOfAllContingencies"
																	label="Removal Of All Contingencies"
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Checkbox
																	size="small"
																	name="contingentOffer"
																	label="Home Sale Contingency"
																	required
																	disabled={revisedState}
																/>
															</Grid>
															<Grid
																flexDirection="column"
																alignItems="center"
																justifyContent="flex-start"
																container
																item
																xs={12}
																md={12}
																sm={12}
																spacing={2}
															>
																<FieldArray
																	tooltipRequired={true}
																	name="secondSeller"
																	render={(arrayHelpers) => (
																		<>
																			{values.secondSeller &&
																			values.secondSeller.length > 0 ? (
																				values.secondSeller.map(
																					(obj, index) => (
																						<>
																							<Grid item xs={4} md={4} sm={4}>
																								<TextField
																									name={`secondSellerName`}
																									label="Second Seller's Name"
																									type="text"
																									required
																									disabled={revisedState}
																								/>
																							</Grid>
																							<Grid item xs={4} md={4} sm={4}>
																								<TextField
																									name={`secondSellerEmail`}
																									label="Second Seller's Email"
																									type="email"
																									required
																									disabled={revisedState}
																								/>
																							</Grid>
																							<Button
																								fullWidth={false}
																								aria-label="delete"
																								color="primary"
																								type="button"
																								variant="outlined"
																								onClick={() =>
																									arrayHelpers.remove(index)
																								}
																							>
																								Remove
																							</Button>
																						</>
																					)
																				)
																			) : (
																				<div
																					style={{
																						display: "flex",
																						flexDirection: "row",
																						alignItems: "center",
																						justifyContent: "flex-start"
																					}}
																				>
																					<AddCircleOutlineIcon
																						style={{
																							marginRight: 5
																						}}
																					/>
																					<Typography
																						variant="outlined"
																						disabled={revisedState}
																						type="button"
																						onClick={() =>
																							arrayHelpers.push("")
																						}
																						style={{
																							fontFamily: "Rubik",
																							fontSize: "18px",
																							cursor: "pointer"
																						}}
																					>
																						Add a Second Seller (if any)
																					</Typography>
																				</div>
																			)}
																		</>
																	)}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Box py={2}>
																	<Button
																		loading={loadingRecipient}
																		loadingPosition="start"
																		variant="contained"
																	>
																		Submit
																	</Button>
																</Box>
															</Grid>
														</Grid>
														<RevisedForm />
													</Form>
												)}
											/>
										</TextDialog>
										<TextDialog
											showActions={false}
											message={askSellerInfo.message}
											state={askSellerInfo.state}
											textDialog={askSellerInfo?.textDialog}
											stateHandler={setAskSellerInfo}
											submitHandler={() => {}}
											onClose={() => {}}
											showField={false}
											showAvatar={false}
											agreementDialog={true}
										>
											<Formik
												onSubmit={onSubmitSellerInfo}
												validationSchema={schemaSellerInfo}
												initialValues={initialValuesSellerInfo}
												render={({ values }) => (
													<Form>
														<Grid container spacing={2}>
															<Grid item xs={12} md={6} sm={6}>
																<TextField
																	name="sellerName"
																	label="Seller Name"
																	type="text"
																	required
																/>
															</Grid>
															<Grid item xs={12} md={6} sm={6}>
																<TextField
																	name="sellerEmail"
																	label="Seller Email"
																	type="email"
																	required
																/>
															</Grid>
															<Grid
																container
																spacing={2}
																flexDirection="column"
																alignItems="center"
																justifyContent="flex-start"
																item
																xs={12}
																md={12}
																sm={12}
															>
																<FieldArray
																	tooltipRequired={true}
																	name="secondSeller"
																	render={(arrayHelpers) => (
																		<>
																			{values.secondSeller &&
																			values.secondSeller.length > 0 ? (
																				values.secondSeller.map(
																					(obj, index) => (
																						<>
																							<Grid item xs={4} md={4} sm={4}>
																								<TextField
																									name={`secondSellerName`}
																									label="Second Seller's Name"
																									type="text"
																									required
																								/>
																							</Grid>
																							<Grid item xs={4} md={4} sm={4}>
																								<TextField
																									name={`secondSellerEmail`}
																									label="Second Seller's Email"
																									type="email"
																									required
																								/>
																							</Grid>
																							<Button
																								fullWidth={false}
																								aria-label="delete"
																								variant="outlined"
																								color="primary"
																								type="button"
																								onClick={() =>
																									arrayHelpers.remove(index)
																								}
																							>
																								Remove
																							</Button>
																						</>
																					)
																				)
																			) : (
																				<div
																					style={{
																						display: "flex",
																						flexDirection: "row",
																						alignItems: "center",
																						justifyContent: "flex-start"
																					}}
																				>
																					<AddCircleOutlineIcon
																						style={{
																							marginRight: 5
																						}}
																					/>
																					<Typography
																						variant="outlined"
																						disabled={revisedState}
																						type="button"
																						onClick={() =>
																							arrayHelpers.push("")
																						}
																						style={{
																							fontFamily: "Rubik",
																							fontSize: "16px",
																							cursor: "pointer"
																						}}
																					>
																						Add a Second Seller (if any)
																					</Typography>
																				</div>
																			)}
																		</>
																	)}
																/>
															</Grid>
															<Grid item xs={12} md={4} sm={6}>
																<Box py={2}>
																	<Button
																		loading={loadingSellerInfo}
																		loadingPosition="start"
																		variant="contained"
																	>
																		Submit
																	</Button>
																</Box>
															</Grid>
														</Grid>
													</Form>
												)}
											/>
										</TextDialog>
										{showConfirmOfferSent.state && (
											<TextDialog
												showActions={true}
												message={showConfirmOfferSent.message}
												state={showConfirmOfferSent.state}
												stateHandler={setShowConfirmOfferSent}
												textDialog={true}
												submitHandler={() => {
													navigate("/app/dashboard?filter=Offers", {
														replace: true
													});
												}}
												onClose={() => {
													navigate("/app/dashboard?filter=Offers", {
														replace: true
													});
												}}
												showField={false}
												Avatar={
													<Avatar
														src={doneIcon}
														style={{
															top: 10,
															marginBottom: 10,
															flex: 1,
															alignContent: "center",
															alignSelf: "center"
														}}
													></Avatar>
												}
												showAvatar={true}
											></TextDialog>
										)}
										{showDocuSignProceed.state && (
											<TextDialog
												showActions={true}
												message={showDocuSignProceed.message}
												state={showDocuSignProceed.state}
												stateHandler={setShowDocuSignProceed}
												submitHandler={async () => {
													let values = {};
													values.propertyId = formValues.id;
													values.termsAcceptedBySecondBuyer = true;
													await updateTermsAcceptedStatus(values);
													navigate(
														`/signingPageForRecipient/${role}/${code}/${userPropertyId}`,
														{
															replace: true
														}
													);
													return;
												}}
												onClose={() => {}}
												showField={false}
												agreementButtonEnabled={agreementButtonEnabled}
												textDialog={true}
												agreementDialog={true}
												dialogDescription={showDocuSignProceed.description}
											>
												<NonExclusiveAgreement
													userFullName={formValues.partners[0].name}
													setButtonEnabled={setAgreementButtonEnabled}
												/>
											</TextDialog>
										)}
										{onAdminReviewDone && (
											<TextDialog
												showActions={false}
												message={
													"This Property Offer has been sent to the seller."
												}
												state={offerSentToSeller.state}
												stateHandler={setOfferSentToSeller}
												submitHandler={() => {}}
												onClose={() => {}}
												showField={false}
												Avatar={
													<Avatar
														src={doneIcon}
														style={{
															top: 10,
															marginBottom: 10,
															flex: 1,
															alignContent: "center",
															alignSelf: "center"
														}}
													></Avatar>
												}
												showAvatar={true}
											></TextDialog>
										)}
									</Form>
								);
							}}
						></Formik>
					</Grid>
				</Grid>
			) : (
				<FormSkeleton loading={true} />
			)}
		</Container>
	);
}

export default RecipientView;
