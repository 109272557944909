import React from "react";
import { makeStyles } from "@material-ui/styles";
import LogoColor from "../assets/logoWhite.png";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
	heroSection: {
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center"
	},
	image: {
		height: "100%",
		objectFit: "cover",
		width: "100%"
	},
	img: {
		position: "absolute",
		right: 0,
		bottom: 0,
		height: "600px",
		[theme.breakpoints.down("sm")]: {
			height: "300px"
		}
	},
	heading: {
		fontSize: "3em",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		color: "#fff",
		[theme.breakpoints.down("xs")]: {
			fontSize: "1.2em"
		}
	},
	logo: {
		height: 39,
		width: 210
	}
}));
function Mobile() {
	const classes = useStyles();

	return (
		<div className={classes.heroSection}>
			<img src={LogoColor} alt="logo" className={classes.logo} />
			<Typography component={"h1"} className={classes.heading}>
				Visit our desktop site to make offers.
			</Typography>
		</div>
	);
}

export default Mobile;
