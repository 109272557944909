import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateUserPropertyEnvelopeStatus } from "../services/propertyForm";
import Cookies from "universal-cookie";
import { useAuth0 } from "@auth0/auth0-react";
import TransparentLoader from "../components/core/TransparentLoader";
const cookies = new Cookies();

function Redirect() {
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const envelopeStatus = searchParams.get("envelopeStatus");
	const envelopeId = cookies.get("envelopeId");
	const isVaForm = cookies.get("isVaForm");
	const userPropertyId = cookies.get("userPropertyId");
	const { user } = useAuth0();

	useEffect(() => {
		const updateUserProperty = async () => {
			try {
				const resp = await updateUserPropertyEnvelopeStatus({
					envelopeStatus,
					envelopeId,
					userPropertyId,
					auth0Id: user?.sub,
					isVaForm: !!isVaForm
				});
				if (resp.data.data.redirectUrl) {
					cookies.set("envelopeId", resp.data.data.envelopeId, {
						doNotParse: true,
						path: "/"
					});
					cookies.set("isVaForm", 1, {
						doNotParse: true,
						path: "/"
					});
					navigate(`/document-e-signing/VAForm`, {
						replace: true,
						state: {
							redirectUrl: resp.data.data.redirectUrl
						}
					});
					return;
				}
				if (resp.data.success && resp.data.data.envelopeCompleted) {
					window.top.location.href = `${process.env.REACT_APP_URL}app/dashboard?filter=Offers&envelopeStatus=Completed&signerRole=buyer`;
				} else {
					window.top.location.href = `${process.env.REACT_APP_URL}app/dashboard?filter=Offers&envelopeStatus=InComplete&signerRole=buyer`;
				}
			} catch (e) {}
		};
		updateUserProperty();
	}, []);

	return <TransparentLoader />;
}

export default Redirect;
