import moment from "moment";
import { Typography, Button, useMediaQuery, Grid } from "@material-ui/core";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
	Container,
	Stack,
	Box,
	Paper,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import makeStyles from "./styles";
import agentImage from "../../assets/defaultImage.jpeg";
import Balcony from "../../assets/Balcony.svg";
import Bar from "../../assets/Bar.svg";
import Elevator from "../../assets/Elevator.svg";
import Laundry from "../../assets/Laundry.svg";
import CentralCooling from "../../assets/CentralCooling.svg";
import Fireplace from "../../assets/Fireplace.svg";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useLocation } from "react-router";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getPropertyById } from "../../services/bridgeInteractive";
import { Loader, Toast } from "../core";
import { useAuth0 } from "@auth0/auth0-react";
import Chat from "../Chat/Chat";
import StatusChip from "../StatusChip/StatusChip";
import Amenities from "../Amenities/Amenities";
import ShowMoreText from "../ShowMoreText/ShowMoreText";
import GalleryModal from "../GalleryModal/GalleryModal";
import SharePropertyLink from "../SharePropertyLink/SharePropertyLink";
import Footer from "../footer/footer";
import { createUserChat, getUserChatById } from "../../services/userChat";
import { useUserState } from "../../context/UserContext";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { PopupModal, useCalendlyEventListener } from "react-calendly";
import {
	createUserInspection,
	getAllInspectors,
	getEvaluatedUserInspections
} from "../../services/inspector";
import {
	createFavourite,
	removeFavourite,
	getAllFavourites
} from "../../services/favorites";
import { getAddresses } from "../../services/typeAhead";

import MultiButtons from "../core/ButtonGroup";
import TextDialog from "../core/TextDialog";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { createShowingWithShowami } from "../../services/userShowings";

function srcset(image, size, rows = 1, cols = 1) {
	return {
		src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
		srcSet: `${image}?w=${size * cols}&h=${
			size * rows
		}&fit=crop&auto=format&dpr=2 2x`
	};
}

const interiorFeatureIcons = ["Balcony", "Bar", "Elevator"];
const interiorFeatureSet = new Set(interiorFeatureIcons);
const featureImageMap = {
	Balcony,
	Bar,
	Elevator
};

function PropertyDetails() {
	const classes = makeStyles();
	const myRef = useRef(null);
	const navigate = useNavigate();
	const { state } = useLocation();
	const { mlsId } = useParams();
	const [selectedDates, setSelectedDates] = useState([null]);
	const searchLink = state?.searchLink || "/app/dashboard?filter=Offers";
	const currentURL = `${window.location.pathname}${window.location.search}`;
	const [propertyData, setPropertyData] = useState(state ? state.data : []);
	const [calendlyData, setCalendlyData] = useState(state ? state.data : []);
	const [inspector, setInspector] = useState(false);
	const { user } = useAuth0();
	const [oldChat, setOldChat] = useState([]);
	const [inspectionReport, setInspectionReport] = useState("");
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const userState = useUserState();
	const [showCalendly, setShowCalendly] = useState({
		message: "Schedule An Inspection With",
		state: false
	});
	const [loading, setLoading] = useState(false);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const [chatForceOpen, setChatForceOpen] = useState(false);
	const [showMoreImages, setShowMoreImages] = useState(false);
	const defaultImageCount = 4; // Number of images to show initially
	const [showBookingWithShowami, setShowBookingWithShowami] = useState({
		message: "Book Showing.",
		state: false
	});
	const toggleShowMoreImages = () => {
		setShowMoreImages(!showMoreImages);
	};

	// favouriting state
	const [userFavourites, setUserFavourites] = useState([]);

	// image list modal state
	const [openImgModal, setOpenImgModal] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Initial index for selected image

	// START image list functions
	const handleOpenImgModal = (index) => {
		setSelectedImageIndex(index); // Set index for selected image
		setOpenImgModal(true);
	};

	const handleCloseImgModal = () => {
		setOpenImgModal(false);
	};

	const updateIndex = (newIndex) => {
		setSelectedImageIndex(newIndex); // Update index from GalleryModal child
	};
	// END image list functions

	// START favouriting functions
	const extractPropertyId = (str) => {
		const match = str.match(/Property\('([^']+)'\)/);
		return match ? match[1] : null;
	};

	const onMarkFavourite = async (isFavourite, propId) => {
		if (!propertyData) {
			console.error("Property data is not available!");
			return;
		}

		const id = userFavourites[propId]?.id;

		try {
			if (isFavourite) {
				// Optimistically remove from favorites
				const newUserFavourites = { ...userFavourites };
				delete newUserFavourites[propId];
				setUserFavourites(newUserFavourites);

				await removeFavourite({
					id: id,
					auth0Id: user?.sub
				});
			} else {
				// Optimistically add to favorites
				const newUserFavourites = {
					...userFavourites,
					[propId]: { id: "tempId" } // Temporary id until server responds
				};

				setUserFavourites(newUserFavourites);

				const data = {
					auth0Id: user?.sub,
					price: propertyData.ListPrice,
					bathrooms: propertyData.BathroomsTotalInteger,
					bedrooms: propertyData.BedroomsTotal,
					name: propertyData.UnparsedAddress,
					street: propertyData.StreetName,
					area: propertyData.MLSAreaMajor,
					images: propertyData.Media?.map((data) => data.MediaURL) || "",
					status: propertyData.MlsStatus,
					mlsId: propertyData.ListingId,
					details: propertyData
				};

				const response = await createFavourite(data);
				// Update with actual id from the server
				newUserFavourites[propId] = { id: response.data.id };
				setUserFavourites(newUserFavourites);
			}
		} catch (e) {
			// Revert UI update in case of error
			setUserFavourites((prevFavourites) => {
				if (isFavourite) {
					return { ...prevFavourites, [propId]: { id: id } };
				} else {
					const updatedFavourites = { ...prevFavourites };
					delete updatedFavourites[propId];
					return updatedFavourites;
				}
			});
			console.error("Error updating favourite:", e);
			// Display a notification or toast here to inform the user
		}
	};

	useEffect(() => {
		const getUserFavourites = async () => {
			try {
				const resp = await getAllFavourites({
					auth0Id: user?.sub
				});
				const favorites = resp.data.data.data.rows;
				const mappedFavourites = favorites.reduce((acc, data) => {
					const details = JSON.parse(data.details);
					const propertyId = extractPropertyId(details["@odata.id"]);
					acc[propertyId] = { id: data.id };
					return acc;
				}, {});

				setUserFavourites(mappedFavourites);
			} catch (e) {
				console.log(e);
			}
		};

		getUserFavourites();
	}, []);
	// END favouriting functions

	const onInspectionsDone = async (params) => {
		try {
			const resp = await createUserInspection(params);
			if (resp.data.data.success) {
				await createFavourite({
					auth0Id: user?.sub,
					price: propertyData.ListPrice,
					bathrooms: propertyData.BathroomsTotalInteger,
					bedrooms: propertyData.BedroomsTotal,
					name: propertyData.UnparsedAddress,
					street: propertyData.StreetName,
					area: propertyData.MLSAreaMajor,
					images: propertyData.Media?.map((data) => data.MediaURL),
					status: propertyData.MlsStatus,
					mlsId: propertyData.ListingId,
					details: propertyData
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	useCalendlyEventListener({
		onProfilePageViewed: () => console.log("onProfilePageViewed"),
		onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
		onEventTypeViewed: () => console.log("onEventTypeViewed"),
		onEventScheduled: (e) => {
			onInspectionsDone({
				inspectorId: inspector.id,
				userId: userState.userProfile.userId,
				address: propertyData.UnparsedAddress
			});
		}
	});
	const handleDateChange = (index, newValue) => {
		const newDates = [...selectedDates];
		newDates[index] = newValue;
		setSelectedDates(newDates);
	};
	const disableDates = (date) => {
		const sevenDaysFromNow = moment().add(0, "days").startOf("day");
		const eightDaysFromNow = moment().add(7, "days").startOf("day");
		return !moment(date).isBetween(
			sevenDaysFromNow,
			eightDaysFromNow,
			"day",
			"[]"
		);
	};
	const disableTimes = (timeValue, clockType) => {
		if (clockType === "hours") {
			return timeValue < 8 || timeValue > 20;
		}
		if (clockType === "minutes") {
			return timeValue % 15 !== 0;
		}
		return false;
	};
	useEffect(() => {
		const getChats = async () => {
			try {
				const resp = await getUserChatById({
					userId: userState?.userProfile?.userId
				});
				setOldChat(resp.data.data.data.rows);
			} catch (e) {
				console.log(e);
			}
		};
		getChats();
	}, [userState?.userProfile?.userId]);

	useEffect(() => {
		const getInspectors = async () => {
			try {
				const resp = await getAllInspectors({
					postCode: propertyData.PostalCode
				});
				setCalendlyData(resp.data.data.data.rows);
			} catch (e) {
				console.log(e);
			}
		};
		const getUserInspections = async () => {
			try {
				const resp = await getEvaluatedUserInspections({
					userId: userState?.userProfile?.userId,
					address: propertyData.UnparsedAddress
				});
				setInspectionReport(resp.data.data.data.rows[0].inspectionReport);
			} catch (e) {
				console.log(e);
			}
		};
		if (userState?.userProfile?.userId) {
			getInspectors();
			getUserInspections();
		}
	}, [userState?.userProfile?.userId]);

	useEffect(() => {
		window.scrollTo({ top: 600, left: 0, behavior: "smooth" });
		const getProperty = async () => {
			try {
				const resp = await getPropertyById({ mlsId, auth0Id: user?.sub });
				setPropertyData(resp.data.data);
			} catch (e) {}
		};
		if (!state) {
			getProperty();
		} else {
			return;
		}
	}, []);

	const onPropertyForm = () => {
		navigate(`/app/property-form/${mlsId}`, {
			replace: false,
			state: {
				data: propertyData,
				searchLink: searchLink,
				propertyLink: currentURL
			}
		});
	};
	const CalendlyPopup = ({ data, url, prefill, pageSettings, utm }) => {
		const [isOpen, setOpen] = useState(false);
		return (
			<>
				<Button
					variant="contained"
					style={{
						textTransform: "none",
						background: "#003A7B",
						color: "#FFF",
						fontWeight: "bold"
					}}
					onClick={() => {
						setOpen(true);
						setInspector(data);
					}}
				>
					Schedule Inspection
				</Button>
				{
					<PopupModal
						url={url}
						pageSettings={pageSettings}
						utm={utm}
						prefill={prefill}
						onModalClose={() => setOpen(false)}
						open={isOpen}
						rootElement={document.getElementById("root")}
					/>
				}
			</>
		);
	};

	return (
		<div ref={myRef} style={{ width: "100%" }}>
			<Helmet>
				{
					<title>
						{`${
							propertyData?.UnparsedAddress
								? propertyData?.UnparsedAddress
								: "Dashboard"
						}`}
						| Alokee
					</title>
				}
			</Helmet>
			{showBookingWithShowami.state && (
				<TextDialog
					showActions={true}
					hideCancel={true}
					message={showBookingWithShowami.message}
					dialogDescription={showBookingWithShowami.description}
					state={showBookingWithShowami.state}
					hideBackdrop={true}
					stateHandler={setShowBookingWithShowami}
					submitHandler={async () => {
						let values = {};
						setLoading(true);
						values.userId = userState?.userProfile?.userId;
						values.mls = mlsId;
						values.address = propertyData?.UnparsedAddress;
						values.showingTimeOne = selectedDates[0];
						values.showingType = "using-showami";
						values.status = "confirmed";
						values.commission = 0;
						values.auth0Id = user.sub;

						const showing_request = {
							showing_type: 0,
							buyer_name:
								userState?.userProfile?.firstName +
								" " +
								userState?.userProfile?.lastName,
							buyer_email: userState?.userProfile?.email,
							buyer_phone: userState?.userProfile?.contactNumber,
							buyer_type: 0,
							met_buyer: 2,
							price: 100,
							access_information: "Open House",
							time_zone: "Mountain Time (US & Canada)",
							showing_request_properties_attributes: [
								{
									"showing_at(1i)": new Date(selectedDates[0])
										.getFullYear()
										.toString(),
									"showing_at(2i)": (
										new Date(selectedDates[0]).getMonth() + 1
									).toString(),
									"showing_at(3i)": new Date(selectedDates[0])
										.getDate()
										.toString(),
									"showing_at(4i)": new Date(selectedDates[0])
										.getHours()
										.toString(),
									"showing_at(5i)": new Date(selectedDates[0])
										.getMinutes()
										.toString(),
									duration: 1.5,
									mls: mlsId,
									line1: propertyData?.UnparsedAddress,
									line2:
										propertyData?.City +
										" " +
										propertyData?.StateOrProvince +
										" " +
										propertyData?.PostalCode,
									city: propertyData?.City,
									state: propertyData?.StateOrProvince,
									zip: propertyData?.PostalCode,
									who_schedules: 1
								}
							]
						};
						const params = {
							showing_request,
							data: values
						};
						try {
							await createShowingWithShowami(params);
							setToast({
								state: "open",
								message: "Showing appointment booked successfully.",
								type: "success"
							});
						} catch (e) {
							console.log(e);
							setToast({
								state: "open",
								message: e.response.data.errorMessage,
								type: "error"
							});
						} finally {
							setLoading(false);
						}
					}}
					onClose={async () => {}}
					showField={false}
					buttonTitle={"Book"}
					textDialog={true}
					agreementDialog={true}
				>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DateTimePicker
								disablePast={true}
								key={0}
								label={`Select Showing Time`}
								value={selectedDates[0]}
								onChange={(newValue) => handleDateChange(0, newValue)}
								renderInput={(params) => (
									<TextField {...params} margin="normal" />
								)}
								shouldDisableDate={disableDates}
								shouldDisableTime={disableTimes}
								minutesStep={15}
							/>
						</LocalizationProvider>
					</Box>
				</TextDialog>
			)}{" "}
			<Toast {...toast} setState={setToast} />
			<Chat
				oldChat={oldChat}
				uniqueKey={userState?.userProfile?.userId}
				createChat={createUserChat}
				chatForceOpen={chatForceOpen}
				setChatForceOpen={setChatForceOpen}
			/>
			<div>
				{propertyData.constructor === Object ? (
					<Container maxWidth="xl" className={classes.popertyDetailsMainCont}>
						<Typography style={{ marginBottom: "30px" }}>
							<Link
								to={searchLink}
								style={{ textDecoration: "none", color: "#686868" }}
								tabIndex={0}
								aria-label="Search"
							>
								Search {">"}
							</Link>{" "}
							<span style={{ color: "#003A7B" }}>Listing Details</span>
						</Typography>
						<div
							style={{
								position: "relative",
								display: "flex",
								flexDirection: "column"
							}}
						>
							<ImageList variant="quilted" cols={4} rowHeight={121} gap={10}>
								{propertyData?.Media?.slice(
									0,
									showMoreImages
										? propertyData?.Media?.length
										: defaultImageCount
								).map((item, index) => {
									const numCols = index === 0 ? 2 : index % 3 === 1 ? 2 : 1;
									const numRows = index === 0 ? 3 : index % 3 === 1 ? 2 : 1;

									return (
										<ImageListItem
											key={item.MediaURL}
											cols={numCols}
											rows={numRows}
										>
											<img
												{...srcset(item.MediaURL, 121, numRows, numCols)}
												alt={item?.title}
												loading="lazy"
												onClick={() => handleOpenImgModal(index)}
												style={{ cursor: "pointer", borderRadius: "6px" }}
											/>
										</ImageListItem>
									);
								})}
							</ImageList>
							<StatusChip
								alreadyOffered={false}
								chipClass={classes.detailsStatus}
								status={propertyData.MlsStatus}
							/>
							{propertyData && propertyData.Media && (
								<GalleryModal
									images={propertyData.Media}
									open={openImgModal}
									onClose={handleCloseImgModal}
									currentIndex={selectedImageIndex}
									updateIndex={updateIndex}
								/>
							)}
						</div>

						<Grid container className={classes.favouriteGrid} spacing={2}>
							<Grid item xs={12} sm={12} md={6}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="flex-end"
								>
									<Button
										onClick={toggleShowMoreImages}
										variant="outlined"
										style={{
											textTransform: "none",
											color: "#003A7B",
											fontSize: "16px",
											fontWeight: "700"
										}}
									>
										{showMoreImages ? "Show Less Images" : "Show More Images"}
									</Button>
									<Box display="flex">
										<SharePropertyLink mlsid={mlsId} />
										<IconButton
											aria-label="Favourite"
											style={{
												padding: 0,
												backgroundColor: "transparent",
												marginLeft: "20px",
												"&:hover": {
													backgroundColor: "transparent"
												}
											}}
											onClick={() => {
												onMarkFavourite(
													userFavourites.hasOwnProperty(
														extractPropertyId(propertyData["@odata.id"])
													),
													extractPropertyId(propertyData["@odata.id"])
												);
											}}
										>
											{userFavourites.hasOwnProperty(
												extractPropertyId(propertyData["@odata.id"])
											) ? (
												<FavoriteIcon
													fontSize="large"
													style={{
														color: "#02A689"
													}}
												/>
											) : (
												<FavoriteBorderIcon
													fontSize="large"
													style={{
														color: "#003A7B"
													}}
												/>
											)}
										</IconButton>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<Box display="flex" justifyContent="flex-end">
									<MultiButtons
										options={[
											{
												text: "Schedule Showing Immediately",
												onClick: () => {
													setShowBookingWithShowami((prev) => {
														return { ...prev, state: true };
													});
												}
											},
											{
												text: "Request Showing from Seller Agent",
												onClick: () => {
													window.open(
														`/app/book-showings/${mlsId}/${propertyData?.UnparsedAddress}`,
														"_blank"
													);
												}
											}
										]}
										loading={loading}
									></MultiButtons>
									<Button
										onClick={() => {
											onPropertyForm();
										}}
										className={classes.offerBtn}
										fullWidth={smDown ? true : false}
									>
										Make an Offer
									</Button>
									<Button
										onClick={() => {
											setChatForceOpen(true);
										}}
										className={classes.inspectionBtn}
										variant="outlined"
										fullWidth={smDown ? true : false}
									>
										Ask us a question
									</Button>
								</Box>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={4}
							flexDirection="row"
							className={classes.mapGrid}
						>
							<Grid item xs={12} sm={6}>
								{!smDown ? (
									<div>
										<Typography className={classes.largeTitle}>
											${propertyData?.ListPrice?.toLocaleString()}
										</Typography>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
										>
											<div>
												<Amenities
													bathrooms={propertyData?.BathroomsFull}
													bedrooms={propertyData.BedroomsTotal}
													area={propertyData.LivingArea}
													clickHandler={false}
												/>
												<Typography className={classes.detailsText}>
													{propertyData?.UnparsedAddress}
												</Typography>
											</div>
											<img
												width="80"
												height="80"
												src={`https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=250&height=250&center=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]}&zoom=15.9318&marker=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]};color:%23ff0000&apiKey=${process.env.REACT_APP_TYPEAHEAD_SECRET}`}
												alt="Map of location"
											/>
										</Box>
									</div>
								) : (
									<div>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
										>
											<Typography className={classes.largeTitle}>
												${propertyData?.ListPrice?.toLocaleString()}
											</Typography>
											<img
												width="80"
												height="80"
												src={`https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=250&height=250&center=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]}&zoom=15.9318&marker=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]};color:%23ff0000&apiKey=${process.env.REACT_APP_TYPEAHEAD_SECRET}`}
												alt="Map of location"
												style={{ marginRight: "30px" }}
											/>
										</Box>
										<Amenities
											bathrooms={propertyData?.BathroomsFull}
											bedrooms={propertyData.BedroomsTotal}
											area={propertyData.LivingArea}
											clickHandler={false}
										/>
										<Typography className={classes.detailsText}>
											{propertyData?.UnparsedAddress}
										</Typography>
									</div>
								)}
								{propertyData && propertyData.PublicRemarks && (
									<ShowMoreText
										text={propertyData.PublicRemarks}
										maxWords={75}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6}>
								{/* When should we show inspection link and option to schedule an inspection? */}
								{/* {smDown &&
									<Box style={{ marginTop: "60px" }}>
										<IconButton
											size="large"
											onClick={() => {
												setShowCalendly((prev) => {
													return { ...prev, state: true };
												});
											}}
										>
											<Box display="flex" alignItems="center">
												<ContentPasteSearchIcon style={{ fontSize: "4rem", color: "#02A689" }} />
												<Typography className={classes.inspectionBtnText}>
													Schedule an Inspection
												</Typography>
											</Box>
										</IconButton>
										<IconButton
											size="large"
											onClick={() => {
												if (inspectionReport) {
													window.open(inspectionReport);
												}
											}}
											disabled={!inspectionReport}
										>
											<Box display="flex" alignItems="center">
												<TextSnippetIcon style={{ fontSize: "4rem", color: inspectionReport ? "#003A7B" : "#C0C0C0" }} />
												<Typography className={classes.inspectionBtnText} style={{ color: inspectionReport ? "#003A7B" : "#C0C0C0" }}>
													{inspectionReport ? "Download Inspection Report" : "No Inspection Report Available"}
												</Typography>
											</Box>
										</IconButton>
									</Box>
								} */}
							</Grid>
						</Grid>
						<Grid container spacing={4}>
							<Grid item xs={12} sm={12} md={6}>
								<Typography
									className={classes.detailsSubHeading}
									style={{ marginTop: "60px" }}
								>
									Property details
								</Typography>
								<Box className={classes.detailsIconBox}>
									{propertyData && propertyData.LaundryFeatures && (
										<Box display="flex" flexDirection="column">
											<img src={Laundry} alt="laundry" />
											<Typography
												className={classes.detailsText}
												style={{ color: "#003A7B" }}
											>
												Laundry
											</Typography>
										</Box>
									)}
									{propertyData && propertyData.FireplaceYN && (
										<>
											<Box display="flex" flexDirection="column">
												<img src={Fireplace} alt="fireplace" />
												<Typography
													className={classes.detailsText}
													style={{ color: "#003A7B" }}
												>
													Fireplace
												</Typography>
											</Box>
										</>
									)}
									{propertyData && propertyData.CoolingYN && (
										<>
											<Box display="flex" flexDirection="column">
												<img src={CentralCooling} alt="cooling" />
												<Typography
													className={classes.detailsText}
													style={{ color: "#003A7B" }}
												>
													Cooling
												</Typography>
											</Box>
										</>
									)}
								</Box>
								<Box
									className={classes.detailsIconBox}
									style={{ marginTop: "40px" }}
								>
									{Array.isArray(propertyData?.InteriorFeatures) &&
										propertyData.InteriorFeatures.map((feature) => {
											if (interiorFeatureSet.has(feature)) {
												const FeatureImage = featureImageMap[feature];
												if (FeatureImage) {
													return (
														<React.Fragment key={feature}>
															<Box display="flex" flexDirection="column">
																<img src={FeatureImage} alt={feature} />
																<Typography
																	className={classes.detailsText}
																	style={{ color: "#003A7B" }}
																>
																	{feature}
																</Typography>
															</Box>
														</React.Fragment>
													);
												}
											}
											return null;
										})}
								</Box>
								<Grid
									container
									spacing={5}
									style={{ marginTop: smDown ? "50px" : "70px" }}
								>
									<Grid item xs={12} sm={12} md={6}>
										<Typography className={classes.detailsSubHeading}>
											Parking / Garage
										</Typography>
										{propertyData?.GarageSpaces > 0 ? (
											<Typography className={classes.detailsText}>
												{Math.floor(propertyData.GarageSpaces)} Garage Space
												{propertyData?.GarageSpaces > 1 ? "s" : ""}
											</Typography>
										) : (
											<Typography className={classes.detailsText}>
												No Garage
											</Typography>
										)}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<Typography className={classes.detailsSubHeading}>
											Exterior features
										</Typography>
										{Array.isArray(propertyData?.ExteriorFeatures) &&
											propertyData?.ExteriorFeatures.map((feature) => (
												<Typography
													key={feature}
													className={classes.detailsText}
												>
													{feature}
												</Typography>
											))}
										{propertyData && propertyData.PoolPrivateYN && (
											<Typography className={classes.detailsText}>
												Private Pool
											</Typography>
										)}
									</Grid>
								</Grid>
								<Grid container spacing={5} style={{ marginTop: "30px" }}>
									<Grid item xs={12} sm={12} md={6}>
										<Typography className={classes.detailsSubHeading}>
											Lot Details
										</Typography>
										<ul>
											<li
												className={`${classes.detailsListItem} ${classes.detailsText}`}
											>
												{propertyData?.LotSizeSquareFeet} Square Feet
											</li>
											{Array.isArray(propertyData?.LotFeatures) &&
												propertyData?.LotFeatures.map((feature) => (
													<li
														className={`${classes.detailsListItem} ${classes.detailsText}`}
														key={feature}
													>
														{feature}
													</li>
												))}
										</ul>
										{propertyData?.ListingId && (
											<Typography style={{ marginTop: "30px" }}>
												<span className={classes.detailsSubHeading}>
													Listing ID:{" "}
												</span>
												<span style={{ color: "#003A7B" }}>
													{propertyData.ListingId}
												</span>
											</Typography>
										)}
										{propertyData?.ParcelNumber && (
											<Typography>
												<span className={classes.detailsSubHeading}>
													Parcel #:{" "}
												</span>
												<span style={{ color: "#003A7B" }}>
													{propertyData.ParcelNumber}
												</span>
											</Typography>
										)}
										{propertyData?.Zoning && (
											<Typography>
												<span className={classes.detailsSubHeading}>
													Zoning:{" "}
												</span>
												<span style={{ color: "#003A7B" }}>
													{propertyData.Zoning}
												</span>
											</Typography>
										)}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<Typography className={classes.detailsSubHeading}>
											Schools in the neighborhood
										</Typography>
										<Typography
											style={{
												color: "#000",
												fontSize: "1.125rem",
												fontWeight: "Bold"
											}}
										>
											Elementary School
										</Typography>
										{propertyData?.ElementarySchool ? (
											<Typography
												className={classes.detailsText}
												style={{ marginBottom: "20px" }}
											>
												{propertyData.ElementarySchool}
											</Typography>
										) : (
											<Typography
												className={classes.detailsText}
												style={{ marginBottom: "20px" }}
											>
												None
											</Typography>
										)}
										<Typography
											style={{
												color: "#000",
												fontSize: "1.125rem",
												fontWeight: "Bold"
											}}
										>
											Middle School
										</Typography>
										{propertyData?.MiddleOrJuniorSchool ? (
											<Typography
												className={classes.detailsText}
												style={{ marginBottom: "20px" }}
											>
												{propertyData.MiddleOrJuniorSchool}
											</Typography>
										) : (
											<Typography
												className={classes.detailsText}
												style={{ marginBottom: "20px" }}
											>
												None
											</Typography>
										)}
										<Typography
											style={{
												color: "#000",
												fontSize: "1.125rem",
												fontWeight: "Bold"
											}}
										>
											High School
										</Typography>
										{propertyData?.HighSchool ? (
											<Typography className={classes.detailsText}>
												{propertyData.HighSchool}
											</Typography>
										) : (
											<Typography
												className={classes.detailsText}
												style={{ marginBottom: "20px" }}
											>
												None
											</Typography>
										)}
									</Grid>
								</Grid>
								<Grid container style={{ marginTop: "100px" }}>
									<Grid item xs={6} sm={4} md={4}>
										<img
											className={classes.agentImage}
											src={agentImage}
											alt="agent image"
										></img>
									</Grid>
									<Grid item xs={6} sm={8} md={4}>
										<Box className={classes.agentBox}>
											<div>
												<Typography
													style={{
														color: "#003A7B",
														fontSize: "1.125rem",
														fontWeight: "Bold"
													}}
												>
													Listed By:
												</Typography>
												<Typography className={classes.detailsText}>
													{propertyData.ListAgentFirstName}{" "}
													{propertyData.ListAgentLastName}
												</Typography>
											</div>
											<div>
												<Typography
													style={{
														color: "#003A7B",
														fontSize: "1.125rem",
														fontWeight: "Bold"
													}}
												>
													Office ID:
												</Typography>
												<Typography className={classes.detailsText}>
													{propertyData?.ListOfficeMlsId}
												</Typography>
											</div>
											<div>
												<Typography
													style={{
														color: "#003A7B",
														fontSize: "1.125rem",
														fontWeight: "Bold",
														textDecoration: "underline"
													}}
												>
													<Link
														to={
															window.location.pathname.startsWith("/app")
																? "/app/financing"
																: "/financing"
														}
														color="primary"
														style={{
															color: "#003A7B"
														}}
													>
														Compare Financing
													</Link>
												</Typography>
											</div>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							{/* When should we show inpection report and option to schedule an inspection? */}
							{/* {!smDown &&
								<Grid item xs={12} sm={12} md={6}>
									<Box style={{ marginTop: "60px" }}>
										<IconButton
											style={{ paddingTop: 0 }}
											size="large"
											onClick={() => {
												setShowCalendly((prev) => {
													return { ...prev, state: true };
												});
											}}
										>
											<Box display="flex" alignItems="center">
												<ContentPasteSearchIcon style={{ fontSize: "4rem", color: "#02A689" }} />
												<Typography className={classes.inspectionBtnText}>
													Schedule an Inspection
												</Typography>
											</Box>
										</IconButton>
										<IconButton
											size="large"
											onClick={() => {
												if (inspectionReport) {
													window.open(inspectionReport);
												}
											}}
											disabled={!inspectionReport}
										>
											<Box display="flex" alignItems="center">
												<TextSnippetIcon style={{ fontSize: "4rem", color: inspectionReport ? "#003A7B" : "#C0C0C0" }} />
												<Typography className={classes.inspectionBtnText} style={{ color: inspectionReport ? "#003A7B" : "#C0C0C0" }}>
													{inspectionReport ? "Download Inspection Report" : "No Inspection Report Available"}
												</Typography>
											</Box>
										</IconButton>
									</Box>
								</Grid>
							} */}
						</Grid>
					</Container>
				) : (
					<Stack className={classes.loader}>
						<Loader type={"Circles"}></Loader>
					</Stack>
				)}
				{showCalendly.state && (
					<Dialog
						open={showCalendly.state}
						onClose={async () => {
							setShowCalendly({ state: false, message: "" });
						}}
						maxWidth="lg"
						fullWidth
					>
						<DialogTitle style={{ textAlign: "center", marginBottom: "30px" }}>
							<Typography className={classes.inspectionModelTitle}>
								Schedule an Inspection With
							</Typography>
						</DialogTitle>
						<DialogContent
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Box>
								<Grid
									container
									flexDirection="row"
									justifyContent="center"
									alignItems="center"
									spacing={2}
								>
									{!!calendlyData.length ? (
										calendlyData.map((data) => {
											return (
												<Grid item key={data.id} xs={12} sm={12} md={4}>
													<Paper
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
															justifyContent: "center",
															padding: "16px"
														}}
													>
														<Box
															style={{
																display: "flex",
																flexDirection: "column",
																alignItems: "center",
																justifyContent: "center"
															}}
														>
															<Typography
																style={{ marginBottom: "8px" }}
																className={classes.detailsText}
															>
																{data.name}
															</Typography>
															<Typography className={classes.detailsText}>
																{data.postCode}
															</Typography>
														</Box>
														<Box mt={5}>
															<CalendlyPopup
																prefill={{
																	email: userState?.userProfile?.email,
																	firstName: userState?.userProfile?.firstName,
																	lastName: userState?.userProfile?.lastName,
																	name:
																		userState?.userProfile.firstName +
																		" " +
																		userState?.userProfile.lastName,
																	customAnswers: {
																		a1: `Meeting for the Inspection of ${propertyData.UnparsedAddress} `
																	}
																}}
																data={data}
																url={data.calendlyLink}
																rootElement={document.getElementById("root")}
															></CalendlyPopup>
														</Box>
													</Paper>
												</Grid>
											);
										})
									) : (
										<Typography
											className={classes.detailsText}
											style={{ marginTop: "10px", textAlign: "center" }}
										>
											No Inspectors are available for this property.
										</Typography>
									)}
								</Grid>
							</Box>
						</DialogContent>
					</Dialog>
				)}
			</div>
			<Footer />
		</div>
	);
}

export default PropertyDetails;
