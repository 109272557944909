import React, { useState } from "react";
import {
	makeStyles,
	InputLabel,
	Button,
	Typography,
	Grid
} from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const ListItem = styled("li")(({ theme }) => ({
	// margin: theme.spacing(0.5)
}));

const useStyles = makeStyles((theme) => ({
	button: {
		borderRadius: 4,
		textTransform: "none",
		fontWeight: theme.typography.fontWeightSemiBold,
		"&:hover": {
			backgroundColor: "#E5E5E5"
		},
		padding: "13px 20px",
		background: "#FFF",
		color: theme.palette.primary.main,
		fontSize: "1rem",
		border: "1px solid #003A7B"
	},
	buttonUpload: {
		backgroundColor: theme.palette.primary.main,
		color: "#FFF"
	},

	errorColor: {
		color: "#FF0000"
	},
	imageView: {
		color: theme.palette.primary.main,
		cursor: "pointer",
		"&:hover": {
			color: "#000000"
		}
	},
	delete: {
		color: theme.palette.primary.main,
		cursor: "pointer",
		"&:hover": {
			color: "#000000"
		},
		marginLeft: 15
	},
	uploadInput: {
		"& .MuiInputBase-input": {
			paddingTop: "16px",
			paddingBottom: "16px"
		}
	}
}));
function FileUploadMultiple({
	onFileSelectError,
	label,
	name,
	value,
	disabled,
	required,
	max
}) {
	const classes = useStyles();
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);
	const [uploaded, setUploaded] = useState(false);
	const [inputs, setInputs] = useState([]);
	const [inputKey, setInputKey] = useState(0);

	let tempArray = [];

	useEffect(() => {
		if (field.value) {
			setUploaded(true);
			return;
		}
	}, []);

	const configFormControl = {};
	if (meta && meta.touched && meta.error) {
		configFormControl.error = true;
	}

	useEffect(() => {
		if (inputs.length == 0) {
			setUploaded(false);
		}
	}, [inputs]);

	const onDelete = (fileName) => {
		setInputs((prev) => {
			return inputs.filter((obj) => {
				return obj.name !== fileName;
			});
		});
		setFieldValue(
			name,
			inputs.filter((obj) => {
				return obj.name !== fileName;
			})
		);

		// Allows deletion and re-upload of same file
		setInputKey((prevKey) => {
			if (prevKey >= 10) {
				// Prevent from getting too large
				return 0;
			} else {
				return prevKey + 1;
			}
		});
	};

	const handleFileInput = (e) => {
		const { files } = e.target;
		if (files.length > max) {
			onFileSelectError({
				error: `You cannot upload more than ${max} files. If you have more files than ${max}, please merge them into a single PDF and upload it.`
			});
			return;
		}
		if (inputs.length == max) {
			onFileSelectError({
				error: `You cannot upload more than ${max} files. If you have more files than ${max}, please merge them into a single PDF and upload it.`
			});
			return;
		}
		for (let i = 0; i < files.length; i++) {
			if (files[i].size > 5048000) {
				onFileSelectError({ error: "File size cannot exceed more than 5MB" });
				continue;
			}
			tempArray.push(files[i]);
		}
		setInputs((prev) => {
			if (prev && prev.length) {
				tempArray = [...prev, ...tempArray];
				return [...tempArray];
			} else {
				return [...tempArray];
			}
		});

		setFieldValue(name, tempArray);
		setUploaded(true);
	};

	const makeArray = (value) => {
		let data = [];

		if (value.length > 0) {
			let obj;
			if (!value[0].name) {
				obj = JSON.parse(value);
			} else {
				obj = value;
			}
			for (let i = 0; i < obj.length; i++) {
				data.push(obj[i]);
			}
		}

		return data;
	};

	const makeName = (data) => {
		return data.name
			? data.name
			: decodeURI(data.split("/")[6].split(".")[0])
					.split("-")[1]
					.split("%")[0] +
					"." +
					data.slice(data.length - 3, data.length);
	};

	return (
		<div>
			<InputLabel
				style={{
					paddingBottom: 10,
					color: "#003A7B",
					fontSize: "1.125rem",
					fontWeight: "700"
				}}
				htmlFor={label}
				required={required}
			>
				{label}
			</InputLabel>
			<Grid container direction="row" alignItems="flex-start" spacing={2}>
				<Grid item xs={12} sm={12} md={6}>
					{value && !!makeArray(value).length ? (
						makeArray(value).map((data, i) => {
							let shouldShowTagFacesIcon = false;

							if (data.label === "React") {
								shouldShowTagFacesIcon = true;
							}

							return (
								<ListItem key={i}>
									<TextField
										id={label}
										className={classes.uploadInput}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<IconButton
														onClick={() => {
															onDelete(data.name);
														}}
														aria-label="Delete"
													>
														<CancelIcon
															style={{ color: "#003A7B" }}
															fontSize="large"
														/>
													</IconButton>
												</InputAdornment>
											),
											endAdornment: shouldShowTagFacesIcon && (
												<InputAdornment position="end">
													<TagFacesIcon style={{ color: "#003A7B" }} />
												</InputAdornment>
											)
										}}
										readOnly
										value={makeName(data)}
										fullWidth={true}
									/>
									<Typography
										variant="caption"
										color="#FF0000"
										className={classes.errorColor}
									>
										{meta && meta.touched && meta.error
											? "Please upload required File"
											: ""}
									</Typography>
								</ListItem>
							);
						})
					) : (
						<ListItem>
							<TextField
								id={label}
								className={classes.uploadInput}
								value="No file selected"
								readOnly
								fullWidth={true}
							/>
							<Typography
								variant="caption"
								color="#FF0000"
								className={classes.errorColor}
							>
								{meta && meta.touched && meta.error
									? "Please upload required File"
									: ""}
							</Typography>
						</ListItem>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<Button
						className={[classes.button, uploaded ? classes.buttonUpload : ""]}
						disabled={!!disabled}
						component="label"
						variant="contained"
						fullWidth={false}
						startIcon={<AttachFileIcon />}
					>
						{uploaded ? "Document Attached" : "Attach a Document"}
						<input
							key={inputKey}
							multiple
							hidden
							type="file"
							className={classes.input}
							onChange={handleFileInput}
							accept="image/jpg,application/pdf,image/jpeg,image/png"
						/>
					</Button>
				</Grid>
			</Grid>
		</div>
	);
}

export default FileUploadMultiple;
