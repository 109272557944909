import axios from "axios";
require("dotenv").config();

export const getAddresses = async (params) => {
	return axios.get(
		`https://api.geoapify.com/v1/geocode/autocomplete?text=${params.text}&format=json&apiKey=${process.env.REACT_APP_TYPEAHEAD_SECRET}`
	);
};
export const getParsedAddress = async (params) => {
	return axios.get(
		`https://api.geoapify.com/v1/geocode/search?text=${params.text}&format=json&apiKey=${process.env.REACT_APP_TYPEAHEAD_SECRET}`
	);
};
