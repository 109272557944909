import React, { useEffect } from "react";
import { useFormikContext } from "formik";

function RevisedForm({}) {
	const {
		values: {
			removalOfAllContingenciesRevised,
			loanContingencyRevised,
			appraisalContingencyRevised,
			investigationContingencyRevised,
			sellerDocumentsReviewRevised,
			titleReportReviewRevised,
			contingentOfferRevised
		},
		setFieldValue
	} = useFormikContext();

	useEffect(() => {
		if (removalOfAllContingenciesRevised) {
			setFieldValue(
				"loanContingencyRevised",
				!removalOfAllContingenciesRevised
			);
			setFieldValue(
				"appraisalContingencyRevised",
				!removalOfAllContingenciesRevised
			);
			setFieldValue(
				"investigationContingencyRevised",
				!removalOfAllContingenciesRevised
			);
			setFieldValue(
				"sellerDocumentsReviewRevised",
				!removalOfAllContingenciesRevised
			);
			setFieldValue(
				"titleReportReviewRevised",
				!removalOfAllContingenciesRevised
			);
			setFieldValue(
				"contingentOfferRevised",
				!removalOfAllContingenciesRevised
			);
		}
	}, [removalOfAllContingenciesRevised]);
	useEffect(() => {
		if (
			loanContingencyRevised == true ||
			appraisalContingencyRevised == true ||
			investigationContingencyRevised == true ||
			sellerDocumentsReviewRevised == true ||
			titleReportReviewRevised == true ||
			contingentOfferRevised == true
		) {
			setFieldValue("removalOfAllContingenciesRevised", false);
		}
	}, [
		loanContingencyRevised,
		appraisalContingencyRevised,
		investigationContingencyRevised,
		sellerDocumentsReviewRevised,
		titleReportReviewRevised
	]);
	return <div></div>;
}

export default RevisedForm;
