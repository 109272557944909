import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Stepper,
	Step,
	StepLabel,
	Typography,
	Checkbox,
	FormControlLabel,
	TextField,
	Container,
	Paper,
	RadioGroup,
	Radio,
	Slider,
	Avatar,
	Tooltip,
	CircularProgress
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { makeStyles } from "@material-ui/core";
import TextDialog from "../components/core/TextDialog";
import { useNavigate, useParams } from "react-router";
import { InfoOutlined } from "@material-ui/icons";
import { useUserState } from "../context/UserContext";
import { createShowing } from "../services/userShowings";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Toast } from "../components/core";
const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: "1.25rem",
		position: "relative",
		top: theme.spacing.unit - 2,
		color: "#686868",
		marginLeft: "8px"
	},
	root: {
		paddingTop: "40px",
		paddingBottom: "40px"
	},
	showingContainer: {
		[theme.breakpoints.down("sm")]: {
			height: "370px !important"
		}
	},
	showingPaperContainer: {
		[theme.breakpoints.down("sm")]: {
			height: "570px !important"
		}
	},
	percent: {
		color: "#003A7B",
		fontWeight: "700"
	},
	customSlider: {
		"& .MuiSlider-markLabelActive": {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightBold
		}
	},
	button: {
		padding: "10px 18px",
		background: "#003A7B !important",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		fontSize: "1rem",
		lineHeight: "normal",
		color: "#FFFFFF",
		textTransform: "none",
		width: 200,
		"&:hover": {
			color: "#000",
			backgroundColor: `${theme.palette.primary.white} !important`
		},
		[theme.breakpoints.down("sm")]: {
			width: 100,
			fontSize: 14,
			padding: "5px 7px"
		},
		[theme.breakpoints.down("md")]: {
			width: 120,
			fontSize: 16,
			padding: "5px 7px"
		},
		[theme.breakpoints.between("sm", "xs")]: {
			width: "100px !important",
			fontSize: "12px !important",
			padding: "5px 7px !important"
		}
	},
	backButton: {
		padding: "10px 18px",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		fontSize: "1rem",
		lineHeight: "normal",
		width: 200,
		color: "#000 !important",
		textTransform: "none",
		border: "1px solid #fff",
		backgroundColor: `${theme.palette.primary.white} !important`,
		"&:hover": {
			color: "#003A7B",
			backgroundColor: `${theme.palette.primary.white} !important`
		},
		[theme.breakpoints.down("sm")]: {
			width: 100,
			fontSize: 14,
			padding: "5px 7px"
		},
		[theme.breakpoints.down("md")]: {
			width: 120,
			fontSize: 16,
			padding: "5px 7px"
		},
		[theme.breakpoints.down("xs")]: {
			width: "100px !important",
			fontSize: "12px !important",
			padding: "5px 7px !important"
		}
	},
	formattedValue: {
		color: "#000"
	}
}));

const marks = [
	{
		value: 0,
		label: "0%"
	},

	{
		value: 1,
		label: "1%"
	},
	{
		value: 2,
		label: "2%"
	},
	{
		value: 3,
		label: "3%"
	}
];

const Showings = () => {
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [selectedDates, setSelectedDates] = useState([null, null, null]);
	const [selectedAgreement, setSelectedAgreement] = useState("using-showami");
	const [commission, setCommission] = useState(0);
	const navigate = useNavigate();
	const { mlsId, address } = useParams();
	const [showConfirmOfferSent, setShowConfirmOfferSent] = useState({
		message: "",
		state: false
	});
	const [loading, setLoading] = useState(false);
	const { user } = useAuth0();
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});

	const userState = useUserState();

	const [signedAgreement, setSignedAgreement] = useState({
		message: "",
		state: false
	});
	const handleDateChange = (index, newValue) => {
		const newDates = [...selectedDates];
		newDates[index] = newValue;
		setSelectedDates(newDates);
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setSelectedDates([null, null, null]);
		setSelectedAgreement("");
	};
	const onBookShowing = async () => {
		setLoading(true);
		let values = {};
		values.userId = userState?.userProfile?.userId;
		values.mls = mlsId;
		values.address = address;
		values.showingTimeOne = selectedDates[0];
		values.showingTimeTwo = selectedDates[1];
		values.showingTimeThree = selectedDates[2];
		values.showingType = selectedAgreement;
		values.commission = commission;
		values.auth0Id = user.sub;
		try {
			await createShowing(values);
			setLoading(false);
			setToast({
				state: "open",
				message: "Showing appointment booked successfully.",
				type: "success"
			});
			setShowConfirmOfferSent((prevState) => {
				return {
					message:
						"Seller Agent has been notified you have requested a showing. We will update you with their response shortly.",
					state: true
				};
			});
		} catch (e) {
			setLoading(false);
			console.log(e);
			setToast({
				state: "open",
				message: e,
				type: "error"
			});
		}
	};
	useEffect(() => {
		window.scrollTo(0, 500);
	}, []);
	const handleSubmit = async () => {
		// Handle form submission

		if (selectedAgreement == "using-seller-agent") {
			setSignedAgreement((prevState) => {
				return {
					message: "Agreement will be here which will be signed by user.",
					description:
						"Contact Alokee if you have any questions or concerns you wish to discuss",
					state: true
				};
			});
		} else {
			await onBookShowing();
		}
	};
	const disableDates = (date) => {
		const sevenDaysFromNow = moment().add(0, "days").startOf("day");
		const eightDaysFromNow = moment().add(7, "days").startOf("day");
		return !moment(date).isBetween(
			sevenDaysFromNow,
			eightDaysFromNow,
			"day",
			"[]"
		);
	};
	const disableTimes = (timeValue, clockType) => {
		if (clockType === "hours") {
			return timeValue < 8 || timeValue > 20;
		}
		if (clockType === "minutes") {
			return timeValue % 15 !== 0;
		}
		return false;
	};
	const steps = ["Select Showing Times", "Select Agreement"];

	return (
		<div
			style={{
				paddingTop: 25,
				height: "600px"
			}}
		>
			<Helmet>
				<title>Book Showing | {address}</title>
			</Helmet>{" "}
			<Toast {...toast} setState={setToast} />;
			<Container maxWidth="lg">
				{signedAgreement.state && (
					<TextDialog
						showActions={true}
						showAvatar={true}
						Avatar={
							<Avatar
								style={{
									top: 10,
									marginBottom: 10,
									flex: 1,
									alignContent: "center",
									alignSelf: "center",
									backgroundColor: "#FFFF",
									color: "#000000"
								}}
							>
								<InfoOutlined />
							</Avatar>
						}
						message={signedAgreement.message}
						dialogDescription={signedAgreement.description}
						state={signedAgreement.state}
						hideBackdrop={true}
						stateHandler={setSignedAgreement}
						submitHandler={async () => {
							await onBookShowing();
						}}
						onClose={async () => {}}
						showField={false}
						buttonTitle={"I Agree"}
						textDialog={true}
						agreementDialog={true}
					></TextDialog>
				)}
				{showConfirmOfferSent.state && (
					<TextDialog
						showActions={true}
						message={showConfirmOfferSent.message}
						state={showConfirmOfferSent.state}
						stateHandler={setShowConfirmOfferSent}
						submitHandler={() => {
							navigate(`/app/property-details/${mlsId}`, {
								replace: true
							});
						}}
						onClose={() => {
							navigate(`/app/property-details/${mlsId}`, {
								replace: true
							});
						}}
						showField={false}
					></TextDialog>
				)}
				<Box sx={{ width: "100%" }}>
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => (
							<Step key={index}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					{activeStep === steps.length ? (
						<Box sx={{ mt: 2, mb: 1 }}>
							<Typography>All steps completed</Typography>
							<Button onClick={handleReset}>Reset</Button>
						</Box>
					) : (
						<Container
							maxWidth="md"
							style={{
								padding: 10
							}}
						>
							<Paper
								style={{
									padding: 10,
									height: 470,
									backgroundColor: "#FAF9F6"
								}}
								className={classes.showingPaperContainer}
							>
								<Box
									sx={{ mt: 2, mb: 1 }}
									style={{
										padding: 10,
										height: 280,
										backgroundColor: "#FAF9F6"
									}}
									className={classes.showingContainer}
								>
									{activeStep === 0 && (
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<Box
												display="flex"
												flexDirection="column"
												alignItems="center"
											>
												<Typography variant="h6">
													Select Three Potential Showing Times
												</Typography>
												{selectedDates.map((date, index) => (
													<DateTimePicker
														disablePast={true}
														key={index}
														label={`Showing Time ${index + 1}`}
														value={date}
														onChange={(newValue) =>
															handleDateChange(index, newValue)
														}
														renderInput={(params) => (
															<TextField {...params} margin="normal" />
														)}
														shouldDisableDate={disableDates}
														shouldDisableTime={disableTimes}
														minutesStep={15}
													/>
												))}
											</Box>
										</LocalizationProvider>
									)}
									{activeStep === 1 && (
										<Box
											display="flex"
											flexDirection="column"
											alignItems="center"
											style={{
												marginTop: 10
											}}
										>
											<Box
												display="flex"
												flexDirection="column"
												alignItems="flex-start"
												style={{
													marginTop: 10
												}}
											>
												<Typography variant="h6">
													From which method do you want to schedule showing?
												</Typography>

												<RadioGroup
													aria-label="buyer-agreement"
													name="buyer-agreement"
													value={selectedAgreement}
													onChange={(e) => setSelectedAgreement(e.target.value)}
												>
													<FormControlLabel
														value="using-seller-agent"
														control={<Radio />}
														label="Use Seller Agent as buying service and will pay commission. If you buy this home."
													/>
													{selectedAgreement == "using-seller-agent" && (
														<div style={{ marginLeft: "10px" }}>
															<Typography
																variant={"body2"}
																id="input-slider"
																gutterBottom
																style={{
																	fontSize: "1.125rem",
																	color: "#003A7B",
																	fontWeight: "700"
																}}
															>
																Commission
																<Tooltip
																	title={
																		"This fee will be added to the purchase price of the home if you buy it. And if you do not buy this home, no fee is owed."
																	}
																>
																	<InfoOutlined className={classes.icon} />
																</Tooltip>
															</Typography>
															<Slider
																className={classes.customSlider}
																marks={marks}
																step={0.1}
																min={0}
																max={3}
																value={commission}
																valueLabelDisplay="auto"
																name="commission"
																label="Commission"
																required
																onChange={(event, value) => {
																	setCommission(value);
																}}
																getAriaValueText={(value) => `${value}%`} // custom value text
															/>
														</div>
													)}
													<FormControlLabel
														value="not-using-seller-agent"
														control={<Radio />}
														label="Not using Seller Agent as a buying service but will pay $50 for the showing."
													/>
												</RadioGroup>
											</Box>
										</Box>
									)}
								</Box>
								<Box display="flex" justifyContent="space-between" mt={10}>
									{activeStep > 0 && (
										<Button
											className={classes.backButton}
											disabled={activeStep === 0}
											onClick={handleBack}
										>
											Back
										</Button>
									)}
									{activeStep < steps.length - 1 ? (
										<Button
											variant="contained"
											color="primary"
											onClick={handleNext}
											disabled={selectedDates.includes(null)}
											className={classes.button}
										>
											Next
										</Button>
									) : (
										<Button
											variant="contained"
											color="primary"
											onClick={handleSubmit}
											className={classes.button}
											disabled={loading}
										>
											Done
											{loading && <CircularProgress size={20} />}
										</Button>
									)}
								</Box>
							</Paper>
						</Container>
					)}
				</Box>
			</Container>
		</div>
	);
};

export default Showings;
