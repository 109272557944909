import { useFormikContext } from "formik";
import { useEffect } from "react";

const AutoSubmitCode = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if (values.code.length === 6) {
      submitForm();
    }
  }, [values]);
  return null;
};
export default AutoSubmitCode;
