import { Avatar, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import chat from "../../assets/chat.svg";
import ChatBox from "./ChatBox";
import useStyles from "./style";
import { Badge } from "@mui/material";
import { socket } from "../../services/socket";

function Chat({
	uniqueKey,
	oldChat,
	createChat,
	chatForceOpen,
	setChatForceOpen,
	setLoadChats
}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [badge, setBadge] = React.useState(0);
	const handleOpen = () => {
		setOpen(true);
		setLoadChats((prev) => !prev);
	};

	useEffect(() => {
		if (chatForceOpen == true) {
			setOpen(true);
		}
	}, [chatForceOpen]);

	const handleClose = () => {
		setOpen(false);
		if (setChatForceOpen) {
			setChatForceOpen(false);
		}
	};
	return (
		<div className={classes.root}>
			<Badge
				badgeContent={badge}
				variant="dot"
				color="primary"
				anchorOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
			>
				<IconButton
					aria-haspopup="true"
					color="inherit"
					onClick={() => {
						handleOpen();
						setBadge(0);
					}}
					style={{
						backgroundColor: "#003a7b"
					}}
				>
					<Avatar alt="chat" src={chat} className={classes.image} />
				</IconButton>
			</Badge>
			<ChatBox
				uniqueKey={uniqueKey}
				oldChat={oldChat}
				socket={socket}
				setBadge={setBadge}
				open={open}
				handleClose={handleClose}
				createChat={createChat}
			/>
		</div>
	);
}

export default Chat;
