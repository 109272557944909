import React, { useEffect } from "react";
import { useFormikContext } from "formik";

function RevisedForm({}) {
	const {
		values: {
			removalOfAllContingencies,
			loanContingency,
			appraisalContingency,
			investigationContingency,
			sellerDocumentsReview,
			titleReportReview,
			contingentOffer
		},
		setFieldValue
	} = useFormikContext();

	useEffect(() => {
		if (removalOfAllContingencies) {
			setFieldValue("loanContingency", !removalOfAllContingencies);
			setFieldValue("appraisalContingency", !removalOfAllContingencies);
			setFieldValue("investigationContingency", !removalOfAllContingencies);
			setFieldValue("sellerDocumentsReview", !removalOfAllContingencies);
			setFieldValue("titleReportReview", !removalOfAllContingencies);
			setFieldValue("contingentOffer", !removalOfAllContingencies);
		}
	}, [removalOfAllContingencies]);
	useEffect(() => {
		if (
			loanContingency == true ||
			appraisalContingency == true ||
			investigationContingency == true ||
			sellerDocumentsReview == true ||
			titleReportReview == true ||
			contingentOffer == true
		) {
			setFieldValue("removalOfAllContingencies", false);
		}
	}, [
		loanContingency,
		appraisalContingency,
		investigationContingency,
		sellerDocumentsReview,
		titleReportReview
	]);
	return <div></div>;
}

export default RevisedForm;
