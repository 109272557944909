import React from "react";
import { Avatar, Grid, Typography, Container, Tooltip } from "@mui/material";
import useStyles from "./style";
import Logo from "../../assets/Logo.png";

function Message({ text, userDetails, data, fullView }) {
	const classes = useStyles();

	return (
		<div className={classes.messageContainer}>
			{data.from == userDetails?.userProfile?.firstName ? (
				<Grid
					container
					direction="row"
					alignItems="center"
					justifyContent={"flex-end"}
				>
					<Grid item className={classes.messageRight} xs={12}>
						<Tooltip title={data?.url?.split("/")[3]}>
							<Typography variant={"caption"} className={classes.subHeading}>
								{text}
							</Typography>
						</Tooltip>
					</Grid>
				</Grid>
			) : (
				<Grid container direction="row" alignItems="center">
					<Grid
						item
						xs={2}
						sm={2}
						lg={fullView ? 1 : 2}
						xl={fullView ? 1 : 2}
						md={fullView ? 1 : 2}
					>
						<Avatar src={Logo} />
					</Grid>
					<Grid item direction="column" className={classes.message}>
						<Grid item>
							<Tooltip title={data?.url?.split("/")[3]}>
								<Typography variant={"caption"} className={classes.subHeading}>
									{text}
								</Typography>
							</Tooltip>
						</Grid>
						<Grid item>
							<Typography
								variant={"caption"}
								style={{ color: "#000" }}
								className={classes.subHeading}
							>
								{data?.url?.split("/")[3] != "admin"
									? data?.url?.split("/")[3]
									: ""}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
		</div>
	);
}

export default Message;
