import axiosInstance from ".";

export const getAllUserPropertiesByUserId = async (params) => {
	return axiosInstance.post(`/propertyForm/getAllUserPropertiesByUserId`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};
export const createPropertyForm = async (params, mlsId, auth0Id) => {
	return axiosInstance.post(`/propertyForm/createPropertyForm`, params, {
		params: {
			mlsId,
			auth0Id
		},
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};

export const uploadFile = async (params) => {
	return axiosInstance.post(`/propertyForm/uploadFile`, params, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};

export const sendEmail = async (params) => {
	return axiosInstance.post(`/email/sendEmail`, params);
};
export const sendEmailToAlokee = async (params) => {
	return axiosInstance.post(`/email/sendEmailToAlokee`, params);
};
export const sendShowingEmailToBuyerAndSeller = async (params) => {
	return axiosInstance.post(`/email/sendShowingEmailToBuyerAndSeller`, params);
};

export const sendEmailToRequestDisclosure = async (params) => {
	return axiosInstance.post(`/email/sendEmailToRequestDisclosure`, params);
};
export const getPropertyDetailsById = async (params) => {
	return axiosInstance.post(`/propertyForm/getPropertyDetailsById`, {
		...params
	});
};

export const updateUserProperties = async (params) => {
	return axiosInstance.post(`/propertyForm/updateUserProperties`, {
		...params
	});
};

export const getArchivedProperties = async (params) => {
	return axiosInstance.post(`/propertyForm/getArchivedProperties`, {
		...params
	});
};

export const updateSellerInfo = async (params) => {
	return axiosInstance.post(`/userPropertyEnvelope/updateSellerInfo`, {
		...params
	});
};

export const updatePropertyForm = async (params, mlsId, auth0Id) => {
	return axiosInstance.post(`/propertyForm/updatePropertyForm`, params, {
		params: {
			mlsId,
			auth0Id
		},
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};

export const updateTermsAcceptedStatus = async (params) => {
	return axiosInstance.post(`/propertyForm/updateTermsAcceptedStatus`, params);
};

export const getAllUserPropertyFormById = async (params) => {
	return axiosInstance.post(`/propertyForm/getAllUserPropertyFormById`, {
		id: params.id,
		auth0Id: params.auth0Id
	});
};

export const getAllUsersProperties = async (params) => {
	return axiosInstance.post(`/propertyForm/getAllUsersProperties`, {
		limit: params.limit,
		offset: params.offset,
		filters: params.filters,
		auth0Id: params.auth0Id
	});
};

export const removePropertyForm = async (params) => {
	return axiosInstance.post(`/propertyForm/deletePropertyForm`, {
		id: params.id,
		auth0Id: params.auth0Id
	});
};

export const updateUserPropertyStatus = async (params) => {
	return axiosInstance.post(`/propertyForm/updatePropertyStatus`, {
		id: params.id,
		status: params.status,
		auth0Id: params.auth0Id
	});
};

export const createUserPropertyEnvelope = async (params) => {
	return axiosInstance.post(
		`/userPropertyEnvelope/createUserPropertyEnvelope`,
		{
			...params
		}
	);
};
export const updateUserPropertyEnvelopeStatus = async (params) => {
	return axiosInstance.post(
		`/userPropertyEnvelope/updateUserPropertyEnvelopeStatus`,
		{
			...params
		}
	);
};

export const updateUserPropertyEnvelopeSignedBy = async (params) => {
	return axiosInstance.post(
		`/userPropertyEnvelope/updateUserPropertyEnvelopeSignedBy`,
		{
			...params
		}
	);
};
