import { Container, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { Loader } from "../components/core";
import { createEnvelope, eSign } from "../services/eSigning";

import Cookies from "universal-cookie";
import { createUserPropertyEnvelope } from "../services/propertyForm";
import { useAuth0 } from "@auth0/auth0-react";
const cookies = new Cookies();
const useStyles = makeStyles((theme) => {
	return {
		root: {
			position: "relative",
			width: "100%",
			overflow: "hidden",
			paddingTop: "100%"
		},
		responsiveIframe: {
			position: "absolute",
			position: "fixed",
			top: 80,
			left: 0,
			bottom: 0,
			right: 0,
			width: "100%",
			height: "85%",
			border: "none",
			overflow: "hidden"
		},
		loader: {
			position: "fixed",
			left: "50%",
			top: "50%"
		}
	};
});

function SigningPage({ route }) {
	const classes = useStyles();
	const [url, setUrl] = useState("");
	const mlsId = cookies.get("mlsId");
	const userPropertyId = cookies.get("userPropertyId");
	const { user } = useAuth0();
	useEffect(() => {
		const embeddedSigning = async () => {
			window.scrollTo(0, 0);
			try {
				const resp = await eSign({ mlsId, userPropertyId, auth0Id: user?.sub });
				setUrl(resp.data.results.redirectUrl);
				if (resp.data.results.envelopeId) {
					setTimeout(async () => {
						const updateEnvelopeStatus = await createUserPropertyEnvelope({
							userPropertyId: userPropertyId,
							envelopeId: resp.data.results.envelopeId,
							envelopeType: "offer",
							auth0Id: user?.sub
						});
						await createEnvelope({
							auth0Id: user?.sub,
							docName: "Non Exclusive Agreement",
							fileName: "NEA.pdf",
							userPropertyId: userPropertyId
						});
					}, 7000);
				}
				cookies.set("envelopeId", resp.data.results.envelopeId, {
					doNotParse: true,
					path: "/"
				});
			} catch (e) {
				console.log(e);
			}
		};

		embeddedSigning();
	}, []);

	return (
		<Container maxWidth="lg" className={classes.root}>
			{url ? (
				<iframe
					title="docuSign-Esigning"
					className={classes.responsiveIframe}
					src={url}
				></iframe>
			) : (
				<Stack className={classes.loader}>
					<Loader type={"Circles"}></Loader>
				</Stack>
			)}
		</Container>
	);
}

export default SigningPage;
