import React, { useState } from "react";
import {
	Box,
	Typography,
	Stack,
	IconButton,
	Chip,
	Tooltip
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { createFavourite, removeFavourite } from "../../services/favorites";
import {
	sendEmail,
	sendEmailToRequestDisclosure,
	updateUserProperties,
	updateUserPropertyStatus
} from "../../services/propertyForm";
import { useAuth0 } from "@auth0/auth0-react";
import { STATUS_TOOLTIPS } from "../../configs/siteData";
import { CircularProgress } from "@material-ui/core";
import { FavoriteBorderOutlined } from "@material-ui/icons";
import StatusChip from "../StatusChip/StatusChip";
import Amenities from "../Amenities/Amenities";
import { ArchiveOutlined } from "@mui/icons-material";

const cookies = new Cookies();

export const Card = ({
	data,
	setShowConfirm,
	userPropertyData,
	setFilter,
	setToast,
	setDataSource,
	dataSource
}) => {
	const classes = cardStyles();
	const currentURL = `${window.location.pathname}${window.location.search}`;
	const [favourite, setFavourite] = useState(data.userFav);
	let [searchParams, setSearchParams] = useSearchParams();
	const filter = searchParams.get("filter");
	const { user } = useAuth0();
	const [loading, setLoading] = useState(false);
	const checkAnotherOffer = (array) => {
		if (data.status == "rejected") {
			const otherOffer = array.find(
				(offer) =>
					offer?.property_form?.propertyId === data.property_form?.propertyId &&
					offer?.status !== "rejected"
			);

			if (otherOffer) {
				return true;
			}
			return false;
		} else {
			return false;
		}
	};

	const navigate = useNavigate();
	const signingStatusCheck = (data) => {
		if ((data, data && Object.keys(data).length > 0)) {
			if (data.userPropertyData && data.user_property_envelopes.length) {
				const userPropertyEnvelope = data.user_property_envelopes;

				if (data.status == "revised-by-buyer") {
					const status = userPropertyEnvelope.filter(
						(data) => (data.envelopeType = "buyer-counter-offer")
					);
					return status;
				} else if (data.status == "revised-by-seller") {
					const status = userPropertyEnvelope.filter(
						(data) => (data.envelopeType = "seller-counter-offer")
					);
					return status;
				}
			}
		}
		return [];
	};
	const latestOffer = (data) => {
		if ((data, data && Object.keys(data).length > 0)) {
			if (data.userPropertyData && data.user_property_envelopes.length) {
				const userPropertyEnvelope = data.user_property_envelopes;
				let offer = userPropertyEnvelope.filter(
					(data) => (data.envelopeType = "offer")
				);
				offer = [offer[offer.length - 1]];
				return offer;
			}
		}
		return [];
	};
	const signingStatus = signingStatusCheck(data);
	const offer = latestOffer(data);

	const onPropertyDetails = () => {
		navigate(`/app/property-details/${data.details.ListingId}`, {
			replace: true,
			state: {
				data: data.details,
				searchLink: currentURL
			}
		});
	};
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD"
	});
	const onUserPropertyFormClick = () => {
		navigate(
			`/app/buyer-property-offer-form/${data.property_form.propertyId}/${data.userId}/${data.id}/${data.property_form.id}`,
			{
				replace: true
			}
		);
	};

	const onCardClickHandler = () => {
		if (
			(data.property_form && data.userId && data.property_form.propertyId) ||
			data.alreadyOffered
		) {
			onUserPropertyFormClick();
		} else {
			onPropertyDetails();
		}
	};
	const onReCreateOffer = () => {
		navigate(
			`/app/buyer-property-offer-form/${data.property_form.propertyId}/${data.userId}/${data.id}/${data.property_form.id}?offerType=recreate`,
			{
				replace: true
			}
		);
	};
	const onMarkFavourite = async (favourite) => {
		try {
			if (favourite) {
				await removeFavourite({
					id: filter == "All" ? data.userFavId : data.id,
					auth0Id: user?.sub
				});
			} else {
				if (data.id) {
					delete data["id"];
				}
				data.auth0Id = user?.sub;
				await createFavourite(data);
			}
		} catch (e) {}
	};
	const onRequestDisclosure = async (values) => {
		try {
			setLoading(true);
			const email = await sendEmailToRequestDisclosure({
				details: {
					from: { email: "offer@alokee.com", name: "Alokee" },
					subject: "Request to upload Disclosure"
				},
				dynamicTemplateData: {},
				requestData: {
					userPropertyId: data.id
				}
			});
			if (email.data) {
				setLoading(false);
				setFilter(null);
				setFilter("Offers");
			}
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};
	const onArchive = async () => {
		try {
			await updateUserProperties({ userPropertyId: data.id, archived: true });
			setToast({
				state: "open",
				message: "This property has been archived.",
				type: "success"
			});
			setFilter(null);
			setFilter("Offers");
		} catch (e) {}
	};

	return (
		<Stack className={classes.card}>
			<img
				className={classes.image}
				src={
					data.images
						? data.images[0]
						: data.property_form?.imageUrl
						? data.property_form?.imageUrl
						: "https://dchba.org/wp-content/uploads/2020/06/house-placeholder.png"
				}
				alt="property-image"
				tabIndex={0}
				aria-label="Property Image. Click to View Property Details."
				onClick={onCardClickHandler}
				onError={async () => {
					if (filter == "All") {
						const updatedList = dataSource?.filter((props) => {
							if (props.mlsId != data.mlsId) {
								return { ...props };
							}
						});
						setDataSource(updatedList);
					}
				}}
			/>
			{data.status && filter != "Favorites" && (
				<StatusChip
					alreadyOffered={data.alreadyOffered}
					chipClass={classes.status}
					status={data.status}
					title={
						STATUS_TOOLTIPS[data.status]
							? STATUS_TOOLTIPS[data.status]
							: data.status
					}
				/>
			)}
			{filter == "Offers" && !loading ? (
				<Stack className={classes.archive}>
					<Tooltip title={"Archive this property."}>
						<IconButton onClick={onArchive}>
							<ArchiveOutlined color="primary" />
						</IconButton>
					</Tooltip>
				</Stack>
			) : null}

			<Box
				style={{
					paddingRight: 10,
					paddingLeft: 10,
					margin: "20px 0"
				}}
			>
				<Stack
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: "10px"
					}}
				>
					<Typography className={classes.price} onClick={onCardClickHandler}>
						{data.price
							? formatter.format(data.price)
							: formatter.format(data.property_form.purchasePrice)}
					</Typography>
					{filter != "Offers" && (
						<IconButton
							style={{ padding: 0 }}
							aria-label="favourite-icon"
							onClick={() => {
								onMarkFavourite(favourite);
								setFavourite(!favourite);
							}}
						>
							{favourite ? (
								<FavoriteIcon
									style={{
										color: "#02A689",
										height: 30,
										width: 30
									}}
								/>
							) : (
								<FavoriteBorderOutlined
									style={{
										color: "#003A7B",
										height: 30,
										width: 30
									}}
								/>
							)}
						</IconButton>
					)}
				</Stack>
				<div
					style={{
						display: "flex",
						flexDirection: "row"
					}}
				>
					<Amenities
						bathrooms={data.bathrooms}
						bedrooms={data.bedrooms}
						area={data.area}
						clickHandler={onCardClickHandler}
					/>
				</div>
				<Typography
					className={classes.cardHeading}
					onClick={onCardClickHandler}
				>
					{data.name}
				</Typography>
				{(offer &&
					offer.length > 0 &&
					offer[0] &&
					offer[0]?.envelopeType == "offer" &&
					offer[0]?.signedBy == null) ||
					(offer[0]?.envelopeType == "seller-counter-offer" &&
						offer[0]?.signedBy == "buyer-agent") ||
					(offer[0]?.envelopeType == "buyer-counter-offer" &&
						offer[0]?.signedBy == null && (
							<Typography
								variant="subtitle1"
								display="inline"
								tabIndex={0}
								aria-label="Click to Start Signing Contracts"
								className={classes.sign}
								onClick={async () => {
									cookies.set(
										"mlsId",
										data.mlsId ? data.mlsId : data.property_form.propertyId,
										{
											doNotParse: true,
											path: "/"
										}
									);
									cookies.set(`userPropertyId`, data.id, {
										doNotParse: true,
										path: "/"
									});
									navigate("/app/document-e-signing", {
										replace: true,
										state: {
											data: data.mlsId
												? data.mlsId
												: data.property_form.propertyId
										}
									});
								}}
							>
								Sign Contracts
							</Typography>
						))}
				<Box>
					{(data.status == "under-review" ||
						data.status == "awaiting-alokee-approval" ||
						data.status == "sent-to-seller-agent" ||
						data.status == "sent-to-seller") && (
						<Typography
							variant="subtitle1"
							display="inline"
							color="text"
							tabIndex={0}
							aria-label="Click to Rescind Offer"
							style={{ marginRight: 10 }}
							className={classes.pullBackAndPing}
							onClick={async () => {
								setShowConfirm({
									message: "Are you sure you want to rescind your offer?",
									description: "Please note, this change can't be undone.",
									buttonTitle: "Rescind",
									onSubmit: async () => {
										const resp = await updateUserPropertyStatus({
											status: "pulled-back",
											id: data.id,
											auth0Id: user?.sub
										});
									},
									state: true,
									textDialog: true,
									hideCancel: false,
									showAvatar: false,
									showActions: true,
									showContact: true
								});
							}}
						>
							Rescind Offer
						</Typography>
					)}
					{data.status == "sent-to-seller" && data.user_pings.length == 0 && (
						<Typography
							variant="subtitle1"
							display="inline"
							color="text"
							tabIndex={0}
							className={classes.pullBackAndPing}
							onClick={async () => {
								const email = await sendEmail({
									pingData: {
										userPropertyId: data.id,
										ping: true,
										userId: data.userId
									},
									auth0Id: user?.sub
								});
								setShowConfirm({
									message:
										"A message has been sent to the Seller to remind them you are awaiting response.",
									state: true
								});
							}}
						>
							Ping Seller
						</Typography>
					)}

					{data.status == "rejected" &&
						!checkAnotherOffer(userPropertyData) && (
							<Typography
								variant="subtitle1"
								display="inline"
								color="text"
								tabIndex={0}
								aria-label="Click to Re-Offer"
								style={{ marginRight: 10 }}
								className={classes.pullBackAndPing}
								onClick={async () => {
									onReCreateOffer();
								}}
							>
								Re-Offer
							</Typography>
						)}

					{filter == "Offers" && !loading ? (
						<Typography
							variant="subtitle1"
							display="inline"
							color="text"
							tabIndex={0}
							style={{ marginRight: 10 }}
							className={classes.pullBackAndPing}
							onClick={async () => {
								if (!data.requestDisclosure) {
									onRequestDisclosure();
								}
							}}
						>
							{!data.requestDisclosure
								? "Request Disclosure"
								: data.disclosureUploaded
								? "Disclosure Received"
								: "Disclosure Requested"}
						</Typography>
					) : (
						filter == "Offers" &&
						loading && <CircularProgress size={15}></CircularProgress>
					)}
				</Box>
				{!!signingStatus[signingStatus?.length - 1]?.signedBy && (
					<Stack
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						<Typography
							variant="subtitle1"
							display="inline"
							className={classes.sign}
							fontStyle={"Roboto Condensed"}
						>
							Counter offer signed by
						</Typography>
						<Chip
							variant="outlined"
							label={
								signingStatus[signingStatus.length - 1]
									? signingStatus[signingStatus.length - 1]["signedBy"]
									: "no one"
							}
						/>
					</Stack>
				)}
			</Box>
		</Stack>
	);
};

const cardStyles = makeStyles((theme) => {
	return {
		card: {
			backgroundColor: "#FFF",
			width: "auto",
			height: "auto",
			position: "relative",
			cursor: "pointer",
			border: "1px solid",
			borderColor: "#cacaca",
			borderRadius: "8px",
			overflow: "hidden",
			boxShadow: "0px 1px 13px rgba(0, 0, 0, 0.20)"
		},
		sign: {
			marginLeft: 5,
			color: theme.palette.secondary.main,
			cursor: "pointer",
			"&:hover": {
				color: "#000000"
			}
		},
		pullBackAndPing: {
			color: "#003a7b",
			cursor: "pointer",
			margin: "10 !important",
			fontFamily: "Rubik !important",
			textAlign: "center",
			fontWeight: 400
		},
		image: {
			objectFit: "cover",
			width: "100%",
			height: "294.243px",
			"&:hover": {
				cursor: "pointer"
			}
		},
		price: {
			fontFamily: theme.typography.fontFamily,
			fontWeight: "bold !important",
			color: theme.palette.primary.mainLight,
			cursor: "pointer",
			fontSize: "1.625rem !important"
		},
		cardHeading: {
			fontSize: "1rem",
			fontWeight: theme.typography.fontWeightMedium,
			fontFamily: theme.typography.fontFamily,
			color: "#000",
			cursor: "pointer"
		},
		amnities: {
			fontFamily: theme.typography.fontFamily,
			color: theme.palette.primary.mainLight,
			fontSize: "1rem",
			cursor: "pointer",
			fontWeight: theme.typography.fontWeightMedium
		},
		status: {
			position: "absolute",
			top: "10px",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1rem",
			color: theme.palette.primary.mainLight,
			right: "10px",
			padding: 15
		},
		archive: {
			position: "absolute",
			top: "10px",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1rem",
			color: theme.palette.primary.mainLight,
			left: "10px",
			backgroundColor: "#fff",
			borderRadius: 20
		}
	};
});
