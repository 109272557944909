import {
	Container,
	IconButton,
	Paper,
	Grid,
	Avatar,
	Typography,
	InputBase
} from "@mui/material";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../style";
import Logo from "../../../assets/Logo.png";
import Message from "../Message";
import { createUserChat } from "../../../services/userChat";
import { useUserState } from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function ChatView({ socket, uniqueKey, oldChat, setLastMessage }) {
	const classes = useStyles();
	const [messages, setMessages] = useState([]);
	const [chatMessage, setChatMessage] = useState("");
	const userDetails = useUserState();
	const { user } = useAuth0();
	const myRef = useRef();
	cookies.set("uniqueKey", uniqueKey, {
		doNotParse: true,
		path: "/"
	});
	useEffect(() => {
		setMessages(oldChat);
	}, [oldChat]);

	useEffect(() => {
		myRef.current?.scroll({
			top: myRef.current?.scrollHeight,
			behavior: "smooth"
		});
	}, [messages]);

	useEffect(() => {
		socket.on(
			"adminMessage",
			(message) => {
				
				if (message.message.room == cookies.get("uniqueKey")) {
					setLastMessage(message.message);
					setMessages((prevState) => [...prevState, message.message]);
					myRef.current?.scroll({
						top: myRef.current?.scrollHeight,
						behavior: "smooth"
					});
				} else {
					return;
				}
			},
			() => {}
		);
	}, [socket]);
	const sendMessage = async () => {
		if (chatMessage.length > 0) {
			
			socket.emit(
				"adminReply",
				{
					room: uniqueKey,
					from: userDetails?.userProfile?.firstName,
					text: chatMessage,
					createdAt: new Date()
				},
				() => {}
			);
			setChatMessage("");
			setMessages((prevState) => [
				...prevState,
				{
					room: uniqueKey,
					from: userDetails?.userProfile?.firstName,
					text: chatMessage,
					createdAt: new Date()
				}
			]);
			myRef.current?.scroll({
				top: myRef.current?.scrollHeight,
				behavior: "smooth"
			});
			try {
				await createUserChat({
					uniqueKey: uniqueKey,
					from: userDetails?.userProfile?.firstName,
					text: chatMessage
				});
			} catch (e) {}
		}
	};
	return (
		<>
			<Container className={classes.chatHeader} style={{ paddingTop: 10 }}>
				<Grid
					container
					direction="row"
					alignItems="center"
					justifyContent={"center"}
				>
					<Grid item xs={2} sm={2} lg={1} xl={1} md={1}>
						<Avatar src={Logo} />
					</Grid>
					<Grid
						container
						direction="column"
						item
						xs={10}
						sm={10}
						lg={11}
						xl={11}
						md={11}
					>
						<Grid item>
							<Typography variant={"body1"} className={classes.heading}>
								Alokee
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant={"caption"}
								className={classes.subHeading}
							></Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Container id="message-area" className={classes.messagesArea} ref={myRef}>
				{messages &&
					messages?.length > 0 &&
					messages.map((data) => {
						return (
							<Message
								userDetails={userDetails}
								user={user}
								text={data.text}
								data={data}
								fullView={true}
							/>
						);
					})}
			</Container>
			<Container className={classes.inboxInputArea}>
				<Grid
					container
					direction="row"
					alignItems="center"
					className={classes.input}
					justifyContent="space-between"
				>
					<Grid item xs={10} sm={10} lg={10} xl={10} md={10}>
						<InputBase
							placeholder="Write a message"
							fullWidth={true}
							value={chatMessage}
							onChange={(e) => {
								setChatMessage(e.target.value);
							}}
							multiline
							maxRows={3}
						/>
					</Grid>
					<Grid item xs={2} sm={2} lg={2} xl={2} md={2}>
						<IconButton
							onClick={() => {
								sendMessage();
							}}
							disabled={chatMessage.length ? false : true}
							type="submit"
						>
							<SendIcon color={chatMessage.length ? "primary" : ""}></SendIcon>
						</IconButton>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default ChatView;
