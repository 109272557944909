import React, { useEffect, useState } from "react";
import { TextField } from "../../core";
import NumberPickerField from "../../core/NumberPickerField";
import {
	Grid,
	InputAdornment,
	makeStyles,
	Slider,
	Typography,
	Tooltip,
} from "@material-ui/core";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useField, useFormikContext } from "formik";
import { InfoOutlined } from "@material-ui/icons";
import LabelAboveTextField from "../../core/LabelAboveTextField";

const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: "1.25rem",
		position: "relative",
		top: theme.spacing.unit - 2,
		color: "#686868",
		marginLeft: "8px"
	},
	root: {
		paddingTop: "40px",
		paddingBottom: "40px"
	},
	percent: {
		color: "#003A7B",
		fontWeight: "700"
	},
	customSlider: {
		"& .MuiSlider-markLabelActive": {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightBold,
		}
	},
	formattedValue: {
		color: "#000"
	}
}));

function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			prefix="$"
		// isNumericString
		/>
	);
}

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD"
});
const marks = [
	{
		value: 0,
		label: "0%"
	},

	{
		value: 20,
		label: "20%"
	},
	{
		value: 50,
		label: "50%"
	},
	{
		value: 75,
		label: "75%"
	},
	{
		value: 100,
		label: "100%"
	}
];

function OfferTerms({ values, revisedState, setBonus }) {
	const classes = useStyles();
	const {
		values: { purchasePrice, deposit, loan, balanceDownPayment },
		setFieldValue
	} = useFormikContext();
	const [lastLoanValue, setLastLoanValue] = useState(values.loan);

	const [lastBalanceDownPaymentValue, setLastBalanceDownPaymentValue] =
		useState(values.balanceDownPayment);
	const [lastDepositValue, setLastDepositValue] = useState(
		values.balanceDownPayment
	);

	useEffect(() => {
		if (deposit < 0) {
			setFieldValue("deposit", 0);
			setLastDepositValue(0);
		}
		if (deposit >= 0 || deposit <= 100) {
			if (balanceDownPayment > 0) {
				setFieldValue(
					"balanceDownPayment",
					(100 - loan - parseFloat(deposit)).toFixed(2)
				);
				setLastBalanceDownPaymentValue(
					parseFloat(100 - loan - parseFloat(deposit)).toFixed(2)
				);
			} else if (loan > 0 || loan <= 100) {
				setFieldValue("loan", (100 - parseFloat(deposit)).toFixed(2));
				setLastLoanValue(parseFloat(100 - parseFloat(deposit)).toFixed(2));
			}
		} else {
			setFieldValue("deposit", parseFloat(lastDepositValue).toFixed(2));
			return;
		}
		setBonus(
			formatter.format(
				(purchasePrice * 0.025 - 9999).toFixed(2) > 0
					? (purchasePrice * 0.025 - 9999).toFixed(2)
					: "0"
			)
		);
	}, [purchasePrice, deposit, lastDepositValue]);

	useEffect(() => {
		if (balanceDownPayment < 0) {
			setFieldValue("balanceDownPayment", 0);
			setLastBalanceDownPaymentValue(0);
		}
		if (balanceDownPayment >= 0 && balanceDownPayment <= 100) {
			if (loan > 0 && loan <= 100) {
				setFieldValue("loan", (100 - balanceDownPayment - deposit).toFixed(2));
				setLastLoanValue((100 - balanceDownPayment - deposit).toFixed(2));
			} else {
				setFieldValue("deposit", (100 - balanceDownPayment - loan).toFixed(2));
				setLastDepositValue((100 - balanceDownPayment - loan).toFixed(2));
			}
		} else {
			setFieldValue(
				"balanceDownPayment",
				parseFloat(lastBalanceDownPaymentValue).toFixed(2)
			);
			return;
		}
	}, [balanceDownPayment, lastBalanceDownPaymentValue]);

	useEffect(() => {
		if (loan == 100) {
			setFieldValue("loan", 100);
			setFieldValue("deposit", 0);
		}
		if (loan < 0) {
			setFieldValue("loan", 0);
			setLastLoanValue(0);
		}
		if (loan >= 0 && loan < 100) {
			if (balanceDownPayment > 0) {
				setFieldValue(
					"balanceDownPayment",
					(
						parseFloat(balanceDownPayment) -
						(parseFloat(loan) - lastLoanValue)
					).toFixed(2)
				);
				setLastBalanceDownPaymentValue(
					(
						parseFloat(balanceDownPayment) -
						(parseFloat(loan) - lastLoanValue)
					).toFixed(2)
				);
			} else {
				setFieldValue(
					"deposit",
					(parseFloat(deposit) - (parseFloat(loan) - lastLoanValue)).toFixed(2)
				);
				setLastDepositValue(
					(parseFloat(deposit) - (parseFloat(loan) - lastLoanValue)).toFixed(2)
				);
			}
			setLastLoanValue(loan);
		}
	}, [loan]);

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				{revisedState ?
					(
						<div>
							<Typography style={{ fontSize: "1.125rem", color: "#003A7B", fontWeight: "700" }}>Purchase Price</Typography>
							<Typography style={{ marginBottom: "25px", fontFamily: "Rubik", color: "#000", fontSize: "1rem" }}>
								{formatter.format(values.purchasePrice)}
							</Typography>
						</div>
					) : (
						<LabelAboveTextField
							tooltipRequired={true}
							name="purchasePrice"
							toolTipMsg={
								"The amount of money you will pay the Seller for the home."
							}
							label={"Purchase Price"}
							type="text"
							defaultValue="0.00"
							InputProps={{
								inputComponent: NumberFormatCustom
							}}
							required
							disabled={revisedState}
						/>
					)
				}
			</Grid>
			<Grid item xs={12}>
				<Typography variant={"body2"} id="input-slider" gutterBottom
					style={{ fontSize: "1.125rem", color: "#003A7B", fontWeight: "700", marginTop: "40px" }}>
					Deposit
					<Tooltip
						title={
							"The amount of money you will pay escrow upon an accepted offer."
						}
					>
						<InfoOutlined className={classes.icon} />
					</Tooltip>
					<Typography id="input-slider" gutterBottom>
						<span className={classes.percent}>{values.deposit}%</span>
						{" "}(
						<span className={classes.formattedValue}>
							{formatter.format(
								(values.purchasePrice * (values.deposit / 100)).toFixed(2)
							)}
						</span>
						)
					</Typography>
				</Typography>
				<Slider
					className={classes.customSlider}
					marks={marks}
					step={1}
					value={deposit}
					min={0}
					max={100}
					aria-label="Deposit Slider"
					valueLabelDisplay="auto"
					name="deposit"
					label="Deposit"
					required
					disabled={revisedState}
					onChange={(event, value) => {

						setFieldValue("deposit", value);
					}}
					getAriaValueText={(value) => `${value}%`} // custom value text
				/>

				{/* <TextField
					tooltipRequired={true}
					name="deposit"
					label="Deposit"
					InputProps={{
						startAdornment: <InputAdornment position="start">%</InputAdornment>,
						endAdornment: (
							<InputAdornment position="end">
								(
								{formatter.format(
									(values.purchasePrice * (values.deposit / 100)).toFixed(2)
								)}
								)
							</InputAdornment>
						)
					}}
					type="number"
					max={100}
					required
					disabled={revisedState}
				/> */}
			</Grid>
			<Grid item xs={12}>
				<Typography variant={"body2"} id="input-slider" gutterBottom
					style={{ fontSize: "1.125rem", color: "#003A7B", fontWeight: "700", marginTop: "40px" }}>
					Balance of Down Payment
					<Tooltip title="The amount of money you will pay upon closing.">
						<InfoOutlined className={classes.icon} />
					</Tooltip>
					<Typography id="input-slider" gutterBottom>
						<span className={classes.percent}>{values.balanceDownPayment}%</span>
						{" "}(
						<span className={classes.formattedValue}>
							{formatter.format(
								(values.purchasePrice * (values.balanceDownPayment / 100)).toFixed(2)
							)}
						</span>
						)
					</Typography>
				</Typography>
				<Slider
					className={classes.customSlider}
					marks={marks}
					value={balanceDownPayment}
					min={0}
					step={1}
					max={100}
					aria-label="Balance of Down Payment Slider"
					valueLabelDisplay="auto"
					name="balanceDownPayment"
					label="Balance of Down Payment"
					required
					disabled={revisedState}
					onChange={(event, value) =>
						setFieldValue("balanceDownPayment", value)
					}
					getAriaValueText={(value) => `${value}%`} // custom value text
				/>

				{/* <TextField
					tooltipRequired={true}
					name="balanceDownPayment"
					label="Balance of Down Payment"
					InputProps={{
						startAdornment: <InputAdornment position="start">%</InputAdornment>,
						endAdornment: (
							<InputAdornment position="end">
								(
								{formatter.format(
									(
										values.purchasePrice *
										(values.balanceDownPayment / 100)
									).toFixed(2)
								)}
								)
							</InputAdornment>
						)
					}}
					disabled={revisedState}
					type="number"
					required
				/> */}
			</Grid>
			<Grid item xs={12}>
				{/* Believe buyer funds needed to close is always calculated? */}
				{/* {revisedState ?
					( */}
				<div>
					<Typography style={{ fontSize: "1.125rem", color: "#003A7B", fontWeight: "700", marginTop: "40px" }}>Buyer Funds Needed to Close</Typography>
					<Typography style={{ fontFamily: "Rubik", color: "#000", fontSize: "1rem" }}>
						{formatter.format(
							values.purchasePrice * (values.deposit / 100) +
							parseFloat(
								(values.purchasePrice * values.balanceDownPayment) / 100
							)
						)}</Typography>
				</div>
				{/* ) : (
						<LabelAboveTextField
							tooltipRequired={true}
							name="buyerFundNeedClose"
							value={formatter.format(
								values.purchasePrice * (values.deposit / 100) +
								parseFloat(
									(values.purchasePrice * values.balanceDownPayment) / 100
								)
							)}
							label="Buyer Funds Needed to Close"
							toolTipMsg={
								"The sum of the Deposit and Balance of Down Payment and/or the Purchase Price minus the Loan amount."
							}
							disabled={revisedState}
							type="text"
							required
						/>
					)
				} */}
			</Grid>
			<Grid item xs={12}>
				<Typography variant={"body2"} id="input-slider" gutterBottom style={{ fontSize: "1.125rem", color: "#003A7B", fontWeight: "700", marginTop: "40px" }}>
					Loan
					<Tooltip title="The amount of money you will borrow from your lender.">
						<InfoOutlined className={classes.icon} />
					</Tooltip>
					<Typography id="input-slider" gutterBottom>
						<span className={classes.percent}>{values.loan}%</span>
						{" "}(
						<span className={classes.formattedValue}>
							{formatter.format(
								(values.purchasePrice * (loan / 100)).toFixed(2)
							)}
						</span>
						)
					</Typography>
				</Typography>
				<Slider
					className={classes.customSlider}
					marks={marks}
					value={loan}
					min={0}
					max={100}
					step={1}
					aria-label="Loan Slider"
					valueLabelDisplay="auto"
					name="loan"
					label="Loan"
					required
					disabled={revisedState}
					onChange={(event, value) => setFieldValue("loan", value)}
					getAriaValueText={(value) => `${value}%`} // custom value text
				/>

				{/* <TextField
					tooltipRequired={true}
					name="loan"
					InputProps={{
						startAdornment: <InputAdornment position="start">%</InputAdornment>,
						endAdornment: (
							<InputAdornment position="end">
								(
								{formatter.format(
									(values.purchasePrice * (loan / 100)).toFixed(2)
								)}
								)
							</InputAdornment>
						)
					}}
					label="Loan"
					type="number"
					required
					disabled={revisedState}
				/> */}
			</Grid>
			<Grid item xs={12}>
				<NumberPickerField
					name={"closingDate"}
					title={"Closing Date"}
					label={"Days"}
					toolTipMsg={"The date you will complete the purchase of the home."}
					min={1}
					max={365}
					disabled={revisedState}
				/>
				<Typography className={classes.closingDate} style={{ marginTop: "25px" }}>
					Date:
					{new Date(
						moment(new Date(), "DD-MM-YYYY").add(values.closingDate, "days")
					).toLocaleDateString()}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default OfferTerms;
