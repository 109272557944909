import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1)
  },
  image: {
    height: 120,
    width: 120
  },
  uploadButton: {
    marginTop: theme.spacing(3)
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    padding: theme.spacing(3)
  },

  errorMessage: {
    color: "red !important",
    display: "flex",
    alignItems: "center"
  },

  button: {
    margin: theme.spacing(2)
  },

  removeButton: {
    marginTop: theme.spacing(4)
  },

  dropContainer: {
    border: `2px solid grey`,
    // borderStyle: "dashed",
    borderRadius: "5px",
    width: "100%",
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(3)
    // "&:hover": {
    //   borderColor: "black"
    // }
  },

  dropContainerError: {
    // borderColor: "red",
    // "&:hover": {
    //   borderColor: "red"
    // }
  },

  dropContainerSuccess: {
    borderColor: theme.palette.primary.main,
    "&:hover": {
      borderColor: theme.palette.primary.main
    }
  },

  typography: {
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },

  uploadIcon: {
    color: theme.palette.primary.main,
    fontSize: 50,
    marginBottom: theme.spacing(3),
    cursor: "pointer"
  },

  invalidFileIcon: {
    color: "red !important"
  },

  textField: {
    margin: theme.spacing(1)
  },

  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main
    }
  },

  dropTypography: {
    color: "grey"
  },

  browseTypography: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    cursor: "pointer"
  },

  filenameText: {
    display: "flex",
    alignItems: "center",
    color: "grey"
  },

  removeIcon: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 20
  }
}));
