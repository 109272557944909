import React from "react";
import { Box, Typography } from "@mui/material";

const Amenities = ({ bathrooms, bedrooms, area, clickHandler }) => {
    const hasBedrooms = typeof bedrooms === "number" && !isNaN(bedrooms) && bedrooms > 0;
    const hasBathrooms = typeof bathrooms === "number" && !isNaN(bathrooms) && bathrooms > 0;
    const hasArea = typeof area === "number" && !isNaN(area) && area > 0;

    return (
        <Typography style={{ color: "#003A7B", fontSize: "1rem" }} onClick={clickHandler}>
            {hasBedrooms && (
                <>
                    <strong>{bedrooms}</strong> bed{hasBedrooms && bedrooms > 1 ? "s" : ""}
                    {hasBathrooms || hasArea ? ", " : ""}
                </>
            )}
            {hasBathrooms && (
                <>
                    <strong>{bathrooms}</strong> bath{hasBathrooms && bathrooms > 1 ? "s" : ""}
                    {hasArea ? ", " : ""}
                </>
            )}
            {hasArea && (
                <>
                    <strong>{area}</strong> Sq. ft.
                </>
            )}
        </Typography>
    );
};

export default Amenities;
