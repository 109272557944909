import React from "react";
import { Container, makeStyles, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
	return {
		faqsTitleHeading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		},

		newsLetter: {
			background:
				"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			height: "290px",
			marginTop: 30,
			paddingTop: 30
		},

		blogPara: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem !important"
			},
			marginInlineStart: 20
		},
		blogParaHeader: {
			marginBottom: "5px",
			listStylePosition: "inside",
			textIndent: "-1em",
			paddingLeft: "1em"
		},
		divider: {
			borderTop: "3px solid #00284c"
		}
	};
});

function BlogFour() {
	const classes = useStyles();

	return (
		<div style={{ width: "100%" }}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.faqsTitleHeading}>
							What is the Alokee Bonus, when do I get it and how can I spend it?
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							When you buy a home using Alokee, you will receive a significant
							cash bonus. Why?
						</p>
					</Grid>

					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Every listed home has a published commission (usually 2.5 - 3% of
							the sale price) that must be paid to the brokerage that creates
							the offer and performs other real estate duties for the Buyer. By
							using Alokee, you will be using our technology to make the
							brokerage process much more efficient. Alokee takes advantage of
							this efficiency and passes the savings on to you.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							For example, if you use an old school agent, the agent manually
							performs many services, such as researching neighborhoods,
							arranging and escorting buyers to showings, and developing and
							submitting written offers. But some buyers prefer to do many of
							these activities themselves yet still are required to pay high
							fees to a buyer’s agent to close the deal. Using Alokee, you can
							research homes, schedule showings, and automate the offer
							generation process on our site. Technology replaces the friction
							of the human back-and-forth of traditional home buying.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Let’s use an example of a $1,000,000 home for simplicity sake. If
							the published commission is 2.5%, that means $25,000 must be paid
							to the Buyer’s brokerage upon a successful closing. It doesn’t
							matter how many showings the Buyer’s agent completed, how much
							negotiation they did, how much time they spent on the deal or even
							if they went to the house or not. Even if the actual buyer (aka
							“you”) did most of the work yourself, the Buyer’s brokerage gets
							$25,000. Using Alokee, Alokee keeps only $8,900 of the commission
							and refunds you $16,100 (or $25,000 minus $8,900).
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							What you do with this $16,100 is entirely up to you. But there are
							two good options to consider. First, working with your lender, you
							can use it to pay your lender’s closing costs at closing. What
							this means is that if your lender fees were $17,000, instead of
							paying $17,000 at closing to your lender, you would only pay $900
							($17,000 total lender fees minus $16,100 Alokee cash bonus). In
							summary, this means your Alokee Bonus saved you $16,100 cash at
							closing ($16,100 you would have otherwise paid the lender).
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							A second good option for your Alokee Bonus is to take cash payment
							after closing. So, using this example, when you close on your new
							home, Alokee would immediately write you a check for $16,100 for
							you to use on anything (literally anything you choose - the money
							is yours). In this case, you will get the money as fast as we can
							intake the Buyer agent commission and issue you a check. Usually
							this takes about a week, though we do it as fast as we can.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							The important thing to know is your Alokee bonus is yours to spend
							as you please. You can spend it at closing (if your lender allows
							it) or after closing. In any case, you will get an Alokee bonus
							and it’s yours to spend!
						</p>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default BlogFour;
