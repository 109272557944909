import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";

function FormSkeleton({ loading }) {
	const classes = cardStyles();

	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="flex-start"
			className={classes.root}
			spacing={5}
		>
			{(loading ? Array.from(new Array(1)) : []).map((item, index) => (
				<>
					<Grid item xs={12} sm={12} lg={4} xl={4} md={4}>
						{
							<Skeleton
								variant="rect"
								className={classes.img}
								width={332.5}
								height={"80vh"}
							/>
						}
					</Grid>
					<Grid
						item
						direction="column"
						justifyContent="center"
						alignItems="center"
						xs={12}
						sm={12}
						lg={8}
						md={8}
						xl={8}
					>
						<Skeleton
							variant="rect"
							className={classes.img}
							width={"55vw"}
							height={"80vh"}
						/>
					</Grid>
				</>
			))}
		</Grid>
	);
}

const cardStyles = makeStyles((theme) => {
	return {
		img: {
			borderRadius: 8
		},
		box: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "row"
		},
		root: {
			height: "100%",
			marginTop: 10
		}
	};
});

export default FormSkeleton;
