import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
	autoComplete: {
		width: "75%",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	searchBarStyle: {
		borderRadius: '33px',
		display: "flex",
		width: "100%",
		padding: "0 16px",
		alignItems: "center",
		justifyContent: "space-between",
		background: "#E5E5E5"
	},
	buttonGroup: {
		[theme.breakpoints.down("xs")]: {
			width: 300
		},
		[theme.breakpoints.down("sm")]: {
			width: 350
		}
	},
	searchBar: {
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
			boxShadow: "none"
		}
	}
}));
