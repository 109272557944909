import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";

function CardSkeleton({ loading }) {
	const classes = cardStyles();

	return (
		<Grid
			container
			display="flex"
			justifyContent="center"
			direction="row"
			spacing={6}
		>
			{(loading ? Array.from(new Array(3)) : []).map((item, index) => (
				<Grid item md={4} lg={4} sm={12} xs={12} key={index}>
					{
						<Skeleton
							variant="rect"
							className={classes.img}
							width={420}
							height={350}
						/>
					}
					<Grid item lg={10} md={10} sm={4} xs={4}>
						<Skeleton width="60%" />
						<Skeleton />
						<Skeleton width="60%" />
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}

const cardStyles = makeStyles((theme) => {
	return {
		img: {
			borderRadius: 8
		},
		box: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "row"
		}
	};
});

export default CardSkeleton;
