import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Typography,
	Box,
	Button,
	List,
	ListItem,
	ListItemIcon
} from "@material-ui/core";
import { Container, Grid, Slider } from "@mui/material";
import { Link } from "react-router-dom";
import bullet from "../../assets/bullet.svg";

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 0
});

const whyAlokeeStyles = makeStyles((theme) => {
	return {
		whyAlokeeSection: {
			paddingTop: "160px",
			paddingBottom: "250px",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "25px",
				paddingBottom: "100px"
			}
		},
		whyAlokeeGridDiv: {
			borderRadius: "6px",
			padding: "32px",
			boxShadow: "5px 13px 36px 0px rgba(0, 0, 0, 0.15)",
			margin: "18px !important"
		},
		whyAlokeeText: {
			color: "#000",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			lineHeight: "132%"
		},
		whyAlokeeListItem: {
			display: "list-item",
			fontWeight: "400",
			marginLeft: "20px",
			fontSize: "1rem",
			[theme.breakpoints.down("sm")]: {
				fontSize: "0.875rem"
			}
		},
		astericksText: {
			fontWeight: "400",
			fontSize: "1rem",
			color: "#686868",
			[theme.breakpoints.down("sm")]: {
				fontSize: "0.875rem"
			}
		},
		button: {
			width: "220px",
			padding: "10px 18px",
			borderRadius: "6px",
			background: "#FFF",
			textTransform: "none",
			fontSize: "1rem",
			color: theme.palette.secondary.mainTwo,
			fontWeight: theme.typography.fontWeightBold,
			"&:hover": {
				background: theme.palette.secondary.main
			}
		},
		youSaveContainer: {
			marginTop: "160px",
			[theme.breakpoints.down("sm")]: {
				marginTop: "80px"
			}
		},
		SmallTitle: {
			color: "#000",
			textAlign: "center",
			fontFamily: theme.typography.fontFamily,
			fontSize: "2rem",
			fontWeight: theme.typography.fontWeightRegular,
			lineHeight: "138%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.5rem"
			}
		},
		LargeTitle: {
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			fontSize: "2.5rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			textAlign: "center",
			marginBottom: "40px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem"
			}
		},
		smallerTitle: {
			color: "#FFF",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1.625rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.375rem"
			}
		}
	};
});

const WhyAlokee = ({
	bonusPercentage,
	commission,
	alokeeProDescriptionText,
	alokeeGivesYouText,
	costOfServiceText,
	alokeeGivesYou,
	costOfService,
	homeTwo,
	homeThree,
	whyAlokeeHeading
}) => {
	const classes = whyAlokeeStyles();
	const thumbSize = 24;
	const [value, setValue] = useState(1000000);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Container maxWidth="false" className={classes.whyAlokeeSection}>
			<Typography
				className={classes.LargeTitle}
				style={{ marginBottom: "58px" }}
			>
				{whyAlokeeHeading}
			</Typography>
			<Grid container justifyContent="center" alignItems={"stretch"}>
				<Grid
					container
					style={{ background: "#F8F9FA" }}
					className={classes.whyAlokeeGridDiv}
					item
					xs={12}
					sm={6}
					md={6}
					lg={4}
					flexDirection="column"
					justifyContent={"space-between"}
				>
					<Grid item>
						<div>
							<Box display="flex" flexDirection="column" alignItems="center">
								<Typography
									className={classes.smallerTitle}
									style={{ color: "#000" }}
								>
									Old School Broker
								</Typography>
								<Typography
									className={classes.whyAlokeeText}
									style={{ marginTop: "24px" }}
								>
									Cost of service
								</Typography>
								<Typography
									className={classes.LargeTitle}
									style={{ color: "#000", marginBottom: "auto" }}
								>
									$39,900*
								</Typography>
								<Typography
									className={classes.whyAlokeeText}
									style={{ marginTop: "24px" }}
								>
									Broker gives you
								</Typography>
								<Typography
									className={classes.LargeTitle}
									style={{ color: "#000", marginBottom: "auto" }}
								>
									$0
								</Typography>
							</Box>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="flex-start"
							>
								<Typography
									className={classes.whyAlokeeText}
									style={{ marginTop: "99px" }}
								>
									The old way of buying real estate requires you to use a real
									estate agent to do all of the following:
								</Typography>
								<ul style={{ marginTop: "24px" }}>
									<li className={classes.whyAlokeeListItem}>
										<Typography>
											attain property information (disclosures)
										</Typography>
									</li>
									<li className={classes.whyAlokeeListItem}>
										<Typography>view homes</Typography>
									</li>
									<li className={classes.whyAlokeeListItem}>
										<Typography>make offers</Typography>
									</li>
									<li className={classes.whyAlokeeListItem}>
										<Typography>negotiate offers</Typography>
									</li>
									<li className={classes.whyAlokeeListItem}>
										<Typography>close</Typography>
									</li>
								</ul>
								<Typography
									className={classes.whyAlokeeText}
									style={{ marginTop: "24px" }}
								>
									The old way of buying real estate forces you to rely on an
									agent and/or their company which is frustrating and awkward.
									It’s also artificially slow and highly expensive.
								</Typography>
							</Box>
						</div>
					</Grid>
					<Grid item>
						<Typography
							className={classes.astericksText}
							style={{ marginTop: "20px" }}
						>
							*Based on average San Francisco home price of $1,330,000
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					style={{ background: "#02A689" }}
					className={classes.whyAlokeeGridDiv}
					item
					xs={12}
					sm={6}
					md={6}
					lg={4}
					flexDirection="column"
					justifyContent={"space-between"}
				>
					<Grid item>
						<div>
							<Box display="flex" flexDirection="column">
								<Box display="flex" flexDirection="column" alignItems="center">
									<Typography className={classes.smallerTitle}>
										Alokee
									</Typography>
									<Typography
										className={classes.whyAlokeeText}
										style={{ marginTop: "24px", color: "#FFF" }}
									>
										{costOfServiceText}
									</Typography>
									<Typography
										className={classes.LargeTitle}
										style={{ color: "#FFF", marginBottom: "auto" }}
									>
										{costOfService}
									</Typography>
									<Typography
										className={classes.whyAlokeeText}
										style={{ marginTop: "24px", color: "#FFF" }}
									>
										{alokeeGivesYouText}
									</Typography>
									<Typography
										className={classes.LargeTitle}
										style={{ color: "#FFF", marginBottom: "32px" }}
									>
										{alokeeGivesYou}
									</Typography>
									<Link to="/signup">
										<Button className={classes.button}>Get Started</Button>
									</Link>
								</Box>
								{homeTwo ? (
									<Box
										display="flex"
										flexDirection="column"
										alignItems="flex-start"
									>
										<List>
											<ListItem disablePadding>
												<ListItemIcon>
													<img src={bullet} />
												</ListItemIcon>
												<Typography
													className={classes.whyAlokeeText}
													style={{ color: "#FFF" }}
												>
													Comparative market analysis free
												</Typography>
											</ListItem>
											<ListItem disablePadding>
												<ListItemIcon>
													<img src={bullet} />
												</ListItemIcon>
												<Typography
													className={classes.whyAlokeeText}
													style={{ color: "#FFF" }}
												>
													Attain disclosures free
												</Typography>
											</ListItem>
											<ListItem disablePadding>
												<ListItemIcon>
													<img src={bullet} />
												</ListItemIcon>
												<Typography
													className={classes.whyAlokeeText}
													style={{ color: "#FFF" }}
												>
													20 min initial consultation free
												</Typography>
											</ListItem>
											<ListItem disablePadding>
												<ListItemIcon>
													<img src={bullet} />
												</ListItemIcon>
												<Typography
													className={classes.whyAlokeeText}
													style={{ color: "#FFF" }}
												>
													Submit offer free
												</Typography>
											</ListItem>
										</List>

										<List>
											<ListItem
												style={{
													borderBottom: "1px solid #fff",
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"flex-start"}
													justifyContent={"space-between"}
												>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															Showing
														</Typography>
													</Grid>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															$199
														</Typography>
													</Grid>
												</Grid>
											</ListItem>

											<ListItem
												style={{
													borderBottom: "1px solid #fff",
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"flex-start"}
													justifyContent={"space-between"}
												>
													<Grid item xs={6} md={6} lg={6} sm={6}>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															Pre-offer expert advice (real estate attorney and
															licensed broker)
														</Typography>
													</Grid>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															$3,200
														</Typography>
													</Grid>
												</Grid>
											</ListItem>
											<ListItem
												style={{
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"flex-start"}
													justifyContent={"space-between"}
												>
													<Grid item xs={6} md={6} lg={6} sm={6}>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															Post-offer expert advice (real estate attorney and
															licensed broker)
														</Typography>
													</Grid>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															$5,200
														</Typography>
													</Grid>
												</Grid>
											</ListItem>
										</List>
									</Box>
								) : homeThree ? (
									<Box
										display="flex"
										flexDirection="column"
										alignItems="flex-start"
									>
										<List>
											<ListItem disablePadding>
												<ListItemIcon>
													<img src={bullet} />
												</ListItemIcon>
												<Typography
													className={classes.whyAlokeeText}
													style={{ color: "#FFF" }}
												>
													Comparative market analysis free
												</Typography>
											</ListItem>
											<ListItem disablePadding>
												<ListItemIcon>
													<img src={bullet} />
												</ListItemIcon>
												<Typography
													className={classes.whyAlokeeText}
													style={{ color: "#FFF" }}
												>
													Attain disclosures free
												</Typography>
											</ListItem>
											<ListItem disablePadding>
												<ListItemIcon>
													<img src={bullet} />
												</ListItemIcon>
												<Typography
													className={classes.whyAlokeeText}
													style={{ color: "#FFF" }}
												>
													20 min initial consultation free
												</Typography>
											</ListItem>
										</List>

										<List>
											<ListItem
												style={{
													borderBottom: "1px solid #fff",
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"flex-start"}
													justifyContent={"space-between"}
												>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															Showing
														</Typography>
													</Grid>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															$199
														</Typography>
													</Grid>
												</Grid>
											</ListItem>
											<ListItem
												style={{
													borderBottom: "1px solid #fff",
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"flex-start"}
													justifyContent={"space-between"}
												>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															Submit Offer
														</Typography>
													</Grid>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															$840
														</Typography>
													</Grid>
												</Grid>
											</ListItem>

											<ListItem
												style={{
													borderBottom: "1px solid #fff",
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"flex-start"}
													justifyContent={"space-between"}
												>
													<Grid item xs={6} md={6} lg={6} sm={6}>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															Pre-offer expert advice (real estate attorney and
															licensed broker)
														</Typography>
													</Grid>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															$3,200
														</Typography>
													</Grid>
												</Grid>
											</ListItem>
											<ListItem
												style={{
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"flex-start"}
													justifyContent={"space-between"}
												>
													<Grid item xs={6} md={6} lg={6} sm={6}>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															Post-offer expert advice (real estate attorney and
															licensed broker)
														</Typography>
													</Grid>
													<Grid item>
														<Typography
															className={classes.whyAlokeeText}
															style={{ color: "#FFF" }}
														>
															$5,200
														</Typography>
													</Grid>
												</Grid>
											</ListItem>
										</List>
									</Box>
								) : (
									<Box
										display="flex"
										flexDirection="column"
										alignItems="flex-start"
									>
										<Typography
											className={classes.whyAlokeeText}
											style={{ marginTop: "39px", color: "#FFF" }}
										>
											Using Alokee, you can do these things for yourself. It’s
											like you’re your own agent:
										</Typography>
										<ul style={{ marginTop: "24px" }}>
											<li
												className={classes.whyAlokeeListItem}
												style={{ color: "#FFF" }}
											>
												<Typography style={{ color: "#FFF" }}>
													attain property information (disclosures)
												</Typography>
											</li>
											<li
												className={classes.whyAlokeeListItem}
												style={{ color: "#FFF" }}
											>
												<Typography style={{ color: "#FFF" }}>
													view homes *
												</Typography>
											</li>
											<li
												className={classes.whyAlokeeListItem}
												style={{ color: "#FFF" }}
											>
												<Typography style={{ color: "#FFF" }}>
													make offers
												</Typography>
											</li>
											<li
												className={classes.whyAlokeeListItem}
												style={{ color: "#FFF" }}
											>
												<Typography style={{ color: "#FFF" }}>
													negotiate offers
												</Typography>
											</li>
											<li
												className={classes.whyAlokeeListItem}
												style={{ color: "#FFF" }}
											>
												<Typography style={{ color: "#FFF" }}>close</Typography>
											</li>
										</ul>
										<Typography
											className={classes.whyAlokeeText}
											style={{ marginTop: "24px", color: "#FFF" }}
										>
											With Alokee, you benefit from top-notch expertise in real
											estate, cost-effective services through automation, and a
											generous cash bonus. Elevate your professionalism – choose
											Alokee!
										</Typography>
									</Box>
								)}
							</Box>
						</div>
					</Grid>
					<Grid item>
						<Typography
							className={classes.astericksText}
							style={{ marginTop: "20px", color: "#fff" }}
						>
							*{alokeeProDescriptionText},{" "}
							<span style={{ color: "#003A7B" }}>
								no out of pocket expense.
							</span>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Container className={classes.youSaveContainer}>
				<div aria-live="polite" aria-atomic="true">
					<Typography className={classes.SmallTitle}>You save:</Typography>
					<Typography
						className={classes.LargeTitle}
						style={{ marginBottom: "65px" }}
					>
						{value > 1000000
							? formatter.format(value * 0.025 - commission)
							: formatter.format(value * 0.03 - commission)}
						{` (on a listing price of ${formatter.format(value)})`}
					</Typography>
				</div>
				<Box width="100%">
					<div style={{ width: "100%", position: "relative" }}>
						<Slider
							value={value}
							min={400000}
							max={8000000}
							step={1}
							onChange={handleChange}
							aria-labelledby="range-slider"
							sx={{
								"& .MuiSlider-thumb": {
									color: "#003A7B"
								},
								"& .MuiSlider-track": {
									color: "#003A7B"
								},
								"& .MuiSlider-rail": {
									color: "#02A689",
									opacity: "1.0"
								}
							}}
						/>
						<div
							style={{
								position: "absolute",
								left: `calc(${
									((value - 400000) / (8000000 - 400000)) * 100
								}% - ${thumbSize / 2}px + 10px)`,
								top: "-30px",
								textAlign: "center",
								transform: "translateX(-50%)"
							}}
						>
							<Typography
								style={{
									color: "#003A7B",
									fontWeight: "700",
									fontSize: "1rem"
								}}
							>
								{formatter.format(value)}
							</Typography>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography
								style={{ color: "#000", fontSize: "1rem", fontWeight: "700" }}
							>
								$400,000
							</Typography>
							<Typography
								style={{ color: "#000", fontSize: "1rem", fontWeight: "700" }}
							>
								$8,000,000
							</Typography>
						</div>
					</div>
					<Typography
						style={{
							fontWeight: "bold",
							textAlign: "center"
						}}
					>
						*Based on example 3% concession offered by the Seller.
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default WhyAlokee;
