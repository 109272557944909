import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useField, useFormikContext } from "formik";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: "40px",
		paddingBottom: "40px"
	},

}));

const FieldWithError = ({ name }) => {
	const [field, meta] = useField(name);

	if (!meta.touched || !meta.error) { // no render if no error
		return null;
	}

	return (
		<p style={{ color: 'red', fontSize: "1rem", fontWeight: "bold", marginBottom: "10px" }}>
			{meta.error}
		</p>
	);
};

const ArrayFieldsWithError = ({ namePrefix }) => {
	const { values } = useFormikContext();
	const arrayValues = values[namePrefix];

	if (!Array.isArray(arrayValues)) {
		return null;
	}

	return (
		<>
			{arrayValues.map((_, index) => (
				<React.Fragment key={index}>
					<FieldWithError name={`${namePrefix}[${index}].name`} />
					<FieldWithError name={`${namePrefix}[${index}].email`} />
				</React.Fragment>
			))}
		</>
	);
};

function OfferSummary({ values, propertyAddress, revisedState, children }) {
	const classes = useStyles();

	return (
		<Grid
			container
			direction="column"
			alignItems="stretch"
			justifyContent="flex-start"
			spacing={5} className={classes.root}
		>
			<Grid item>{children}</Grid>
			<Grid item aria-live="polite" aria-atomic="true">
				{Object.keys(values).map((key) => {
					if (key === "partners" || key === "entity") {
						return <ArrayFieldsWithError key={key} namePrefix={key} />;
					}
					return <FieldWithError key={key} name={key} />;
				})}
			</Grid>
		</Grid>
	);
}

export default OfferSummary;
