import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: "30px 0 0 0"
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	actionsContainer: {
		marginBottom: theme.spacing(2)
	},
	resetContainer: {
		padding: theme.spacing(3)
	},
	label: {
		fontSize: "1rem",
		[theme.breakpoints.down("sm")]: {
			fontSize: "13px"
		},
		color: "#000"
	}
}));

function getSteps() {
	return ["Offer Terms", "Uploads", "Offer Details", "Review & Submit"];
}

function getStepContent(step) {
	switch (step) {
		case 0:
			return ``;
		case 1:
			return "";
		case 2:
			return ``;
		case 3:
			return ``;
		default:
			return "Unknown step";
	}
}

export default function VerticalLinearStepper({ activeStep }) {
	const classes = useStyles();
	const steps = getSteps();

	return (
		<div className={classes.root}>
			<Stepper
				activeStep={activeStep}
				orientation="horizontal"
				alternativeLabel
			>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel
							classes={{
								label: classes.label
							}}
						>
							{label}
						</StepLabel>

						<Typography style={{ color: "#003A7B" }}>
							{getStepContent(index)}
						</Typography>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} className={classes.resetContainer}>
					<Typography>All steps completed - you&apos;re finished</Typography>
				</Paper>
			)}
		</div>
	);
}
