import { Avatar, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TextDialog from "../core/TextDialog";
import PropertyForm from "../Properties/PropertyForm";
import makeStyles from "./styles";
import Cookies from "universal-cookie";
import {
	createUserPropertyEnvelope,
	getPropertyDetailsById,
	updateUserPropertyStatus
} from "../../services/propertyForm";
import { useNavigate, useParams } from "react-router";
import doneIcon from "../../assets/done.png";
import { createCounterOfferByBuyer } from "../../services/userPropertyEnvelope";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Chat from "../Chat/Chat";
import { getChatById } from "../../services/userPropertyChat";
import { createChat } from "../../services/userPropertyChat";

const cookies = new Cookies();

function Form() {
	const classes = makeStyles();
	let [searchParams, setSearchParams] = useSearchParams();
	const offerType = searchParams.get("offerType");
	const navigate = useNavigate();
	const [revisedState, setRevisedState] = useState(true);
	const [showActions, setShowActions] = useState(false);
	const [oldChat, setOldChat] = useState([]);

	const [showConfirm, setShowConfirm] = useState({
		message: "",
		state: false
	});
	const [statusUpdate, setStatusUpdate] = useState({
		message: "",
		state: false
	});
	const [revisedFields, setRevisedFields] = useState({});
	const [status, setStatus] = useState();
	const { id } = useParams();
	const token = cookies.get("token", {
		doNotParse: true
	});
	const [loading, setLoading] = useState(false);
	const [showRevised, setShowRevised] = useState({
		message: "",
		state: false
	});
	const { user } = useAuth0();
	const onSubmitRevision = async (values) => {
		values.revisedBy = "buyer";
		cookies.set(`userPropertyId`, id, {
			doNotParse: true,
			path: "/"
		});
		cookies.set(`signerRole`, "buyer", {
			doNotParse: true,
			path: "/"
		});

		setLoading(true);
		try {
			const resp = await createCounterOfferByBuyer({
				userPropertyId: id,
				revisedFields: values,
				auth0Id: user?.sub
			});
			if (resp.data.data.results.envelopeId) {
				setTimeout(async () => {
					await createUserPropertyEnvelope({
						userPropertyId: id,
						envelopeId: resp.data.data.results.envelopeId,
						envelopeType: "buyer-counter-offer",
						revisedFields: JSON.stringify(values),
						auth0Id: user?.sub
					});
				}, 7000);
				navigate(`/app/buyerCounterOffer/signing/${id}`, {
					replace: true,
					state: {
						redirectUrl: resp.data.data.results.redirectUrl
					}
				});
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	useEffect(() => {
		const getPropertyDetails = async () => {
			try {
				const resp = await getPropertyDetailsById({ id, auth0Id: user?.sub });
				
				if (
					resp.data.data.data[0].status == "accepted" ||
					resp.data.data.data[0].status == "revised" ||
					resp.data.data.data[0].status == "revised-by-seller" ||
					resp.data.data.data[0].status == "revised-by-buyer"
				) {
					setShowActions(true);
					setStatus(resp.data.data.data[0].status);
					if (
						resp.data.data.data[0].status == "revised-by-seller" ||
						resp.data.data.data[0].status == "revised-by-buyer"
					) {
						setRevisedFields(
							JSON.parse(
								resp.data.data.data[0].user_property_envelopes.revisedFields
							)
						);
					}
				} else if (resp.data.data.data[0].status.includes("sent")) {
					setShowActions(false);
					setStatus(resp.data.data.data[0].status);
				} else if (
					resp.data.data.data[0].status == "awaiting-signatures" ||
					resp.data.data.data[0].status == "in-progress" ||
					resp.data.data.data[0].status == "awaiting-alokee-approval"
				) {
					setRevisedState(false);
					setStatus(resp.data.data.data[0].status);
				} else if (resp.data.data.data[0].status == "under-review") {
					setStatus(resp.data.data.data[0].status);
				} else if (
					resp.data.data.data[0].status == "rejected" &&
					offerType == "recreate"
				) {
					setStatus(resp.data.data.data[0].status);
					setRevisedState(false);
					setShowActions(false);
				} else if (resp.data.data.data[0].status == "rejected") {
					setStatus(resp.data.data.data[0].status);
					setRevisedState(true);
					setShowActions(false);
				} else if (resp.data.data.data[0].status == "pulled-back") {
					setStatus(resp.data.data.data[0].status);
					setRevisedState(false);
				}
				if (
					resp.data.data.data[0].status == "awaiting-alokee-approval" &&
					resp.data.data.data[0].revisedFields
				) {
					setRevisedState(true);
				}
			} catch (e) {}
		};
		getPropertyDetails();
	}, [id]);

	useEffect(() => {
		const getChats = async () => {
			try {
				const resp = await getChatById({ userPropertyId: id });
				setOldChat(resp.data.data.data.rows);
			} catch (e) {}
		};
		getChats();
	}, [id]);

	const updateStatus = async (status) => {
		try {
			const resp = await updateUserPropertyStatus({
				status,
				id: id,
				token,
				auth0Id: user?.sub
			});
			if (resp.data.data.success) {
				setStatusUpdate({
					state: true,
					message: `This offer has been ${status}.`
				});
				setTimeout(() => {
					navigate("/app/dashboard?filter=Offers", {
						replace: true
					});
				}, 1500);
			}
		} catch (e) {}
	};

	return (
		<div>
			{/* <Chat uniqueKey={id} createChat={createChat} oldChat={oldChat} /> */}
			<TextDialog
				showActions={false}
				message={statusUpdate.message}
				state={statusUpdate.state}
				stateHandler={setStatusUpdate}
				textDialog={true}
				submitHandler={() => {}}
				onClose={() => {}}
				showField={false}
				Avatar={
					<Avatar
						src={doneIcon}
						style={{
							top: 10,
							marginBottom: 10,
							flex: 1,
							alignContent: "center",
							alignSelf: "center"
						}}
					></Avatar>
				}
				showAvatar={true}
			></TextDialog>
			<TextDialog
				title="Confirmation Dialog"
				message={`Are you sure you want to ${status} this offer?`}
				state={showConfirm.state}
				showActions={true}
				stateHandler={setShowConfirm}
				submitHandler={async () => {
					if (status == "revised") {
						setRevisedState(false);
						setShowRevised((prevState) => {
							return {
								message: "Add Revision Info",
								state: true
							};
						});
					}
					if (status == "accepted") {
						navigate(`/signingPageSellerCounterOffer/buyer/WvoArlOyLR/${id}`, {
							replace: true
						});
					}
					if (status == "rejected") {
						updateStatus(status);
					}
				}}
				showField={false}
			></TextDialog>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				className={classes.buttonContainer}
				spacing={2}
			>
				<Grid item xs={2} sm={2} lg={2} xl={2} md={2}></Grid>
			</Grid>
			{status && (
				<PropertyForm
					setStatus={setStatus}
					revisedFields={revisedFields}
					showActions={showActions}
					setShowConfirmStatusChange={setShowConfirm}
					status={status}
					revisedState={revisedState}
					showRevised={showRevised}
					setShowRevised={setShowRevised}
					onSubmitRevision={onSubmitRevision}
					loadingRecipient={loading}
					offerType={offerType}
					propertyRelatedChats={true}
				></PropertyForm>
			)}
		</div>
	);
}

export default Form;
