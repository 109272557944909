import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL
});
axiosInstance.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		const token = cookies.get("token", {
			doNotParse: true
		});

		if (token) {
			config.headers.authorization = `Bearer ${token}`;
		} else {
			config.headers.sellerAccessToken = "";
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);
export default axiosInstance;
