import React, { useEffect } from "react";
import {
	Box,
	Grid,
	Typography,
	Container,
	InputAdornment,
	IconButton
} from "@material-ui/core";
import { Toast } from "../components/core";
import { TextField } from "../components/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import GoogleIcon from "../assets/flat-color-icons_google.svg";
import LogoColor from "../assets/logoColor.png";
import auth0 from "auth0-js";
import { Button } from "@mui/material";
import ButtonWrapper from "../components/core/Button";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { sendEmailToAlokee } from "../services/propertyForm";

const initialValues = {
	email: "",
	password: "",
	confirmPassword: "",
	eighteen: false,
	newsLetter: false
};

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.email("Must be a valid email")
		.max(255)
		.required("Email is required"),
	password: Yup.string()
		.max(20)
		.required("Password is required")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
		),
	confirmPassword: Yup.string()
		.required("Please confirm password")
		.oneOf([Yup.ref("password"), null], "Passwords must match"),
	eighteen: Yup.boolean()
		.oneOf([true], "You must be eighteen years or older to sign up.")
		.required("You must be eighteen years or older to sign up."),
	newsLetter: Yup.boolean()
});
const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%"
	},

	socialIcons: {
		height: "32px",
		width: "32px"
	},
	forgotPass: {
		float: "right",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.main
		}
	},
	signUp: {
		color: theme.palette.primary.mainLight,
		marginLeft: 5,
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.main
		}
	},
	button: {
		backgroundColor: theme.palette.primary.mainLight,
		color: "#fff",
		borderRadius: "6px",
		textTransform: "none",
		fontFamily: theme.typography.fontFamily,
		border: "none",
		fontWeight: theme.typography.fontWeightBold,
		"&:hover": {
			backgroundColor: theme.palette.secondary.mainTwo
		},
		fontSize: "1rem"
	},
	signupPageContainer: {
		padding: "100px 25px 100px 90px",
		[theme.breakpoints.down("md")]: {
			padding: "90px 25px 90px 50px"
		},
		[theme.breakpoints.down("sm")]: {
			padding: "auto"
		}
	},
	LargeTitle: {
		color: theme.palette.primary.mainLight,
		fontFamily: theme.typography.fontFamily,
		fontSize: "2.5rem",
		width: "100%",
		fontWeight: theme.typography.fontWeightSemiBold,
		[theme.breakpoints.down("md")]: {
			width: "100%",
			fontSize: "2.2rem"
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.75rem"
		}
	},
	SmallTitle: {
		color: "#000",
		fontSize: "1.5rem",
		marginTop: "25px",
		width: "75%",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		[theme.breakpoints.down("md")]: {
			fontSize: "1.125rem"
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%"
		}
	},
	customFormLabel: {
		fontFamily: theme.typography.fontFamily,
		fontSize: "1rem",
		fontWeight: theme.typography.fontWeightRegular,
		lineHeight: "140%",
		letterSpacing: "-0.32px"
	},
	error: {
		color: "#f44336",
		fontSize: "0.75rem",
		fontFamily: theme.typography.fontFamily
	},
	logo: {
		width: "139.953px",
		height: "25.266px",
		marginRight: "10px",
		"&:hover": {
			cursor: "pointer"
		}
	}
}));
function SignUp() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { pathname } = useLocation();
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const [emailToast, setEmailToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const auth0Client = new auth0.WebAuth({
		domain: process.env.REACT_APP_AUTH0_DOMAIN, // Replace with your Auth0 domain
		clientID: process.env.REACT_APP_AUTH0_CLIENT_ID, // Replace with your Auth0 client ID
		redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI, // Replace with your callback URL
		responseType: "token id_token",
		scope: "openid profile email" // Customize scopes as needed
	});

	const [activeStep, setActiveStep] = useState(0);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const steps = ["Step 1"];

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const onSubmit = async (values) => {
		setLoading(true);
		try {
			auth0Client.signup(
				{
					connection: "Username-Password-Authentication", // Replace with your Auth0 connection name
					email: values.email,
					password: values.password
				},
				async (err) => {
					if (err) {
						let errorMessage = "An error occurred during signup.";

						if (err.code) {
							// auth0 error codes
							switch (err.code) {
								case "invalid_password":
									errorMessage =
										"Invalid password. Please choose a different password.";
									break;
								case "invalid_signup":
									errorMessage = "Invalid user for signup.";
									break;
								case "password_dictionary_error":
									errorMessage =
										"The chosen password is too common. Please choose a different password.";
									break;
								case "password_no_user_info_error":
									errorMessage =
										"The chosen password is based on user information. Please choose a different password.";
									break;
								case "password_strength_error":
									errorMessage =
										"The chosen password is too weak. Please choose a stronger one.";
									break;
								case "unauthorized":
									errorMessage =
										"You are not authorized to sign up for this application.";
									break;
								case "user_exists":
									errorMessage =
										"The user you are attempting to sign up has already signed up.";
									break;
								case "username_exists":
									errorMessage =
										"The username you are attempting to sign up with is already in use.";
									break;
								default:
									errorMessage = "An unexpected error occurred.";
							}
						}

						setToast({
							state: "open",
							message: errorMessage,
							type: "error"
						});
						setLoading(false);
					} else {
						// Check if user opted in for the newsletter
						if (values.newsLetter) {
							try {
								const sendEmail = await sendEmailToAlokee({
									details: {
										from: { email: "offer@alokee.com", name: "Alokee" },
										subject: "Newsletter Subscription"
									},
									dynamicTemplateData: {
										name: "New User",
										email: values.email,
										message: "Hey! I want to Subscribe for your newsletters."
									}
								});
								if (sendEmail.data) {
									setEmailToast({
										state: "open",
										message:
											"Your have successfully subscribed our newsletter!",
										type: "success",
										hideDuration: 5000
									});
								}
							} catch (e) {
								// Handle errors in newsletter subscription
								setEmailToast({
									state: "open",
									message: "Error Subscribing to our newsletter!",
									type: "error",
									hideDuration: 5000
								});
							}
						}
						// Continue with user sign-up success logic
						setToast({
							state: "open",
							message: "You have successfully created an account.",
							type: "success"
						});
						// Sent email to admins that new use just signed up.

						setTimeout(() => {
							navigate("/thank-you");
						}, 2000);
					}
				}
			);
		} catch (e) {
			setToast({
				state: "open",
				message: "Something went wrong! Check your network please.",
				type: "error"
			});
			setLoading(false);
		}
	};

	const handleGoogleLogin = () => {
		auth0Client.authorize({
			connection: "google-oauth2" // Specify the Google social connection
		});
	};

	return (
		<div>
			<Stack
				sx={{
					flexDirection: "row",
					alignItems: "center",
					"&:hover": {
						cursor: "pointer"
					},
					paddingTop: 10,
					paddingLeft: 12
				}}
				component="a"
				onClick={() => {
					navigate(
						window.location.pathname.startsWith("/app") ? "/app/home" : "/home"
					);
				}}
			>
				<img src={LogoColor} alt="logo" className={classes.logo} />
			</Stack>
			<Container
				maxWidth="xl"
				className={
					pathname == "/SignUp" || pathname == "/signup"
						? classes.signupPageContainer
						: classes.signupPageContainer
				}
			>
				<Toast {...toast} setState={setToast} />
				<Toast
					{...emailToast}
					setState={setEmailToast}
					style={{ marginTop: "50px" }}
				/>

				<Grid container justifyContent="flex-start" columnSpacing={3}>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<Typography className={classes.LargeTitle}>
							Sign up and save on
						</Typography>
						<Typography
							className={classes.LargeTitle}
							style={{
								fontWeight: 400
							}}
						>
							your dream home today.
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
						<Container maxWidth="xs" style={{ paddingLeft: 0 }}>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
								render={({ values, errors, handleChange, handleSubmit }) => {
									return (
										<Form>
											<Grid container>
												{activeStep === 0 && (
													<>
														<Grid item xs={12} sm={12} md={12}>
															<TextField
																name="email"
																type="email"
																label="Email"
																value={values.email}
																onChange={handleChange}
																fullWidth={true}
																required
																InputLabelProps={{
																	className: classes.customFormLabel
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={12} md={12}>
															<TextField
																name="password"
																type={showPassword ? "text" : "password"}
																label="Password"
																value={values.password}
																onChange={handleChange}
																fullWidth={true}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="toggle password visibility"
																				onClick={handleClickShowPassword}
																				onMouseDown={handleMouseDownPassword}
																			>
																				{showPassword ? (
																					<Visibility />
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																InputLabelProps={{
																	className: classes.customFormLabel
																}}
																required
															/>
														</Grid>
														<Grid item xs={12} sm={12} md={12}>
															<TextField
																name="confirmPassword"
																type={showPassword ? "text" : "password"}
																label="Confirm Password"
																value={values.confirmPassword}
																onChange={handleChange}
																fullWidth={true}
																required
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="toggle password visibility"
																				onClick={handleClickShowPassword}
																				onMouseDown={handleMouseDownPassword}
																			>
																				{showPassword ? (
																					<Visibility />
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																InputLabelProps={{
																	className: classes.customFormLabel
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={12} md={12}>
															<FormControlLabel
																control={
																	<Checkbox
																		name="eighteen"
																		checked={values.eighteen}
																		onChange={handleChange}
																		style={{
																			color: "#02A689"
																		}}
																	/>
																}
																label="I certify I am at least 18 years old"
															/>
															{errors.eighteen && (
																<div className={classes.error}>
																	{errors.eighteen}
																</div>
															)}
														</Grid>
														<Grid item xs={12} sm={12} md={12}>
															<FormControlLabel
																control={
																	<Checkbox
																		name="newsLetter"
																		checked={values.newsLetter}
																		onChange={handleChange}
																		style={{
																			color: "#02A689"
																		}}
																	/>
																}
																label="Sign me up for the Alokee newsletter"
															/>
														</Grid>
													</>
												)}
											</Grid>
											<Box
												py={2}
												style={{
													display: "flex",
													justifyContent: "space-between",
													flexDirection: "column"
												}}
											>
												{activeStep !== 0 && (
													<Button onClick={handleBack}>Back</Button>
												)}
												{activeStep == steps.length - 1 ? (
													<ButtonWrapper
														loading={loading}
														variant="outlined"
														loadingPosition="start"
														className={classes.button}
														fullWidth={true}
													>
														Sign Up Now
													</ButtonWrapper>
												) : (
													<Button
														variant="contained"
														className={classes.button}
														onClick={
															activeStep === steps.length - 1
																? handleSubmit
																: handleNext
														}
													>
														{"Next"}
													</Button>
												)}
											</Box>
											<Box pb={1}>
												<Button
													loading={loading}
													variant="outlined"
													loadingPosition="start"
													style={{
														color: "#003A7B",
														marginTop: 8,
														fontWeight: "bold",
														textTransform: "none",
														fontFamily: "Rubik",
														border: "1px solid #003A7B"
													}}
													onClick={handleGoogleLogin}
													fullWidth={true}
													startIcon={
														<img src={GoogleIcon} alt="Google Icon"></img>
													}
												>
													Sign Up with Google
												</Button>
											</Box>
											<Typography variant="subtitle2" display="inline">
												Already have an Account?
											</Typography>
											<Typography
												variant="subtitle2"
												display="inline"
												color="text"
												aria-label="Already Have an Account? Sign In Here."
												role="button"
												tabIndex={0}
												className={classes.signUp}
												onClick={() => {
													// window.location.href =
													// 	"https://www.alokeebeta.com/home";

													navigate("/login");
												}}
											>
												Sign in here
											</Typography>

											<Box
												py={2}
												sx={{
													marginLeft: 10
												}}
											></Box>
										</Form>
									);
								}}
							></Formik>
						</Container>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default SignUp;
