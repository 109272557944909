import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Box, Grid, Avatar, Typography, makeStyles } from "@material-ui/core";
import { Button, Toast, TextField, SingleSelect } from "../core";
import userIcon from "../../assets/userIcon.png";
import { useNavigate } from "react-router-dom";
import { createUser, getUserProfile, updateUser } from "../../services/users";
import { useAuth0 } from "@auth0/auth0-react";
import FileUpload from "../core/FileUpload";
import Footer from "../footer/footer";
import ImagePicker from "../UploadAvatar/ImagePicker";
import { useUserState } from "../../context/UserContext";

const initialValues = {
	firstName: "",
	lastName: "",
	email: "",
	address: "",
	state: "",
	city: "",
	zipCode: "",
	age: "",
	id: "",
	contactNumber: ""
};

const validationSchema = Yup.object().shape({
	firstName: Yup.string().required("first Name is required"),
	age: Yup.number()
		.min(18, "You must be at least 18 years old")
		.required("Age is required"),
	contactNumber: Yup.string()
		.matches(
			/^(?:\(([0-9]{3})\)|[0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
			"Phone number is not valid"
		)
		.required("Contact number is required"),
	lastName: Yup.string().required("last Name is required"),
	address: Yup.string().required("street is required"),
	state: Yup.string().required("state is required"),
	city: Yup.string().required("city is required"),
	zipCode: Yup.string()
		.matches(/^\d{5}$/, "Must be 5 digits")
		.required("ZIP code is required"),
	id: Yup.mixed()
});

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(3),
		padding: theme.spacing(7)
	},
	avatar: {
		backgroundColor: theme.palette.primary.main,
		minWidth: 120,
		minHeight: 120
	}
}));

const UpdateProfile = () => {
	const classes = useStyles();
	const { user } = useAuth0();
	const userState = useUserState();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState(null);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const [initValues, setInitValues] = useState(
		userState?.userProfile || initialValues
	);

	const onSubmit = async (values) => {
		try {
			setLoading(true);
			const data = new FormData();

			const modifiedValues = {
				...values,
				isProfileCompleted: true,
				auth0Id: user?.sub,
				email: user.email,
				age: String(values.age),
				contactNumber: cleanPhoneNumber(values.contactNumber)
			};

			data.append("id", modifiedValues.id);
			if (image) {
				data.append("photo", image);
			}

			data.append("otherValues", JSON.stringify(modifiedValues));

			const resp = await updateUser(data);
			if (resp.data.success && resp.data.message != "User Already Exists.") {
				setLoading(false);
				navigate("/app/dashboard?filter=Offers", { replace: true });
				return;
			} else {
				setToast({
					state: "open",
					message: resp.data.message,
					type: "error"
				});
				setLoading(false);
			}
		} catch (e) {
			setToast({
				state: "open",
				message: "Something went wrong! Check your network please.",
				type: "error"
			});
			setLoading(false);
		}
	};

	const cleanPhoneNumber = (phoneNumber) => {
		return phoneNumber.replace(/\D/g, ""); // Remove any non-digits
	};

	return (
		<>
			<Toast {...toast} setState={setToast} />
			<Box className={classes.root} maxWidth="xl" maxHeight="xl">
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					<Grid
						spacing={2}
						container
						item
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<ImagePicker
								setImage={setImage}
								imageSource={
									userState?.userProfile?.photo || user?.picture || userIcon
								}
							/>
						</Grid>
						<Grid item>
							<Typography variant="h5">
								{loading
									? "Updating your Profile..."
									: "Want to update your Profile?"}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						item
						direction="column"
						justifyContent="center"
						alignItems="center"
						xs={12}
						sm={12}
						lg={8}
						md={8}
					>
						<Formik
							initialValues={initValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
							render={({ values, errors, handleSubmit }) => (
								<Form>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="firstName"
												label="First Name"
												type="text"
												placeholder={user?.nickname}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="lastName"
												label="Last Name"
												type="text"
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="age"
												label="Age"
												type="number"
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="contactNumber"
												label="Contact Number"
												type="text"
												placeholder="(XXX) XXX-XXXX"
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={5}>
											<TextField
												name="address"
												label="Address Street"
												type="text"
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={3}>
											<TextField name="city" label="City" type="text" />
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField name="state" label="State" type="text" />
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField name="zipCode" label="Zip Code" type="text" />
										</Grid>

										<Grid item xs={12} sm={4} md={4}>
											<FileUpload
												disabled={loading}
												value={values.id || userState?.userProfile?.identityUrl}
												name="id"
												label="Upload your Id"
												onFileSelectError={({ error }) => alert(error)}
											/>
										</Grid>
									</Grid>
									<Box py={2}>
										<Button
											onClick={() => {
												handleSubmit();
											}}
											loading={loading || undefined}
										>
											{"Proceed"}
										</Button>
										<Button
											style={{ marginTop: "20px" }}
											color="default"
											onClick={() => {
												navigate("/app/dashboard?filter=Offers");
											}}
										>
											{"Cancel"}
										</Button>
									</Box>
								</Form>
							)}
						></Formik>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default UpdateProfile;
