import axiosInstance from ".";

export const getAllChats = async (params) => {
	return axiosInstance.post(`/userPropertyChat/getAllChats`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};
export const getChatById = async (params) => {
	return axiosInstance.post(`/userPropertyChat/getChatById`, {
		userPropertyId: params.userPropertyId
	});
};
export const createChat = async (params) => {
	return axiosInstance.post(`/userPropertyChat/createChat`, params);
};
