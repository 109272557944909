import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
	loaderBackground: {
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%"
	},
	description: {
		fontSize: "1.5rem",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		color: "#fff",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.125rem"
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "0.8rem"
		}
	},
	loader: {
		position: "fixed",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	},
	wrapper: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden"
		// paddingTop: 44
	},
	contentContainer: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "50px"
		}
	},
	content: {
		flex: "1 1 auto",
		height: "100%",
		overflow: "hidden",
		overflowX: "auto",
		flexGrow: 1
		// paddingTop: theme.spacing(1)
	},
	toolbarSpacing: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	},
	heroSection: {
		position: "relative",
		height: "570px"
	},
	image: {
		height: "100%",
		objectFit: "cover",
		width: "100%"
	},
	imageOverlay: {
		position: "absolute",
		top: "50%",
		left: "50%",
		height: "100%",
		width: "100%",
		transform: "translate(-50%,-50%)",
		backgroundColor: "rgba(0, 0, 0, 0.2)"
	},
	heroContent: {
		position: "absolute",
		top: "45%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		width: "100%"
	},
	heading: {
		fontSize: "3em",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		color: "#fff",
		width: "420px",
		lineHeight: 1.2,
		[theme.breakpoints.down("xs")]: {
			fontSize: "2.25em",
			width: "100%"
		}
	}
}));
