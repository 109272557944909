import { Container, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { Loader } from "../components/core";

import Cookies from "universal-cookie";
import { createUserPropertyEnvelope } from "../services/propertyForm";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router";
const cookies = new Cookies();
const useStyles = makeStyles((theme) => {
	return {
		root: {
			position: "relative",
			width: "100%",
			overflow: "hidden",
			paddingTop: "100%"
		},
		responsiveIframe: {
			position: "absolute",
			position: "fixed",
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			width: "100%",
			height: "100%",
			border: "none",
			overflow: "hidden"
		},
		loader: {
			position: "fixed",
			left: "50%",
			top: "50%"
		}
	};
});

function SigningPageVaForm({ route }) {
	const classes = useStyles();
	const { state } = useLocation();
	const [url, setUrl] = useState(state.redirectUrl);
	const mlsId = cookies.get("mlsId");
	const userPropertyId = cookies.get("userPropertyId");
	const envelopeId = cookies.get("envelopeId");
	const { user } = useAuth0();
	useEffect(() => {
		const embeddedSigning = async () => {
			window.scrollTo(0, 0);
			try {
				const updateEnvelopeStatus = await createUserPropertyEnvelope({
					userPropertyId,
					envelopeId,
					envelopeType: "va-form",
					auth0Id: user?.sub
				});
			} catch (e) {
				console.log(e);
			}
		};
		embeddedSigning();
	}, []);

	return (
		<Container maxWidth="lg" className={classes.root}>
			{url ? (
				<iframe
					title="docuSign-Esigning"
					className={classes.responsiveIframe}
					src={url}
				></iframe>
			) : (
				<Stack className={classes.loader}>
					<Loader type={"Circles"}></Loader>
				</Stack>
			)}
		</Container>
	);
}

export default SigningPageVaForm;
