import React from "react";
import { Container, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import ThankYouIcon from "../assets/ThankYouIcon.svg";
import { Link } from "react-router-dom";

const thankYouStyles = makeStyles((theme) => {
    return {
        thankYouContainer: {
            paddingTop: "170px",
            paddingBottom: "170px",
            [theme.breakpoints.down("sm")]: {
                paddingBottom: "230px"
            }
        },
        largeTitle: {
            color: theme.palette.primary.mainLight,
            textAlign: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: "2.5rem",
            fontWeight: theme.typography.fontWeightBold,
            [theme.breakpoints.down("sm")]: {
                fontSize: "2rem"
            }
        },
        subText: {
            color: "#000",
            textAlign: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: "1.5rem",
            [theme.breakpoints.down("sm")]: {
                fontSize: "1rem"
            }
        },
        button: {
            textTransform: "none",
            borderRadius: "5px",
            border: "1px solid #000",
            color: "#FFF",
            fontWeight: theme.typography.fontWeightBold,
            fontSize: "1rem",
            paddingLeft: "70px",
            paddingRight: "70px",
            background: theme.palette.primary.mainLight,
            '&:hover': {
                background: theme.palette.secondary.mainTwo
            }
        },
        linkStyle: {
            color: theme.palette.primary.mainLight,
            textAlign: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: "1.375rem",
            lineHeight: '128%',
            textDecorationLine: 'underline',
            [theme.breakpoints.down("sm")]: {
                fontSize: "1rem"
            }
        }
    }
});

const ThankYou = () => {
    const classes = thankYouStyles();

    return (
        <Container maxWidth="md" className={classes.thankYouContainer}>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={5}>
                <Grid item>
                    <img src={ThankYouIcon} alt="Thank you icon" />
                </Grid>
                <Grid item>
                    <Typography className={classes.largeTitle}>Thank You!</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.subText}>You have successfully signed up to Alokee.</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.subText}>An email has been sent to you.
                        Please confirm your email and sign into start making offers.</Typography>
                </Grid>
                <Grid item>
                    <Link to="/login">
                        <Button className={classes.button}>Sign In</Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="/home" className={classes.linkStyle}>go back to homepage</Link>
                </Grid>
            </Grid>
        </Container>
    )
};

export default ThankYou;