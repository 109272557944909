import { Box } from "@material-ui/core";
import { Grid, Paper, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserInspections } from "../../services/inspector";
import { useUserState } from "../../context/UserContext";
import makeStyles from "./styles";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FileUpload from "../core/FileUpload";
import { uploadFile } from "../../services/propertyForm";

const initialValues = {
	inspectionReport: ""
};

const validationSchema = Yup.object().shape({
	inspectionReport: Yup.mixed().required("inspectionReport is required")
});

function InspectorDashboard() {
	const [events, setEvents] = useState();
	const [loading, setLoading] = useState();
	const [inspectorDetails, setInspectorDetails] = useState();
	const [payload, setPayload] = useState({});
	const userState = useUserState();
	const classes = makeStyles();

	const onUploadInspectionReport = async (values) => {
		try {
			setLoading(true);
			values.userId = payload.userId;
			values.inspectionId = payload.inspectionId;
			values.isInspectionReport = true;
			const data = new FormData();
			data.append("document", values.inspectionReport);
			data.append("otherValues", JSON.stringify(values));
			const resp = await uploadFile(data);
			if (resp.data.success) {
				setLoading(false);
				window.location.reload();
				return;
			} else {
				setLoading(false);
			}
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		const getInspectors = async () => {
			try {
				const resp = await getUserInspections({
					email: userState?.userProfile?.email,
					auth0Id: userState?.userProfile?.sub
				});

				setInspectorDetails(resp.data.data.data.rows[0]);
				setEvents(resp.data.data.data.rows[0].user_inspections);
			} catch (e) {
				console.log(e);
			}
		};
		getInspectors();
	}, [userState?.userProfile?.email]);
	return (
		<Box className={classes.container} >
			<Grid
				container
				flexDirection="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={2}
				className={classes.grid}
			>
				{events &&
					events?.length &&
					events.map((data) => {
						return (
							<Grid item>
								<Paper className={classes.card}>
									<Grid
										container
										flexDirection="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
									>
										<Grid item>
											<Typography className={classes.cardHeading}>
												{data.address}
											</Typography>
										</Grid>
										<Grid item>
											<Typography>{inspectorDetails.postCode}</Typography>
										</Grid>
									</Grid>
									<Box mt={5}>
										<Typography>{inspectorDetails.name}</Typography>
									</Box>
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={onUploadInspectionReport}
										render={({ values, errors, handleSubmit }) => (
											<Form>
												<Grid
													container
													direction="row"
													justifyContent="flex-start"
													alignItems="flex-start"
												>
													<Grid item xs={6} sm={6} md={6}>
														<FileUpload
															disabled={loading || data.inspectionUploaded}
															value={values.inspectionReport}
															name="inspectionReport"
															label={
																data.inspectionUploaded
																	? "Report Uploaded"
																	: "Upload Report"
															}
															onFileSelectError={({ error }) => alert(error)}
														/>
													</Grid>
													<Grid item xs={6} sm={6} md={6}>
														<Box py={3} px={1}>
															<Button
																type="submit"
																fullWidth={false}
																loading={loading || undefined}
																disabled={
																	data.inspectionUploaded ? true : false
																}
																onClick={() => {
																	setPayload({
																		inspectionId: data.id,
																		userId: data.userId
																	});
																	handleSubmit(values);
																}}
															>
																{data.inspectionUploaded ? "Done" : "Proceed"}
															</Button>
														</Box>
													</Grid>
												</Grid>
											</Form>
										)}
									></Formik>
								</Paper>
							</Grid>
						);
					})}
			</Grid>
		</Box>
	);
}

export default InspectorDashboard;
