import axiosInstance from ".";

export const getProperties = async (filter, params) => {
	return axiosInstance.post(`/bridgeMLS/getProperties`, {
		params,
		filter: filter,
		auth0Id: params.auth0Id
	});
};

export const getPropertyById = async (params) => {
	return axiosInstance.post(`/bridgeMLS/getPropertyById`, {
		params,
		auth0Id: params.auth0Id
	});
};
