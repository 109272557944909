import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Cookies from "universal-cookie";
import {
	updateSignByBuyerCounterOffer,
	updateSignBySellerCounterOffer
} from "../services/userPropertyEnvelope";
import { useAuth0 } from "@auth0/auth0-react";
import TransparentLoader from "../components/core/TransparentLoader";
const cookies = new Cookies();

function RedirectCounterOffer() {
	let [searchParams, setSearchParams] = useSearchParams();
	const envelopeStatus = searchParams.get("envelopeStatus");
	const counterOfferType = searchParams.get("counterOfferType");
	const userPropertyId = cookies.get("userPropertyId");
	const userPropertyEnvelopeId = cookies.get("userPropertyEnvelopeId");
	const signerRole = cookies.get("signerRole");
	const { user } = useAuth0();
	useEffect(() => {
		const updateUserProperty = async () => {
			let resp;
			try {
				if (counterOfferType == "seller-counter-offer") {
					resp = await updateSignBySellerCounterOffer({
						envelopeStatus,
						userPropertyId,
						userPropertyEnvelopeId,
						signerRole,
						auth0Id: user?.sub
					});
				} else {
					resp = await updateSignByBuyerCounterOffer({
						envelopeStatus,
						userPropertyId,
						userPropertyEnvelopeId,
						signerRole,
						auth0Id: user?.sub
					});
				}
				if (resp.data.success && resp.data.data.envelopeCompleted) {
					window.top.location.href = `${resp.data.data.returnUrl}?envelopeStatus=Completed&signerRole=${resp.data.data.signerRole}`;
				} else {
					window.top.location.href = `${resp.data.data.returnUrl}?envelopeStatus=InComplete&signerRole=${resp.data.data.signerRole}`;
				}
			} catch (e) {}
		};
		updateUserProperty();
	}, []);

	return <TransparentLoader />;
}

export default RedirectCounterOffer;
