import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Typography,
	Radio,
	RadioGroup,
	FormControlLabel
} from "@mui/material";
import moment from "moment";
import { getShowingsById } from "../services/userShowings";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { AppBar } from "../components/AppBar";
import TextDialog from "../components/core/TextDialog";
import { sendShowingEmailToBuyerAndSeller } from "../services/propertyForm";

const ViewShowings = () => {
	const [selectedTime, setSelectedTime] = useState("");
	const [showingData, setShowingData] = useState(null);
	const [confirmed, setConfirmed] = useState(false);

	const [loading, setLoading] = useState(false);
	useState([]);
	const { mlsId, address, id } = useParams();
	const [showConfirmAppointment, setShowConfirmAppointment] = useState({
		message: "",
		state: false
	});
	const handleConfirm = () => {
		if (selectedTime) {
			onConfirm(selectedTime);
		}
	};

	const onConfirm = async (selectedTime) => {
		console.log(selectedTime);
		try {
			setLoading(true);
			const email = await sendShowingEmailToBuyerAndSeller({
				selectedTime,
				showingId: id,
				address
			});

			if (email.data) {
				setShowConfirmAppointment((prevState) => {
					return {
						message: "Thank you for the confirmation.",
						state: true
					};
				});
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};
	useEffect(async () => {
		try {
			const resp = await getShowingsById({ id });
			const showingDetails = resp.data.data[0];
			let times = [];
			times[0] = showingDetails.showingTimeOne;
			times[1] = showingDetails.showingTimeTwo;
			times[2] = showingDetails.showingTimeThree;
			setShowingData({ times, ...showingDetails });
			if (showingDetails.confirmedTime) {
				setSelectedTime(showingDetails.confirmedTime);
			}
			if (showingDetails.status == "confirmed") {
				setConfirmed(true);
			}
		} catch (e) {
			console.log(e);
		}
	}, []);

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			sx={{ mt: 2 }}
		>
			<Helmet>Confirm Showing | {address}</Helmet>
			{showConfirmAppointment.state && (
				<TextDialog
					showActions={true}
					message={showConfirmAppointment.message}
					state={showConfirmAppointment.state}
					stateHandler={setShowConfirmAppointment}
					submitHandler={() => {
						window.location.href = process.env.REACT_APP_URL;
					}}
					onClose={() => {
						window.location.href = process.env.REACT_APP_URL;
					}}
					showField={false}
				></TextDialog>
			)}
			<AppBar background={true} whiteBackground={true} />
			<Box
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				sx={{ mt: 10 }}
			>
				<Typography variant="h6" style={{ marginBottom: 10 }}>
					Select Showing Time
				</Typography>
				<RadioGroup
					aria-label="showing-time"
					name="showing-time"
					value={selectedTime}
					onChange={(e) => setSelectedTime(e.target.value)}
				>
					{showingData?.times &&
						showingData?.times.length > 0 &&
						showingData?.times?.map((time, index) => (
							<FormControlLabel
								key={index}
								value={time}
								control={<Radio />}
								label={new Date(time).toLocaleString()}
							/>
						))}
				</RadioGroup>
				<Typography
					variant="h5"
					style={{ marginTop: 10, color: "#003A7B", fontSize: 18 }}
				>
					The buyer {showingData?.commission > 0 ? "has" : "has not"} signed a
					buyer agency agreement and will pay{" "}
					{showingData?.commission > 0
						? showingData?.commission + "% of the Sale Price "
						: "$50 "}
					for the showing.
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={handleConfirm}
					disabled={!selectedTime || loading || confirmed}
					sx={{ mt: 2 }}
				>
					Confirm Appointment
				</Button>
			</Box>
		</Box>
	);
};

export default ViewShowings;
