export const STATUS_TOOLTIPS = {
	"awaiting-alokee-approval":
		"Your offer has been signed and is being reviewed by Alokee before it is sent to the Seller. Once approved, it will be sent to the Seller Agent or Seller and this button will read Sent to Seller Agent.",
	"awaiting-signatures":
		"You have created and signed an offer and are awaiting signatures from an additional Buyer. If you want to remind that buyer they have an offer awaiting signature, ping them here.",
	rejected:
		"your offer has been officially rejected by the Seller. If you would like to offer again, change your offer here and resubmit it.",
	"pulled-back":
		"Alokee has retracted this offer to the Seller. If you would like to offer again, change your offer here and resubmit it.",
	"sent-to-seller":
		"Alokee has officially delivered your offer to the Seller Agent who must present your offer to the Seller in a timely manner. You are awaiting response from the Seller, but please be advised they do not have to respond to you. Your offer expires on the date specified on your contract. If you would like to remind the Seller Agent you have submitted an offer, ping them here.",
	"sent-to-seller-agent":
		"Alokee has officially delivered your offer to the Seller Agent who must present your offer to the Seller's Agent in a timely manner. You are awaiting response from the Seller Agent, but please be advised they do not have to respond to you.",
	"in-progress": "You have started an offer that you have not finished.",
	"under-review":
		"Alokee admin and brokerage service are reviewing your offer for accuracy. When finished, they will send it to the Seller Agent and this will change to 'Sent-To-Seller.'"
};
export const STATUS_OPTIONS = [
	{ key: "Rejected", value: "rejected" },
	{ key: "Closed", value: "closed" },
	{
		key: "Under Review",
		value: "under-review"
	},
	{ key: "Awaiting approval", value: "awaiting-alokee-approval" }
];
export const PREDICTION_PROMPT = (predictionData, propertyData) =>
	`${predictionData} , I am offering on property with List Price of ${propertyData.ListPrice}, Lot Size ${propertyData.LotSizeSquareFeet}, Year Built ${propertyData.YearBuilt}, ${propertyData.BedroomsTotal} Bedrooms Total and  ${propertyData.BathroomsFull} Bathrooms Full and Living Area is ${propertyData.LivingArea}. Based on housing data that I gave you, tell me what should be my close price range on this property so that my chances for acceptance of offer from seller is maximized. Please focus on each and every feature in data and give decision in "ONE WORD just price RANGE in dollars with proper formatting like $XXX,XXX,XXX" DO NOT WRITE SENTENCE`;

export function generateRandomString() {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";

	for (let i = 0; i < 7; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters.charAt(randomIndex);
	}

	return result;
}
export const pricePredictorTooltip =
	"Based on real-time data and machine learning, a home like this should sell in this price range.";
