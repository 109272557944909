import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Loader } from "../core";
import BuildingImage from "../../assets/Alokee-landing.png";
import HeroImage from "../../assets/Gradient-02.png";
const useStyles = makeStyles((theme) => ({
	loaderBackground: {
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%"
	},
	loader: {
		position: "fixed",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	},
	heroSection: {
		position: "relative",
		height: "100%",
		backgroundColor: theme.palette.primary.main
	},
	image: {
		height: "100%",
		objectFit: "cover",
		width: "100%"
	},
	img: {
		position: "absolute",
		right: 0,
		bottom: 0,
		height: "600px",
		[theme.breakpoints.down("sm")]: {
			height: "300px"
		}
	}
}));
function BackgroundLoader() {
	const classes = useStyles();

	return (
		<div className={classes.heroSection}>
			<div className={classes.loader}>
				<Loader height={90} width={150} color="#fff" />
			</div>
		</div>
	);
}

export default BackgroundLoader;
