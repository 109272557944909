import React, { useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const SharePropertyLink = ({ mlsid }) => {
    const [isCopied, setIsCopied] = useState(false);

    const currentUrl = `${window.location.origin}/app/property-details/${mlsid}`;

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(currentUrl);
            setIsCopied(true);
        } catch (error) {
            console.error('Failed to copy to clipboard:', error);
        }
    };

    const handleCloseToast = () => {
        setIsCopied(false);
    };

    return (
        <div>
            <IconButton onClick={copyToClipboard} aria-label='Copy Property Link'>
                <ShareIcon />
            </IconButton>
            <Snackbar
                open={isCopied}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message="Property link copied to clipboard!"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseToast}
                    >
                        <FileCopyIcon fontSize="small" />
                    </IconButton>
                }
            />
        </div>
    );
};

export default SharePropertyLink;
