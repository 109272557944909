import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
	buttonContainer: {
		paddingTop: 10,
		marginLeft: 60,
		width: "50%"
	},
	MLSDetails: {
		display: "none"
	},
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		backgroundColor: "#fff"
	},
	button: {
		margin: "10px !important"
	},
	paper: {
		height: 335
	},
	root: {
		marginTop: 10
	},
	journeyContainer: {
		paddingTop: 20,
		flexWrap: "nowrap",
		minHeight: 305
	},
	img: {
		height: "235px",
		width: "100%",
		maxWidth: "100%",
		objectFit: "cover"
	},
	formStatusChip: {
		position: "absolute",
		top: "20px",
		fontFamily: "Rubik",
		fontSize: "12px !important",
		fontWeight: "400",
		color: "#fff !important",
		right: "25px"
	},
	listedPriceText: {
		color: theme.palette.text.primary,
		position: "relative",
		marginBottom: 5,
		marginLeft: 20
	},
	price: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: "400 !important",
		color: "#000",
		cursor: "pointer",
		fontSize: "24px !important"
	},
	heading: {
		fontSize: "1.95em",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		color: theme.palette.text.primary,
		lineHeight: 1.2,
		[theme.breakpoints.down("xs")]: {
			fontSize: "1.55em"
		}
	},
	subheading: {
		fontSize: "1.65em",
		[theme.breakpoints.down("xs")]: {
			fontSize: "1.35em"
		}
	},
	text: {
		fontSize: "1.15em",
		lineHeight: 1,
		letterSpacing: "0.5px",
		fontWeight: theme.typography.fontWeightRegular,
		[theme.breakpoints.down("xs")]: {
			fontSize: "1em"
		},
		marginTop: "10px"
	},
	propDet: {
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: "1.05em",
		color: theme.palette.text.primary
	},
	scrollableContainer: {
		flex: 1,
		overflowY: "auto",
		padding: theme.spacing(2)
	},
	subtext: {
		marginTop: "20px",
		fontSize: "1.25em",
		color: "#000",
		fontWeight: theme.typography.fontWeightRegular,
		lineHeight: 1,
		[theme.breakpoints.down("xs")]: {
			fontSize: "1em"
		}
	},
	listItems: {
		minWidth: "50%",
		fontFamily: theme.typography.fontFamily,
		marginBottom: "12px",
		fontWeight: theme.typography.fontWeightLight,
		color: theme.palette.text.primary,
		fontSize: "1em"
	},
	bonusPriceText: {
		color: theme.palette.text.primary,
		position: "relative",
		textTransform: "uppercase",
		marginBottom: 5,
		marginLeft: 50,
		"&::before": {
			content: "''",
			left: "-16px",
			position: "absolute",
			width: "1.2px",
			height: "45px",
			backgroundColor: theme.palette.text.primary
		}
	},
	icon: {
		color: theme.palette.text.primary,
		height: "25px",
		width: "25px",
		marginRight: "3px"
	},
	amnities: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		fontSize: "16px"
	},
	sliderCont: {
		maxWidth: "800px",
		overflow: "hidden",
		borderRadius: "8px",
		alignSelf: "center",
		[theme.breakpoints.up("lg")]: {
			maxWidth: "1000px"
		}
	},
	image: {
		height: "400px",
		width: "100%",
		objectFit: "fill"
	},

	carousel: {
		"& button": {
			backgroundColor: "rgba(242, 242, 242, 0.5) !important",
			color: "#fff",
			height: "80px",
			width: "80px",
			borderRadius: "40px",
			top: "50% !important",
			transform: "translateY(-50%)"
		}
	},
	offerBtn: {
		border: "2px solid #003a7b",
		borderRadius: "10px",
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		paddingRight: "25px",
		paddingLeft: "25px",
		fontSize: "1.3em",
		width: "300px",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: "none",
		transition: "all 0.3s ease-in",
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
			color: "#fff"
		}
	},
	cashBonus: {
		cursor: "pointer",
		fontSize: "16px",
		fontWeight: "600 !important",
		color: "#000",
		marginTop: 10
	},
	cashBonusText: {
		fontWeight: "bold",
		fontSize: "20px",
		width: "240px",
		overflowWrap: "break-word"
	},
	address: {
		color: theme.palette.text.primary,
		position: "relative",
		textTransform: "uppercase",
		marginBottom: 5,
		marginLeft: 40
	},
	addressInContainer: {
		fontWeight: "400 !important",
		color: "#686868",
		fontSize: "16px !important",
		cursor: "pointer"
	},
	agentImage: {
		height: "75px",
		width: "75px",
		objectFit: "contain"
	},
	loader: {
		position: "fixed",
		left: "50%",
		top: "50%"
	}
}));
