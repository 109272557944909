import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
	Box,
	Container,
	makeStyles,
	Select,
	Typography
} from "@material-ui/core";
import Table from "../table/Table";
import {
	getAllUserPropertyFormById,
	sendEmail,
	updateUserPropertyStatus
} from "../../services/propertyForm";
import CommentForm from "./CommentForm";
import { getAllUsersProperties } from "../../services/propertyForm";
import PropertyForm from "../Properties/PropertyForm";
import {
	Avatar,
	Chip,
	CircularProgress,
	IconButton,
	Grid,
	Button,
	MenuItem
} from "@mui/material";
import doneIcon from "../../assets/done.png";
import TextDialog from "../core/TextDialog";
import moment from "moment";
import { updateSellerAgentInfo } from "../../services/userPropertyEnvelope";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadDocumentById, sendDocument } from "../../services/eSigning";
import { ImportContactsOutlined } from "@material-ui/icons";
import { Link, Navigate } from "react-router-dom";
import { saveAs } from "file-saver";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import jwtDecode from "jwt-decode";
import { STATUS_OPTIONS } from "../../configs/siteData";
import Toolbar from "./Toolbar/Toolbar";
import Inbox from "../Chat/Inbox/Inbox";
import { getAllInspectors } from "../../services/inspector";
import Inspector from "./Toolbar/Forms/Inspector";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(2),
		overflow: "hidden"
	},
	buttonContainer: {
		marginTop: 10,
		marginLeft: 60,
		[theme.breakpoints.down("xs")]: {
			marginLeft: 0
		}
	},
	iconColor: {
		color: "#003a7b"
	},
	image: {
		position: "fixed !important",
		bottom: "3%",
		left: "95%",
		height: "60px !important",
		width: "60px !important",
		zIndex: 20,
		borderRadius: "0 !important"
	}
}));

const headers = [
	{
		label: "ID",
		value: "id",
		type: "numeric",
		filterKey: "id",
		filterType: "text"
	},
	{
		label: "Buyer Email",
		value: "email",
		filterKey: "email",
		filterType: "text"
	},
	{
		label: "Buyer Name",
		value: "name",
		filterKey: "firstName",
		filterType: "text"
	},
	{
		label: "Buyer's Identity",
		value: "identityUrl"
	},
	{
		label: "Property Address",
		value: "address",
		filterKey: "address",
		filterType: "text"
	},

	{
		label: "Status",
		value: "status",
		filterKey: "status",
		filterType: "select",
		options: [
			{ key: "Rejected", value: "rejected" },
			{ key: "Closed", value: "closed" },
			{
				key: "Under Review",
				value: "under-review"
			},
			{ key: "Awaiting approval", value: "awaiting-alokee-approval" }
		]
	},
	{
		label: "MLS ID",
		value: "propertyId",
		filterKey: "propertyId",
		filterType: "text"
	},
	{
		label: "Last Signed By",
		value: "lastSignedBy"
	},

	{ label: "Created At", value: "createdAt", type: "date" },
	{ label: "Updated At", value: "updatedAt", type: "date" }
];
const inspectionTableHeaders = [
	{
		label: "ID",
		value: "id",
		type: "numeric",
		filterKey: "id",
		filterType: "text"
	},
	{
		label: "Inspector's Email",
		value: "email",
		filterKey: "email",
		filterType: "text"
	},
	{
		label: "Inspector's Name",
		value: "name",
		filterKey: "Name",
		filterType: "text"
	},
	{
		label: "Postal Code",
		value: "postCode",
		filterKey: "postCode",
		filterType: "text"
	},
	{
		label: "Inspector's Calendly",
		value: "calendlyLink"
	},
	{ label: "Created At", value: "createdAt", type: "date" },
	{ label: "Updated At", value: "updatedAt", type: "date" }
];

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD"
});
function Properties() {
	const classes = useStyles();

	const [formComment, setFormComment] = useState();
	const [propertyStatus, setPropertyStatus] = useState("closed");
	const [showConfirmOfferSent, setShowConfirmOfferSent] = useState({
		message: "",
		state: false
	});
	const [showCommentForm, setShowCommentForm] = useState({
		message: "",
		state: false
	});
	const [showStatusChangeForm, setShowStatusChangeForm] = useState({
		message: "",
		state: false
	});
	const [askSellerAgentInfo, setAskSellerAgentInfo] = useState({
		message: "",
		state: false
	});
	const [isCommentFormVisible, setCommentFormVisible] = useState(false);
	const [propertyData, setPropertyData] = useState("");
	const [propertyId, setPropertyId] = useState("");
	const [isPropertyFormVisible, setPropertyFormVisible] = useState(false);
	const [isInboxVisible, setInboxVisible] = useState(false);
	const [isInspectorsVisible, setInspectorsVisible] = useState(false);
	const [sendingToBuyer, setSendingToBuyer] = useState(false);
	const [revisedFields, setRevisedFields] = useState({});
	const [loadingSellerAgentInfo, setLoadingSellerAgentInfo] = useState(false);
	const [comments, setComments] = useState([]);
	const [inspectorForm, setInspectorForm] = useState("");
	const [viewIdentity, setViewIdentity] = useState({
		state: false,
		url: ""
	});
	const [viewInspectorForm, setViewInspectorForm] = useState({
		state: false,
		url: ""
	});
	const [downloading, setDownLoading] = useState(false);
	const { user, getAccessTokenSilently, logout } = useAuth0();
	const [isAdmin, setIsAdmin] = useState(true);

	useEffect(() => {
		const getUserIsAdmin = async () => {
			const token = await getAccessTokenSilently();
			const userData = jwtDecode(token);
			if (userData.permissions[0] == "read:admin-data") {
				setIsAdmin(true);
				return;
			} else {
				setIsAdmin(false);
			}
		};
		getUserIsAdmin();
	}, []);

	const fetchAllUsersProperties = async (params) => {
		try {
			params.auth0Id = user?.sub;
			const resp = await getAllUsersProperties(params);

			const users = resp.data.data.data.rows.map((data) => {
				return {
					...data,
					name: data.user.firstName + " " + data.user.lastName,
					identityUrl: (
						<div
							onClick={() => {
								if (
									typeof data.user.identityUrl == "string" &&
									data.user.identityUrl.includes("pdf")
								) {
									window.open(data.user.identityUrl, "_blank");
								} else
									setViewIdentity((prev) => {
										return {
											url: data.user.identityUrl,
											state: true
										};
									});
							}}
						>
							<Avatar
								alt={data.user.name}
								src={data.user.identityUrl}
								style={{ marginRight: "1em" }}
							/>
						</div>
					),
					email: data.user.email,
					address: data.property_form.address,
					propertyId: data.property_form.propertyId,
					status: (
						<Chip
							key={data.status}
							label={data.status}
							style={{
								margin: "0.1rem",
								backgroundColor:
									data.status == "Pending" ||
									data.status == "under-review" ||
									data.status == "awaiting-signatures" ||
									data.status == "in-progress"
										? "#FFC107"
										: "" || data.status == "Active" || data.status == "accepted"
										? "#28A745"
										: data.status == "rejected"
										? "#FF4842"
										: "#17A2B8",
								color: "#000000"
							}}
							color="primary"
							size="large"
						/>
					),
					envelopeStatus: (
						<Chip
							key={data.envelopeStatus}
							label={data.envelopeStatus}
							style={{
								margin: "0.1rem",
								backgroundColor:
									data.envelopeStatus == "signing_complete"
										? "#28A745"
										: "#FFC107",

								color: "#000000"
							}}
							color="primary"
							size="large"
						/>
					),
					signedBy: data.user_property_envelopes.map((data) => {
						if (data.signedBy) {
							return (
								<Chip
									key={data.signedBy}
									label={data.signedBy ? data.signedBy : "no one"}
									style={{
										margin: "0.1rem",
										backgroundColor: "#C1C745",

										color: "#000000"
									}}
									color="primary"
									size="large"
								/>
							);
						}
					}),
					lastSignedBy: (
						<Chip
							key={data?.user_property_envelopes?.signedBy}
							label={
								data?.user_property_envelopes?.signedBy
									? data?.user_property_envelopes?.signedBy
									: "no one"
							}
							style={{
								margin: "0.1rem",
								backgroundColor: "#C1C745",

								color: "#000000"
							}}
							color="primary"
							size="large"
						/>
					)
				};
			});
			return {
				total: resp.data.data.data.rows.length,
				data: users,
				count: resp.data.data.data.count
			};
		} catch (e) {}
	};
	const fetchAllInspectors = async (params) => {
		try {
			params.auth0Id = user?.sub;
			const resp = await getAllInspectors(params);
			const inspectors = resp.data.data.data.rows;

			return {
				total: resp.data.data.data.rows.length,
				data: inspectors,
				count: resp.data.data.data.count
			};
		} catch (e) {}
	};

	const onSuccess = async (id) => {
		setSendingToBuyer(true);
		const resp = await getAllUserPropertyFormById({ id, auth0Id: user?.sub });
		const propertyData = resp.data.data.data[0];
		const data = resp.data.data.data[0].property_form;

		if (data) {
			try {
				const url = `${process.env.REACT_APP_URL}recipientForm/buyerAgent/${data.buyerAgentCode}/${data.propertyId}/${propertyData.id}`;
				const email = await sendEmail({
					auth0Id: user?.sub,
					details: {
						from: { email: "offer@alokee.com", name: "Alokee" },
						subject: `Offer on ${data.propertyId}, ${data.address}`,
						text: `${url}`
					},
					dynamicTemplateData: {
						name: "Hamed",
						url: url,
						propertyAddress: data.address,
						purchasePrice: formatter.format(data.purchasePrice),
						depositAmount: formatter.format(
							parseInt((data.deposit / 100) * data.purchasePrice).toFixed(2)
						),
						loanAmount: formatter.format(
							parseInt((data.loan / 100) * data.purchasePrice).toFixed(2)
						),
						closingDate: new Date(
							moment(new Date(), "DD-MM-YYYY").add(data.closingDate, "days")
						).toLocaleDateString(),
						expirationDate: new Date(
							moment(new Date(), "DD-MM-YYYY").add(data.expiryDate, "days")
						).toLocaleDateString()
					},
					id
				});

				if (email.data) {
					setShowConfirmOfferSent({
						message: "This offer has been sent to Buyer Agent",
						state: true
					});
					setSendingToBuyer(false);
					setPropertyFormVisible(false);
				}
			} catch (e) {}
		}
	};
	const onSubmitSellerAgentInfo = async (values) => {
		try {
			setLoadingSellerAgentInfo(true);

			const resp = await updateSellerAgentInfo({
				userPropertyId: values.userPropertyId,
				sellerAgentInfo: values,
				auth0Id: user?.sub
			});
			setLoadingSellerAgentInfo(false);
			if (resp.data.success) {
				onSuccess(values.userPropertyId);
				setAskSellerAgentInfo((prevState) => {
					return {
						message: "",
						state: false
					};
				});
			}
			setLoadingSellerAgentInfo(false);
		} catch (e) {
			setLoadingSellerAgentInfo(false);
		}
	};
	const updateStatus = async (status) => {
		try {
			const resp = await updateUserPropertyStatus({
				status,
				id: propertyData.id
			});
			if (resp.data.data.success) {
				setPropertyFormVisible(false);
			}
		} catch (e) {}
	};
	const tableProps = {
		headers,
		getData: fetchAllUsersProperties,
		onDetails: async (details) => {
			const resp = await getAllUserPropertyFormById({
				id: details.id,
				auth0Id: user?.sub
			});
			const data = resp.data.data.data[0].property_form;
			data.userPropertyId = resp.data.data.data[0].id;
			data.id = resp.data.data.data[0].property_form.id;
			data.status = resp.data.data.data[0].status;
			data.buyer =
				resp.data.data.data[0].user.firstName +
				" " +
				resp.data.data.data[0].user.lastName;

			setComments(resp.data.data.data[0].comments);
			if (
				resp.data.data.data[0].user_property_envelopes[0] &&
				resp.data.data.data[0].user_property_envelopes[0].revisedFields
			) {
				setRevisedFields(
					JSON.parse(
						resp.data.data.data[0].user_property_envelopes[0].revisedFields
					)
				);
			}

			if (data.partners) {
				data.partners = [JSON.parse(data.partners)];
			}
			if (!data.partners) {
				data.partners = [];
			}
			if (data.entity) {
				data.entity = [JSON.parse(data.entity)];
			}
			if (!data.entity) {
				data.entity = [];
			}
			if (details.identityUrl?.props?.children?.props?.src?.length) {
				data.buyerIdentity = details.identityUrl?.props?.children?.props?.src;
			}
			if (data) {
				setPropertyData(data);
				setPropertyId(data.propertyId);
				setPropertyFormVisible(true);
				return {
					total: resp.data.data.data.count,
					data: data,
					count: resp.data.data.data.count,
					identityUrl: details.identityUrl
				};
			}
		}
	};
	const inspectTableProps = {
		headers: inspectionTableHeaders,
		getData: fetchAllInspectors,
		onEdit: (InspectorObj) => {
			setInspectorForm(InspectorObj);
			setViewInspectorForm((prev) => {
				return { ...prev, state: true };
			});
		}
	};
	const handleChange = (event) => {
		setPropertyStatus(event.target.value);
	};

	if (user && !isAdmin) {
		logout({
			logoutParams: {
				returnTo: `${process.env.REACT_APP_URL}home`
			}
		});
	}

	return (
		<div style={{ marginTop: 80 }}>
			<Helmet>
				<title> Admin Portal | Alokee</title>
			</Helmet>
			{isCommentFormVisible ? (
				<CommentForm
					comment={formComment}
					commentHandler={setFormComment}
					commentFormHandler={setCommentFormVisible}
				/>
			) : isPropertyFormVisible && propertyData && propertyId ? (
				<Box className={classes.root}>
					<Grid
						container
						direction="row"
						justifyContent="space-evenly"
						alignItems="center"
						className={classes.buttonContainer}
					>
						<Grid>
							<IconButton
								style={{ color: "#003a7b" }}
								disabled={downloading}
								onClick={async () => {
									try {
										setDownLoading(true);
										const response = await downloadDocumentById({
											propertyId,
											userPropertyId: propertyData.id
										});
										const file = new Blob([response.data], {
											type: "application/pdf"
										});
										//Build a URL from the file
										const fileURL = URL.createObjectURL(file);
										//Open the URL on new Window
										window.open(fileURL, "_blank");
										saveAs(file, `MLS-${propertyData.id}.pdf`);
										setDownLoading(false);
									} catch (e) {
										setDownLoading(false);
									}
								}}
							>
								{!downloading ? (
									<CloudDownloadIcon />
								) : (
									<CircularProgress size={20} />
								)}
								<Typography
									style={{
										marginLeft: "10px"
									}}
									color="textSecondary"
								>
									{!downloading ? "Offer Form" : "Downloading..."}
								</Typography>
							</IconButton>
						</Grid>
						<Grid item xs={4} sm={4} lg={2} xl={2} md={4}>
							<IconButton
								style={{ color: "#003a7b" }}
								disabled={downloading}
								onClick={async () => {
									try {
										setDownLoading(true);
										const response = await sendDocument({
											propertyId,
											userPropertyId: propertyData.id,
											docName: "NEA.pdf",
											fileName: "Non Exclusive Agreement",
											auth0Id: user?.sub
										});
										
										//Open the URL on new Window
										window.open(response.data.redirectUrl, "_blank");
										setDownLoading(false);
									} catch (e) {
										setDownLoading(false);
									}
								}}
							>
								{!downloading ? (
									<CloudDownloadIcon />
								) : (
									<CircularProgress size={20} />
								)}
								<Typography
									style={{
										marginLeft: "10px"
									}}
									color="textSecondary"
								>
									{!downloading ? "Buyer Broker Agreement" : "Downloading..."}
								</Typography>
							</IconButton>
						</Grid>
						<Grid item xs={2} sm={2} lg={2} xl={2} md={2}>
							<IconButton style={{ color: "#003a7b" }}>
								<ImportContactsOutlined />
								<Link
									target="_blank"
									to={`/MLSDetails/${"admin"}/${"Alkmq3s1234ss"}/${propertyId}/${
										propertyData.id
									}`}
									style={{ overflowX: "visible", color: "black" }}
								>
									<Typography
										style={{
											marginLeft: "10px"
										}}
										color="textSecondary"
									>
										MLS Details
									</Typography>
								</Link>
							</IconButton>
						</Grid>
						<Grid item xs={4} sm={4} lg={4} xl={4} md={4}>
							<Button
								style={{ color: "#003a7b" }}
								color="primary"
								variant="outlined"
								onClick={() => {
									setShowStatusChangeForm((prevState) => {
										return {
											message: "Change Current Status of this Property",
											state: true
										};
									});
								}}
							>
								Change Status
							</Button>
						</Grid>
					</Grid>
					<PropertyForm
						setPropertyFormVisible={setPropertyFormVisible}
						hideStepper={true}
						sendingToBuyer={sendingToBuyer}
						userPropertyData={propertyData}
						askSellerAgentInfo={askSellerAgentInfo}
						setAskSellerAgentInfo={setAskSellerAgentInfo}
						onSubmitSellerAgentInfo={onSubmitSellerAgentInfo}
						loadingSellerAgentInfo={loadingSellerAgentInfo}
						propertyMlsId={propertyId}
						revisedState={true}
						commentsProp={comments}
						showCommentBox={true}
						onAdminReviewDone={onSuccess}
						showCommentForm={showCommentForm}
						setShowCommentForm={setShowCommentForm}
						revisedFields={revisedFields}
						status={propertyData ? propertyData.status : ""}
						propertyRelatedChats={true}
					></PropertyForm>
					<TextDialog
						message={showStatusChangeForm.message}
						state={showStatusChangeForm.state}
						stateHandler={setShowStatusChangeForm}
						submitHandler={async () => {
							await updateStatus(propertyStatus);
						}}
						onClose={() => {
							setShowStatusChangeForm((prev) => {
								return {
									...prev,
									state: false
								};
							});
						}}
						showField={false}
						showAvatar={false}
						showActions={true}
					>
						<Select
							labelId="select-label"
							id="select"
							value={propertyStatus}
							label="Property Status"
							onChange={handleChange}
						>
							{STATUS_OPTIONS.map((data) => (
								<MenuItem value={data.value}>{data.key}</MenuItem>
							))}
						</Select>
					</TextDialog>
				</Box>
			) : isInboxVisible ? (
				<Inbox setInboxVisible={setInboxVisible} />
			) : isInspectorsVisible ? (
				<Box className={classes.root}>
					<Container maxWidth={false}>
						<Toolbar
							setInboxVisible={setInboxVisible}
							setInspectorsVisible={setInspectorsVisible}
							setViewInspectorForm={setViewInspectorForm}
							showTabs={false}
						/>
						<Table {...inspectTableProps} />
					</Container>
					<TextDialog
						state={viewInspectorForm.state}
						stateHandler={setViewInspectorForm}
						showAvatar={false}
						showActions={false}
						onClose={() => {
							setInspectorForm(null);
						}}
					>
						{viewInspectorForm.state && (
							<Inspector
								inspectorObj={inspectorForm}
								inspector={setInspectorForm}
								inspectorFormHandler={setViewInspectorForm}
							/>
						)}
					</TextDialog>
				</Box>
			) : (
				<Box className={classes.root}>
					<Container maxWidth={false}>
						<Toolbar
							setInboxVisible={setInboxVisible}
							setInspectorsVisible={setInspectorsVisible}
							showTabs={true}
						/>
						<Table {...tableProps} />
						<TextDialog
							message={showConfirmOfferSent.message}
							state={showConfirmOfferSent.state}
							stateHandler={setShowConfirmOfferSent}
							submitHandler={() => {}}
							onClose={() => {}}
							showField={false}
							Avatar={
								<Avatar
									src={doneIcon}
									style={{
										top: 10,
										marginBottom: 10,
										flex: 1,
										alignContent: "center",
										alignSelf: "center"
									}}
								></Avatar>
							}
							showAvatar={true}
							showActions={false}
							textDialog={true}
						></TextDialog>
						<TextDialog
							state={viewIdentity.state}
							stateHandler={setViewIdentity}
							showAvatar={false}
							showActions={false}
						>
							<img src={viewIdentity.url} width="100%" height="100%" />
						</TextDialog>
					</Container>
				</Box>
			)}
		</div>
	);
}

export default Properties;
