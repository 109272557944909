import { Outlet, useSearchParams } from "react-router-dom";
// components
import { AppBar } from "../AppBar";
import makeStyles from "./styles";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { HeroSection } from "../HeroSection/HeroSection";
import heroHome from "../../assets/newHeroImage.png";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import BackgroundLoader from "../core/BackgroundLoader";
import { useUserState } from "../../context/UserContext";
import heroHomeV2 from "../../assets/Header image.png";

function DashboardLayout() {
	const classes = makeStyles();
	const { user, getAccessTokenSilently } = useAuth0();
	const [isUser, setIsUser] = useState(true);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState(false);
	let [searchParams, setSearchParams] = useSearchParams();
	const [filter, setFilter] = useState(searchParams.get("filter") || "Offers");
	const [archiveDialog, setArchiveDialog] = useState(false);

	const [paginator, setPaginator] = useState({
		limit: 10,
		offset: 0
	});
	const [dataCount, setDataCount] = useState(0);
	const userState = useUserState();

	useEffect(async () => {
		const token = await getAccessTokenSilently();
		const userData = jwtDecode(token);

		if (userData.permissions[0] == "read:admin-data") {
			setIsUser(false);
		}
	}, []);

	return (
		<>
			<Helmet>
				<title>Dashboard | Alokee</title>
			</Helmet>
			{user ? (
				<div className={classes.root}>
					{isUser ? (
						<AppBar
							background={false}
							whiteBackground={false}
							showArchiveOption={true}
							archiveDialog={archiveDialog}
							setArchiveDialog={setArchiveDialog}
						/>
					) : (
						<AppBar background={true} showArchiveOption={false} />
					)}
					{user &&
						isUser &&
						!userState?.userProfile?.isInspector &&
						(sessionStorage.getItem("selectedHomePage") == 0 ? (
							<HeroSection
								heroImage={heroHome}
								heroTextOne={"The Revolutionary "}
								heroTextTwo={"Digital Real Estate Experience"}
								heroDescriptionTextOne={"Looking for your dream home?"}
								heroDescriptionTextTwo={
									"Use Alokee to make an offer and close."
								}
								heroDescriptionTextThree={"Unlock savings of nearly $30,000."}
								isDashboard={true}
							/>
						) : sessionStorage.getItem("selectedHomePage") == 1 ? (
							<HeroSection
								heroImage={heroHomeV2}
								heroTextOne={"Place an offer in seconds."}
								heroDescriptionTextOne={
									<>
										<b>Save up to $30,000 on credited commissions</b>
									</>
								}
								heroDescriptionTextTwo={
									"by using Alokee to purchase your next home."
								}
								heroDescriptionTextThree={""}
								isDashboard={false}
							/>
						) : sessionStorage.getItem("selectedHomePage") == 2 ? (
							<HeroSection
								heroImage={heroHome}
								heroTextOne={"Buying a home?"}
								heroTextTwo={"Keep up to 90% of the commission."}
								heroDescriptionTextOne={
									"Pay only for the showings and features you used at closing."
								}
								heroDescriptionTextTwo={
									<>
										Alokee will refund the balance of the buyer commission{" "}
										<br></br>
										directly to you.
									</>
								}
								isDashboard={true}
							/>
						) : (
							<HeroSection
								heroImage={heroHome}
								heroTextOne={"10% of people don't use a real estate"}
								heroTextTwo={"agent to buy a home."}
								heroDescriptionTextOne={
									"Find out if you're capable of buying a house way other"
								}
								heroDescriptionTextTwo={
									<>
										professionals do. <b>Unlock savings of nearly $30,000!</b>
									</>
								}
								heroDescriptionTextThree={""}
								isDashboard={true}
							/>
						))}
					<div className={classes.wrapper}>
						<div className={classes.contentContainer}>
							<main className={classes.content}>
								<Outlet
									context={[
										data,
										loading,
										setLoading,
										search,
										filter,
										setFilter,
										paginator,
										setPaginator,
										dataCount,
										setDataCount,
										setData, // new
										setSearch, // new,
										archiveDialog, //new
										setArchiveDialog //new
									]}
								/>
							</main>
						</div>
					</div>
				</div>
			) : (
				<BackgroundLoader />
			)}
		</>
	);
}

export default DashboardLayout;
