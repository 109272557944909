import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ShowMoreText = ({ text, maxWords }) => {
    const [showAll, setShowAll] = useState(false);

    const words = text.split(' ');

    const truncatedText = showAll
        ? text
        : words.slice(0, maxWords).join(' ');

    const handleShowMoreClick = () => {
        setShowAll(!showAll);
    };

    return (
        <div>
            <Typography
                style={{
                    fontSize: "1rem",
                    color: "#000",
                    lineHeight: "136%",
                    marginTop: "30px"
                }}
            >
                {truncatedText}
            </Typography>
            {words.length > maxWords && (
                <Button
                    variant="outlined"
                    style={{ textTransform: "none", color: "#003A7B", fontSize: "16px", fontWeight: "700", border: "1px solid rgba(0, 0, 0, 0.23)", marginTop: "8px" }}
                    onClick={handleShowMoreClick}
                >
                    {showAll ? 'Show Less Description' : "Show More Description"}
                </Button>
            )}
        </div>
    );
};

export default ShowMoreText;
