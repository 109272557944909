import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";
const theme = createMuiTheme({
	palette: {
		background: {
			default: "rgba(242, 242, 242, 0.5)",
			paper: colors.common.white
		},
		primary: {
			main: "#003A7B",
			// main: "#00284c",
			mainLight: "#003A7B",
			dark: "#000",
			white: "#fff",
			red: "#eb564b"
		},
		secondary: {
			mainLight: "#67e5c4",
			main: "#a9a9a9",
			mainTwo: "#02A689",
			light: "rgb(123, 152, 182)"
		},
		text: {
			primary: "#000",
			secondary: "#6b778c",
			tertiary: "#96bc00",
			white: "#fff"
		}
	},
	shadows,
	typography: {
		...typography,
		fontFamily: "Rubik",
		fontWeightSemiBold: "700",
		fontWeightBold: "900",
		fontWeightRegular: "400",
		fontWeightMedium: "500",
		fontWeightLight: "300"
	},
	overrides: {
		MuiInputLabel: {
			root: {
				fontSize: 14
			}
		},
		MuiMenuItem: {
			root: {
				"&$selected": {
					backgroundColor: "transparent" // updated backgroundColor
				}
			}
		}
	}
});

export default theme;
