import React from "react";
import { Field, useField } from "formik";
import { Tooltip, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const NumberPickerField = ({ name, title, label, toolTipMsg, min, max, disabled }) => {
    const [field, meta] = useField(name);

    return (
        <>
            <Typography style={{ marginBottom: "25px", marginTop: "40px", fontFamily: "Rubik", color: "#000", fontSize: "1rem" }}>
                {title}
                <Tooltip title={toolTipMsg}>
                    <InfoOutlined
                        style={{ paddingLeft: "8px", marginBottom: "-7px", color: "#686868", fontSize: "1.8rem" }}
                    />
                </Tooltip>
            </Typography>
            <label htmlFor={name}
                style={{ marginRight: "10px", fontFamily: "Rubik", color: "#000", fontSize: "1rem" }} aria-label={`${title} ${label}`}>
                {label}
            </label>
            <Field
                name={name}
                type="number"
                id={name}
                min={min}
                max={max}
                style={{
                    width: "75px",
                    height: "auto",
                    fontSize: "1rem",
                    padding: "9px 5px 9px 13px",
                    border: "1px solid #003A7B",
                    borderRadius: "4px"
                }}
                disabled={disabled}
                required
            ></Field>
        </>
    )
};

export default NumberPickerField;