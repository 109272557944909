import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppBar } from "../components/AppBar";
import { Container, makeStyles } from "@material-ui/core";
import Footer from "../components/footer/footer";
import { Grid, Typography } from "@mui/material";

const pricePredictorStyling = makeStyles((theme) => {
	return {
		container: {
			marginTop: "150px",
			paddingBottom: "100px",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "120px"
			}
		},
		paragraph: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`
		},
		heading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		}
	};
});

function PricePredictor({}) {
	const classes = pricePredictorStyling();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div style={{ width: "100%" }}>
			<Helmet>
				<title>Alokee | Price Predictor</title>
			</Helmet>
			<Container maxWidth="lg" className={classes.container}>
				<AppBar whiteBackground={true} />
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.heading}>
							What is the Alokee Price Predictor and how is it different from
							the price estimates on all other real estate sites?
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							One of the main reasons people rely on the assistance of a Real
							Estate Agent is to help answer the question: “What price should I
							offer if I really want this home?”
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Alokee has built a powerful AI-backed tool, the Alokee Price
							Predictor, to put real time information in your hands and help
							answer that question when you are buying a home!
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Now that you&#39;re looking at real estate, you know that most
							real estate sites - including popular sites like Redfin and Zillow
							- include price estimates for each home (in most cases) until that
							home is listed for sale - after which they show the listing price.
							That&#39;s not very helpful to you if you are going to make an
							offer, because the time a home is listed is exactly the time you
							need help figuring out what to offer. To achieve your goals,
							should your offer be at, below or above the listing price?
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Further, a single price estimate of &quot;market value&quot; (in
							other words, &quot;what this home should be worth, based on other
							sold homes like it&quot;) doesn&#39;t give you enough information
							to answer the question &quot;what should I offer on this
							home?&quot; at all. What if the home listing is brand new? Do you
							need to offer above list price? How much? What if it has been
							listed for several weeks and has not sold yet? What if it&#39;s
							overpriced? How low can you offer and expect to be taken
							seriously?
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							As you can see, old school estimates were a great start (we still
							use those sites too), but the future is here. When you are
							shopping for a home and you want to know what you would need to
							offer on that home, use the Alokee Price Predictor. It&#39;s
							inside the platform, so you will have to create an account to see
							it. The Alokee Price Predictor will give you a relatively small
							range of offer price suggestions to help you make the winning
							offer.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							The Alokee Price Predictor is a proprietary AI model we have built
							that uses live digital information to help predict a winning offer
							price range. It give you a private suggestion (only available to
							Alokee users) for what you will need to offer on a home to be the
							achieve your goals and win (by &quot;winning&quot; we mean making
							the best, strongest, highest offer to beat out competing buyers,
							OR making the smartest, lowest offer you can and still have a
							chance to get the home). The Alokee Price Predictor is like having
							the world’s best real estate agent right at your side!
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Example: A new listing may go on the market for $1,000,000. What
							should you offer? The Alokee Price Predictor will model an offer
							price range in real time (that second) - $1,220,000- $1,260,000 -
							so you know what it will take to win. Interpret the $1,220,000 as
							&quot;I don&#39;t have to have this house, so I can make a lower
							offer and try to get a better price.&quot; Interpret $1,260,000 as
							&quot;I have to have this so I am going to offer at least this and
							I need to to win the bidding war!&quot; Wow. Real estate agent on
							steroids!
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							It’s important to understand that the AI model - The Alokee Price
							Predictor - is a model that incorporates large quantities of
							available data, but it does not guarantee any results. It is
							simply a guide, and you are still welcome to consult for advice
							when making an offer.
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Having said this, it is nice to have a competitive edge when vying
							to buy your dream home, and to finally have more transparency into
							&quot;what should I offer on this home&quot; without having to get
							phone time with the right real estate agent.
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Cheers and good luck out there. We&#39;ll be with you!
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</div>
	);
}

export default PricePredictor;
