import { Box, Grid, InputAdornment } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import NumberFormat from "react-number-format";
import { Button, Checkbox, TextField } from "../../core";
import RevisedForm from "./Observer/RevisedForm";
function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
		// isNumericString
		/>
	);
}

function BuyerRevisionForm({
	initialValuesRevision,
	schemaRevision,
	onSubmitRevision,
	revisedState,
	loadingRecipient
}) {
	return (
		<Formik
			initialValues={initialValuesRevision}
			validationSchema={schemaRevision}
			onSubmit={onSubmitRevision}
		>
			<Form>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="purchasePriceRevised"
							label="Purchase Price"
							type="text"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">$</InputAdornment>
								),

								inputComponent: NumberFormatCustom
							}}
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12} md={4} sm={6}>
						<Checkbox
							name="loanContingencyRevised"
							label="Loan Contingency"
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12} md={4} sm={6}>
						<Checkbox
							name="appraisalContingencyRevised"
							label="Appraisal Contingency"
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12} md={4} sm={6}>
						<Checkbox
							name="investigationContingencyRevised"
							label="Investigation Contingency"
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12} md={4} sm={6}>
						<Checkbox
							name="sellerDocumentsReviewRevised"
							label="Seller Documents Review"
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12} md={4} sm={6}>
						<Checkbox
							name="titleReportReviewRevised"
							label="Title Report Review"
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12} md={4} sm={6}>
						<Checkbox
							name="removalOfAllContingenciesRevised"
							label="Removal Of All Contingencies"
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12} md={4} sm={6}>
						<Checkbox
							name="contingentOfferRevised"
							label="Home Sale Contingency"
							required
							disabled={revisedState}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box py={2} display="flex" justifyContent="center" width="50%">
							<Button
								loading={loadingRecipient}
								loadingPosition="start"
								variant="contained"
								style={{ textTransform: "none", fontWeight: "700" }}
							>
								Submit
							</Button>
						</Box>
					</Grid>
				</Grid>
				<RevisedForm />
			</Form>
		</Formik>
	);
}

export default BuyerRevisionForm;
