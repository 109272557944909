import { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import config from "../../configs/image";
import ImageDrop from "./ImageDrop";
import useStyles from "./styles";

export default function ImageDialog({ open, onClose, onSuccess }) {
	const classes = useStyles();
	const [imageSrc, setImageSrc] = useState("");
	const [isInvalid, setIsInvalid] = useState(undefined);
	const [imageFile, setImageFile] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		if (imageFile) {
			setImageSrc(URL.createObjectURL(imageFile));
		}
	}, [imageFile]);

	const isFileSizeBig = (file) => {
		if (file.size / 1024 > config.MAX_SIZE_IN_KB) {
			setIsInvalid(true);
			setErrorMessage(`File Size must be less than ${config.MAX_SIZE_IN_KB}KB`);
			return true;
		}
		return false;
	};

	const handleInvalidImage = () => {
		if (!imageSrc) return;
		setIsInvalid(true);
		setErrorMessage(
			`we only support ${config.SUPPORTED_EXTENSIONS.join(" ")}.`
		);
	};

	const handleFileChange = (e) => {
		const [file] = e.target.files;
		if (file === undefined) {
			return;
		}
		if (isFileSizeBig(file)) return;

		setIsInvalid(false);
		setImageFile(file);
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		const [file] = e.dataTransfer.files;

		if (isFileSizeBig(file)) return;
		setIsInvalid(false);
		setImageFile(file);
	};

	const resetState = () => {
		setImageSrc("");
		setImageFile(null);
		setIsInvalid(undefined);
		setErrorMessage("");
	};

	const handleClose = () => {
		resetState();
		onClose();
	};

	const handleSubmit = () => {
		resetState();
		onSuccess(imageFile, imageSrc);
		onClose();
	};

	const dropImageProps = {
		isInvalid,
		imageFile,
		errorMessage,
		resetState,
		handleFileDrop,
		handleFileChange
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			aria-labelledby="form-dialog-title"
			className={classes.dialog}
		>
			<DialogContent className={classes.dialogContent}>
				<img
					width="120"
					height="120"
					alt=""
					src={imageSrc}
					hidden
					onError={handleInvalidImage}
				/>
				<ImageDrop {...dropImageProps} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary" variant="contained">
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={isInvalid || !imageFile}
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
