import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Typography, Box, useMediaQuery, Button } from "@material-ui/core";
import {
	Container,
	Grid,
	Card,
	CardContent,
	CardActions,
	Slider
} from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/styles";
import heroHome from "../assets/Header image.png";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Footer from "../components/footer/footer";
import { AppBar } from "../components/AppBar";
import { HeroSection } from "../components/HeroSection/HeroSection";
import { Toast } from "../components/core";
import SignUp from "./SignUp";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import noExclContracts from "../assets/noExclContracts.svg";
import doItYourself from "../assets/doItYourself.svg";
import saveTime from "../assets/saveTime.svg";
import happyIcon from "../assets/HappyIcon.png";
import happyColored from "../assets/happyColored.png";
import homeColored from "../assets/homeColored.png";
import moneyColored from "../assets/moneyColored.png";
import homeIcon from "../assets/homeBookingIcon.png";
import moneyIcon from "../assets/MoneyIcon.png";
import saveMoney from "../assets/saveMoney.svg";
import { SendPageEvent } from "../configs/googleAnalytics";
import AlokeeComparison from "../components/AlokeeComparison/AlokeeComparison";
import { Avatar } from "@mui/material";
import { styled } from "@mui/system";
import katherine from "../assets/katherine.png";
import ryo from "../assets/ryo.png";
import matthew from "../assets/matthew.png";
import li from "../assets/Li.webp";
import marcus from "../assets/marcus.jpeg";
import mellisia from "../assets/mellisia.jpeg";

const StyledCard = styled(Card)(({ theme }) => ({
	overflow: "visible",
	"&:hover .avatar": {
		transform: "scale(1.2)",
		opacity: 1
	},
	"&:hover .content": {
		transform: "translateY(20px)"
	},
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	height: "28rem",
	borderRadius: "8px",
	width: 370
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	transition: "transform 0.3s ease, opacity 0.3s ease",
	opacity: 0,
	transform: "scale(0)",
	marginTop: 0
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
	transition: "transform 0.3s ease",
	flex: 1,
	padding: "12px 12px"
}));

const homeStyles = makeStyles((theme) => {
	return {
		faqSubtitle: {
			color: "#003A7B",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1.125rem",
			fontWeight: "600",
			lineHeight: "130.5%" /* 23.49px */,
			textAlign: "center",
			[theme.breakpoints.down("md")]: {
				fontSize: "1.0rem"
			}
		},
		faqHover: {
			transition: "transform 0.3s ease, box-shadow 0.3s ease",
			borderRadius: 8,
			"&:hover": {
				transform: "scale(1.05)",
				boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
				backgroundColor: "#fff"
			},
			height: 227
		},

		faqParagraph: {
			color: "#000",
			textAlign: "center",
			width: "75%",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1rem",
			fontWeight: theme.typography.fontWeightRegular,
			marginTop: "8px",
			lineHeight: "132%" /* 21.12px */,
			[theme.breakpoints.down("md")]: {
				fontSize: "0.8rem"
			}
		},
		faqSection: {
			paddingTop: "80px",
			backgroundColor: "#EEFFFE",
			paddingBottom: "130px",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "70px"
			}
		},
		SmallTitle: {
			color: "#000",
			textAlign: "center",
			fontFamily: theme.typography.fontFamily,
			fontSize: "26px",
			fontWeight: theme.typography.fontWeightRegular,
			lineHeight: "138%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "22px"
			}
		},
		LargeTitle: {
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			fontSize: "2.5rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			textAlign: "center",
			marginBottom: "40px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "32px"
			}
		},
		youSaveTitle: {
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			fontSize: "2.5rem",
			marginBottom: "40px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem"
			}
		},
		smallerTitle: {
			color: "#FFF",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1.625rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.375rem"
			}
		},
		cardActionsHover: {
			flexDirection: "column",
			alignItems: "flex-start !important",
			padding: "10px"
		},
		stepsSection: {
			paddingTop: "100px",
			paddingBottom: "180px",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "78px",
				paddingBottom: "30px"
			}
		},
		journeySection: {
			paddingLeft: "0 !important",
			paddingRight: "0 !important",
			position: "relative",
			paddingBottom: "19rem"
		},
		journeyTitleContainer: {
			background:
				"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			paddingTop: "50px",
			paddingLeft: 0,
			paddingRight: 0,
			paddingBottom: "290px"
		},
		journeyCommentText: {
			fontSize: "1rem"
		},
		authorFrom: {
			marginLeft: "10px",
			color: "#FFF",
			fontSize: "1.0rem",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem"
			}
		},
		signup: {
			paddingBottom: "120px"
		},
		stepLabel: {
			fontSize: "22px !important",
			[theme.breakpoints.down("sm")]: {
				fontSize: "17px !important"
			},
			transition: "color 0.5s ease, transform 0.5s ease",
			"&:hover": {
				color: "#02A689"
			},
			[theme.breakpoints.between("sm", "xs")]: {
				fontSize: "20px !important"
			},
			[theme.breakpoints.down("md")]: {
				fontSize: "20px !important"
			}
		},

		stepIcon: {
			fontSize: "1.5rem",
			height: 80,
			width: 80,
			borderRadius: "50%",
			backgroundColor: "white",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			margin: "0 auto 10px auto",
			boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
			color: "rgb(0, 58, 123)"
		},
		youSaveContainer: {
			marginTop: "50px",
			[theme.breakpoints.down("sm")]: {
				marginTop: "80px"
			},
			backgroundColor: "#EEFFFE",
			padding: 150
		}
	};
});
const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 0
});
const comments = [
	{
		avatar: katherine,
		author: "Katherine",
		profession: "Professor",
		from: "Oakland",
		comment:
			"I was skeptical. Buying a home is scary and I needed to buy a home in a state - California - I had never bought in before. I also knew buying is hard. How could Alokee deliver? I think having an experienced attorney on call when I needed it was huge, that and the fact I could get any information I needed without asking a real estate agent. I highly recommend Alokee."
	},
	{
		avatar: ryo,

		author: "Ryo",
		profession: "Product Manager",
		from: "San Diego",
		comment:
			"I've bought two houses before. In both cases, I found the houses, researched the neighborhood and even toured them without an agent at open houses. All I needed was the ability to gather the disclosure documents, make an offer and close. Alokee exceeded my expectations because I did all these things AND had personal support from their experienced real estate agent (who was also a real estate lawyer)."
	},
	{
		avatar: matthew,

		author: "Matthew",
		profession: "Startup Founder",
		from: "San Francisco",
		comment:
			"Does anyone really think real estate agents have their best interests in mind? I certainly don't. I didn't feel like the Alokee expert that helped me was trying to push me or influence me... at all. And, when I wanted to gather information or make an offer, I simply did. The old real estate model should be afraid of the simplicity and transparency Alokee offers."
	},
	{
		avatar: li,

		author: "Li",
		profession: "Director of Sustainability",
		from: "San Francisco",
		comment:
			"I had no idea you don't need a real estate agent to buy a house! I also had no idea so many of my friends where buying a house without an agent! All you need is someone familiar with the purchase forms to help you fill them out. Well, Alokee gives you a real estate lawyer for that! And, there didn't seem to be any sales tactics."
	},
	{
		avatar: marcus,

		author: "Marcus",
		profession: "Product Operations Lead",
		from: "San Jose",
		comment:
			"The old way (of buying a home) and paying for it - a flat 3% fee - never made any sense to me. With Alokee, I paid for what I used. The value seemed really high to me. I think the fact that they have automated the offer made the process much easier. Next time I buy, I will use them again. If you are buying and you like getting involved in your business deals, there's no better way to buy a home."
	},
	{
		avatar: mellisia,

		author: "Melissa",
		profession: "Program Manager",
		from: "Los Angeles",
		comment:
			'What impresses me is that their process feels more personal and trustworthy than the usual way. Within minutes of signing up, multiple members of their team reached out to make sure I felt like their process was serving me correctly. This is the way real estate should be; simpler, more straightforward and less "pushy."'
	}
];

const stepperData = [
	{ stepNumber: "1", label: "Enter an address", Icon: HomeIcon },
	{ stepNumber: "2", label: "Fill out your offer", Icon: AttachMoneyIcon },
	{
		stepNumber: "3",
		label: "Review and submit",
		Icon: SentimentSatisfiedAltIcon
	}
];

const faqs = [
	{
		title: "Close seamlessly",
		description: "Access an experienced professional only if you need help.",
		icon: doItYourself
	},
	{
		title: "Save time",
		description: "Establish your offer criteria and we’ll do the rest.",
		icon: saveTime
	},
	{
		title: "Spend less",
		description: "Save thousands of dollars on buyer’s agent commissions.",
		icon: saveMoney
	}
];
const ReviewCard = ({ avatar, comment, author, profession, from }) => {
	const classes = homeStyles();
	const [isCardHovered, setIsCardHovered] = useState(false);
	return (
		<StyledCard
			onMouseEnter={() => setIsCardHovered(true)}
			onMouseLeave={() => setIsCardHovered(false)}
		>
			<StyledCardContent className="content">
				<StyledAvatar
					variant="circular"
					sx={{ width: 56, height: 56, marginBottom: 2 }}
					className="avatar"
					src={avatar}
				/>
				<Typography className={classes.journeyCommentText}>
					"{comment}"
				</Typography>
			</StyledCardContent>
			<CardActions
				className={classes.cardActionsHover}
				style={{
					background: isCardHovered
						? "linear-gradient(10deg, #02A689 3%, #003A7B 100%)"
						: "#02A689"
				}}
			>
				<Typography
					variant="subtitle1"
					className={classes.smallerTitle}
					style={{ marginLeft: "10px" }}
				>
					{author}
				</Typography>
				<Typography className={classes.authorFrom}>
					{profession} from {from}
				</Typography>
			</CardActions>
		</StyledCard>
	);
};
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const HomeB = () => {
	const classes = homeStyles();
	const theme = useTheme();
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const [isHovered, setIsHovered] = useState({
		happy: false,
		money: false,
		home: false
	});
	const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
	const mdUp = useMediaQuery(theme.breakpoints.up("md"));
	const mdDown = useMediaQuery(theme.breakpoints.down("md"));
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [activeStep, setActiveStep] = useState(0);
	const [groupedComments, setGroupedComments] = useState([]);
	const [faqActiveStep, setFaqActiveStep] = useState(0);
	const faqMaxSteps = faqs.length;
	const [value, setValue] = useState(1000000);
	const thumbSize = 24;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	SendPageEvent({
		hitType: "pageview",
		page: window.location.pathname,
		title: "Home Version B"
	});

	useEffect(() => {
		if (lgUp) {
			// Group comments into subarrays of 3 for lg and larger screens
			const grouped = [];
			for (let i = 0; i < comments.length; i += 3) {
				grouped.push(comments.slice(i, i + 3));
			}
			setGroupedComments(grouped);
		} else {
			// For smaller screens, use individual comments
			setGroupedComments(comments.map((comment) => [comment]));
		}
	}, [comments, lgUp]);

	// Update maxSteps based on groupedComments
	const maxSteps = groupedComments.length;

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	const handleDotClick = (step) => {
		setActiveStep(step);
	};

	const handleFaqStepChange = (step) => {
		const newStep = step >= faqs.length ? 0 : step;
		setFaqActiveStep(newStep);
	};

	const handleFaqDotClick = (step) => {
		setFaqActiveStep(step);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Helmet>
				<title>Alokee | Home</title>
			</Helmet>
			<Toast {...toast} setState={setToast} />
			<div>
				<AppBar background={false} />
				<HeroSection
					heroImage={heroHome}
					heroTextOne={"Place an offer in seconds."}
					heroDescriptionTextOne={
						<>
							<b>Save up to $30,000 on credited commissions</b>
						</>
					}
					heroDescriptionTextTwo={"by using Alokee to purchase your next home."}
					heroDescriptionTextThree={""}
					isDashboard={false}
				/>
			</div>
			<div style={{ width: "100%" }}>
				<Container maxWidth="false" className={classes.faqSection}>
					<Typography className={classes.SmallTitle}>
						NO LONG TERM CONTRACTS, NO TYPICAL AGENTS, JUST SIMPLICITY.
					</Typography>
					<Typography className={classes.LargeTitle}>
						Buy a home at one-fourth the cost.
					</Typography>
					{mdUp ? (
						<Grid
							container
							spacing={5}
							flexDirection="row"
							alignItems="center"
							justifyContent="center"
							style={{
								marginTop: 10
							}}
						>
							{faqs.map((faq) => (
								<Grid item lg={3} md={4} sm={4} xs={12} key={faq.title}>
									<Box
										display="flex"
										flexDirection="column"
										alignItems="center"
										className={classes.faqHover}
									>
										<img src={faq.icon} alt={faq.title} />
										<Typography className={classes.faqSubtitle}>
											{faq.title}
										</Typography>
										<Typography className={classes.faqParagraph}>
											{faq.description}
										</Typography>
									</Box>
								</Grid>
							))}
						</Grid>
					) : (
						<div>
							<AutoPlaySwipeableViews
								axis={theme.direction === "rtl" ? "x-reverse" : "x"}
								index={faqActiveStep}
								onChangeIndex={handleFaqStepChange}
								enableMouseEvents
							>
								{faqs.map((faq, index) => (
									<div key={faq.title}>
										{Math.abs(faqActiveStep - index) <= 2 ? (
											<Box
												display="flex"
												flexDirection="column"
												alignItems="center"
											>
												<img src={faq.icon} alt={faq.title} />
												<Typography className={classes.faqSubtitle}>
													{faq.title}
												</Typography>
												<Typography className={classes.faqParagraph}>
													{faq.description}
												</Typography>
											</Box>
										) : null}
									</div>
								))}
							</AutoPlaySwipeableViews>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "20px"
								}}
							>
								{Array.from({ length: faqMaxSteps }, (_, index) => (
									<Button
										aria-label={`Go to Step ${index + 1} of Home FAQs`}
										key={index}
										style={{
											margin: "0 4px",
											padding: 0,
											minWidth: "20px",
											height: "20px",
											borderRadius: "20px",
											backgroundColor:
												faqActiveStep === index ? "#003A7B" : "lightgrey"
										}}
										onClick={() => handleFaqDotClick(index)}
									/>
								))}
							</div>
						</div>
					)}
				</Container>
				<Container className={classes.stepsSection} maxWidth="xl">
					<Typography className={classes.SmallTitle}>
						THE FIRST SELF-GUIDED HOME BUYER EXPERIENCE
					</Typography>
					<Typography className={classes.LargeTitle}>
						Making an offer has never been easier.
					</Typography>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						mt={5}
					>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
							justifyContent={"space-between"}
							spacing={4}
						>
							<Grid
								container
								item
								direction={"row"}
								alignItems={"center"}
								justifyContent={"center"}
								md={4}
								sm={4}
								lg={4}
								spacing={2}
								onMouseEnter={() =>
									setIsHovered((prev) => {
										return {
											...prev,
											home: true
										};
									})
								}
								onMouseLeave={() =>
									setIsHovered((prev) => {
										return {
											...prev,
											home: false
										};
									})
								}
							>
								<Grid item>
									<img src={isHovered.home ? homeColored : homeIcon}></img>
								</Grid>
								<Grid item>
									<Typography
										className={classes.stepLabel}
										style={{
											color: isHovered.home ? "#02A689" : "#000"
										}}
									>
										Enter an address
									</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction={"row"}
								alignItems={"center"}
								justifyContent={"center"}
								md={4}
								sm={4}
								lg={4}
								spacing={2}
								onMouseEnter={() =>
									setIsHovered((prev) => {
										return {
											...prev,
											money: true
										};
									})
								}
								onMouseLeave={() =>
									setIsHovered((prev) => {
										return {
											...prev,
											money: false
										};
									})
								}
							>
								<Grid item>
									<img src={isHovered.money ? moneyColored : moneyIcon}></img>
								</Grid>
								<Grid item>
									<Typography
										className={classes.stepLabel}
										style={{
											color: isHovered.money ? "#02A689" : "#000"
										}}
									>
										Fill out your offer
									</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction={"row"}
								alignItems={"center"}
								justifyContent={"center"}
								md={4}
								sm={4}
								lg={4}
								spacing={2}
								onMouseEnter={() =>
									setIsHovered((prev) => {
										return {
											...prev,
											happy: true
										};
									})
								}
								onMouseLeave={() =>
									setIsHovered((prev) => {
										return {
											...prev,
											happy: false
										};
									})
								}
							>
								<Grid item style={{ marginLeft: "18px" }}>
									<img src={isHovered.happy ? happyColored : happyIcon}></img>
								</Grid>
								<Grid item>
									<Typography
										onMouseEnter={() =>
											setIsHovered((prev) => {
												return {
													...prev,
													happy: true
												};
											})
										}
										onMouseLeave={() =>
											setIsHovered((prev) => {
												return {
													...prev,
													happy: false
												};
											})
										}
										className={classes.stepLabel}
										style={{
											color: isHovered.happy ? "#02A689" : "#000"
										}}
									>
										Review and Submit
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Container>

				<Container maxWidth="false" className={classes.journeySection}>
					<Container maxWidth="false" className={classes.journeyTitleContainer}>
						<Typography
							className={classes.LargeTitle}
							style={{ color: "#FFF" }}
						>
							See what our customers have to say
						</Typography>
					</Container>
					<div
						style={{
							position: "absolute",
							bottom: "6rem",
							left: 0,
							right: 0
						}}
					>
						<AutoPlaySwipeableViews
							index={activeStep}
							onChangeIndex={handleStepChange}
							enableMouseEvents
							slideStyle={{ overflow: "hidden" }}
							interval={lgUp ? 20000 : 10000}
						>
							{groupedComments.map((group, index) => (
								<Grid
									container
									key={index}
									justifyContent={"center"}
									alignItems={"center"}
									spacing={mdDown ? 0 : 3}
								>
									{group.map((comment, cIndex) => (
										<Grid item key={cIndex}>
											<ReviewCard
												comment={comment.comment}
												author={comment.author}
												profession={comment.profession}
												from={comment.from}
												avatar={comment.avatar}
											/>
										</Grid>
									))}
								</Grid>
							))}
						</AutoPlaySwipeableViews>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "20px"
							}}
						>
							{Array.from({ length: maxSteps }, (_, index) => (
								<Button
									key={index}
									aria-label={`Go to Step ${
										index + 1
									} of Customer Success Stories`}
									style={{
										margin: "0 4px",
										padding: 0,
										minWidth: "20px",
										height: "20px",
										borderRadius: "20px",
										backgroundColor:
											activeStep === index ? "#003A7B" : "lightgrey"
									}}
									onClick={() => handleDotClick(index)}
								/>
							))}
						</div>
					</div>
				</Container>
				<Container maxWidth="xl">
					<AlokeeComparison
						whyAlokeeHeading={"No commission necessary."}
						alokeeProDescriptionText={
							"All billed at closing, balance of commission paid to you as cash"
						}
						bonusPercentage={0.03}
						commission={0}
						costOfServiceText={"flat fee"}
						costOfService={"$9,999"}
						alokeeGivesYouText={"Alokee gives you as much as"}
						alokeeGivesYou={"$39,900"}
						homeTwo={true}
					/>
				</Container>
				<Container maxWidth="false" className={classes.youSaveContainer}>
					<Container maxWidth="md">
						<div aria-live="polite" aria-atomic="true">
							<Typography
								className={classes.youSaveTitle}
								style={{ marginBottom: "65px" }}
							>
								You save:{" "}
								<b>
									{value > 1000000
										? formatter.format(value * 0.025 - 0)
										: formatter.format(value * 0.03 - 0)}
								</b>
							</Typography>
						</div>
						<Box width="100%">
							<div style={{ width: "100%", position: "relative" }}>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<Typography
											className={classes.youSaveTitle}
											style={{
												fontSize: 20,
												fontWeight: 600
											}}
										>
											LIST PRICE
										</Typography>
									</Grid>
									<Grid item xs={9} sm={10} md={10} lg={10}>
										<Slider
											value={value}
											min={400000}
											max={8000000}
											step={1}
											onChange={handleChange}
											aria-labelledby="range-slider"
											sx={{
												"& .MuiSlider-thumb": {
													color: "#003A7B"
												},
												"& .MuiSlider-track": {
													color: "#003A7B"
												},
												"& .MuiSlider-rail": {
													color: "#02A689",
													opacity: "1.0"
												}
											}}
										/>
										<div
											style={{
												position: "absolute",
												left: `50%`,
												top: "-30px",
												textAlign: "center",
												transform: "translateX(-50%)"
											}}
										>
											<Typography
												style={{
													color: "#003A7B",
													fontWeight: "700",
													fontSize: "1.3rem"
												}}
											>
												{formatter.format(value)}
											</Typography>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between"
											}}
										>
											<Typography
												style={{
													color: "#000",
													fontSize: "1rem",
													fontWeight: "700"
												}}
											>
												$400,000
											</Typography>
											<Typography
												style={{
													color: "#000",
													fontSize: "1rem",
													fontWeight: "700"
												}}
											>
												$8,000,000
											</Typography>
										</div>
									</Grid>
								</Grid>
							</div>
						</Box>
					</Container>
				</Container>
				<Container
					maxWidth="xl"
					sx={{
						paddingTop: 5
					}}
					className={classes.signup}
				>
					<SignUp />
				</Container>
				<Footer />
			</div>
		</div>
	);
};

export default HomeB;
