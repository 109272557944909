import { Container, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router";
import { useState } from "react";
import { Loader } from "../components/core";

const useStyles = makeStyles((theme) => {
	return {
		root: {
			position: "relative",
			width: "100%",
			overflow: "hidden",
			paddingTop: "100%"
		},
		responsiveIframe: {
			position: "absolute",
			position: "fixed",
			top: 80,
			left: 0,
			bottom: 0,
			right: 0,
			width: "100%",
			height: "90%",
			border: "none",
			overflow: "hidden"
		},
		loader: {
			position: "fixed",
			left: "50%",
			top: "50%"
		}
	};
});

function SigningPageBuyerCounterOffer({ route }) {
	const classes = useStyles();
	const { pathname } = useLocation();

	const { state } = useLocation();
	const [url, setUrl] = useState(state.redirectUrl);

	useEffect(() => {}, []);

	return (
		<Container maxWidth="lg" className={classes.root}>
			{url ? (
				<iframe
					title="docuSign-Esigning"
					className={classes.responsiveIframe}
					style={{
						top: pathname.includes("/counterOffer/") ? 10 : 70
					}}
					src={url}
				></iframe>
			) : (
				<Stack className={classes.loader}>
					<Loader type={"Circles"}></Loader>
				</Stack>
			)}
		</Container>
	);
}

export default SigningPageBuyerCounterOffer;
