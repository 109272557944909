import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import FileUploadMultiple from "../../core/FileUploadMultiple";
const useStyles = makeStyles((theme) => ({
	uploadContainer: {
		paddingTop: "40px",
		paddingBottom: "300px",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "130px"
		}
	}
}));
function Uploads({ values, onAdminReviewDone, disabled, userIdExists }) {
	const classes = useStyles();

	return (
		<div className={classes.uploadContainer}>
			<Grid
				container
				spacing={5}
				flexDirection="row"
				justifyContent="center"
				alignItems="flex-start"
				alignContent="flex-start"
			>
				<Grid item xs={12} md={12} sm={12}>
					<FileUploadMultiple
						name="proofOfFunds"
						label="Proof of Funds"
						onFileSelectError={({ error }) => alert(error)}
						disabled={onAdminReviewDone || disabled}
						value={values.proofOfFunds}
						required={true}
						max={8}
					/>
				</Grid>
				<Grid item xs={12} md={12} sm={12}>
					<FileUploadMultiple
						name="loanQualification"
						label="Loan Qualification (if any)"
						onFileSelectError={({ error }) => alert(error)}
						disabled={onAdminReviewDone || disabled}
						value={values.loanQualification}
						required={true}
						max={8}
					/>
				</Grid>
				{!userIdExists && (
					<Grid item xs={12} md={12} sm={12}>
						<FileUploadMultiple
							name="id"
							label="Identity Document"
							onFileSelectError={({ error }) => alert(error)}
							disabled={onAdminReviewDone || disabled}
							value={values.id}
							required={true}
							max={1}
						/>
					</Grid>
				)}
				<Grid item xs={12} md={12} sm={12}>
					<FileUploadMultiple
						name="otherDocuments"
						label="Other Documents (if any)"
						onFileSelectError={({ error }) => alert(error)}
						disabled={onAdminReviewDone || disabled}
						value={values.otherDocuments}
						max={3}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

export default Uploads;
