import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Button } from "@material-ui/core";
import { MessageLeft } from "./Message";
import { Form, Formik } from "formik";
import { TextField, Toast } from "../../core";
import * as Yup from "yup";
import { createComment, deleteComment } from "../../../services/comment";
import TextDialog from "../../core/TextDialog";
import ButtonWrapper from "../../core/Button";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			width: "100vw",
			maxWidth: "100%"
		},
		container: {
			paddingLeft: 10,
			width: "100%",
			height: "50%",
			display: "flex",
			alignItems: "flex-start",
			justifyContent: "flex-start"
		},
		messagesBody: {
			padding: 10,
			height: 200,
			width: "calc( 100% - 20px )",
			margin: 10,
			overflowY: "scroll"
		}
	})
);

export default function CommentBox({
	commentsProp,
	userPropertyId,
	showCommentBox,
	showCommentForm,
	setShowCommentForm,
	setPropertyFormVisible
}) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [comments, setComments] = useState([...commentsProp] || []);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});

	const initialValues = {
		comment: ""
	};

	const validationSchema = Yup.object().shape({
		comment: Yup.string().max(1000).required("Comment is required")
	});
	const { user } = useAuth0();

	const onSubmit = async (values, { resetForm }) => {
		setLoading(true);
		let resp;
		if (userPropertyId) {
			resp = await createComment({
				userPropertyId,
				description: values.comment,
				auth0Id: user?.sub
			});
		}
		if (resp.data.success) {
			setToast({
				state: "open",
				message: resp.data.message,
				type: "success"
			});

			let anotherComment = [
				{
					id: resp.data.data.data,
					userPropertyId,
					description: values.comment,
					createdAt: new Date()
				}
			];

			setComments((prev) => {
				return prev.concat(anotherComment);
			});

			if (comments.length == 0) {
				setPropertyFormVisible(false);
			}
			resetForm();
		} else {
			setToast({
				state: "open",
				message: resp.data.message,
				type: "error"
			});
		}

		setLoading(false);
	};
	return (
		<div className={classes.container}>
			<Toast {...toast} setState={setToast} />
			<Paper className={classes.paper} zDepth={2}>
				<Paper id="style-1" className={classes.messagesBody}>
					{comments && comments.length
						? comments.map((comment, idx) => (
								<MessageLeft
									onDelete={async () => {
										await deleteComment({ id: comment.id, auth0Id: user?.sub });
										setComments(
											comments.filter((obj) => {
												return obj.id !== comment.id;
											})
										);
									}}
									showCommentBox={showCommentBox}
									message={comment.description}
									date={new Date(comment.createdAt).toLocaleDateString()}
									timestamp={new Date(comment.createdAt).toLocaleTimeString()}
								/>
						  ))
						: ""}
				</Paper>
				{!!showCommentForm && showCommentForm.state && (
					<TextDialog
						showActions={false}
						message={showCommentForm.message}
						state={showCommentForm.state}
						stateHandler={setShowCommentForm}
						submitHandler={() => {}}
						onClose={() => {}}
						showField={false}
						showAvatar={false}
					>
						{showCommentBox && (
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
							>
								{(props) => (
									<Form>
										<Grid
											container
											direction="row"
											justifyContent="space-evenly"
											alignItems="flex-start"
											spacing={2}
										>
											<Grid item xs={12}>
												<TextField
													multiline
													rows={4}
													name="comment"
													label="Comment"
													required
												/>
											</Grid>

											<Grid item xs={5}>
												<Box py={2}>
													<ButtonWrapper
														loading={loading}
														loadingPosition="start"
													>
														{"Add Comment"}
													</ButtonWrapper>
												</Box>
											</Grid>
											<Grid item xs={5}>
												<Box py={2}>
													<Button
														variant="contained"
														color="primary"
														fullWidth={true}
														style={{
															borderRadius: "10px"
														}}
														onClick={() => {
															setShowCommentForm((prev) => {
																return {
																	...prev,
																	state: false
																};
															});
														}}
													>
														{"Close"}
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						)}
					</TextDialog>
				)}
			</Paper>
		</div>
	);
}
