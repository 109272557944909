import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box, Stack } from "@mui/material";
import Logo from "../../assets/logoWhite.png";
import LogoColor from "../../assets/logoColor.png";
import makeStyles from "./styles";
import { useTheme } from "@material-ui/styles";
import { Menu, MenuItem } from "@mui/material";
import userIcon from "../../assets/userIcon.png";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router";
import { useUserDispatch, useUserState } from "../../context/UserContext";
import { Avatar, Grid, useMediaQuery } from "@material-ui/core";
import { ArchiveOutlined, Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuizIcon from "@mui/icons-material/Quiz";
import CommentIcon from "@mui/icons-material/Comment";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const pages = [
	{ label: "Home", value: "home" },
	{ label: "About Us", value: "about-us" },
	{ label: "FAQs", value: "faqs" },
	{
		label: "Blog",
		value: "blog"
	}
];

const appLandingPages = [
	{
		label: "Dashboard",
		value: "dashboard",
		icon: <DashboardIcon style={{ color: "#003A7B", marginRight: "8px" }} />
	},
	{
		label: "Home",
		value: "home",
		icon: <HomeIcon style={{ color: "#003A7B", marginRight: "8px" }} />
	},
	{
		label: "About Us",
		value: "about-us",
		icon: <MenuBookIcon style={{ color: "#003A7B", marginRight: "8px" }} />
	},
	{
		label: "FAQs",
		value: "faqs",
		icon: <QuizIcon style={{ color: "#003A7B", marginRight: "8px" }} />
	},
	{
		label: "Blog",
		value: "blog",
		icon: <CommentIcon style={{ color: "#003A7B", marginRight: "8px" }} />
	}
];

function AppBarWrapper(props) {
	const classes = makeStyles();
	const { loginWithRedirect, user } = useAuth0();
	const userDispatch = useUserDispatch();
	const userState = useUserState();
	const navigate = useNavigate();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const { state, pathname } = useLocation();

	const appLanding =
		pathname.startsWith("/app/faqs") ||
		pathname.startsWith("/app/about-us") ||
		pathname.startsWith("/app/blog")
			? true
			: false;

	const [profileMenu, setProfileMenu] = useState(null);

	const { logout } = useAuth0();
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleSignOut = async () => {
		logout({
			logoutParams: {
				returnTo: `${process.env.REACT_APP_URL}home`
			}
		});
		cookies.remove("otpVerified", {
			path: "/"
		});
		setTimeout(() => {
			userDispatch({ type: "LOGOUT" });
		}, 2000);
	};

	return (
		<AppBar
			position="absolute"
			color="transparent"
			className={
				props.tealBackground
					? classes.appBarAboutUs
					: props.background
					? classes.appBar
					: classes.appBarSimple
			}
		>
			<Toolbar className={classes.toolBarStyles}>
				<Stack
					sx={{
						flexDirection: "row",
						alignItems: "center",
						"&:hover": {
							cursor: "pointer"
						}
					}}
					component="a"
					onClick={() => {
						navigate(
							window.location.pathname.startsWith("/app")
								? "/app/home"
								: "/home"
						);
					}}
				>
					<img
						src={props.whiteBackground ? LogoColor : Logo}
						alt="logo"
						className={classes.logo}
					/>
				</Stack>
				{!user && (
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "flex", md: "none" },
							justifyContent: "flex-end"
						}}
					>
						<IconButton
							size="large"
							aria-label="Open Main Navigation Menu"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
						>
							<MenuIcon
								className={
									pathname === "/home"
										? classes.menuIconwhite
										: classes.menuIconBlue
								}
							/>
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left"
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left"
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" }
							}}
						>
							{pages.map((page) => {
								const isActive = pathname === `/${page.value}`;
								return (
									<Link key={page.label} to={`/${page.value}`}>
										<MenuItem onClick={handleCloseNavMenu}>
											<Typography
												style={{
													color: "#003A7B",
													fontWeight: isActive ? "bold" : "normal"
												}}
												className={classes.navMenu}
												textAlign="center"
											>
												{page.label}
											</Typography>
										</MenuItem>
									</Link>
								);
							})}
						</Menu>
					</Box>
				)}
				{!user && (
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", md: "flex" },
							justifyContent: { xs: "center", md: "space-between" },
							alignItems: "center",
							marginLeft: "90px"
						}}
					>
						<div>
							<Box
								sx={{
									flexGrow: 1,
									display: { xs: "none", md: "flex" },
									justifyContent: { xs: "center", md: "flex-start" },
									alignItems: "center",
									marginLeft: "90px"
								}}
							>
								{pages.map((page) => {
									const isActive = pathname === `/${page.value}`;

									return (
										<Link to={`/${page.value}`}>
											<MenuItem key={page.label}>
												<Typography
													style={{
														color: props.whiteBackground ? "#000" : "#fff",
														fontWeight: isActive ? "bold" : "normal"
													}}
													className={classes.navMenu}
													textAlign="center"
												>
													{page.label}
												</Typography>
											</MenuItem>
										</Link>
									);
								})}
							</Box>
						</div>
						<Grid
							container
							alignItems="center"
							flexDirection="row"
							justifyContent="flex-end"
							spacing={2}
						>
							{props.whiteBackground && (
								<Grid item>
									<Button
										aria-label="sign-up"
										className={classes.button}
										onClick={async () => {
											navigate("/signup");
										}}
									>
										Sign Up Now
									</Button>
								</Grid>
							)}
							<Grid item>
								<Button
									aria-label="login"
									className={classes.loginButton}
									onClick={async () => {
										navigate("/login");
									}}
									variant={props.whiteBackground ? "contained" : "outlined"}
									color={props.whiteBackground ? "#003a7b" : ""}
									style={{
										color: props.whiteBackground ? "#003a7b" : "",
										backgroundColor: props.whiteBackground ? "#fff" : ""
									}}
								>
									Login
								</Button>
							</Grid>
						</Grid>
					</Box>
				)}
				{user && (
					<>
						{!smDown ? (
							<>
								<div>
									<Box
										sx={{
											flexGrow: 1,
											display: { xs: "none", md: "flex" },
											justifyContent: { xs: "center", md: "flex-start" },
											alignItems: "center",
											marginLeft: "90px"
										}}
									>
										{appLandingPages.map((page) => {
											const isActive = pathname === `/app/${page.value}`;

											return (
												<Link to={`/app/${page.value}`}>
													<MenuItem key={page.label}>
														<Typography
															style={{
																color: props.whiteBackground ? "#000" : "#fff",
																fontWeight: isActive ? "bold" : "normal"
															}}
															className={classes.navMenu}
															textAlign="center"
														>
															{page.label}
														</Typography>
													</MenuItem>
												</Link>
											);
										})}
									</Box>
								</div>
								<Stack
									sx={{
										flexDirection: "row",
										width: "100%",
										justifyContent: "flex-end",
										alignItems: "center"
									}}
								>
									<Avatar
										alt="User"
										src={
											userState?.userProfile?.photo || user?.picture || userIcon
										}
										className={classes.userIcon}
									/>
									<Typography
										style={{ color: appLanding ? "#003A7B" : "#FFF" }}
									>
										{userState?.userProfile?.firstName +
											" " +
											userState?.userProfile?.lastName}
									</Typography>
									<IconButton
										aria-haspopup="true"
										aria-controls="profile-menu"
										aria-label="Open Main Navigation Menu"
										onClick={(e) => setProfileMenu(e.currentTarget)}
									>
										<ArrowDropDownIcon
											fontSize="large"
											style={{ color: appLanding ? "#003A7B" : "#FFF" }}
										/>
									</IconButton>
								</Stack>
							</>
						) : (
							<Stack
								sx={{
									flexDirection: "row",
									width: "100%",
									justifyContent: "flex-end",
									alignItems: "center"
								}}
							>
								<IconButton
									aria-haspopup="true"
									aria-controls="profile-menu"
									aria-label="Open Main Navigation Menu"
									onClick={(e) => setProfileMenu(e.currentTarget)}
								>
									<MenuIcon
										fontSize="large"
										style={{ color: appLanding ? "#003A7B" : "#FFF" }}
									/>
								</IconButton>
							</Stack>
						)}
						<Menu
							id="profile-menu"
							open={Boolean(profileMenu)}
							anchorEl={profileMenu}
							onClose={() => setProfileMenu(null)}
							elevation={0}
							disableAutoFocusItem
						>
							<MenuItem
								sx={{
									flexDirection: "row",
									alignItems: "center",
									paddingRight: {
										xs: 5,
										sm: 5,
										md: "100px",
										lg: "100px",
										xl: "100px"
									}
								}}
							>
								<PersonOutlineIcon
									style={{ color: "#003A7B", marginRight: "8px" }}
								/>
								<Typography
									className={classes.menuItem}
									color="primary"
									onClick={() => {
										navigate("/edit-profile", { replace: true });
									}}
								>
									Edit Profile
								</Typography>
							</MenuItem>
							<MenuItem>
								<Logout
									style={{ color: "#003A7B", marginRight: "8px" }}
								></Logout>
								<Typography
									className={classes.menuItem}
									color="primary"
									onClick={handleSignOut}
									style={{
										paddingRight: 2
									}}
								>
									Logout{" "}
								</Typography>
							</MenuItem>
							{props.showArchiveOption == true ? (
								<MenuItem>
									<ArchiveOutlined
										style={{ color: "#003A7B", marginRight: "8px" }}
										color="primary"
									/>
									<Typography
										className={classes.menuItem}
										color="primary"
										onClick={() => {
											props?.setArchiveDialog(true);
										}}
										style={{
											paddingRight: 2
										}}
									>
										Manage Archived Properties
									</Typography>
								</MenuItem>
							) : null}
							{appLandingPages.map((page) => {
								const isActive = pathname === `/app/${page.value}`;
								return (
									<Link key={page.label} to={`/app/${page.value}`}>
										<MenuItem onClick={handleCloseNavMenu}>
											{page.icon}
											<Typography
												style={{
													color: "#003A7B",
													fontWeight: isActive ? "bold" : "normal"
												}}
												className={classes.navMenu}
												textAlign="center"
											>
												{page.label}
											</Typography>
										</MenuItem>
									</Link>
								);
							})}
						</Menu>
					</>
				)}
			</Toolbar>
		</AppBar>
	);
}

export default AppBarWrapper;
