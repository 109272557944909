import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 10,
		height: 450,
		width: 280,
		[theme.breakpoints.down("sm")]: {
			width: "auto"
		}
	},
	image: {
		height: 148,
		backgroundColor: "#8BDED0",
		borderRadius: 10
	},
	cardWrapper: {
		padding: 15
	},
	name: {
		color: "#003A7B",
		fontSize: 24,
		fontWeight: "bold"
	},
	profession: {
		color: "#003A7B",
		fontSize: 22
	},
	description: {
		color: "#000",
		fontSize: 16,
		marginTop: 5
	}
}));

const TeamMemberCard = ({ name, profession, description, imageUrl }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<div className={classes.cardWrapper}>
				<div className={classes.image}>
					<img src={imageUrl} />
				</div>
				<CardContent>
					<Typography className={classes.name}>{name}</Typography>
					<Typography
						className={classes.profession}
						variant="body2"
						color="textSecondary"
						component="p"
					>
						{profession}
					</Typography>
					<Typography
						className={classes.description}
						variant="body2"
						color="textPrimary"
						component="p"
					>
						{description}
					</Typography>
				</CardContent>
			</div>
		</Card>
	);
};

export default TeamMemberCard;
