import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

const horizontalStepperStyles = makeStyles((theme) => {
	return {
		container: {
			width: "100%",
			marginTop: 100
		},
		progressbar: {
			counterReset: "step"
		},
		step: {
			listStyleType: "none",
			float: "left",
			width: "33.33%",
			position: "relative",
			textAlign: "center",
			fontWeight: 600,
			"&:after": {
				content: "''",
				position: "absolute",
				width: "100%",
				border: "1px dashed #000",
				top: 20,
				left: "-50%",
				zIndex: -1
			},
			"&:first-child:after": {
				content: "none"
			}
		},
		stepIcon: {
			fontSize: "1.5rem",
			height: 40,
			width: 40,
			borderRadius: "50%",
			backgroundColor: "white",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			margin: "0 auto 10px auto",
			boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)"
		},
		stepNumber: {
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.secondary.mainTwo,
			fontSize: "2.5rem"
		},
		stepLabel: {
			fontSize: "1.5rem",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem"
			}
		}
	};
});

const HorizontalStepper = ({ stepperData }) => {
	const classes = horizontalStepperStyles();

	return (
		<div className={classes.container}>
			<ul className={classes.progressbar}>
				{stepperData.map((step, index) => (
					<li key={index} className={classes.step}>
						<div className={classes.stepIcon}>
							<step.Icon />
						</div>
						{step.stepNumber ? (
							<Box display="flex" flexDirection="column" alignItems="center">
								<Typography className={classes.stepLabel}>
									<span className={classes.stepNumber}>{step.stepNumber}</span>{" "}
								</Typography>
								<Typography className={classes.stepLabel}>
									{step.label}
								</Typography>
							</Box>
						) : (
							<Typography className={classes.stepLabel}>
								{step.label}
							</Typography>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default HorizontalStepper;
