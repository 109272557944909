import { Helmet } from "react-helmet";
import { Box, makeStyles } from "@material-ui/core";

import UpdateProfile from "../components/Profile/UpdateProfile";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: "100%"
	}
}));

const Settings = () => {
	const classes = useStyles();

	return (
		<>
			<Helmet>
				<title>Settings</title>
			</Helmet>
			<Box className={classes.root}>
				<UpdateProfile />
			</Box>
		</>
	);
};

export default Settings;
