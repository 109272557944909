import React from "react";
import { Container, Grid } from "@mui/material";
import { Typography, Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Logo from "../../assets/logoWhite.png";
import NewsLetter from "../NewsLetter/NewsLetter";

const footerLinks = [
	{
		title: "Home",
		link: "/home",
		appLink: "/app/home"
	},
	{
		title: "About Us",
		link: "/about-us",
		appLink: "/app/about-us"
	},
	{
		title: "Sign Up",
		link: "/signup"
	},
	{
		title: "FAQs",
		link: "/faqs",
		appLink: "/app/faqs"
	},
	{
		title: "Blog",
		link: "/blog",
		appLink: "/app/blog"
	},
	{
		title: "Alokee Price Predictor",
		link: "/alokee-price-predictor",
		appLink: "/app/alokee-price-predictor"
	},
	{
		title: "Financing",
		link: "/financing",
		appLink: "/app/financing"
	},
	{
		title: "Cookie Policy",
		link: "https://alokeeinc.github.io/alokee-pv.github.io/CookiePolicy.html",
		appLink: "https://alokeeinc.github.io/alokee-pv.github.io/CookiePolicy.html"
	},
	{
		title: "Privacy Policy",
		link: "https://alokeeinc.github.io/alokee-pv.github.io/PrivacyPolicy.html",
		appLink:
			"https://alokeeinc.github.io/alokee-pv.github.io/PrivacyPolicy.html"
	}
];

const newFooterStyles = makeStyles((theme) => {
	return {
		footerContainer: {
			background: theme.palette.primary.mainLight,
			paddingTop: "130px",
			paddingBottom: "130px",
			paddingLeft: "100px !important",
			paddingRight: "100px important!",
			[theme.breakpoints.down("sm")]: {
				paddingLeft: "30px !important",
				paddingRight: " 30px !important",
				paddingTop: "80px",
				paddingBottom: "80px"
			}
		},
		logoStyle: {
			width: "139.953px",
			height: "25.266px"
		},
		navBox: {
			[theme.breakpoints.down("sm")]: {
				paddingTop: "47px"
			}
		},
		baseLink: {
			marginBottom: theme.spacing(3),
			textDecoration: "none",
			color: "#FFF"
		},
		activeLink: {
			fontWeight: theme.typography.fontWeightBold
		},
		socialNavBox: {
			marginLeft: "-13px",
			marginTop: "24px"
		},
		linkIcon: {
			color: "#FFF"
		},
		icon: {
			width: "1.5em !important",
			height: "2em !important"
		}
	};
});

const Footer = () => {
	const classes = newFooterStyles();
	const { pathname } = useLocation();
	const footerSocialLinks = [
		{
			title: "Facebook",
			icon: <FacebookIcon className={classes.icon} />,
			link: "https://www.facebook.com/Alokeehomes"
		},
		{
			title: "LinkedIn",
			icon: <LinkedInIcon className={classes.icon} />,
			link: "https://www.linkedin.com/company/alokee/"
		},
		{
			title: "Instagram",
			icon: <InstagramIcon className={classes.icon} />,
			link: "https://www.instagram.com/alokeehomes/"
		}
	];
	return (
		<Container maxWidth="false" className={classes.footerContainer}>
			<Grid container justifyContent="flex-start">
				<Grid item xs={12}>
					<NewsLetter />
				</Grid>
				<Grid item xs={12} sm={6}>
					<img src={Logo} className={classes.logoStyle} />
					<Typography
						style={{ fontSize: "14px", color: "#FFF", marginTop: "23px" }}
					>
						© Copyright {new Date().getFullYear()} - Alokee
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Box className={classes.navBox}>
						{footerLinks.map((link, index) => {
							const isActive =
								link.link === pathname ||
								(link.appLink && link.appLink === pathname);
							const linkClasses = `${classes.baseLink} ${
								isActive ? classes.activeLink : ""
							}`;

							let renderedLink = null;

							if (
								link.title === "Cookie Policy" ||
								link.title === "Privacy Policy"
							) {
								renderedLink = (
									<a
										href={link.link}
										target="_blank"
										rel="noopener noreferrer"
										className={linkClasses}
									>
										{link.title}
									</a>
								);
							} else if (pathname.startsWith("/app") && link.appLink) {
								renderedLink = (
									<Link to={link.appLink} className={linkClasses}>
										{link.title}
									</Link>
								);
							} else if (!pathname.startsWith("/app")) {
								renderedLink = (
									<Link to={link.link} className={linkClasses}>
										{link.title}
									</Link>
								);
							}

							return (
								<Typography key={index} style={{ marginBottom: "24px" }}>
									{renderedLink}
								</Typography>
							);
						})}
						<Typography
							style={{ color: "#FFF", marginTop: "40px", fontWeight: "700" }}
						>
							Available In: CA, TX (Pilot), WA (Pilot)
						</Typography>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						className={classes.socialNavBox}
					>
						{footerSocialLinks.map((link) => (
							<IconButton
								key={link.title}
								onClick={() => window.open(link.link, "_blank", "noopener")}
								aria-label={link.title}
								className={classes.linkIcon}
							>
								{link.icon}
							</IconButton>
						))}
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Footer;
