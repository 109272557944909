import { useState } from "react";
import { Avatar, Grid, Button } from "@material-ui/core";
import Dialog from "./Dialog";
import useStyles from "./styles";

export default function ImagePicker({ setImage, imageSource }) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [imageSrc, setImageSrc] = useState(imageSource);

	const handleUpload = (img, src) => {
		setImage(img);
		setImageSrc(src);
	};

	const handleDialogAction = () => {
		if (imageSrc) {
			setImage(null);
			setImageSrc("");
		} else {
			setOpen(true);
		}
	};

	const dialogProp = {
		open,
		onClose: () => setOpen(false),
		onSuccess: handleUpload
	};

	return (
		<Grid
			container
			className={classes.container}
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Avatar
				alt="User"
				src={imageSrc || "/static/avatar.svg"}
				className={classes.image}
			/>
			<Button
				variant="contained"
				color="primary"
				className={classes.uploadButton}
				onClick={handleDialogAction}
				size="small"
			>
				{imageSrc ? "Remove" : "Upload"}
			</Button>
			<Dialog {...dialogProp} />
		</Grid>
	);
}
