import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Typography,
	Box,
	Button,
	List,
	ListItem,
	ListItemIcon
} from "@material-ui/core";
import { Container, Grid, Slider } from "@mui/material";
import { Link } from "react-router-dom";
import bullet from "../../assets/bullet.svg";

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 0
});

const whyAlokeeStyles = makeStyles((theme) => {
	return {
		whyAlokeeSection: {
			paddingTop: "160px",
			paddingBottom: "50px",
			[theme.breakpoints.down("sm")]: {
				paddingTop: "25px",
				paddingBottom: "100px"
			}
		},
		whyAlokeeGridDiv: {
			borderRadius: "6px",
			padding: "32px",
			boxShadow: "5px 13px 36px 0px rgba(0, 0, 0, 0.15)",
			margin: "18px !important",
			transition: "transform 0.5s ease, border 0.5s ease",
			"&:hover": {
				border: "1px solid #003A7B",
				transform: "scale(1.01)"
			}
		},
		secondCard: {
			borderRadius: "6px",
			padding: "32px",
			boxShadow: "5px 13px 36px 0px rgba(0, 0, 0, 0.15)",
			margin: "18px !important",
			background:
				"linear-gradient(180deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			transition: "transform 0.5s ease, border 0.5s ease",

			"&:hover": {
				border: "1px solid #fff",
				transform: "scale(1.01)"
			}
		},
		whyAlokeeText: {
			color: "#000",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			lineHeight: "132%"
		},
		whyAlokeeListItem: {
			display: "list-item",
			fontWeight: "400",
			marginLeft: "20px",
			fontSize: "1rem",
			[theme.breakpoints.down("sm")]: {
				fontSize: "0.875rem"
			}
		},
		astericksText: {
			fontWeight: "400",
			fontSize: "1rem",
			color: "#686868",
			[theme.breakpoints.down("sm")]: {
				fontSize: "0.875rem"
			}
		},
		button: {
			width: "220px",
			padding: "10px 18px",
			borderRadius: "6px",
			background: "#FFF",
			textTransform: "none",
			fontSize: "1rem",
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightBold,
			"&:hover": {
				background: "#02A689",
				color: "#fff"
			}
		},
		youSaveContainer: {
			marginTop: "160px",
			[theme.breakpoints.down("sm")]: {
				marginTop: "80px"
			},
			backgroundColor: "#EEFFFE"
		},
		SmallTitle: {
			color: "#000",
			textAlign: "center",
			fontFamily: theme.typography.fontFamily,
			fontSize: "2rem",
			fontWeight: theme.typography.fontWeightRegular,
			lineHeight: "138%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.5rem"
			}
		},
		LargeTitle: {
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			fontSize: "2.5rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			textAlign: "center",
			marginBottom: "40px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.60rem"
			}
		},
		smallerTitle: {
			color: "#FFF",
			fontFamily: theme.typography.fontFamily,
			fontSize: "1.625rem",
			fontWeight: theme.typography.fontWeightSemiBold,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.20rem"
			}
		},
		cardHover: {
			"&:hover": {
				border: "1px solid #003A7B"
			},
			transition: "transform 0.3s ease, box-shadow 0.3s ease"
		}
	};
});

const AlokeeComparison = ({
	bonusPercentage,
	commission,
	alokeeProDescriptionText,
	alokeeGivesYouText,
	costOfServiceText,
	alokeeGivesYou,
	costOfService,
	homeTwo,
	homeThree,
	whyAlokeeHeading
}) => {
	const classes = whyAlokeeStyles();
	const thumbSize = 24;
	const [value, setValue] = useState(1000000);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [isButtonHovered, setIsButtonHovered] = useState(false);

	return (
		<Container maxWidth="false" className={classes.whyAlokeeSection}>
			<Typography
				className={classes.LargeTitle}
				style={{ marginBottom: "58px" }}
			>
				{whyAlokeeHeading}
			</Typography>
			<Grid container justifyContent="center" alignItems={"stretch"}>
				<Grid
					container
					style={{ background: "#FFFF" }}
					className={classes.whyAlokeeGridDiv}
					item
					xs={12}
					sm={6}
					md={6}
					lg={4}
					flexDirection="column"
					justifyContent={"space-between"}
				>
					<Grid item>
						<div>
							<Box display="flex" flexDirection="column" alignItems="center">
								<Typography
									className={classes.smallerTitle}
									style={{ color: "#000" }}
								>
									Traditional Buyer's Agent
								</Typography>
								<Typography
									className={classes.LargeTitle}
									style={{
										color: "#000",
										marginBottom: "auto",
										marginLeft: 15
									}}
								>
									2.5% <sup>*</sup>
								</Typography>
								<Typography
									className={classes.whyAlokeeText}
									style={{ marginTop: "0px" }}
								>
									commission fee
								</Typography>

								<Typography
									className={classes.whyAlokeeText}
									style={{
										marginTop: "24px",
										color: "#003A7B",
										fontWeight: "400",
										fontSize: "12px"
									}}
								>
									*Subject to negotiation with your agent
								</Typography>
							</Box>
						</div>
					</Grid>
				</Grid>
				<Grid
					container
					className={classes.secondCard}
					onMouseEnter={() => setIsButtonHovered(true)}
					onMouseLeave={() => setIsButtonHovered(false)}
					item
					xs={12}
					sm={6}
					md={6}
					lg={4}
					flexDirection="column"
					justifyContent={"space-between"}
				>
					<Grid item>
						<div>
							<Box display="flex" flexDirection="column">
								<Box display="flex" flexDirection="column" alignItems="center">
									<Typography className={classes.smallerTitle}>
										Alokee
									</Typography>
									<Typography
										className={classes.LargeTitle}
										style={{ color: "#FFF", marginBottom: "auto" }}
									>
										{costOfService}
									</Typography>
									<Typography
										className={classes.whyAlokeeText}
										style={{ marginTop: "0px", color: "#FFF" }}
									>
										{costOfServiceText}
									</Typography>

									<Link to="/signup">
										<Button
											className={classes.button}
											style={{
												background: isButtonHovered ? "#02A689" : "",
												color: isButtonHovered ? "#fff" : "",
												marginTop: "10px"
											}}
										>
											Get Started
										</Button>
									</Link>
								</Box>
							</Box>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default AlokeeComparison;
