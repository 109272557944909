import { Container, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router";
import { useState } from "react";
import { Loader } from "../components/core";
import Cookies from "universal-cookie";
import { sendEnvelopeToRecipient } from "../services/eSigning";
import { useAuth0 } from "@auth0/auth0-react";

const cookies = new Cookies();

const useStyles = makeStyles((theme) => {
	return {
		root: {
			position: "relative",
			width: "100%",
			overflow: "hidden",
			paddingTop: "100%"
		},
		responsiveIframe: {
			position: "absolute",
			position: "fixed",
			left: 0,
			bottom: 0,
			right: 0,
			width: "100%",
			height: "97%",
			border: "none",
			overflow: "hidden"
		},
		loader: {
			position: "fixed",
			left: "50%",
			top: "50%"
		}
	};
});

function SigningPageOtherRecipient({ route }) {
	const classes = useStyles();
	let { userPropertyId, code, role } = useParams();
	const [url, setUrl] = useState("");
	const { user } = useAuth0();
	useEffect(() => {
		cookies.set(`userPropertyId`, userPropertyId, {
			doNotParse: true,
			path: "/"
		});
		cookies.set(`signerRole`, role, {
			doNotParse: true,
			path: "/"
		});
		const embeddedSigning = async () => {
			try {
				const resp = await sendEnvelopeToRecipient({
					signerRole: role,
					userPropertyId,
					code,
					auth0Id: user?.sub
				});

				setUrl(resp.data.redirectUrl);
			} catch (e) {
                
            }
		};

		embeddedSigning();
	}, []);

	return (
		<Container maxWidth="lg" className={classes.root}>
			{url ? (
				<iframe
					title="docuSign-Esigning"
					className={classes.responsiveIframe}
					src={url}
				></iframe>
			) : (
				<Stack className={classes.loader}>
					<Loader type={"Circles"}></Loader>
				</Stack>
			)}
		</Container>
	);
}

export default SigningPageOtherRecipient;
