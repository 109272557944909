import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useUserDispatch } from "../../context/UserContext";
import { getUserProfile } from "../../services/users";
import BackgroundLoader from "../core/BackgroundLoader";
import EmailVerificationWarning from "../core/EmailVerificationWarning";
import CompleteProfile from "../Profile/CompleteProfile";
import ConfirmationCode from "../OTP/ConfirmationCode";
const cookies = new Cookies();
const ProtectedRoute = ({ component, ...args }) => {
	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => <BackgroundLoader />
	});
	const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
		useAuth0();
	const [isProfileCompleted, setIsProfileCompleted] = useState(false);
	const [isProfileLoading, setIsProfileLoading] = useState(true);
	const userDispatch = useUserDispatch();
	useEffect(async () => {
		if (isAuthenticated && !isLoading) {
			const token = await getAccessTokenSilently();
			cookies.set("token", token, {
				doNotParse: true,
				path: "/"
			});
			try {
				const userProfileDetails = await getUserProfile({
					auth0Id: user.sub
				});
				if (userProfileDetails?.data?.data?.data?.length) {
					setIsProfileCompleted(
						userProfileDetails.data.data.data[0].isProfileCompleted
					);
					let profileData = userProfileDetails.data.data.data[0];
					profileData.userId = userProfileDetails.data.data.data[0].id;
					delete profileData["id"];
					userDispatch({
						type: "UPDATE_USER",
						payload: { data: profileData }
					});
					setIsProfileLoading(false);
				} else {
					setIsProfileCompleted(false);
					setIsProfileLoading(false);
				}
			} catch (e) {
				setIsProfileCompleted(false);
				setIsProfileLoading(false);
			}
		}
	}, [isLoading, isAuthenticated]);
	if (!isProfileCompleted && isAuthenticated && isProfileLoading)
		return <BackgroundLoader />;

	if (!isProfileCompleted && isAuthenticated && !isProfileLoading)
		return <CompleteProfile />;
	if (!isLoading && isAuthenticated && user && !user.email_verified)
		return <EmailVerificationWarning />;
	// if (!isLoading && isAuthenticated && user && !cookies.get("otpVerified"))
	// 	return <ConfirmationCode />;
	return <Component {...args} />;
};

export default ProtectedRoute;
