import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
	card: {
		backgroundColor: "#F2F2F2",
		maxWidth: "330px",
		borderRadius: 8,
		cursor: "pointer",
		border: "2px 2px solid #fff",
		boxShadow: "2px 2px 2px #ccc",
		padding: 10
	},
	cardHeading: {
		fontFamily: "Roboto !important",
		fontWeight: "700 !important",
		color: "#2a3d57",
		fontSize: "24px !important",
		cursor: "pointer"
	},
	container: {
		backgroundColor: theme.palette.primary.mainLight
	},
	grid: {
		paddingTop: 100,
		padding: 5
	}
}));
