import React, { useState } from "react";
import { makeStyles, InputLabel, Button, Typography } from "@material-ui/core";
import { FileUploadOutlined } from "@mui/icons-material";
import { useField, useFormikContext } from "formik";
import { CheckCircle } from "@material-ui/icons";
import { useEffect } from "react";
import TextDialog from "./TextDialog";

const useStyles = makeStyles((theme) => ({
	button: {
		borderRadius: 20
	},
	errorColor: {
		color: "#FF0000"
	},
	imageView: {
		color: theme.palette.primary.main,
		cursor: "pointer",
		"&:hover": {
			color: "#000000"
		}
	}
}));
function FileUpload({ onFileSelectError, label, name, value, disabled }) {
	const classes = useStyles();
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);
	const [viewImage, setViewImage] = useState({
		state: false
	});

	const [uploaded, setUploaded] = useState(false);
	useEffect(() => {
		if (field.value) {
			setUploaded(true);
			return;
		}
	}, []);
	const configFormControl = {};
	if (meta && meta.touched && meta.error) {
		configFormControl.error = true;
	}

	const handleFileInput = (e) => {
		const file = e.target.files[0];
		if (file.size > 5048000) {
			onFileSelectError({ error: "File size cannot exceed more than 5MB" });
		} else {
			setFieldValue(name, file);
			setUploaded(true);
		}
	};

	const cleanFilename = (url) => {
		const fileNameWithPossiblePrefixAndDoubleExtension = url.split("/").pop();
		const parts = fileNameWithPossiblePrefixAndDoubleExtension.split(".");

		// Get rid of the possible double extension
		const fileNameWithoutDoubleExtension =
			parts.length > 2
				? parts.slice(0, -1).join(".")
				: fileNameWithPossiblePrefixAndDoubleExtension;

		// Gte rid of the possible 'id-' prefix
		const fileNameWithoutPrefix = fileNameWithoutDoubleExtension.startsWith(
			"id-"
		)
			? fileNameWithoutDoubleExtension.slice(3)
			: fileNameWithoutDoubleExtension;

		return fileNameWithoutPrefix;
	};

	return (
		<div>
			<InputLabel style={{ paddingBottom: 10 }} htmlFor={label}>
				{label}
			</InputLabel>
			<Button
				className={classes.button}
				disabled={!!disabled}
				component="label"
				variant="contained"
				color="primary"
				fullWidth="false"
				endIcon={!uploaded ? <FileUploadOutlined /> : <CheckCircle />}
			>
				{uploaded ? "Uploaded" : "Upload"}
				<input
					hidden
					type="file"
					className={classes.input}
					onChange={handleFileInput}
					accept="image/jpg,application/pdf,image/jpeg,image/png"
				/>
			</Button>
			<Typography
				onClick={() => {
					if (typeof value == "string" && value.includes("pdf")) {
						window.open(value, "_blank");
					} else if (
						typeof value == "object" &&
						value.type &&
						value.type.includes("pdf")
					) {
						window.open(URL.createObjectURL(value), "_blank");
					} else setViewImage({ state: true });
				}}
				variant="h6"
				className={classes.imageView}
				color="textSecondary"
			>
				{cleanFilename(field?.value ? field?.value?.name : value || "")}
			</Typography>
			{value && (
				<TextDialog
					state={viewImage.state}
					stateHandler={setViewImage}
					showAvatar={false}
					showActions={false}
				>
					<img
						src={typeof value == "object" ? URL.createObjectURL(value) : value}
						width="100%"
						height="100%"
					/>
				</TextDialog>
			)}
			<Typography
				variant="caption"
				color="#FF0000"
				className={classes.errorColor}
			>
				{meta && meta.touched && meta.error
					? "Please upload required File"
					: ""}
			</Typography>
		</div>
	);
}

export default FileUpload;
