import React, { useEffect, useState } from "react";
import {
	getArchivedProperties,
	sendEmailToAlokee,
	updateUserProperties
} from "../../services/propertyForm";
import { TextField, Toast } from "../core";
import { Box, Grid, Container, Dialog, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	Typography,
	InputLabel,
	DialogContent,
	DialogContentText,
	Paper
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ArchiveOutlined, UnarchiveOutlined } from "@mui/icons-material";

const ArchiveDialogStyles = makeStyles((theme) => {
	return {
		messageDialogTitle: {
			color: "#003A7B",
			fontFamily: theme.typography.fontFamily,
			fontSize: "24px",
			fontStyle: "normal",
			fontWeight: theme.typography.fontWeightMedium,
			lineHeight: "138%" /* 44.16px */
		},
		noUnderline: {
			"&:before": {
				borderBottom: "none"
			},
			"&:after": {
				borderBottom: "none"
			}
		},
		placeholderText: {
			paddingLeft: "8px",
			paddingRight: "8px"
		},
		contentHeading: {
			fontSize: "1.5rem",
			fontFamily: theme.typography.fontFamily,
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightBold,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem"
			},
			paddingTop: 20
		},
		addressPaper: {
			padding: 5
		}
	};
});

const ArchiveDialog = ({ state, stateHandler, userId, setFilter }) => {
	const classes = ArchiveDialogStyles();
	const [reloadArchived, setReloadArchived] = useState(false);
	const [archivedProperties, setArchivedProperties] = useState([]);

	useEffect(async () => {
		try {
			const resp = await getArchivedProperties({ userId });
			setArchivedProperties(resp.data.data.data.rows);
		} catch (e) {
			console.log(e);
		}
	}, [reloadArchived]);

	const onUnArchive = async (userPropertyId) => {
		try {
			await updateUserProperties({
				userPropertyId: userPropertyId,
				archived: null
			});
			setReloadArchived((prev) => !prev);
			setFilter(null);
			setFilter("Offers");
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Container>
			<Dialog
				open={state}
				onClose={stateHandler}
				fullWidth={true}
				maxWidth={"md"}
			>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={() => {
						stateHandler(false);
					}}
					style={{ position: "absolute", right: 5, top: 15 }}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<DialogContentText className={classes.contentHeading}>
						Archived Properties
					</DialogContentText>

					<Grid
						alignItems={"center"}
						direction={"column"}
						justifyContent={"flex-start"}
						container
						spacing={2}
					>
						{archivedProperties && archivedProperties.length > 0 ? (
							archivedProperties.map((data) => {
								return (
									<Grid
										container
										direction={"row"}
										justifyContent={"space-between"}
										alignItems={"center"}
										item
										spacing={2}
									>
										<Grid item xs={10}>
											<Paper className={classes.addressPaper}>
												<Typography>{data.property_form.address}</Typography>
											</Paper>
										</Grid>
										<Grid item xs={2}>
											<Button
												onClick={onUnArchive.bind(this, data.id)}
												endIcon={<UnarchiveOutlined color="primary" />}
												variant="outlined"
												style={{
													textTransform: "none"
												}}
											>
												Unarchive
											</Button>
										</Grid>
									</Grid>
								);
							})
						) : (
							<Grid item xs={10}>
								<Paper className={classes.addressPaper}>
									<Typography>You have no archived properties.</Typography>
								</Paper>
							</Grid>
						)}
					</Grid>
				</DialogContent>
			</Dialog>
		</Container>
	);
};

export default ArchiveDialog;
