import React from "react";
import { Container, makeStyles, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
	return {
		faqsTitleHeading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		},

		newsLetter: {
			background:
				"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			height: "290px",
			marginTop: 30,
			paddingTop: 30
		},

		blogPara: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem !important"
			},
			marginInlineStart: 20
		},
		blogParaHeader: {
			marginBottom: "5px",
			listStylePosition: "inside",
			textIndent: "-1em",
			paddingLeft: "1em"
		},
		divider: {
			borderTop: "3px solid #00284c"
		}
	};
});

function BlogFive() {
	const classes = useStyles();

	return (
		<div style={{ width: "100%" }}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.faqsTitleHeading}>
							Open House or Real Estate Showing. How to "Hack" The Showing In
							DIY Home Buying.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							No matter what you’ve heard, DIY (Do-It-Yourself) real estate is
							real. There are several great reasons to DIY real estate. You
							could save thousands or tens of thousands of dollars in realtor
							fees that can help you become a savvy real estate investor and you
							will be empowered by understanding the process much better. But,
							it’s not for everyone! If you are going to leverage the awesome
							power of DIY real estate, first, you will need to "hack" the
							showing (how you see homes when you need or want to).
						</p>
					</Grid>

					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Believe it or not, lots of people buy homes without ever seeing
							them. This trend increased during pandemic lockdowns when people
							couldn’t leave their homes. But, many agree this isn’t a great
							idea for most people in most circumstances. If you are buying a
							home, you probably need to see it at least one time, if not two or
							three. The most obvious way to see a home is through an open
							house. These are often posted on neighborhood streets, but they
							are also advertised on the major search sites like Zillow and
							Redfin. You can simply look up the house you want to see, and
							check for posted dates and times of any upcoming open houses.
							Then, attend the open house free of charge. You may be asked to
							sign a register, which is just a courtesy to the seller to let
							them get a feel for how much interest the home is getting it’s
							rare that someone “makes” you sign a register to see the house.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							If you are like most people, you will want to see the house a
							second or even third time before making an offer. One way to do
							this is to contact the seller’s agent (also called the “listing
							agent”) and request a showing. Most seller agents will do this,
							but they may ask if you already have an agent and they may not
							grant the showing if you do have an agent. You can find the
							seller’s agent (again, listing agent) listed on most search sites.
							Lookup their contact information and message them. Note that the
							agent you met at the open house may not be the official seller’s
							agent. Again, you are looking for the “listing agent (or one of
							the listing agents or more than one are listed on the posting).”
							Most listing agents are pretty cooperative, as they are
							contractually obligated to do everything needed to best represent
							the seller in selling their home, to include letting qualified
							people see it.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							If you can’t or don’t want to use the seller agent, you can use
							your favorite real estate search site to find other local agents
							who may show you the property. Again, they will ask you questions
							before showing the property, primarily trying to see if you have
							an agent already. But, at the end of the day, most agents will
							consider showing you a property to meet you and try to gain your
							business. Some agents may ask you to sign a “buyer agency
							agreement” which would force you to use that agent in the future
							(and/or pay them a commission). We recommend NOT signing such an
							agreement under these circumstances.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Finally, if you don’t want to use the listing agent or a “random”
							real estate agent, book a no obligation showing using Alokee.
							Simply set the appointment here. If you have a question about a
							house before you want to see it, contact us here and we’ll answer
							the question, no obligations. Alokee does not use your contact
							information for marketing purposes and we will not share or sell
							your data. It’s truly a no obligation showing and we do this to
							introduce you to our service.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Once you learn how to hack showings, you are well on your way to
							DIY real estate! Happy showing!
						</p>
					</Grid>
					
				</Grid>
			</Container>
		</div>
	);
}

export default BlogFive;
