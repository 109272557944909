import { React, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Box, Grid, Container, makeStyles, Paper } from "@material-ui/core";
import * as _ from "lodash";

import { TextField, Button, Toast } from "../../../core";
import {
	createInspector,
	updateInspector
} from "../../../../services/inspector";

const useStyles = makeStyles((theme) => ({
	formWrapper: {
		margin: theme.spacing(3),
		padding: theme.spacing(3)
	},
	icon: {
		fontSize: 25,
		color: theme.palette.primary.main,
		cursor: "pointer",
		float: "right"
	}
}));

const Inspector = ({ inspectorObj, inspector, inspectorFormHandler }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const [initialValues, setInitialValues] = useState({
		id: inspectorObj?.id,
		name: inspectorObj?.name,
		email: inspectorObj?.email,
		postCode: inspectorObj?.postCode,
		calendlyLink: inspectorObj?.calendlyLink
	});

	const validationSchema = Yup.object().shape({
		name: Yup.string().max(150).required("Name is required"),
		postCode: Yup.string().max(150).required("Postal Code is required"),
		calendlyLink: Yup.string().required("Calendly Link is required"),
		email: Yup.string().email().required("Email is required")
	});

	const onSubmit = async (values, { resetForm }) => {
		setLoading(true);
		// Transform values

		let resp;
		if (inspectorObj) {
			resp = await updateInspector(values);
		} else {
			resp = await createInspector(values);
		}
		if (resp.data.success && resp.data.message != "Inspector Already Exists.") {
			setToast({
				state: "open",
				message: resp.data.message,
				type: "success"
			});
			if (inspectorObj) {
				setTimeout(() => {
					resetForm();
					inspectorFormHandler((prevState) => {
						return { ...prevState, state: false };
					});
					inspector(null);
				}, 500);
			} else {
				resetForm();
			}
		} else {
			setToast({
				state: "open",
				message: resp.data.message,
				type: "error"
			});
		}

		setLoading(false);
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<Container maxWidth="md">
					<Toast {...toast} setState={setToast} />
					<Paper>
						<div className={classes.formWrapper}>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
							>
								<Form>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												name="name"
												label="Inspectors's Name"
												required
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField name="postCode" label="Postal Code" required />
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="email"
												label="Inspectors's Email"
												required
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="calendlyLink"
												label="Calendly Link"
												required
											/>
										</Grid>
										<Grid item xs={12}>
											<Box py={2}>
												<Button
													fullWidth={false}
													loading={loading}
													loadingPosition="start"
												>
													{inspectorObj
														? "Update Inspector"
														: "Create Inspector"}
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Form>
							</Formik>
						</div>
					</Paper>
				</Container>
			</Grid>
		</Grid>
	);
};

export default Inspector;
