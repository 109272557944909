import axiosInstance from ".";

export const getAllChats = async (params) => {
	return axiosInstance.post(`/userChat/getAllChats`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};
export const getAllUserChats = async (params) => {
	return axiosInstance.post(`/userChat/getAllUserChats`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};
export const getUserChatById = async (params) => {
	return axiosInstance.post(`/userChat/getChatById`, {
		userId: params.userId
	});
};
export const createUserChat = async (params) => {
	return axiosInstance.post(`/userChat/createChat`, params);
};
