import axiosInstance from ".";

export const getCode = async (params) => {
	return axiosInstance.get(
		`/docuSign/getCode`,
		{ ...params },
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};

export const eSign = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/signDocument`,
		{ ...params },
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};

export const sendEnvelopeToRecipient = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/sendEnvelopeToRecipient`,
		{ ...params },
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};

export const sendCounterOfferEnvelopeToRecipient = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/sendCounterOfferEnvelopeToRecipient`,
		{ ...params },
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};

export const createEnvelope = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/createEnvelope`,
		{ ...params },
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};

export const getEnvelopes = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/list`,
		{ ...params },
		{
			headers: { "Access-Control-Allow-Origin": "*" }
		}
	);
};
export const getEnvelopeById = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/getEnvelopeById`,
		{ envelopeId: params.envelopeId },
		{
			headers: { "Access-Control-Allow-Origin": "*" }
		}
	);
};

export const sendDocument = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/sendDocument`,
		{ ...params },
		{
			headers: {
				"Access-Control-Allow-Origin": "*"
			}
		}
	);
};

export const downloadDocumentById = async (params) => {
	return axiosInstance.post(
		`/embeddedSigning/downloadDocumentById`,
		{
			mlsId: params.mlsId,
			userPropertyId: params.userPropertyId,
			auth0Id: params.auth0Id,
			docName: params.docName
		},
		{
			responseType: "arraybuffer"
		},
		{
			headers: { "Access-Control-Allow-Origin": "*" }
		}
	);
};
