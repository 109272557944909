import React from "react";
import {
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress } from "@material-ui/core";
import ButtonWrapper from "../core/Button";
import { useNavigate, useParams } from "react-router";
import VerticalLinearStepper from "../core/Stepper";

const accordionStyles = makeStyles((theme) => {
	return {
		accordion: {
			boxShadow: "none !important"
		},
		summary: {
			maxHeight: 90
		},
		button: {
			textTransform: "none",
			fontWeight: theme.typography.fontWeightSemiBold,
			fontSize: "1rem",
			borderRadius: "6px"
		},
		backButton: {
			border: "1px solid",
			borderColor: "#003A7B"
		}
	};
});

const Collapsable = ({
	children,
	handleBack,
	handleNext,
	activeStep,
	loading,
	onClick,
	revisedState,
	enableFinish,
	onAdminReviewDone,
	status,
	showActions,
	setShowConfirm,
	setStatus,
	signedBy,
	setShowCommentForm,
	sendingToBuyer,
	userPropertyDetails,
	hideStepper,
	isBuyer,
	propertyId,
	propertyMlsId
}) => {
	const classes = accordionStyles();
	const navigate = useNavigate();
	const { role } = useParams();
	
	let signByRole;
	if (role) {
		signByRole = role
			.match(
				/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
			)
			.map((x) => x.toLowerCase())
			.join("-");
		signByRole = signByRole
			.replace(/\w+/g, function (w) {
				return w[0].toUpperCase() + w.slice(1).toLowerCase();
			})
			.replace("-", "");
	}

	return (
		<div>
			<Accordion
				TransitionProps={{ unmountOnExit: false }}
				className={classes.accordion}
				expanded={true}
			>
				<AccordionSummary
					aria-controls="panel1a-content"
					id="panel1a-header"
					onClick={onClick}
					className={classes.summary}
				>
					{!hideStepper && (
						<Grid item xs={12} lg={12}>
							<VerticalLinearStepper
								activeStep={activeStep}
								revisedState={revisedState}
							></VerticalLinearStepper>
						</Grid>
					)}
				</AccordionSummary>
				<AccordionDetails>
					{children}
					<Grid container justifyContent="flex-end" spacing={3}>
						{onAdminReviewDone || role == "buyerAgent" ? (
							<Grid item xs={12} sm={12} md={4} lg={4}>
								<Button
									variant="contained"
									onClick={() => {
										setShowCommentForm((prevState) => {
											return {
												...prevState,
												state: true
											};
										});
									}}
									className={classes.button}
									fullWidth={true}
									style={{ backgroundColor: "#FFC107" }}
									disabled={
										userPropertyDetails
											? userPropertyDetails[
													`signedBy${role ? signByRole : "Buyer"}`
											  ] == true
											: false
									}
								>
									{status == "awaiting-alokee-approval"
										? "Add Comment"
										: "Return to Buyer"}
								</Button>
							</Grid>
						) : role == "buyerAgent" ||
						  role == "sellerAgent" ||
						  role == "seller" ||
						  role == "secondSeller" ||
						  role == "secondBuyer" ? (
							""
						) : (
							<Grid item sm={12} xs={12} md={4} lg={4}>
								<Button
									disabled={activeStep === 0 || loading}
									onClick={handleBack}
									className={[classes.button, classes.backButton]}
									fullWidth={true}
									variant="outlined"
								>
									Back
								</Button>
							</Grid>
						)}
						{status == "under-review" &&
						(onAdminReviewDone || role == "buyerAgent") ? (
							<Grid item xs={12} sm={12} md={4} lg={4}>
								<Button
									variant="contained"
									onClick={() => {
										navigate(
											`/app/dashboard/admin/revise-offer/${propertyId}/${propertyMlsId}`,
											{
												replace: true
											}
										);
									}}
									className={classes.button}
									fullWidth={true}
									style={{ backgroundColor: "#28A745" }}
									disabled={
										userPropertyDetails
											? userPropertyDetails[
													`signedBy${role ? signByRole : "Buyer"}`
											  ] == true
											: false
									}
								>
									{status == "under-review" ? "Revise Offer" : ""}
								</Button>
							</Grid>
						) : (
							""
						)}
						{role == "sellerAgent" &&
							!userPropertyDetails[`signedBy${role ? signByRole : "Buyer"}`] &&
							userPropertyDetails["lastOfferType"] !==
								"seller-counter-offer" && (
								<Grid item xs={12} sm={12} md={4}>
									<Button
										label="Counter Offer"
										className={classes.button}
										variant="contained"
										style={{ backgroundColor: "#28A745", color: "#fff" }}
										disabled={
											userPropertyDetails[
												`signedBy${role ? signByRole : "Buyer"}`
											] == true || status == "rejected"
										}
										onClick={async () => {
											setShowConfirm((prevState) => {
												return {
													...prevState,
													state: true
												};
											});
											setStatus("revised");
										}}
										fullWidth={true}
									>
										Counter Offer
									</Button>
								</Grid>
							)}
						{role == "sellerAgent" &&
							!userPropertyDetails[`signedBy${role ? signByRole : "Buyer"}`] &&
							userPropertyDetails["lastOfferType"] !==
								"seller-counter-offer" && (
								<Grid item xs={12} sm={12} md={4}>
									<Button
										label="Reject"
										className={classes.button}
										variant="contained"
										style={{ backgroundColor: "#FF4842", color: "#fff" }}
										disabled={
											userPropertyDetails[
												`signedBy${role ? signByRole : "Buyer"}`
											] == true || status == "rejected"
										}
										onClick={() => {
											setShowConfirm((prevState) => {
												return {
													...prevState,
													state: true
												};
											});
											setStatus("rejected");
										}}
										fullWidth={true}
									>
										Reject
									</Button>
								</Grid>
							)}
						{signedBy == "buyer" ||
						signedBy == "second-buyer" ||
						signedBy == "buyer-agent" ||
						signedBy == "seller-agent" ||
						signedBy == "seller" ||
						signedBy == "second-seller" ||
						(signedBy == null &&
							(role == "buyerAgent" ||
								role == "sellerAgent" ||
								role == "seller" ||
								role == "secondSeller")) ? (
							<Grid item xs={12} sm={12} md={4}>
								<ButtonWrapper
									variant="contained"
									color="primary"
									className={classes.button}
									fullWidth={true}
									disabled={
										userPropertyDetails[
											`signedBy${role ? signByRole : "Buyer"}`
										] == true || status == "rejected"
									}
								>
									{userPropertyDetails[
										`signedBy${role ? signByRole : "Buyer"}`
									] == true
										? "Already Signed"
										: "Proceed to Sign"}
								</ButtonWrapper>
							</Grid>
						) : showActions &&
						  status != "accepted" &&
						  status != "rejected" &&
						  role != "buyerAgent" &&
						  role != "sellerAgent" &&
						  role != "seller" &&
						  role != "secondSeller" ? (
							<>
								<Grid item xs={12} sm={12} md={4}>
									<Button
										style={{ backgroundColor: "#17A2B8", color: "#fff" }}
										className={classes.button}
										onClick={() => {
											setShowConfirm((prevState) => {
												return {
													...prevState,
													state: true
												};
											});
											setStatus("accepted");
										}}
										label="Accept"
										variant="contained"
										fullWidth={true}
									>
										Accept
									</Button>
								</Grid>
								<Grid item xs={12} sm={12} md={4}>
									<Button
										label="Reject"
										className={classes.button}
										variant="contained"
										style={{ backgroundColor: "#FF4842", color: "#fff" }}
										onClick={() => {
											setShowConfirm((prevState) => {
												return {
													...prevState,
													state: true
												};
											});
											setStatus("rejected");
										}}
										fullWidth={true}
									>
										Reject
									</Button>
								</Grid>
								<Grid item xs={12} sm={12} md={4}>
									<Button
										label="Counter Offer"
										className={classes.button}
										variant="contained"
										style={{ backgroundColor: "#28A745", color: "#fff" }}
										onClick={() => {
											setShowConfirm((prevState) => {
												return {
													...prevState,
													state: true
												};
											});
											setStatus("revised");
										}}
										fullWidth={true}
									>
										Counter Offer
									</Button>
								</Grid>
							</>
						) : status == "under-review" &&
						  !onAdminReviewDone &&
						  activeStep === 4 - 1 ? (
							<Grid item xs={12} sm={12} md={4}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										navigate("/app/dashboard?filter=Offers", {
											replace: true
										});
									}}
									className={classes.button}
									fullWidth={true}
								>
									{"Back to Offers"}
								</Button>
							</Grid>
						) : (
							<Grid item md={4} lg={4} xs={12} sm={12}>
								{!onAdminReviewDone && activeStep === 4 - 1 ? (
									<ButtonWrapper
										variant="contained"
										color="primary"
										className={classes.button}
										loading={loading}
										disabled={
											(loading && !enableFinish) ||
											(revisedState && !enableFinish)
										}
										fullWidth={true}
									>
										{"Finish"}
									</ButtonWrapper>
								) : (
									<Button
										variant="contained"
										fullWidth={true}
										disabled={
											activeStep === 4 ||
											loading ||
											sendingToBuyer ||
											(onAdminReviewDone && status != "under-review")
										}
										color="primary"
										onClick={handleNext}
										className={classes.button}
										loading={loading || sendingToBuyer}
									>
										{loading || sendingToBuyer ? (
											<CircularProgress size={20} />
										) : onAdminReviewDone && status == "under-review" ? (
											"Send to Buyer Agent"
										) : status == "awaiting-alokee-approval" ? (
											"Action Required"
										) : onAdminReviewDone &&
										  status != "under-review" &&
										  status != "awaiting-alokee-approval" &&
										  status != "awaiting-signatures" &&
										  status != "in-progress" ? (
											"Already Sent To Buyer Agent"
										) : status == "in-progress" && !isBuyer ? (
											"In Progress"
										) : (
											"Next"
										)}
									</Button>
								)}
							</Grid>
						)}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default Collapsable;
