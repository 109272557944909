import { useState } from "react";
import * as _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core";
import { Paper, Typography } from "@mui/material";

import { Loader } from "../core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	table: {
		// padding: "40px 0px 20px 0"
	},
	icon: {
		fontSize: 25,
		color: theme.palette.primary.main,
		cursor: "pointer",
		float: "right"
	},
	loaderPosition: {
		position: "absolute",
		right: "48%",
		top: "28%",
		zIndex: 1
	},
	loaderPositionEmpty: {
		position: "absolute",
		right: "48%",
		top: "28%",
		zIndex: 1
	}
}));
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 15,
		padding: 10
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);
const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD"
});

const headers = [
	{ value: "alokeeCashBonus" },
	{ value: "purchasePrice" },
	{ value: "purchasePriceRevised" },
	{ value: "percentageChangeInPurchasePrice" },
	{ value: "revisedBy" },
	{ value: "deposit" },
	{ value: "balanceDownPayment" },
	{ value: "loan" },
	{ value: "secondBuyer" },
	{ value: "entity" },
	{ value: "buyer" },
	{ value: "sellerName" },
	{ value: "sellerAgentName" },
	{ value: "secondSellerName" },
	{ value: "buyerFundsNeededToClose" },
	{ value: "address" },
	{ value: "closingDate" },
	{ value: "expiryDate" },
	{ value: "increaseDeposit" },
	{ value: "loanType" },
	{ value: "loanRate" },
	{ value: "loanRateType" },
	{ value: "additionalLoanAmount" },
	{ value: "additionalLoanType" },
	{ value: "occupancyType" },
	{ value: "loanContingency" },
	{ value: "appraisalContingency" },
	{ value: "investigationContingency" },
	{ value: "sellerDocumentsReview" },
	{ value: "titleReportReview" },
	{ value: "removalOfAllContingencies" },
	{ value: "contingentOffer" },
	{ value: "liquidatedDamages" },
	{ value: "arbitrationOfDisputes" },
	{ value: "proofOfFunds" },
	{ value: "loanQualification" },
	{ value: "disclosure" },
	{ value: "otherDocuments" },
	{ value: "buyerIdentity" }
];

const camelToTitle = (value) => {
	if (value) {
		if (value == "expiryDate") {
			return "Offer Expiration Date";
		}
		const result = value.replace(/([A-Z])/g, " $1");
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
		return finalResult;
	}
	return;
};

const FormSummary = ({
	values,
	buyerFunds,
	address,
	revisedFields,
	cashBonus,
	hideCashBonus,
	hideDocuments
}) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	let filledData = [
		{
			...values,
			disclosure: values?.disclosure
				? JSON.stringify([values?.disclosure])
				: null,
			buyerFundsNeededToClose: buyerFunds,
			address,
			alokeeCashBonus: cashBonus,
			revisedFields,
			percentageChangeInPurchasePrice: revisedFields
				? revisedFields.purchasePrice || revisedFields.purchasePriceRevised
				: null,
			purchasePriceRevised: revisedFields
				? revisedFields.purchasePrice || revisedFields.purchasePriceRevised
				: null,
			revisedBy: revisedFields ? revisedFields.revisedBy : null,
			secondBuyer: values.partners
		}
	];

	let purchasePrice = filledData[0]["purchasePrice"];
	if (
		revisedFields &&
		(revisedFields.purchasePrice || revisedFields.purchasePriceRevised)
	) {
		filledData[0]["deposit"] = null;
		filledData[0]["balanceDownPayment"] = null;
		filledData[0]["loan"] = null;
	}

	return (
		<Paper
			elevation={0}
			sx={{ width: { sm: "100%" } }}
		>
			{loading && (
				<div
					className={
						filledData.length
							? classes.loaderPosition
							: classes.loaderPositionEmpty
					}
				>
					<Loader height={50} />
				</div>
			)}
			<TableContainer className={classes.table}>
				<Table className={classes.table} aria-label="simple table">
					<TableBody className={classes.table}>
						{filledData[0] &&
							headers.map((header) => {
								if (header.value == "percentageChangeInPurchasePrice") {
									if (filledData[0][header.value]) {
										if (
											filledData[0]["percentageChangeInPurchasePrice"] -
											purchasePrice ==
											0
										) {
											filledData[0][header.value] = "0%";
										} else {
											filledData[0][header.value] =
												(
													((filledData[0]["percentageChangeInPurchasePrice"] -
														purchasePrice) /
														purchasePrice) *
													100
												).toFixed(2) + "%";
										}
									}
								}

								if (header.value == "revisedBy") {
									if (filledData[0][header.value]) {
										filledData[0][header.value] = camelToTitle(
											filledData[0][header.value]
										);
									}
								}
								if (
									header.value == "purchasePriceRevised" ||
									header.value == "alokeeCashBonus"
								) {
									if (filledData[0][header.value] > 0) {
										filledData[0][header.value] = formatter.format(
											filledData[0][header.value]
										);
									} else {
										filledData[0][header.value] = null;
									}
								}
								if (header.value == "secondBuyer" || header.value == "entity") {
									if (filledData[0][header.value]?.length) {
										filledData[0][header.value] = filledData[0][
											header.value
										].map((data, idx) => data.name);
									} else {
										filledData[0][header.value] = "None";
									}
									if (hideCashBonus) {
										filledData[0][header.value] = null;
									}
								}
								if (
									header.value == "loanQualification" ||
									header.value == "proofOfFunds" ||
									header.value == "otherDocuments" ||
									header.value == "disclosure"
								) {
									if (hideDocuments) {
										filledData[0][header.value] = null;
										return;
									}
									if (
										filledData[0][header.value] &&
										filledData[0][header.value].length > 0 &&
										!filledData[0][header.value][0]?.name
									) {
										filledData[0][header.value] = JSON.parse(
											filledData[0][header.value]
										);
									}
									if (
										filledData[0][header.value] &&
										filledData[0][header.value].length > 0 &&
										filledData[0][header.value][0]?.name
									) {
										let names = [];
										for (
											let i = 0;
											i < filledData[0][header.value].length;
											i++
										) {
											names.push(filledData[0][header.value][i].name);
										}

										filledData[0][header.value] = names.map((data, idx) => {
											return <h4>{data}</h4>;
										});
									} else if (
										filledData[0][header.value] &&
										filledData[0][header.value].length > 0 &&
										filledData[0][header.value][0].includes("https") &&
										header.value == "disclosure"
									) {
										filledData[0][header.value] = filledData[0][
											header.value
										].map((data, idx) => {

											return (
												<Typography>
													<a target="_blank" href={`${data}`}>
														{idx + 1}:{" "}
														{decodeURI(data.split("/")[6].split(".")[0])
															.split("-")[1]
															.split("%")[0] +
															"." +
															data.slice(data.length - 3, data.length)}
													</a>
												</Typography>
											);
										});
									} else if (
										filledData[0][header.value] &&
										filledData[0][header.value].length > 0 &&
										filledData[0][header.value][0].includes("https")
									) {
										filledData[0][header.value] = filledData[0][
											header.value
										].map((data, idx) => {
											return (
												<Typography>
													<a target="_blank" href={`${data}`}>
														{idx + 1}:{" "}
														{decodeURI(data.split("/")[6].split(".")[0])
															.split("-")[1]
															.split("%")[0] +
															"." +
															data.slice(data.length - 3, data.length)}
													</a>
												</Typography>
											);
										});
									}
								}
								if (header.value == "buyerIdentity") {
									if (
										filledData[0][header.value]?.length > 0 &&
										filledData[0][header.value]?.includes("https")
									) {
										let buyersId = filledData[0][header.value];
										filledData[0][header.value] = (
											<Typography>
												<a target="_blank" href={buyersId}>
													1: Buyers Identity
												</a>
											</Typography>
										);
									} else {
										filledData[0][header.value] = null;
									}
								}
								if (header.value == "purchasePrice") {
									if (filledData[0][header.value]) {
										filledData[0][header.value] = formatter.format(
											filledData[0][header.value]
										);
									}
								}
								if (header.value == "additionalLoanAmount") {
									if (filledData[0][header.value]) {
										filledData[0][header.value] = formatter.format(
											filledData[0][header.value]
										);
									}
								}
								if (header.value == "increaseDeposit") {
									if (filledData[0][header.value] == 0) {
										filledData[0][header.value] = null;
									} else {
										filledData[0][header.value] = formatter.format(
											filledData[0][header.value]
										);
									}
								}
								if (header.value == "loanRate") {
									if (filledData[0][header.value] == 0) {
										filledData[0][header.value] = null;
									} else {
										filledData[0][header.value] =
											filledData[0][header.value] + "%";
									}
								}
								if (
									header.value == "deposit" ||
									header.value == "loan" ||
									header.value == "balanceDownPayment"
								) {
									if (filledData[0][header.value] == 0) {
										filledData[0][header.value] =
											filledData[0][header.value] +
											"%" +
											" " +
											"(" +
											formatter.format(
												(parseInt(purchasePrice) *
													parseInt(filledData[0][header.value])) /
												100
											) +
											")";
										return;
									}
									if (filledData[0][header.value]) {
										filledData[0][header.value] =
											formatter.format(
												(parseInt(purchasePrice) *
													parseInt(filledData[0][header.value])) /
												100
											) +
											" " +
											"(" +
											filledData[0][header.value] +
											"%" +
											")";
									}
								}

								if (
									header.value == "closingDate" ||
									header.value == "expiryDate"
								) {
									if (filledData[0][header.value]) {
										filledData[0][header.value] = new Date(
											moment(new Date(), "DD-MM-YYYY").add(
												filledData[0][header.value],
												"days"
											)
										).toLocaleDateString();
									}
								}
								if (
									header.value == "loanContingency" ||
									header.value == "appraisalContingency" ||
									header.value == "investigationContingency" ||
									header.value == "sellerDocumentsReview" ||
									header.value == "titleReportReview" ||
									header.value == "removalOfAllContingencies" ||
									header.value == "contingentOffer"
								) {
									if (filledData[0][header.value]) {
										filledData[0][header.value] = "Yes";
									} else {
										filledData[0][header.value] = "No";
									}
								}
								if (
									(header.value == "alokeeCashBonus" ||
										header.value == "buyerFunds") &&
									hideCashBonus
								) {
									filledData[0][header.value] = null;
								}
								if (
									header.value == "loanType" ||
									header.value == "additionalLoanType" ||
									header.value == "occupancyType" ||
									header.value == "liquidatedDamages" ||
									header.value == "arbitrationOfDisputes"
								) {
									if (hideCashBonus) {
										filledData[0][header.value] = null;
									}
								}

								return (
									<StyledTableRow key={header.name}>
										{filledData[0][header.value] && (
											<StyledTableCell
												component="th"
												scope="row"
												style={{ fontWeight: "bold", color: "#003A7B" }}
											>
												{camelToTitle(header.value)}
											</StyledTableCell>
										)}
										{filledData[0][header.value] && (
											<StyledTableCell style={{ textAlign: "right" }}>
												{filledData[0][header.value] &&
													filledData[0][header.value]}
											</StyledTableCell>
										)}
									</StyledTableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default FormSummary;
