import React from "react";
import { ColorRing, InfinitySpin } from "react-loader-spinner";

const LoaderWrapper = ({ type, height, width, color, visible }) => {
	return (
		<InfinitySpin
			visible={true}
			height={height || 90}
			width={width || 90}
			color="#003a7b"
		/>
	);
};

export default LoaderWrapper;
