import axiosInstance from ".";

export const getAllComments = async (params) => {
	return axiosInstance.post(`/user/getAllComments`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};
export const createComment = async (params) => {
	return axiosInstance.post(`/comment/create`, params);
};

export const deleteComment = async (params) => {
	return axiosInstance.post(`/comment/delete`, params);
};
