import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppBar } from "../components/AppBar";
import { Container, makeStyles } from "@material-ui/core";
import Footer from "../components/footer/footer";
import { Grid, Typography } from "@mui/material";
import AimLoanIcon from "../assets/AimLoanIcon.webp";
import RelianceFinancialIcon from "../assets/RelianceFinancialIcon.png";
import BankRate from "../assets/bankrate-square.jpeg";
import { useUserState } from "../context/UserContext";
import { TrackGoogleAnalyticsEvent } from "../configs/googleAnalytics";

const financingStyling = makeStyles((theme) => {
	return {
		container: {
			marginTop: "150px",
			paddingBottom: "100px",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "120px"
			}
		},
		paragraph: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`
		},
		heading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		},
		imageCursor: {
			"&:hover": {
				cursor: "pointer"
			}
		}
	};
});

function Financing({}) {
	const classes = financingStyling();
	const userState = useUserState();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div style={{ width: "100%" }}>
			<Helmet>
				<title>Alokee | Financing</title>
			</Helmet>
			<Container maxWidth="lg" className={classes.container}>
				<AppBar whiteBackground={true} />
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.heading}>Need Financing?</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							With Alokee, you can use any lender of your choice. Today, many
							lenders use a fully online application process. You can compare
							loan packages, pick the one that best fits your needs, and apply
							within minutes.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Here are some lenders that Alokee users have found to be fast and
							cost effective. You can get an instant quote, and return to making
							an offer on Alokee:
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.imageCursor}>
						<img
							src={RelianceFinancialIcon}
							onClick={() => {
								window.open(
									"https://app.reliancefinancial.com/partner/PTV89UZ7/search",
									"_blank"
								);
								TrackGoogleAnalyticsEvent(
									"lender_click",
									"RelianceFinancial",
									window.location.pathname + window.location.search,
									{
										userId: userState?.userProfile?.userId,
										userName: userState?.userProfile?.firstName
									}
								);
							}}
							variant="square"
							style={{
								height: 100,
								width: 300
							}}
						></img>
					</Grid>
					<Grid item xs={12} className={classes.imageCursor}>
						<img
							src={AimLoanIcon}
							onClick={() => {
								window.open("https://www.aimloan.com/", "_blank");
								TrackGoogleAnalyticsEvent(
									"lender_click",
									"AimLoan",
									window.location.pathname + window.location.search,
									{
										userId: userState?.userProfile?.userId,
										userName: userState?.userProfile?.firstName
									}
								);
							}}
							variant="square"
							style={{
								height: 100,
								width: 300
							}}
						></img>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							You can also compare rates and terms of more lenders at:
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.imageCursor}>
						<img
							src={BankRate}
							onClick={() => {
								window.open(
									"https://www.bankrate.com/mortgages/mortgage-rates/",
									"_blank"
								);
							}}
							variant="square"
							style={{
								height: 50,
								width: 300
							}}
						></img>
					</Grid>

					<Grid item xs={12}>
						<Typography className={classes.paragraph}>
							Once you have a pre-approval letter, upload it to your secure
							Alokee portal and continue your offer.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</div>
	);
}

export default Financing;
