import axiosInstance from ".";

export const getAllInspectors = async (params) => {
	return axiosInstance.post(`/inspector/getAllInspectors`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id,
		postCode: params.postCode
	});
};
export const getUserInspections = async (params) => {
	return axiosInstance.post(`/inspector/getUserInspections`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id,
		email: params.email
	});
};
export const getEvaluatedUserInspections = async (params) => {
	return axiosInstance.post(`/inspector/getEvaluatedUserInspections`, {
		auth0Id: params.auth0Id,
		...params
	});
};
export const createUserInspection = async (params) => {
	return axiosInstance.post(`/inspector/createUserInspection`, params);
};
export const createInspector = async (params) => {
	return axiosInstance.post(`/inspector/createInspectors`, params);
};
export const updateInspector = async (params) => {
	return axiosInstance.post(`/inspector/updateInspectors`, params);
};
export const removeInspector = async (params) => {
	return axiosInstance.post(`/inspector/deleteInspectors`, {
		id: params.id,
		auth0Id: params.auth0Id
	});
};
