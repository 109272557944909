import { Outlet, useLocation } from "react-router-dom";
// components
import { AppBar } from "../AppBar";
import makeStyles from "./styles";
import { Helmet } from "react-helmet";
import { useAuth0 } from "@auth0/auth0-react";
import BackgroundLoader from "../core/BackgroundLoader";
import { HeroSection } from "../HeroSection/HeroSection";
import heroHome from "../../assets/newHeroImage.png";
import { useUserState } from "../../context/UserContext";

function ViewLayout() {
	const classes = makeStyles();
	const { user } = useAuth0();
	const { pathname } = useLocation();
	const userState = useUserState();
	return (
		<>
			<Helmet>
				<title>Dashboard | Alokee</title>
			</Helmet>
			{user && !userState?.userProfile?.isInspector ? (
				<div className={classes.root}>
					{pathname == "/app/redirecting" ? (
						""
					) : (
						<>
							<AppBar background={false} whiteBackground={false} />
							{(sessionStorage.getItem("selectedHomePage") == 0 ? (
							<HeroSection
								heroImage={heroHome}
								heroTextOne={"The Revolutionary "}
								heroTextTwo={"Digital Real Estate Experience"}
								heroDescriptionTextOne={"Looking for your dream home?"}
								heroDescriptionTextTwo={
									"Use Alokee to make an offer and close."
								}
								heroDescriptionTextThree={"Unlock savings of nearly $30,000."}
								isDashboard={true}
							/>
							) : sessionStorage.getItem("selectedHomePage") == 1 ? (
							<HeroSection
								heroImage={heroHome}
								heroTextOne={"Keep the buyer commission."}
								heroTextTwo={""}
								heroDescriptionTextOne={
									"Submit an offer on a house for free. If you need showings, "
								}
								heroDescriptionTextTwo={
									"real estate attorney or real estate broker advice, we'll bill "
								}
								heroDescriptionTextThree={
									"you at closing and subtract it from the commission."
								}
								isDashboard={true}
							/>
							) : sessionStorage.getItem("selectedHomePage") == 2 ? (
							<HeroSection
								heroImage={heroHome}
								heroTextOne={"Buying a home?"}
								heroTextTwo={"Keep up to 90% of the commission."}
								heroDescriptionTextOne={
									"Pay only for the showings and features you used at closing."
								}
								heroDescriptionTextTwo={
									<>
										Alokee will refund the balance of the buyer commission{" "}
										<br></br>
										directly to you.
									</>
								}
								isDashboard={true}
							/>
							) : (
							<HeroSection
								heroImage={heroHome}
								heroTextOne={"10% of people don't use a real estate"}
								heroTextTwo={"agent to buy a home."}
								heroDescriptionTextOne={
									"Find out if you're capable of buying a house way other"
								}
								heroDescriptionTextTwo={
									<>
										professionals do. <b>Unlock savings of nearly $30,000!</b>
									</>
								}
								heroDescriptionTextThree={""}
								isDashboard={true}
							/>
							))}
						</>
					)}
					<div className={classes.wrapper}>
						<div className={classes.contentContainer}>
							<main className={classes.content}>
								<Outlet />
							</main>
						</div>
					</div>
				</div>
			) : (
				<BackgroundLoader />
			)}
		</>
	);
}

export default ViewLayout;
