import axiosInstance from ".";

export const getAllShowings = async (params) => {
	return axiosInstance.post(`/userShowing/getAllUserShowingsById`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};

export const getShowingsById = async (params) => {
	return axiosInstance.post(`/userShowing/getShowingsById`, {
		id: params.id,
		auth0Id: params.auth0Id
	});
};

export const createShowing = async (params) => {
	return axiosInstance.post(`/userShowing/createShowing`, params);
};
export const createShowingWithShowami = async (params) => {
	return axiosInstance.post(`/userShowing/createShowingWithShowami`, params);
};

export const removeShowing = async (params) => {
	return axiosInstance.post(`/userShowing/deleteShowing`, {
		id: params.id,
		auth0Id: params.auth0Id
	});
};

export const cancelShowingWithShowami = async (params) => {
	return axiosInstance.post(`/userShowing/cancelShowingWithShowami`, {
		...params
	});
};
