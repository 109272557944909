import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
	createStyles({
		messageRow: {},
		messageRowRight: {
			display: "flex",
			justifyContent: "flex-end"
		},
		messageBlue: {
			position: "relative",
			marginLeft: "20px",
			marginBottom: "10px",
			padding: "10px",
			backgroundColor: "rgba(242, 242, 242, 0.5)",
			minWidth: "85%",
			height: "70px",
			textAlign: "left",
			font: "400 .9em 'Open Sans', sans-serif",
			border: "1px solid #003a5a",
			borderRadius: "10px"
		},

		messageContent: {
			padding: 0,
			margin: 0,
			color: "#000"
		},
		messageTimeStampRight: {
			position: "absolute",
			fontSize: ".85em",
			fontWeight: "300",
			marginTop: "10px",
			bottom: "-0px",
			right: "5px",
			color: "#000",
			paddingLeft: 10
		},
		messageDate: {
			position: "absolute",
			fontSize: ".85em",
			fontWeight: "300",
			marginTop: "10px",
			bottom: "-0px",
			right: "75px",
			color: "#000",
			paddingLeft: 10,
			paddingRight: 10
		},
		displayName: {
			marginLeft: "20px"
		},
		delete: {
			position: "fixed",
			bottom: "-0px",
			left: "1100px"
		},
		delete: {
			position: "absolute",
			fontSize: ".85em",
			fontWeight: "300",
			marginTop: "10px",
			bottom: "40px",
			right: "5px",
			color: theme.palette.primary.main,
			cursor: "pointer",
			"&:hover": {
				color: "#000000"
			}
		}
	})
);

export const MessageLeft = (props) => {
	const message = props.message ? props.message : "no message";
	const timestamp = props.timestamp ? props.timestamp : "";
	const date = props.date ? props.date : "";

	const classes = useStyles();
	return (
		<>
			<div className={classes.messageRow}>
				<div className={classes.messageBlue}>
					{props.showCommentBox && (
						<div className={classes.delete} onClick={props.onDelete}>
							<CloseIcon />
						</div>
					)}
					<p className={classes.messageContent}>{message}</p>
					<div className={classes.messageTimeStampRight}>{timestamp}</div>
					<div className={classes.messageDate}>{date}</div>
				</div>
			</div>
		</>
	);
};
