import React, { useState } from "react";
import { Container, Grid, TextField, InputAdornment } from "@mui/material";
import { Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { sendEmailToAlokee } from "../../services/propertyForm";
import { Toast } from "../core";

const newsLetterStyles = makeStyles((theme) => {
    return {
        newsLetterContainer: {
            paddingBottom: "142px",
            [theme.breakpoints.down("sm")]: {
                paddingBottom: "70px"
            }
        },
        LargeTitle: {
            color: "#FFF",
            fontFamily: theme.typography.fontFamily,
            fontSize: "2.5rem",
            fontWeight: theme.typography.fontWeightSemiBold,
            [theme.breakpoints.down("sm")]: {
                fontSize: "1.75rem"
            }
        },
        SmallTitle: {
            color: "#FFF",
            fontSize: "1.5rem",
            marginTop: "25px",
            width: "50%",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            [theme.breakpoints.down("md")]: {
                fontSize: "1.125rem"
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },
        inputText: {
            '& .MuiInputBase-input': {
                color: "#FFF"
            },
            '& fieldset': {
                borderColor: '#FFF',
            }
        }
    }
});

const NewsLetter = () => {
    const classes = newsLetterStyles();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [toast, setToast] = useState({
        state: "close",
        message: "",
        type: ""
    });

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);

        if (inputEmail && !isValidEmail(inputEmail)) {
            setEmailError("Invalid email address");
        } else {
            setEmailError('');
        }
    };


    const submitForSubscription = async (e) => {
        e.preventDefault();

        if (!isValidEmail(email)) {
            return;
        }

        try {
            const sendEmail = await sendEmailToAlokee({
                details: {
                    from: { email: "offer@alokee.com", name: "Alokee" },
                    subject: "Newsletter Subscription"
                },
                dynamicTemplateData: {
                    name: "New User",
                    email: email,
                    message: "Hey! I want to Subscribe for your newsletters."
                }
            });
            if (sendEmail.data) {
                setToast({
                    state: "open",
                    message: "Your have successfully subscribed our newsletter!",
                    type: "success",
                    hideDuration: 5000
                });
            }
        } catch (e) {
            setToast({
                state: "open",
                message: "Error Subscribing to our newsletter!",
                type: "error",
                hideDuration: 5000
            });
        } finally {
            setEmail("");
        }
    };

    return (
        <div className={classes.newsLetterContainer}>
            <Toast {...toast} setState={setToast} />
            <Grid container justifyContent="flex-start">
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.LargeTitle}>Join our Newsletter</Typography>
                    <Typography className={classes.SmallTitle}>Subscribe to our newsletter and stay ahead of the curve in the real estate market</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <TextField
                        style={{ marginTop: "25px", }}
                        value={email}
                        label="Your Email"
                        fullWidth={true}
                        error={!!emailError}
                        helperText={emailError || ' '}
                        onChange={handleEmailChange}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={submitForSubscription}
                                        aria-label="Submit Email for NewsLetter Subscription"
                                    >
                                        <ArrowCircleRightIcon style={{ color: "#FFF" }} fontSize="large" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            className: classes.inputText
                        }}
                        InputLabelProps={{
                            style: { color: '#fff' }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default NewsLetter;

