import { Helmet } from "react-helmet";
import { Typography, Button, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, Stack, Box } from "@mui/material";
import { Card } from "../components/Card/Card";
import buildings from "../assets/buildings.png";
import Footer from "../components/footer/footer.jsx";
import Searchbar from "../components/searchbar/Searchbar.jsx";
import React, { useEffect, useState } from "react";
import { Navigate, useOutletContext } from "react-router";
import {
	getAllUserPropertiesByUserId,
	updateUserProperties
} from "../services/propertyForm";
import { useSearchParams } from "react-router-dom";
import congratsImage from "../assets/congrats.svg";
import ThankYouIcon from "../assets/ThankYouIcon.svg";
import UnionIcon from "../assets/Union.svg";
import Cookies from "universal-cookie";
import TextDialog from "../components/core/TextDialog";
import { getAllFavourites } from "../services/favorites";
import Paginator from "../components/table/Paginator";
import CardSkeleton from "../components/core/CardSkeleton";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { useUserState } from "../context/UserContext";
import Chat from "../components/Chat/Chat";
import { createUserChat, getUserChatById } from "../services/userChat";
import VerifiedIcon from "@mui/icons-material/Verified";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveDialog from "../components/ArchiveDialog/ArchiveDialog";
import { Toast } from "../components/core";

const cookies = new Cookies();

const useStyles = makeStyles((theme) => {
	return {
		root: {
			backgroundColor: theme.palette.text.white
		},
		heading: {
			fontSize: "2.25em",
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightSemiBold,
			color: theme.palette.text.primary,
			lineHeight: 1.2,
			[theme.breakpoints.down("xs")]: {
				fontSize: "2em"
			}
		},
		text: {
			textAlign: "center",
			fontSize: "1.35em",
			lineHeight: 1,
			[theme.breakpoints.down("xs")]: {
				fontSize: "1.05em"
			}
		},
		subtext: {
			textAlign: "center",
			marginTop: 10,
			fontSize: "1.05em",
			color: "#a9a9a9",
			fontWeight: theme.typography.fontWeightRegular,
			lineHeight: 1,
			[theme.breakpoints.down("xs")]: {
				fontSize: "0.95em"
			}
		},
		filterBtnCont: {
			margin: "20px 0 90px 0",
			[theme.breakpoints.down("sm")]: {
				justifyContent: "center",
				margin: "20px 0 40px 0"
			}
		},
		filterBtn: {
			width: "15%",
			marginRight: 10,
			borderRadius: "6px",
			color: theme.palette.primary.mainLight,
			fontFamily: theme.typography.fontFamily,
			fontWeight: theme.typography.fontWeightRegular,
			fontSize: "1rem",
			textTransform: "none",
			backgroundColor: "#E5E5E5",
			[theme.breakpoints.down("md")]: {
				width: "25%"
			},
			[theme.breakpoints.down("sm")]: {
				width: "50%"
			},
			"&:hover": { backgroundColor: "none" }
		},
		active: {
			backgroundColor: theme.palette.primary.mainLight,
			color: "#FFF",
			"&:hover": { backgroundColor: "#02A689" }
		},
		cardGrid: {
			justifyContent: "flex-start",
			alignItems: "flex-start",
			[theme.breakpoints.down("sm")]: {
				justifyContent: "center"
			}
		},
		image: {
			height: "100%",
			objectFit: "contain",
			width: "100%"
		},
		loader: {
			left: "50%",
			top: "50%"
		}
	};
});

const Dashboard = () => {
	let [searchParams, setSearchParams] = useSearchParams();
	const envelopeStatus = searchParams.get("envelopeStatus");
	const signerRole = searchParams.get("signerRole");
	const [showConfirmOfferSent, setShowConfirmOfferSent] = useState({
		message: "",
		state: false,
		description: "",
		buttonTitle: "",
		onSubmit: () => {}
	});
	const [offerOnAValidAddress, setOfferOnAValidAddress] = useState({
		message: "",
		state: false,
		description: "",
		buttonTitle: "",
		onSubmit: () => {}
	});
	const [userPropertyReviewStatus, setUserPropertyReviewStatus] = useState({
		message: "",
		state: false,
		description: "",
		buttonTitle: "",
		onSubmit: () => {}
	});
	const [dataSource, setDataSource] = useState([]);
	const [validAddress, setValidAddress] = useState(null);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});

	const [
		data,
		loading,
		setLoading,
		search,
		filter,
		setFilter,
		paginator,
		setPaginator,
		dataCount,
		setDataCount,
		setData, // new
		setSearch, // new,
		archiveDialog, //new
		setArchiveDialog //new
	] = useOutletContext();
	const [userPropertyData, setUserPropertyData] = useState([]);
	const [userFavourite, setUserFavourite] = useState([]);
	const [oldChat, setOldChat] = useState([]);
	const { user, getAccessTokenSilently } = useAuth0();
	const [isAdmin, setIsAdmin] = useState(false);
	const userState = useUserState();
	const classes = useStyles();
	const [chatForceOpen, setChatForceOpen] = useState(false);
	const [loadChats, setLoadChats] = useState(false);

	useEffect(() => {
		const getChats = async () => {
			try {
				const resp = await getUserChatById({
					userId: userState?.userProfile?.userId
				});

				setOldChat(resp.data.data.data.rows);
			} catch (e) {}
		};
		getChats();
	}, [userState?.userProfile?.userId, loadChats]);
	useEffect(() => {
		function inIframe() {
			if (window.self !== window.top) {
				window.top.location.href = `${
					process.env.REACT_APP_URL
				}app/dashboard?filter=Offers${
					envelopeStatus ? "&envelopeStatus=" : ""
				}${envelopeStatus ? envelopeStatus : ""}${
					signerRole ? "&signerRole=" : ""
				}${signerRole ? signerRole : ""}`;
			}
		}
		inIframe();
	}, [window]);

	const updateReviewedByBuyer = async (params) => {
		try {
			await updateUserProperties(params);
		} catch (error) {}
	};

	useEffect(() => {
		const getUserPropertyStatusCheck = async () => {
			const token = await getAccessTokenSilently();
			const userData = jwtDecode(token);
			if (userData.permissions[0] == "read:admin-data") {
				setIsAdmin(true);
				return;
			} else {
				try {
					const resp = await getAllUserPropertiesByUserId({
						auth0Id: user?.sub,
						token,
						limit: 100,
						offset: 0
					});
					const propertyData = resp.data.data.data.rows;

					propertyData.forEach((data) => {
						if (!data.reviewedByBuyer && data.status == "rejected") {
							setUserPropertyReviewStatus((prevState) => {
								return {
									message: "Your offer has been rejected by the seller",
									state: true,
									showContact: true,
									showAvatar: false,
									onClose: () => {
										updateReviewedByBuyer({
											userPropertyId: data.id,
											reviewedByBuyer: true
										});
									},
									textDialog: true,
									description: `Unfortunately, the offer has been rejected by the seller. on property ${data.property_form.address}.`
								};
							});
						} else if (
							!data.reviewedByBuyer &&
							data.status == "revised-by-seller"
						) {
							setShowConfirmOfferSent((prevState) => {
								return {
									message: "You have a counter-offer!",
									state: true,
									showContact: true,
									avatar: (
										<Avatar
											variant="square"
											src={UnionIcon}
											style={{
												marginTop: 100,
												marginBottom: 50,
												flex: 1,
												alignContent: "center",
												alignSelf: "center",
												height: "138.695px",
												width: "150.545px"
											}}
										></Avatar>
									),
									showAvatar: true,
									onClose: async () => {
										updateReviewedByBuyer({
											userPropertyId: data.id,
											reviewedByBuyer: true
										});
									},
									textDialog: true,
									description: `The seller has sent a counter offer on property ${data.property_form.address}. Please check your email to review the counter offer.`
								};
							});
						}
					});
				} catch (e) {}
			}
		};
		getUserPropertyStatusCheck();
	}, []);

	useEffect(() => {
		const getUserProperties = async () => {
			const token = await getAccessTokenSilently();
			cookies.set("token", token, {
				doNotParse: true,
				path: "/"
			});
			const userData = jwtDecode(token);
			if (userData.permissions[0] == "read:admin-data") {
				setIsAdmin(true);
			}
			try {
				setLoading(true);
				const resp = await getAllUserPropertiesByUserId({
					auth0Id: user?.sub,
					token,
					...paginator
				});

				const propertyData = resp.data.data.data.rows;

				if (resp.data.data.data.rows.length) {
					window.scrollTo({ top: 600, left: 0, behavior: "smooth" });
				}
				setUserPropertyData(
					propertyData.map((data) => {
						return {
							userPropertyData: true,
							...data
						};
					})
				);
				setDataCount(resp.data.data.data.count);
				setDataSource(
					propertyData.map((data) => {
						return {
							userPropertyData: true,
							...data
						};
					})
				);
				setLoading(false);
			} catch (e) {
				setLoading(false);
			}
		};
		const getUserFavourites = async () => {
			window.scrollTo(0, 600);
			setLoading(true);

			try {
				const resp = await getAllFavourites({
					auth0Id: user?.sub
				});

				const favorites = resp.data.data.data.rows;

				setUserFavourite(
					favorites.map((data) => {
						return {
							...data,
							userFav: true,
							details: JSON.parse(data.details),
							images: JSON.parse(data.images)
						};
					})
				);
				setDataCount(resp.data.data.data.count);
				setDataSource(
					favorites.map((data) => {
						return {
							...data,
							userFav: true,
							details: JSON.parse(data.details),
							images: JSON.parse(data.images)
						};
					})
				);
				setLoading(false);
			} catch (e) {
				setLoading(false);
			}
		};
		if (envelopeStatus == "Completed") {
			setShowConfirmOfferSent((prevState) => {
				return {
					message:
						"Thank you for signing the offer. It has been submitted for review You will be notified soon",
					state: true,

					avatar: (
						<Avatar
							src={congratsImage}
							style={{
								top: 10,
								marginBottom: 10,
								flex: 1,
								alignContent: "center",
								alignSelf: "center",
								height: "100px !important",
								width: "72px !important"
							}}
						></Avatar>
					),
					showAvatar: true,
					onClose: () => {
						setSearchParams({});
					},
					textDialog: true
				};
			});
		}
		if (envelopeStatus == "Completed" && signerRole == "buyer") {
			setShowConfirmOfferSent((prevState) => {
				return {
					message: "Congratulations!",
					state: true,
					avatar: (
						<Avatar
							variant="square"
							src={ThankYouIcon}
							style={{
								marginTop: 100,
								marginBottom: 50,
								flex: 1,
								alignContent: "center",
								alignSelf: "center",
								height: "138.695px",
								width: "150.545px"
							}}
						></Avatar>
					),
					showAvatar: true,
					onClose: () => {
						setSearchParams({});
					},
					textDialog: true,
					description:
						"You are one step closer to your dream house. Alokee will review your offer. " +
						"If there are multiple buyers, Alokee will send your offer after receiving all " +
						"buyer's signatures. Fully signed offers will be sent to the seller within one " +
						"business day. You will receive a confirmation email once it has been sent."
				};
			});
		}

		if (filter == "Offers" && paginator) {
			setDataSource([]);
			getUserProperties();
			let queryParams = {};
			if (envelopeStatus) {
				queryParams.envelopeStatus = envelopeStatus;
			}
			if (signerRole) {
				queryParams.signerRole = signerRole;
			}
			setSearchParams({
				...queryParams,
				filter: "Offers"
			});
			return;
		}
		if (filter == "Favorites" && paginator) {
			setDataSource([]);
			getUserFavourites();
			let queryParams = {};
			if (envelopeStatus) {
				queryParams.envelopeStatus = envelopeStatus;
			}
			if (signerRole) {
				queryParams.signerRole = signerRole;
			}
			setSearchParams({
				...queryParams,
				filter: "Favorites"
			});
			return;
		}
		if (filter == "All") {
			setDataSource(data);
			let queryParams = {};
			if (envelopeStatus) {
				queryParams.envelopeStatus = envelopeStatus;
			}
			if (signerRole) {
				queryParams.signerRole = signerRole;
			}
			setSearchParams({
				...queryParams,
				q: searchParams.get("q"),
				filter: "All"
			});
			setFilter("All");

			return;
		}
		if (searchParams.get("q") && searchParams.get("q").length) {
			let queryParams = {};
			if (envelopeStatus) {
				queryParams.envelopeStatus = envelopeStatus;
			}
			if (signerRole) {
				queryParams.signerRole = signerRole;
			}
			setSearchParams({
				...queryParams,
				q: searchParams.get("q"),
				filter: "All"
			});
			setDataSource(data);
			return;
		}
	}, [filter, data, paginator]);

	if (user && isAdmin) {
		return <Navigate to={{ pathname: "admin/properties" }} />;
	}
	if (
		user &&
		userState &&
		userState?.userProfile &&
		userState?.userProfile?.isInspector
	) {
		return <Navigate to={{ pathname: "inspector" }} />;
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>Dashboard | Alokee</title>
			</Helmet>
			<Toast {...toast} setState={setToast} />
			{archiveDialog && (
				<ArchiveDialog
					state={archiveDialog}
					stateHandler={setArchiveDialog}
					userId={userState?.userProfile?.userId}
					setFilter={setFilter}
				></ArchiveDialog>
			)}
			<Chat
				oldChat={oldChat}
				uniqueKey={userState?.userProfile?.userId}
				createChat={createUserChat}
				chatForceOpen={chatForceOpen}
				setChatForceOpen={setChatForceOpen}
				setLoadChats={setLoadChats}
			/>
			<div style={{ width: "100%" }} className={classes.root}>
				<Container maxWidth="xl" style={{ paddingBottom: "120px" }}>
					<TextDialog
						dialogDescription={showConfirmOfferSent?.description}
						message={showConfirmOfferSent?.message}
						state={showConfirmOfferSent?.state}
						buttonTitle={showConfirmOfferSent?.buttonTitle}
						textDialog={showConfirmOfferSent?.textDialog}
						stateHandler={setShowConfirmOfferSent}
						submitHandler={() => {
							showConfirmOfferSent?.onSubmit();
						}}
						onClose={showConfirmOfferSent?.onClose}
						Avatar={showConfirmOfferSent.avatar}
						showAvatar={showConfirmOfferSent?.showAvatar}
						showActions={showConfirmOfferSent?.showActions}
						hideCancel={showConfirmOfferSent?.hideCancel}
						showContact={showConfirmOfferSent?.showContact}
						setChatForceOpen={setChatForceOpen}
					></TextDialog>
					<TextDialog
						dialogDescription={userPropertyReviewStatus?.description}
						message={userPropertyReviewStatus?.message}
						state={userPropertyReviewStatus?.state}
						buttonTitle={userPropertyReviewStatus?.buttonTitle}
						textDialog={userPropertyReviewStatus?.textDialog}
						stateHandler={setUserPropertyReviewStatus}
						submitHandler={() => {
							userPropertyReviewStatus?.onSubmit();
						}}
						onClose={userPropertyReviewStatus?.onClose}
						Avatar={userPropertyReviewStatus.avatar}
						showAvatar={userPropertyReviewStatus?.showAvatar}
						showActions={userPropertyReviewStatus?.showActions}
						hideCancel={userPropertyReviewStatus?.hideCancel}
						showContact={userPropertyReviewStatus?.showContact}
						setChatForceOpen={setChatForceOpen}
					></TextDialog>
					<TextDialog
						dialogDescription={offerOnAValidAddress.description}
						message={offerOnAValidAddress.message}
						state={offerOnAValidAddress.state}
						buttonTitle={offerOnAValidAddress.buttonTitle}
						textDialog={offerOnAValidAddress.textDialog}
						stateHandler={setOfferOnAValidAddress}
						submitHandler={() => {
							offerOnAValidAddress.onSubmit();
						}}
						onClose={offerOnAValidAddress.onClose}
						Avatar={offerOnAValidAddress.avatar}
						showAvatar={offerOnAValidAddress.showAvatar}
						showActions={offerOnAValidAddress.showActions}
						hideCancel={offerOnAValidAddress.hideCancel}
						showContact={offerOnAValidAddress.showContact}
						setChatForceOpen={setChatForceOpen}
					></TextDialog>
					<Searchbar
						filter={filter}
						setData={setData}
						setLoading={setLoading}
						setSearch={setSearch}
						setFilter={setFilter}
						paginator={paginator}
						setPaginator={setPaginator}
						dataCount={dataCount}
						setDataCount={setDataCount}
						setValidAddress={setValidAddress}
						validAddress={validAddress}
						setOfferOnAValidAddressDialog={setOfferOnAValidAddress}
					/>
					<Stack flexDirection={"row"} className={classes.filterBtnCont}>
						<Button
							className={[
								classes.filterBtn,
								filter == "Favorites" ? classes.active : ""
							]}
							onClick={() => setFilter("Favorites")}
							startIcon={<FavoriteIcon />}
						>
							Favorites
						</Button>
						<Button
							className={[
								classes.filterBtn,
								filter == "Offers" ? classes.active : ""
							]}
							onClick={() => {
								setPaginator({ limit: 10, offset: 0 });
								setFilter("Offers");
							}}
							startIcon={<VerifiedIcon />}
						>
							Offers
						</Button>
					</Stack>
					{dataSource.length > 0 && (
						<div style={{ margin: "0 0 10px 0" }}>
							<Grid
								container
								spacing={2}
								flexDirection={"row"}
								className={classes.cardGrid}
							>
								{dataSource.map((item) => {
									return (
										<Grid
											item
											key={item.propertyId}
											xs={12}
											sm={10}
											md={6}
											lg={4}
											xl={4}
											sx={{
												marginBottom: "30px"
											}}
										>
											<Card
												setFilter={setFilter}
												userPropertyData={userPropertyData}
												filter={filter}
												data={item}
												setShowConfirm={setShowConfirmOfferSent}
												setToast={setToast}
												setDataSource={setDataSource}
												dataSource={dataSource}
											/>
										</Grid>
									);
								})}
							</Grid>
						</div>
					)}
					{dataSource.length > 0 && dataCount > 10 && (
						<Stack direction="row" justifyContent="center" alignItems="center">
							{
								<Paginator
									totalRecords={dataCount}
									paginator={paginator}
									paginatorHandler={setPaginator}
									justify={"center"}
								/>
							}
						</Stack>
					)}
					{loading && (
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							style={{ margin: "40px 0px" }}
							className={classes.loader}
						>
							<CardSkeleton loading={loading} />
						</Stack>
					)}
					{!dataSource.length > 0 && !loading && (
						<div style={{ margin: "0 0 200px 0" }}>
							<Box
								sx={{
									width: { xs: "200px", sm: "300px" },
									margin: "0 auto"
								}}
							>
								<img
									className={classes.image}
									src={buildings}
									alt="nothing-found-image"
								/>
							</Box>
							{(filter == "Favorites" || filter == "Offers") && (
								<Typography className={[classes.heading, classes.text]}>
									{`Nothing found in your ${filter}`}
								</Typography>
							)}
							{filter == "All" && (
								<Typography className={[classes.heading, classes.text]}>
									{`Currently, We do not operate in this Area.`}
								</Typography>
							)}
							<Typography className={[classes.heading, classes.subtext]}>
								Start building your feed by saving searches and favorite homes.
							</Typography>
						</div>
					)}
				</Container>
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Dashboard;
