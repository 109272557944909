import React from "react";
import { Container, makeStyles, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
	return {
		faqsTitleHeading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		},

		newsLetter: {
			background:
				"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			height: "290px",
			marginTop: 30,
			paddingTop: 30
		},

		blogPara: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem !important"
			},
			marginInlineStart: 20
		},
		blogParaHeader: {
			marginBottom: "5px",
			listStylePosition: "inside",
			textIndent: "-1em",
			paddingLeft: "1em"
		},
		divider: {
			borderTop: "3px solid #00284c"
		}
	};
});

function BlogThree() {
	const classes = useStyles();

	return (
		<div style={{ width: "100%" }}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.faqsTitleHeading}>
							Should you use a real estate agent to buy a house?
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<p className={classes.blogPara}>
							You may not have known that you don’t need a real estate agent to
							buy a house. In fact, about a million people buy a house without a
							real estate agent every year. Still, many more use a real estate
							agent every year. The question “should I use a real estate agent
							or not?” is subject to your specific needs and preferences. Here
							are some things to consider when making this decision.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							The first thing that will impact your decision to use a real
							estate agent or not is how much help you need learning about the
							neighborhoods and types of homes you want to buy, and how many
							showings you think you will need. Though you may not know it
							before you start shopping, there are some generalizations that can
							be made, depending on what stage of your life you are in. For
							example, first-time home buyers may need anywhere from five to
							fifty showings. Second-time and third-time buyers may only need
							one to five showings. If you think you will need a lot of
							showings, you may find it worthwhile to incur the higher expense
							of hiring a real estate agent. But if you like to research homes
							on your own and can evaluate homes with fewer showings, there are
							alternate options you may want to consider that could save you
							time and money{" "}
							{
								<a href="//23233402.hs-sites.com/using-the-digital-real-estate-agent-alokee/how-to-buy-a-house-without-a-realtor">
									http://23233402.hs-sites.com/using-the-digital-real-estate-agent-alokee/how-to-buy-a-house-without-a-realtor.
								</a>
							}
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							The second thing that will impact your decision is how familiar
							you are with the region where you are considering buying a home.
							For example, if you grew up in the southwest and you are moving to
							the northeast (say, from Arizona to Maine) you may be moving into
							a geographic region that has real estate nuances that could be
							unfamiliar to you. In this case, you may want to incur the cost of
							using a traditional real estate agent to ensure that you have
							support learning about specifics that may be harder to uncover
							through online research. If, on the other hand, you grew up in the
							Southeast and are moving within the Southeast (say, from Georgia
							to Florida) you may have enough confidence in your knowledge to
							use one of these{" "}
							{
								<a href="//23233402.hs-sites.com/using-the-digital-real-estate-agent-alokee/how-to-buy-a-house-without-a-realtor">
									http://23233402.hs-sites.com/using-the-digital-real-estate-agent-alokee/how-to-buy-a-house-without-a-realtor
								</a>
							}{" "}
							options that could save you money and time when buying your home.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Finally, a major part of your decision on whether or not to use a
							real estate agent will be based on your comfort with digital
							business. Do you like shopping at an old-school car dealership, or
							having a financial planner (an actual person) buy stocks for you?
							If so, you might be more comfortable with a traditional real
							estate agent with a business model built around offering a high
							level of personal customer attention. But, if you prefer buying
							cars, groceries, and stocks online - if you enjoy digital
							transactions and platforms with live support when you need it -
							you may find more joy using one of these options{" "}
							{
								<a href="//23233402.hs-sites.com/using-the-digital-real-estate-agent-alokee/how-to-buy-a-house-without-a-realtor">
									http://23233402.hs-sites.com/using-the-digital-real-estate-agent-alokee/how-to-buy-a-house-without-a-realtor
								</a>
							}{" "}
							to buy a house.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Deciding whether or not to use a traditional real estate agent
							when buying a house is an important decision. There is no
							“one-size-fits-all” answer. We suggest you do research and talk to
							trusted associates so you can make the decision that’s right for
							you. If you’d like to discuss your options with us, we are happy
							to chat with you{" "}
							{
								<a href="http://www.alokee.com/?__hstc=233546881.c03f59367b1010d55e327260f76fb6fe.1707443189199.1707443189199.1707443189199.1&amp;__hssc=233546881.1.1707443189199&amp;__hsfp=3429938066">
									here
								</a>
							}
							. Best of luck!
						</p>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default BlogThree;
