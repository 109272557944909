import { Button, Grid } from "@mui/material";
import React from "react";
import makeStyles from "./styles";
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

function Toolbar({
	setInboxVisible,
	setInspectorsVisible,
	showTabs,
	setViewInspectorForm
}) {
	const classes = makeStyles();

	return (
		<Grid
			container
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			className={classes.container}
            spacing={2}
		>
			<Grid item>
				{showTabs && (
					<Button
						style={{ color: "#003a7b" }}
						color="primary"
						variant="outlined"
						onClick={() => {
							setInboxVisible(true);
						}}
					>
						Chats
					</Button>
				)}
				{!showTabs && (
					<IconButton
						onClick={() => {
							setInspectorsVisible(false);
						}}
						color="primary"
					>
						<ArrowBack></ArrowBack>
					</IconButton>
				)}
			</Grid>
			{showTabs && (
				<Grid item>
					<Button
						style={{ color: "#003a7b" }}
						color="primary"
						variant="outlined"
						onClick={() => {
							setInspectorsVisible(true);
						}}
					>
						Inspectors
					</Button>
				</Grid>
			)}
			{!showTabs && (
				<Grid item>
					<Button
						style={{ color: "#003a7b" }}
						color="primary"
						variant="outlined"
						onClick={() => {
							setViewInspectorForm((prevState) => {
								return { ...prevState, state: true };
							});
						}}
					>
						Add Inspectors
					</Button>
				</Grid>
			)}
		</Grid>
	);
}

export default Toolbar;
