import React from "react";
import "./style.css";
import { Grid, TextField } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useState } from "react";

function NonExclusiveAgreement({ setButtonEnabled, userFullName, id }) {
	const [name, setName] = useState("");

	return (
		<div className="main" id={id}>
			<div id="sec1">
				<p
					className="s1"
					style={{ paddingTop: "3pt", textIndent: "0pt", textAlign: "center" }}
				>
					NONEXCLUSIVE REPRESENTATION AGREEMENT
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					className="s2"
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "justify"
					}}
				>
					This NONEXCLUSIVE REPRESENTATION AGREEMENT (“<b>Agreement</b>”) is
					entered into between Alokee Inc., a California corporation, DRE
					#02201041 (“
					<b>Broker</b>”), and you (“<b>Client</b>” or “<b>Buyer</b>
					”). Broker and Client may be referred to individually as “<b>Party</b>
					” and collectively as “<b>Parties</b>.”
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<ol id="l1">
					<li data-list-text={1}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Representation. Client hereby grants Broker the nonexclusive right
							to represent Client in acquiring real property.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={2}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Term. This Agreement is effective on the date that Client
							electronically signs it, and will end upon Client’s written notice
							of termination to Broker (which can be sent by email to
							<span className="s3"> legal@alokee.com</span>). Client or Broker
							may terminate this Agreement at any time without penalty by
							written or email notice to the other Party.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={3}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Commission. Client understanding that Seller and Seller Broker
							will compensate Broker. Broker will be paid $8,900.00 and any
							remaining commission amounts will paid by Broker to Buyer after
							closing.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={4}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Conduct. Broker will endeavor to act in the best interests of
							Client and fulfill all legal obligations of Broker to Client.
							Broker to use diligence in the performance of their duties.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
						<ol id="l2">
							<li data-list-text="4.1.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									Agent does not does not decide what price buyers should pay or
									what price sellers will accept;
								</p>
							</li>
							<li data-list-text="4.2.">
								<p
									className="s2"
									style={{
										paddingLeft: "78pt",
										textIndent: "-36pt",
										textAlign: "left"
									}}
								>
									Does not guaranty the condition of the property;
								</p>
							</li>
							<li data-list-text="4.3.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									Does not guaranty the completeness of inspections, services,
									products or repairs provided or made by seller or others;
								</p>
							</li>
							<li data-list-text="4.4.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									does not have an obligation to conduct an inspection of common
									areas or areas off the site of the property;
								</p>
							</li>
							<li data-list-text="4.5.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									shall not be responsible for identifying defects on the
									property, in common areas or offsite unless such defects are
									visually observable by an inspection of reasonable accessible
									areas of the property or are known to Agent;
								</p>
							</li>
							<li data-list-text="4.6.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									shall not be responsible for inspecting public records or
									permits concerning the title or use of Property;
								</p>
							</li>
							<li data-list-text="4.7.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									shall not be responsible for identifying the location of
									boundary lines, easements, or other encroachments on title;
								</p>
							</li>
							<li data-list-text="4.8.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									shall not be responsible for verifying square footage,
									representations of others or information contained in
									Investigation reports, Multiple Listing Service,
									advertisements, flyers or other promotional material;
								</p>
							</li>
							<li data-list-text="4.9.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									shall not be responsible for providing legal or tax advice
									regarding any aspect of a transaction entered into by buyers
									or seller; and
								</p>
							</li>
							<li data-list-text="4.10.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									shall not be responsible for providing other advice or
									information that exceeds the knowledge, education and
									experience required to perform real estate licensed
									activities. Buyers are advised to, and agree to, see legal,
									tax, insurance, title and other desired assistance from
									appropriate professionals.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
						</ol>
					</li>
					<li data-list-text={5}>
						<p
							className="s2"
							style={{
								paddingLeft: "42pt",
								textIndent: "-36pt",
								textAlign: "left"
							}}
						>
							Client Duties.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
						<ol id="l3">
							<li data-list-text="5.1.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									Client is obligated to read all documents, and understands
									real estate acquisition documents are legally binding
									contracts.
								</p>
							</li>
							<li data-list-text="5.2.">
								<p
									className="s2"
									style={{
										paddingTop: "3pt",
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									Client will cooperate and communicate with Broker when
									requested by Broker. Client will notify Broker of any
									important issues.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
							<li data-list-text="5.3.">
								<p
									className="s2"
									style={{
										paddingLeft: "78pt",
										textIndent: "-36pt",
										lineHeight: "13pt",
										textAlign: "left"
									}}
								>
									REASONABLE CARE AND OTHER PROFESSIONAL ASSISTANCE: Buyers
								</p>
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									have an affirmative duty to take steps to protect themselves,
									including discovery of the legal, practical and technical
									implications of discovered or disclosed facts, and
									investigation of information and facts which are known to them
									or are within their diligent attention and observation. Buyers
									are obligated to read all documents provided to them. Buyers
									should seek desired assistance from appropriate professionals
									as Buyer sees fit.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
						</ol>
					</li>
					<li data-list-text={6}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Incorrect, Inaccurate, or Incomplete Information. Client agrees to
							indemnify, defend and hold Broker harmless from all claims,
							disputes, litigation, judgments, attorney fees and costs arising
							from any incorrect information supplied by Buyer, or from any
							Material Issues that Client fails to disclose in writing to
							Broker.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={7}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Third Party Compensation or Incentives. Any outside compensation
							paid or benefit to Broker from this Agreement or the Services
							shall be disclosed to Client.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={8}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Notice. Any notice, report, or statement required or permitted
							under this Agreement will be deemed effective and given (i) upon
							receipt, if personally delivered, (ii) by overnight courier,
						</p>
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							<a href="mailto:legal@alokee.com" className="s4" target="_blank">
								(iii) or by email to Client by their account email or to Broker
								at
							</a>
							<a href="mailto:legal@alokee.com" className="a" target="_blank">
								{" "}
								legal@alokee.com
							</a>
							<a href="mailto:legal@alokee.com" className="s4" target="_blank">
								.
							</a>
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={9}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							No Waiver. No waiver of a breach, failure of any condition, or any
							right or remedy contained in or granted by this Agreement will be
							effective unless it is in writing and signed by the party waiving
							the breach, failure, right, or remedy. No waiver of any breach,
							failure, right, or remedy will be deemed a waiver of any other
							breach, failure, right, or remedy, whether or not similar, nor
							will any waiver constitute a continuing waiver unless the writing
							so specifies.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={10}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Governing Law. The laws of California govern all matters relating
							to this Agreement, including torts.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={11}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Severability. In event of partial invalidity or if any provision
							of this Agreement is held in whole or in part to be unenforceable
							for any reason, the remainder of that provision and of the entire
							Agreement will be severable and remain in effect, unless an
							essential purpose of this Agreement would be defeated by the loss
							of the illegal, unenforceable, or invalid provision.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={12}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Rights and Obligations. The rights and obligations of the parties
							to this Agreement shall be defined exclusively by the terms of
							this Agreement.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={13}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							Integration. This Agreement sets forth the entire agreement
							between the Parties and supersedes all other prior or
							contemporaneous oral or written agreements and understanding.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
				</ol>
			</div>
			<div id="sec2">
				<ul>
					<li data-list-text={14}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							14. Amendments. This Agreement may be modified or amended by
							subsequent agreement or terms and conditions sent by Broker to
							Client.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={15}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							15. Electronic Signatures. This Agreement may be executed by
							Client appending or typing their signature. Electronic signatures
							shall have the same force and effect as original ink signatures.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={16}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							16. Construction. The language in all parts of this Agreement
							shall in all cases be construed as a whole according to its fair
							meaning and not strictly for or against either Party, it being the
						</p>
						<p
							className="s2"
							style={{
								paddingTop: "3pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							intent of the Parties that this Agreement shall be interpreted as
							if it was prepared by both Parties, and any ambiguities shall not
							be resolved in favor of one Party because all or a portion of this
							Agreement was prepared by another Party.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={17}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							17. Review. Each of the Parties hereto acknowledges that (a) such
							Party has read this Agreement in its entirety and understands all
							of its terms and conditions, (b) such Party has had the
							opportunity to consult with any individuals of its choice
							regarding its agreement to the provisions contained herein,
							including legal counsel of its choice, and any decision not to was
							such Party's alone and (c) such party is entering into this
							Agreement of its own free will, without coercion from any source,
							based upon its own independent judgment.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={18}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							18. Arbitration. Any controversy, dispute or claim arising out of
							or relating to the terms and performance of this Agreement, or its
							breach, or between the Parties, shall be settled by arbitration
							administered by JAMS Arbitration under its Commercial Arbitration
							Rules. The place of Arbitration will be Berkeley, California, and
							the number of arbiters shall be one. It is intended that
							controversies or claims submitted to arbitration shall remain
							confidential, and the parties agree that neither the facts
							disclosed in the arbitration, the issues arbitrated, nor the views
							or opinions of any persons concerning them, shall be disclosed to
							third persons at any time, except to the extent necessary to
							enforce an award or judgment or as required by law or in response
							to legal process or in connection with such arbitration.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={19}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "justify"
							}}
						>
							19. Attorney Fees: In any action, proceeding or arbitration
							between Buyer and Broker arising out of the Agreement, Buyer and
							Broker are each responsible for paying their own attorney fees and
							costs.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={20}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							20. Headings and Captions. The paragraph headings and captions
							contained in this Agreement are for convenience only and shall not
							be construed to define, limit or affect the scope or meaning of
							the provisions hereof.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={21}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							21. Complete Agreement. This Agreement, and all documents
							referenced herein are the complete and exclusive statement of the
							agreement between the parties regarding the terms of the Agreement
							and supersedes all, oral or written and all other communications
							between the parties relating to the performance of services, and
							no statements, promises or inducements made by either party, or an
							agent of either party that is not contained in this Agreement
							shall be valid or binding. This Agreement may not be enlarged,
							modified or altered, except as specifically hereinabove or
							otherwise by written agreement of the parties.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={22}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							22. Relation to Other Documents. In any conflict between this
							Agreement and the terms of any document, work order or invoice
							(either prior to or subsequent to the Effective Date), this
							Agreement shall control.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
				</ul>
			</div>
			<div id="sec2.5">
				<ul>
					<li data-list-text={23}>
						<p
							className="s2"
							style={{
								paddingLeft: "42pt",
								textIndent: "-36pt",
								textAlign: "justify"
							}}
						>
							23. Agency Disclosure.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
						<ol>
							<li data-list-text="23.1.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									23.1. FACTS: When you enter into a discussion with a real
									estate agent regarding a real estate transaction, you should
									from the outset understand what type of agency relationship or
									representation you wish to have with the agent in the
									transaction.
								</p>
							</li>
							<li data-list-text="23.2.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "justify"
									}}
								>
									23.2. SELLER'S AGENT: A Seller’s Agent under a listing
									agreement with the Seller acts as the Agent for the Seller
									only. A Seller’s Agent or a subagent of that Agent has the
									following affirmative obligations:
								</p>
								<ol>
									<li data-list-text="23.2.1.">
										<p
											className="s2"
											style={{
												paddingTop: "3pt",
												paddingLeft: "78pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											23.2.1 To the Seller a fiduciary duty of utmost care,
											integrity, honesty and loyalty in dealings with the
											Seller.
										</p>
									</li>
									<li data-list-text="23.2.2.">
										<p
											className="s2"
											style={{
												paddingLeft: "78pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											23.2.2. To the Buyer and the Seller: (a) Diligent exercise
											of reasonable skill and care in performance of the Agent’s
											duties. (b) A duty of honest and fair dealing and good
											faith. (c) A duty to disclose all facts known to the Agent
											materially affecting the value or desirability of the
											property that are not known to, or within the diligent
											attention and observation of the parties.
										</p>
									</li>
									<li data-list-text="23.2.3.">
										<p
											className="s2"
											style={{
												paddingLeft: "78pt",
												textIndent: "0pt",
												lineHeight: "108%",
												textAlign: "left"
											}}
										>
											23.2.3. An Agent is not obligated to reveal to either
											party any confidential information obtained from the other
											party which does not involve the affirmative duties set
											forth above.
										</p>
									</li>
								</ol>
							</li>
							<li data-list-text="23.3.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									23.3. BUYER’S AGENT: A Buyer's Agent can, with a Buyer’s
									consent, agree to act as the Agent for the Buyer only. In
									these situations, the Agent is not the Seller’s Agent, even if
									by agreement the Agent may receive compensation for services
									rendered, either in full or in part, from the Seller. An Agent
									acting only for a Buyer has the following affirmative
									obligations:
								</p>
								<ol>
									<li data-list-text="23.3.1.">
										<p
											className="s2"
											style={{
												paddingLeft: "78pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											23.3.1. To the Buyer. A fiduciary duty of utmost care,
											integrity, honesty, and loyalty in dealings with the
											Buyer.
										</p>
									</li>
									<li data-list-text="23.3.2.">
										<p
											className="s2"
											style={{
												paddingLeft: "78pt",
												textIndent: "0pt",
												lineHeight: "108%",
												textAlign: "left"
											}}
										>
											23.3.2. To the Buyer and the Seller: (a) Diligent exercise
											of reasonable skill and care in performance of the Agent’s
											duties. (b) A duty of honest and fair dealing and good
											faith. (c) A duty to disclose all facts known to the Agent
											materially affecting the value or desirability of the
											property that are not known to, or within the diligent
											attention and observation, of the parties. An Agent is not
											obligated to reveal to either party any confidential
											information obtained from the other party that does not
											involve the affirmative duties set forth above.
										</p>
									</li>
								</ol>
							</li>
							<li data-list-text="23.4.">
								<p
									className="s2"
									style={{
										paddingLeft: "78pt",
										textIndent: "-36pt",
										lineHeight: "13pt",
										textAlign: "left"
									}}
								>
									23.4. AGENT REPRESENTING BOTH THE SELLER AND THE BUYER: A Real
								</p>
								<p
									className="s2"
									style={{
										paddingTop: "1pt",
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									Estate Agent, either acting directly or through one or more
									salespersons and broker associates, can legally be the Agent
									of both the Seller and the Buyer in a transaction, but only
									with the knowledge and consent of both the Seller and the
									Buyer.
								</p>
								<ol>
									<li data-list-text="23.4.1.">
										<p
											className="s2"
											style={{
												paddingLeft: "78pt",
												textIndent: "0pt",
												lineHeight: "108%",
												textAlign: "left"
											}}
										>
											23.4.1. In a dual agency situation, the Agent has the
											following affirmative obligations to both the Seller and
											the Buyer: (a) A fiduciary duty of utmost care, integrity,
											honesty and loyalty in the dealings with either the Seller
											or the Buyer.
										</p>
										<p
											className="s2"
											style={{
												paddingLeft: "78pt",
												textIndent: "0pt",
												lineHeight: "108%",
												textAlign: "left"
											}}
										>
											(b) Other duties to the Seller and the Buyer as stated
											above in their respective sections.
										</p>
									</li>
									<li data-list-text="23.4.2.">
										<p
											className="s2"
											style={{
												paddingLeft: "78pt",
												textIndent: "0pt",
												lineHeight: "108%",
												textAlign: "left"
											}}
										>
											23.4.2. In representing both Seller and Buyer, a dual
											agent may not, without the express permission of the
											respective party, disclose to the other party confidential
											information, including, but not limited to, facts relating
											to either the Buyer's or Seller's financial position,
											motivations, bargaining position or other personal
											information that may impact price, including the Seller's
											willingness to accept a price less than the listing price
											or the Buyer's willingness to pay a price greater than the
											price offered.
										</p>
									</li>
								</ol>
							</li>
							<li data-list-text="23.5.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									23.5. SELLER AND BUYER RESPONSIBILITIES: Either the purchase
									agreement or a separate document will contain a confirmation
									of which agent is representing you and whether that agent is
									representing you exclusively in the transaction or acting as a
									dual agent. Please pay attention to that confirmation to make
									sure it accurately reflects your understanding of your agent's
									role.
								</p>
							</li>
							<li data-list-text="23.6.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									23.6. The above duties of the Agent in a real estate
									transaction do not relieve a Seller or a Buyer from the
									responsibility to protect their own interests. You should
									carefully read all agreements to assure that they adequately
									express your understanding of the
								</p>
								<p
									className="s2"
									style={{
										paddingTop: "3pt",
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									transaction. A Real Estate Agent is a person qualified to
									advise about real estate. If legal or tax advice is desired,
									consult a competent professional.
								</p>
							</li>
							<li data-list-text="23.7.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									23.7. If you are a Buyer, you have the duty to exercise
									reasonable care to protect yourself, including as to those
									facts about the property which are known to you or within your
									diligent attention and observation.
								</p>
							</li>
							<li data-list-text="23.8.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									23.8. Both Sellers and Buyers should strongly consider
									obtaining tax advice from a competent professional because the
									federal and state tax consequences of a transaction can be
									complex and subject to change.
								</p>
							</li>
							<li data-list-text="23.9.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									23.9. Throughout your real property transaction, you may
									receive more than one disclosure form, depending upon the
									number of Agents assisting in the transaction. The law
									requires each Agent with whom you have more than a casual
									relationship to present you with this disclosure form. You
									should read its contents each time it is presented to you,
									considering the relationship between you and the Real Estate
									Agent in your specific transaction.
								</p>
							</li>
							<li data-list-text="23.10.">
								<p
									className="s2"
									style={{
										paddingLeft: "42pt",
										textIndent: "0pt",
										lineHeight: "108%",
										textAlign: "left"
									}}
								>
									23.10. This Agreement includes the provisions of §2079.13 to
									§2079.24, inclusive, of the Calif. Civil Code. Read them
									carefully.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
						</ol>
					</li>
					<li data-list-text={24}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							24. California Privacy Rights. You can obtain more information
							about the CCPA and your rights under the law from the State of
							California Department of Justice (oag.ca.gov/privacy/ccpa).
							Additionally, the California Privacy Protection Agency is
							authorized to promulgate regulations which may further clarify
							requirements of the CCPA (cppa.ca.gov/regulations/).
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={25}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							25. Discrimination and Fair Housing. There are many laws
							prohibiting discrimination and providing for fair housing. Broker
							will endeavor to respect all laws.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={26}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							26. Property Inspection: It is recommended you hire professional
							inspector to review the real property and improvements. YOU ARE
							STRONGLY ADVISED TO INVESTIGATE THE CONDITION AND SUITABILITY OF
							ALL ASPECTS OF THE PROPERTY, INCLUDING BUT NOT LIMITED TO THE
							FOLLOWING. IF YOU DO NOT DO SO, YOU ARE ACTING AGAINST
						</p>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							THE ADVICE OF BROKERS. Those include but are not limited to
							property condition, environmental condition, structural matters,
							soil matters, utilities, square footage, security, safety,
							neighborhood, pests, hazards, earthquake, flooding, fire issues,
							building or governmental issues, permitting, feasibility, use.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={27}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							27. Broker Obligations: Brokers do not have expertise in all areas
							and therefore cannot advise you on many items. If Broker gives you
							referrals to professionals, Broker does not guarantee their
							performance.
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
					</li>
					<li data-list-text={28}>
						<p
							className="s2"
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								lineHeight: "108%",
								textAlign: "left"
							}}
						>
							28. Wire Fraud: Broker hereby informs Client that wire fraud has
							become a frequent crime. Please call the escrow office directly to
							confirm any wiring instructions.
						</p>
					</li>
				</ul>
			</div>
			<div id="sec3">
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					<a name="bookmark0">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.13.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					As used in this section and Sections 2079.7 and 2079.14 to 2079.24,
					inclusive, the following terms have the following meanings:
				</p>
				<ol id="l8">
					<li data-list-text="(a)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Agent” means a person acting under provisions of Title 9
							(commencing with Section 2295) in a real property transaction, and
							includes a person who is licensed as a real estate broker
						</p>
						<p
							style={{
								paddingTop: "3pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							under Chapter 3 (commencing with Section 10130) of Part 1 of
							Division 4 of the Business and Professions Code, and under whose
							license a listing is executed or an offer to purchase is obtained.
						</p>
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							The agent in the real property transaction bears responsibility
							for that agent’s salespersons or broker associates who perform as
							agents of the agent. When a salesperson or broker associate owes a
							duty to any principal, or to any buyer or seller who is not a
							principal, in a real property transaction, that duty is equivalent
							to the duty owed to that party by the broker for whom the
							salesperson or broker associate functions.
						</p>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Buyer” means a transferee in a real property transaction, and
							includes a person who executes an offer to purchase real property
							from a seller through an agent, or who seeks the services of an
							agent in more than a casual, transitory, or preliminary manner,
							with the object of entering into a real property transaction.
							“Buyer” includes a vendee or lessee of real property.
						</p>
					</li>
					<li data-list-text="(c)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Commercial real property” means all real property in the state,
							except (1) single-family residential real property, (2) dwelling
							units made subject to Chapter 2 (commencing with Section 1940) of
							Title 5, (3) a mobilehome, as defined in Section 798.3, (4) vacant
							land, or (5) a recreational vehicle, as defined in Section 799.29.
						</p>
					</li>
					<li data-list-text="(d)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Dual agent” means an agent acting, either directly or through a
							salesperson or broker associate, as agent for both the seller and
							the buyer in a real property transaction.
						</p>
					</li>
					<li data-list-text="(e)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Listing agreement” means a written contract between a seller of
							real property and an agent, by which the agent has been authorized
							to sell the real property or to find or obtain a buyer, including
							rendering other services for which a real estate license is
							required to the seller pursuant to the terms of the agreement.
						</p>
					</li>
					<li data-list-text="(f)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Seller’s agent” means a person who has obtained a listing of real
							property to act as an agent for compensation.
						</p>
					</li>
					<li data-list-text="(g)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Listing price” is the amount expressed in dollars specified in
							the listing for which the seller is willing to sell the real
							property through the seller’s agent.
						</p>
					</li>
					<li data-list-text="(h)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Offering price” is the amount expressed in dollars specified in
							an offer to purchase for which the buyer is willing to buy the
							real property.
						</p>
					</li>
					<li data-list-text="(i)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Offer to purchase” means a written contract executed by a buyer
							acting through a buyer’s agent that becomes the contract for the
							sale of the real property upon acceptance by the seller.
						</p>
					</li>
					<li data-list-text="(j)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Real property” means any estate specified by subdivision (1) or
							(2) of Section 761 in property, and includes (1) single-family
							residential property, (2) multiunit residential property with more
							than four dwelling units, (3) commercial real property, (4) vacant
							land, (5) a ground lease coupled with improvements, or (6) a
							manufactured home as defined in Section 18007 of the Health and
							Safety Code, or a mobilehome as defined in Section 18008 of the
							Health and Safety Code, when offered for sale or sold through an
							agent pursuant to the authority contained in Section 10131.6 of
							the Business and Professions Code.
						</p>
					</li>
					<li data-list-text="(k)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "justify"
							}}
						>
							“Real property transaction” means a transaction for the sale of
							real property in which an agent is retained by a buyer, seller, or
							both a buyer and seller to act in that transaction, and includes a
							listing or an offer to purchase.
						</p>
					</li>
					<li data-list-text="(l)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "justify"
							}}
						>
							“Single-family residential property” or “single-family residential
							real property” means any of the following:
						</p>
						<ol id="l9">
							<li data-list-text="(1)">
								<p
									style={{
										paddingLeft: "18pt",
										textIndent: "0pt",
										textAlign: "justify"
									}}
								>
									Real property improved with one to four dwelling units,
									including a leasehold exceeding one year’s duration.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
							<li data-list-text="(2)">
								<p
									style={{
										paddingLeft: "34pt",
										textIndent: "-16pt",
										textAlign: "justify"
									}}
								>
									A unit in a residential stock cooperative, condominium, or
									planned unit development.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
							<li data-list-text="(3)">
								<p
									style={{
										paddingLeft: "18pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									A mobilehome or manufactured home when offered for sale or
									sold through a real estate broker pursuant to Section 10131.6
									of the Business and Professions Code.
								</p>
							</li>
						</ol>
					</li>
					<li data-list-text="(m)">
						<p
							style={{
								paddingTop: "3pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Sell,” “sale,” or “sold” refers to a transaction for the transfer
							of real property from the seller to the buyer and includes
							exchanges of real property between the seller and buyer,
							transactions for the creation of a real property sales contract
							within the meaning of Section 2985, and transactions for the
							creation of a leasehold exceeding one year’s duration.
						</p>
					</li>
					<li data-list-text="(n)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Seller” means the transferor in a real property transaction and
							includes an owner who lists real property with an agent, whether
							or not a transfer results, or who receives an offer to purchase
							real property of which they are the owner from an agent on behalf
							of another. “Seller” includes both a vendor and a lessor of real
							property.
						</p>
					</li>
					<li data-list-text="(o)">
						<p
							style={{
								paddingLeft: "22pt",
								textIndent: "-16pt",
								textAlign: "left"
							}}
						>
							“Buyer’s agent” means an agent who represents a buyer in a real
							property transaction.
						</p>
					</li>
				</ol>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Amended by Stats. 2019, Ch. 310, Sec. 9. (AB 892) Effective January
					1, 2020.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark1">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.14.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<ol id="l10">
					<li data-list-text="(a)">
						<p
							style={{
								paddingTop: "4pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A copy of the disclosure form specified in Section 2079.16 shall
							be provided in a real property transaction as follows:
						</p>
						<ol id="l11">
							<li data-list-text="(1)">
								<p
									style={{
										paddingLeft: "18pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									The seller’s agent, if any, shall provide the disclosure form
									to the seller before entering into the listing agreement.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
							<li data-list-text="(2)">
								<p
									style={{
										paddingLeft: "18pt",
										textIndent: "0pt",
										textAlign: "left"
									}}
								>
									The buyer’s agent shall provide the disclosure form to the
									buyer as soon as practicable before execution of the buyer’s
									offer to purchase. If the offer to purchase is not prepared by
									the buyer’s agent, the buyer’s agent shall present the
									disclosure form to the buyer not later than the next business
									day after receiving the offer to purchase from the buyer.
								</p>
								<p style={{ textIndent: "0pt", textAlign: "left" }}>
									<br />
								</p>
							</li>
						</ol>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							The agent providing the disclosure form specified in Section
							2079.16 shall obtain a signed acknowledgment of receipt from the
							buyer or seller except as provided in Section 2079.15.
							<i>
								(Amended by Stats. 2019, Ch. 310, Sec. 10. (AB 892) Effective
								January 1, 2020.)
							</i>
						</p>
					</li>
				</ol>
			</div>
			<div id="sec4">
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark2">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.15.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					In any circumstance in which the seller or buyer refuses to sign an
					acknowledgment of receipt pursuant to Section 2079.14, the agent shall
					set forth, sign, and date a written declaration of the facts of the
					refusal.
				</p>
				<p
					className="s7"
					style={{
						paddingLeft: "6pt",
						textIndent: "0pt",
						lineHeight: "13pt",
						textAlign: "left"
					}}
				>
					(Amended by Stats. 2018, Ch. 907, Sec. 40. (AB 1289) Effective January
					1, 2019.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark3">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.16.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					The disclosure form required by Section 2079.14 shall have Sections
					2079.13 to 2079.24, inclusive, excluding this section, printed on the
					back, and on the front of the disclosure form the following shall
					appear:
				</p>
				<p
					className="s8"
					style={{
						paddingTop: "8pt",
						textIndent: "0pt",
						textAlign: "center"
					}}
				>
					<a name="bookmark4">DISCLOSURE REGARDING</a>
				</p>
				<p
					className="s8"
					style={{
						textIndent: "0pt",
						lineHeight: "13pt",
						textAlign: "center"
					}}
				>
					REAL ESTATE AGENCY RELATIONSHIP
				</p>
				<p
					className="s8"
					style={{
						textIndent: "0pt",
						lineHeight: "13pt",
						textAlign: "center"
					}}
				>
					(As required by the Civil Code)
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					When you enter into a discussion with a real estate agent regarding a
					real estate transaction, you should from the outset understand what
					type of agency relationship or representation you wish to have with
					the agent in the transaction.
				</p>
				<p
					className="s8"
					style={{
						paddingTop: "3pt",

						textIndent: "0pt",
						textAlign: "center"
					}}
				>
					<a name="bookmark5">SELLER’S AGENT</a>
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					A Seller’s agent under a listing agreement with the Seller acts as the
					agent for the Seller only. A Seller’s agent or a subagent of that
					agent has the following affirmative obligations:
				</p>
				<p
					style={{
						paddingLeft: "6pt",
						textIndent: "0pt",
						lineHeight: "13pt",
						textAlign: "left"
					}}
				>
					To the Seller:
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					A fiduciary duty of utmost care, integrity, honesty, and loyalty in
					dealings with the Seller. To the Buyer and the Seller:
				</p>
				<ol id="l12">
					<li data-list-text="(a)">
						<p
							style={{
								paddingLeft: "22pt",
								textIndent: "-16pt",
								textAlign: "left"
							}}
						>
							Diligent exercise of reasonable skill and care in performance of
							the agent’s duties.
						</p>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingLeft: "22pt",
								textIndent: "-16pt",
								lineHeight: "13pt",
								textAlign: "left"
							}}
						>
							A duty of honest and fair dealing and good faith.
						</p>
					</li>
					<li data-list-text="(c)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A duty to disclose all facts known to the agent materially
							affecting the value or desirability of the property that are not
							known to, or within the diligent attention and observation of, the
							parties. An agent is not obligated to reveal to either party any
							confidential information obtained from the other party that does
							not involve the affirmative duties set forth above.
						</p>
					</li>
				</ol>
				<p
					className="s8"
					style={{
						paddingTop: "8pt",

						textIndent: "0pt",
						textAlign: "center"
					}}
				>
					<a name="bookmark6">BUYER’S AGENT</a>
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					A Buyer’s agent can, with a Buyer’s consent, agree to act as agent for
					the Buyer only. In these situations, the agent is not the Seller’s
					agent, even if by agreement the agent may receive compensation for
					services rendered, either in full or in part from the Seller. An agent
					acting only for a Buyer has the following affirmative obligations:
				</p>
				<p
					style={{
						paddingLeft: "6pt",
						textIndent: "0pt",
						lineHeight: "13pt",
						textAlign: "left"
					}}
				>
					To the Buyer:
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					A fiduciary duty of utmost care, integrity, honesty, and loyalty in
					dealings with the Buyer. To the Buyer and the Seller:
				</p>
				<ol id="l13">
					<li data-list-text="(a)">
						<p
							style={{
								paddingLeft: "22pt",
								textIndent: "-16pt",
								textAlign: "left"
							}}
						>
							Diligent exercise of reasonable skill and care in performance of
							the agent’s duties.
						</p>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingLeft: "22pt",
								textIndent: "-16pt",
								lineHeight: "13pt",
								textAlign: "left"
							}}
						>
							A duty of honest and fair dealing and good faith.
						</p>
					</li>
					<li data-list-text="(c)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A duty to disclose all facts known to the agent materially
							affecting the value or desirability of the property that are not
							known to, or within the diligent attention and observation of, the
							parties. An agent is not obligated to reveal to either party any
							confidential information obtained from the other party that does
							not involve the affirmative duties set forth above.
						</p>
					</li>
				</ol>
				<p
					className="s8"
					style={{
						paddingTop: "8pt",

						textIndent: "0pt",
						textAlign: "center"
					}}
				>
					<a name="bookmark7">AGENT REPRESENTING BOTH SELLER AND BUYER</a>
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					A real estate agent, either acting directly or through one or more
					salespersons and broker associates, can legally be the agent of both
					the Seller and the Buyer in a transaction, but only with the knowledge
					and consent of both the Seller and the Buyer.
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					In a dual agency situation, the agent has the following affirmative
					obligations to both the Seller and the Buyer:
				</p>
				<ol id="l14">
					<li data-list-text="(a)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A fiduciary duty of utmost care, integrity, honesty, and loyalty
							in the dealings with either the Seller or the Buyer.
						</p>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingLeft: "22pt",
								textIndent: "-16pt",
								textAlign: "left"
							}}
						>
							Other duties to the Seller and the Buyer as stated above in their
							respective sections.
						</p>
					</li>
				</ol>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					In representing both Seller and Buyer, a dual agent may not, without
					the express permission of the respective party, disclose to the other
					party confidential information, including, but not limited to, facts
					relating to either the Buyer’s or Seller’s financial position,
					motivations, bargaining position, or other personal information that
					may impact price, including the Seller’s willingness to accept a price
					less than the listing price or the Buyer’s willingness to pay a price
					greater than the price offered.
				</p>
			</div>
			<div id="sec5">
				<p
					className="s8"
					style={{
						paddingTop: "8pt",
						textIndent: "0pt",
						lineHeight: "13pt",
						textAlign: "center"
					}}
				>
					<a name="bookmark8">SELLER AND BUYER RESPONSIBILITIES</a>
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					Either the purchase agreement or a separate document will contain a
					confirmation of which agent is representing you and whether that agent
					is representing you exclusively in the transaction or acting as a dual
					agent. Please pay attention to that confirmation to make sure it
					accurately reflects your understanding of your agent’s role.
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					The above duties of the agent in a real estate transaction do not
					relieve a Seller or Buyer from the responsibility to protect his or
					her own interests. You should carefully read all agreements to assure
					that they adequately express your understanding of the transaction. A
					real estate agent
				</p>
				<p
					style={{
						paddingTop: "3pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					is a person qualified to advise about real estate. If legal or tax
					advice is desired, consult a competent professional.
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					If you are a Buyer, you have the duty to exercise reasonable care to
					protect yourself, including as to those facts about the property which
					are known to you or within your diligent attention and observation.
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					Both Sellers and Buyers should strongly consider obtaining tax advice
					from a competent professional because the federal and state tax
					consequences of a transaction can be complex and subject to change.
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					Throughout your real property transaction you may receive more than
					one disclosure form, depending upon the number of agents assisting in
					the transaction. The law requires each agent with whom you have more
					than a casual relationship to present you with this disclosure form.
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					You should read its contents each time it is presented to you,
					considering the relationship between you and the real estate agent in
					your specific transaction.
				</p>
				<p style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}>
					This disclosure form includes the provisions of Sections 2079.13 to
					2079.24, inclusive, of the Civil Code set forth on the reverse hereof.
					Read it carefully.
				</p>
				<div
					style={{
						paddingTop: "10pt",
						paddingLeft: "10pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<h1
						style={{
							paddingTop: "10pt",
							textIndent: "0pt",
							textAlign: "left"
						}}
					>
						COMPANY
					</h1>
					<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
						ALOKEE INC
					</p>
					<p
						className="s2"
						style={{
							paddingTop: "3pt",

							textIndent: "0pt",
							textAlign: "left"
						}}
					>
						By: /s/ Hamed Adibnatanzi
					</p>
					<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
						Name: Hamed Adibnatanzi DRE 0199763
					</p>
					<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
						Its: Broker
					</p>
					<p style={{ textIndent: "0pt", textAlign: "left" }}>
						<br />
					</p>
					<h1 style={{ textIndent: "0pt", textAlign: "left" }}>CLIENT</h1>
					<div
						style={{
							display: "flex",
							textAlign: "left",
							alignItems: "center",

							textIndent: "0pt"
						}}
					>
						<p className="s2">By:</p>
						<TextField
							style={{
								marginLeft: 10,
								marginBottom: 0,
								paddingBottom: 18
							}}
							value={name}
							label="Write your full name here."
							variant="standard"
							fullWidth={false}
							onChange={(e) => {
								setName(e.target.value);
							}}
							type="text"
							required
						/>
					</div>

					{/* <Grid item md={4} lg={4} sm={12} xs={12} order={{ xs: 2, sm: 1 }}>
					<Stack
						component="form"
						sx={{
							flexDirection: "row",
							alignItems: "center",
							marginTop: "60px"
						}}
					>
						<TextField
							label="Write your full name here."
							variant="standard"
							fullWidth={false}
							onChange={(e) => {
								setName(e.target.value);
							}}
							type="text"
							required
						/>
					</Stack>
				</Grid> */}
					<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
						Name: {userFullName}
					</p>
				</div>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Amended by Stats. 2018, Ch. 907, Sec. 41. (AB 1289) Effective January
					1, 2019.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark9">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.17.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<ol>
					<li data-list-text="(a)">
						<p
							style={{
								paddingTop: "4pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							As soon as practicable, the buyer’s agent shall disclose to the
							buyer and seller whether the agent is acting in the real property
							transaction as the buyer’s agent, or as a dual agent representing
							both the buyer and the seller. This relationship shall be
							confirmed in the contract to purchase and sell real property or in
							a separate writing executed or acknowledged by the seller, the
							buyer, and the buyer’s agent prior to or coincident with execution
							of that contract by the buyer and the seller, respectively.
						</p>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							As soon as practicable, the seller’s agent shall disclose to the
							seller whether the seller’s agent is acting in the real property
							transaction as the seller’s agent, or as a dual agent representing
							both the buyer and seller. This relationship shall be confirmed in
							the contract to purchase and sell real property or in a separate
							writing executed or acknowledged by the seller and the seller’s
							agent prior to or coincident with the execution of that contract
							by the seller.
						</p>
					</li>
				</ol>
			</div>
			<div id="sec6">
				<ol id="l15">
					<li data-list-text="(c)">
						<p
							style={{
								paddingLeft: "22pt",
								textIndent: "-15pt",
								textAlign: "left"
							}}
						>
							The confirmation required by subdivisions (a) and (b) shall be in
							the following form:
						</p>
						<table
							style={{
								borderCollapse: "collapse",
								marginLeft: "5.625pt",
								border: "solid",
								borderColor: "#e0dfdb",
								borderWidth: "thin"
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: "36pt" }}>
									<td
										style={{
											width: "381pt",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											(Name of Seller’s Agent, Brokerage firm and license
											number)
											<span className="s10">&nbsp;&nbsp; </span>
										</p>
										<p
											className="s9"
											style={{
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											is the broker of (check one):
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]the seller; or
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]both the buyer and seller. (dual agent)
										</p>
									</td>
								</tr>
							</tbody>
						</table>
						<table
							style={{
								borderCollapse: "collapse",
								marginLeft: "5.625pt",
								border: "solid",
								borderColor: "#e0dfdb",
								borderWidth: "thin"
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: "36pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												lineHeight: "13pt",
												textAlign: "left"
											}}
										>
											(Name of Seller’s Agent and license number)
											<span className="s10">&nbsp;&nbsp; </span>
										</p>
										<p
											className="s9"
											style={{
												paddingLeft: "6pt",
												textIndent: "0pt",
												lineHeight: "13pt",
												textAlign: "left"
											}}
										>
											is (check one):
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]is the Seller’s Agent. (salesperson or broker
											associate)
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]is both the Buyer’s and Seller’s Agent. (dual agent)
										</p>
									</td>
								</tr>
								<tr style={{ height: "36pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											<p className="s9">
												(Name of Buyer’s Agent, Brokerage firm and license
												number)
											</p>
										</p>
										<p
											className="s9"
											style={{
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											is the broker of (check one):
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]the buyer; or
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]both the buyer and seller. (dual agent)
										</p>
									</td>
								</tr>
								<tr style={{ height: "36pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												lineHeight: "13pt",
												textAlign: "left"
											}}
										>
											(Name of Buyer’s Agent and license number)
											<span className="s10">&nbsp;&nbsp; </span>
										</p>
										<p
											className="s9"
											style={{
												paddingLeft: "6pt",
												textIndent: "0pt",
												lineHeight: "13pt",
												textAlign: "left"
											}}
										>
											is (check one):
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]the Buyer’s Agent. (salesperson or broker associate)
										</p>
									</td>
								</tr>
								<tr style={{ height: "23pt" }}>
									<td
										style={{
											width: "381pt",
											borderTopStyle: "solid",
											borderTopWidth: "1pt",
											borderTopColor: "#e0dfdb",
											borderBottomStyle: "solid",
											borderBottomWidth: "1pt",
											borderBottomColor: "#e0dfdb",
											borderRightStyle: "solid",
											borderRightWidth: "1pt",
											borderRightColor: "#e0dfdb"
										}}
									>
										<p
											className="s9"
											style={{
												paddingTop: "1pt",
												paddingLeft: "6pt",
												textIndent: "0pt",
												textAlign: "left"
											}}
										>
											[ ]both the Buyer’s and Seller’s Agent. (dual agent)
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</li>
					<li data-list-text="(d)">
						<p
							style={{
								paddingTop: "1pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							The disclosures and confirmation required by this section shall be
							in addition to the disclosure required by Section 2079.14. An
							agent’s duty to provide disclosure and confirmation of
							representation in this section may be performed by a real estate
							salesperson or broker associate affiliated with that broker.
						</p>
					</li>
				</ol>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Amended by Stats. 2018, Ch. 907, Sec. 42. (AB 1289) Effective January
					1, 2019.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark10">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.19.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					The payment of compensation or the obligation to pay compensation to
					an agent by the seller or buyer is not necessarily determinative of a
					particular agency relationship between an agent and the seller or
					buyer. A listing agent and a selling agent may agree to share any
					compensation or commission paid, or any right to any compensation or
					commission for which an obligation arises as the result of a real
					estate transaction, and the terms of any such agreement shall not
					necessarily be determinative of a particular relationship.
				</p>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Added by Stats. 1995, Ch. 428, Sec. 8. Effective January 1, 1996.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark11">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.20.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					Nothing in this article prevents an agent from selecting, as a
					condition of the agent’s employment, a specific form of agency
					relationship not specifically prohibited by this article if the
					requirements of Section 2079.14 and Section 2079.17 are complied with.
				</p>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Added by Stats. 1995, Ch. 428, Sec. 9. Effective January 1, 1996.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark12">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.21.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<ol id="l16">
					<li data-list-text="(a)">
						<p
							style={{
								paddingTop: "4pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A dual agent may not, without the express permission of the
							seller, disclose to the buyer any confidential information
							obtained from the seller.
						</p>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingTop: "3pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A dual agent may not, without the express permission of the buyer,
							disclose to the seller any confidential information obtained from
							the buyer.
						</p>
					</li>
					<li data-list-text="(c)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							“Confidential information” means facts relating to the client’s
							financial position, motivations, bargaining position, or other
							personal information that may impact price, such as the seller is
							willing to accept a price less than the listing price or the buyer
							is willing to pay a price greater than the price offered.
						</p>
					</li>
					<li data-list-text="(d)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							This section does not alter in any way the duty or responsibility
							of a dual agent to any principal with respect to confidential
							information other than price.
						</p>
					</li>
				</ol>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Amended by Stats. 2018, Ch. 907, Sec. 44. (AB 1289) Effective January
					1, 2019.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark13">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.22.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					Nothing in this article precludes a seller’s agent from also being a
					buyer’s agent. If a seller or buyer in a transaction chooses to not be
					represented by an agent, that does not, of itself, make that agent a
					dual agent.
				</p>
				<p
					className="s7"
					style={{
						paddingLeft: "6pt",
						textIndent: "0pt",
						lineHeight: "13pt",
						textAlign: "left"
					}}
				>
					(Amended by Stats. 2018, Ch. 907, Sec. 45. (AB 1289) Effective January
					1, 2019.)
				</p>
			</div>
			<div id="sec7">
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark14">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.23.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<ol id="l17">
					<li data-list-text="(a)">
						<p
							style={{
								paddingTop: "4pt",
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A contract between the principal and agent may be modified or
							altered to change the agency relationship at any time before the
							performance of the act which is the object of the agency with the
							written consent of the parties to the agency relationship.
						</p>
					</li>
					<li data-list-text="(b)">
						<p
							style={{
								paddingLeft: "6pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							A lender or an auction company retained by a lender to control
							aspects of a transaction of real property subject to this part,
							including validating the sales price, shall not require, as a
							condition of receiving the lender’s approval of the transaction,
							the homeowner or listing agent to defend or indemnify the lender
							or auction company from any liability alleged to result from the
							actions of the lender or auction company. Any clause, provision,
							covenant, or agreement purporting to impose an obligation to
							defend or indemnify a lender or an auction company in violation of
							this subdivision is against public policy, void, and
							unenforceable.
						</p>
					</li>
				</ol>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Amended by Stats. 2014, Ch. 893, Sec. 2. (AB 2039) Effective January
					1, 2015.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<h1
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					<a name="bookmark15">California Civil Code </a>
					<span style={{ color: "#030b27" }}>§</span>
					<span className="s6">2079.24.</span>
				</h1>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "4pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					Nothing in this article shall be construed to either diminish the duty
					of disclosure owed buyers and sellers by agents and their associate
					licensees, subagents, and employees or to relieve agents and their
					associate licensees, subagents, and employees from liability for their
					conduct in connection with acts governed by this article or for any
					breach of a fiduciary duty or a duty of disclosure.
				</p>
				<p
					className="s7"
					style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "left" }}
				>
					(Added by Stats. 1995, Ch. 428, Sec. 13. Effective January 1, 1996.)
				</p>
				<p style={{ textIndent: "0pt", textAlign: "left" }}>
					<br />
				</p>
				<p
					style={{
						paddingTop: "10pt",
						paddingLeft: "6pt",
						textIndent: "0pt",
						textAlign: "left"
					}}
				>
					IN WITNESS WHEREOF, the Parties hereto have executed this Agreement by
					electronic signature effective as of the Effective Date.
				</p>
				<div
					style={{
						display: "flex",
						alignItems: "flex-end",
						justifyContent: "flex-end"
					}}
				>
					<div>
						<h1
							style={{
								paddingTop: "10pt",
								paddingLeft: "0pt",
								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							COMPANY
						</h1>
						<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
							ALOKEE INC
						</p>
						<p
							className="s2"
							style={{
								paddingTop: "3pt",

								textIndent: "0pt",
								textAlign: "left"
							}}
						>
							By: /s/ Hamed Adibnatanzi
						</p>
						<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
							Name: Hamed Adibnatanzi DRE 0199763
						</p>
						<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
							Its: Broker
						</p>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
						<h1 style={{ textIndent: "0pt", textAlign: "left" }}>CLIENT</h1>
						<p style={{ textIndent: "0pt", textAlign: "left" }}>
							<br />
						</p>
						<div
							style={{
								display: "flex",
								textAlign: "left",
								alignItems: "center",

								textIndent: "0pt"
							}}
						>
							<p className="s2">By:</p>
							<TextField
								style={{
									marginLeft: 10,
									marginBottom: 0,
									paddingBottom: 18
								}}
								label="Write your full name here."
								variant="standard"
								fullWidth={false}
								onChange={(e) => {
									setName(e.target.value);

									if (
										e.target.value.trim().toLowerCase() ==
										userFullName.trim().toLowerCase()
									) {
										setButtonEnabled(false);
									} else {
										setButtonEnabled(true);
									}
								}}
								type="text"
								required
							/>
						</div>
						<p
							style={{
								textIndent: "0pt",
								textAlign: "left",
								paddingBottom: 10
							}}
							className="s2"
						>
							(By Signing here you will sign the whole form.)
						</p>
						<p className="s2" style={{ textIndent: "0pt", textAlign: "left" }}>
							Name: {userFullName}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NonExclusiveAgreement;
