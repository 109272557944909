import {
	Avatar,
	CircularProgress,
	Grid,
	IconButton,
	Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TextDialog from "../core/TextDialog";
import makeStyles from "./styles";
import Cookies from "universal-cookie";
import {
	createUserPropertyEnvelope,
	getPropertyDetailsById,
	updateSellerInfo,
	updateUserPropertyStatus,
	uploadFile
} from "../../services/propertyForm";
import { useNavigate, useParams } from "react-router";
import doneIcon from "../../assets/done.png";
import RecipientView from "./RecipientView";
import { createCounterOfferBySeller } from "../../services/userPropertyEnvelope";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as Yup from "yup";
import { Link, useSearchParams } from "react-router-dom";
import { ImportContactsOutlined } from "@material-ui/icons";
import { downloadDocumentById } from "../../services/eSigning";
import { Form, Formik } from "formik";
import { Button } from "../core";
import { Box } from "@mui/material";
import FileUpload from "../core/FileUpload";
const cookies = new Cookies();
const initialValues = {
	disclosure: ""
};

const validationSchema = Yup.object().shape({
	disclosure: Yup.mixed().required("Disclosure is required")
});

function RecipientForm() {
	const classes = makeStyles();
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
	const envelopeStatus = searchParams.get("envelopeStatus");
	const signerRole = searchParams.get("signerRole");
	const [revisedState, setRevisedState] = useState(true);
	const [alreadyGetSellerInfo, setAlreadyGetSellerInfo] = useState(false);
	const [signedBy, setSignedBy] = useState("");
	const [showActions, setShowActions] = useState(false);
	const [revisedFields, setRevisedFields] = useState({});
	const [showRevised, setShowRevised] = useState({
		message: "",
		state: false
	});
	const [showConfirm, setShowConfirm] = useState({
		message: "",
		state: false
	});
	const [statusUpdate, setStatusUpdate] = useState({
		message: "",
		state: false
	});
	const [status, setStatus] = useState();
	const [loading, setLoading] = useState(false);
	const [downloading, setDownLoading] = useState(false);

	const [loadingSellerInfo, setLoadingSellerInfo] = useState(false);
	const [askSellerInfo, setAskSellerInfo] = useState({
		message: "",
		state: false
	});
	const { userPropertyId, code, role, mlsId } = useParams();
	const token = cookies.get("token", {
		doNotParse: true
	});
	const [showCommentForm, setShowCommentForm] = useState({
		message: "",
		state: false
	});
	const [allow, setAllow] = useState(true);
	const [propertyFormId, setPropertyFormId] = useState("");
	const [disclosureUploaded, setDisclosureUploaded] = useState("");
	const [requestDisclosure, setRequestDisclosure] = useState(null);

	useEffect(() => {
		const getPropertyDetails = async () => {
			try {
				const resp = await getPropertyDetailsById({
					id: userPropertyId,
					code,
					role
				});
				setPropertyFormId(resp.data.data.data[0].property_form.id);
				setDisclosureUploaded(resp.data.data.data[0].disclosureUploaded);
				setRequestDisclosure(resp.data.data.data[0].requestDisclosure);

				if (!resp.data.data.allow) {
					setAllow(false);
					setStatusUpdate({
						state: true,
						message: `Sorry you are not a valid ${role} of this Offer`
					});
					return;
				}
				if (resp.data.data.data[0].status == "pulled-back") {
					setStatusUpdate({
						state: true,
						message: `This Offer has been Pulled back By Buyer. Sorry for the inconvenience`
					});
					return;
				}
				if (
					resp.data.data.data[0].property_form.sellerName &&
					resp.data.data.data[0].property_form.sellerEmail
				) {
					setAlreadyGetSellerInfo(true);
				}
				if (resp.data.data.data[0].user_property_envelopes.revisedFields) {
					setRevisedFields(
						JSON.parse(
							resp.data.data.data[0].user_property_envelopes.revisedFields
						)
					);
				}

				setSignedBy(resp.data.data.data[0].user_property_envelopes.signedBy);
				cookies.set(
					`userPropertyEnvelopeId`,
					resp.data.data.data[0].user_property_envelopes.id,
					{
						doNotParse: true,
						path: "/"
					}
				);
				if (
					resp.data.data.data[0].status == "accepted" ||
					resp.data.data.data[0].status == "revised" ||
					resp.data.data.data[0].status == "revised-by-seller" ||
					resp.data.data.data[0].status == "revised-by-buyer" ||
					resp.data.data.data[0].status == "awaiting-alokee-approval" ||
					resp.data.data.data[0].status == "sent-to-seller-agent" ||
					resp.data.data.data[0].status == "sent-to-buyer-agent" ||
					resp.data.data.data[0].status == "sent-to-seller"
				) {
					setShowActions(true);
					setStatus(resp.data.data.data[0].status);
				} else if (
					resp.data.data.data[0].status == "awaiting-signatures" ||
					resp.data.data.data[0].status == "awaiting-alokee-approval" ||
					resp.data.data.data[0].status == "in-progress"
				) {
					setRevisedState(false);
					setStatus(resp.data.data.data[0].status);
				} else if (resp.data.data.data[0].status == "under-review") {
					setStatus(resp.data.data.data[0].status);
				} else if (resp.data.data.data[0].status == "rejected") {
					setStatus(resp.data.data.data[0].status);
					setRevisedState(false);
					setShowActions(false);
				} else if (resp.data.data.data[0].status == "pulled-back") {
					setStatus(resp.data.data.data[0].status);
				}
			} catch (e) {}
		};
		getPropertyDetails();
	}, [userPropertyId]);

	useEffect(() => {
		if (envelopeStatus == "Completed" && signerRole == "sellerAgent") {
			setStatusUpdate((prevState) => {
				return {
					message: "The offer has been sent to your customer to sign.",
					state: true,
					description:
						"Once they have signed, you will receive a copy of the offer back for you to approve then send to the Buyer Agent."
				};
			});
		}

		if (envelopeStatus == "Completed" && signerRole == "buyerAgent") {
			setStatusUpdate((prevState) => {
				return {
					message: "Thank you for signing.",
					state: true,
					description: "This offer has been delivered to the Seller Agent."
				};
			});
		}
	}, []);

	const updateStatus = async (status) => {
		try {
			const resp = await updateUserPropertyStatus({
				status,
				id: userPropertyId,
				token,
				bySellerAgent: true
			});
			if (resp.data.data.success) {
				setStatusUpdate({
					state: true,
					message: `This offer has been ${status}.`
				});
				setTimeout(() => {
					window.location.reload();
				}, 1200);
			}
		} catch (e) {}
	};

	const onSubmitRevision = async (values) => {
		setLoading(true);
		values.revisedBy = "sellerAgent";
		delete values["MLSData"];

		try {
			const resp = await createCounterOfferBySeller({
				userPropertyId,
				revisedFields: values
			});
			if (resp.data.data.results.envelopeId) {
				setAlreadyGetSellerInfo(true);
				setShowRevised((prevState) => {
					return {
						message: "Add Counter Offer Info",
						state: false
					};
				});
				navigate(`/signingPageForRecipient/${role}/${code}/${userPropertyId}`, {
					replace: true
				});
				setTimeout(async () => {
					const updateEnvelopeStatus = await createUserPropertyEnvelope({
						userPropertyId: userPropertyId,
						envelopeId: resp.data.data.results.envelopeId,
						envelopeType: "seller-counter-offer",
						revisedFields: JSON.stringify(values)
					});
				}, 7000);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};
	const onSubmitSellerInfo = async (values) => {
		try {
			setLoadingSellerInfo(true);
			const resp = await updateSellerInfo({
				userPropertyId,
				sellerInfo: values
			});
			setLoadingSellerInfo(false);
			if (resp.data.success) {
				navigate(`/signingPageForRecipient/${role}/${code}/${userPropertyId}`, {
					replace: true
				});
			}
		} catch (e) {
			setLoadingSellerInfo(false);
		}
	};
	const onUploadDisclosure = async (values) => {
		try {
			setLoading(true);
			values.mlsId = mlsId;
			values.id = propertyFormId;
			values.userPropertyId = userPropertyId;
			const data = new FormData();
			data.append("document", values.disclosure);
			data.append("otherValues", JSON.stringify(values));
			const resp = await uploadFile(data);

			if (resp.data.success) {
				setLoading(false);
				setDisclosureUploaded(true);
				return;
			} else {
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
		}
	};

	return (
		<>
			<TextDialog
				showActions={false}
				message={statusUpdate.message}
				state={statusUpdate.state}
				dialogDescription={statusUpdate.description}
				stateHandler={setStatusUpdate}
				textDialog={true}
				submitHandler={() => {}}
				onClose={() => {}}
				showField={false}
				Avatar={
					<Avatar
						src={doneIcon}
						style={{
							top: 10,
							marginBottom: 10,
							flex: 1,
							alignContent: "center",
							alignSelf: "center"
						}}
					></Avatar>
				}
				showAvatar={true}
			></TextDialog>
			<TextDialog
				title="Confirmation Dialog"
				message={`Are you sure you want to ${status} this offer?`}
				state={showConfirm.state}
				showActions={true}
				stateHandler={setShowConfirm}
				submitHandler={async () => {
					if (status == "revised") {
						setRevisedState(false);
						setShowRevised((prevState) => {
							return {
								message: "Add Counter Offer Info",
								state: true
							};
						});
					}
					if (status == "accepted" || status == "rejected") {
						updateStatus(status);
					}
				}}
				showField={false}
			></TextDialog>
			{allow && (
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					className={classes.buttonContainer}
				>
					<Grid item xs={6} sm={6} lg={4} xl={4} md={4}>
						<IconButton
							color="primary"
							disabled={downloading}
							onClick={async () => {
								try {
									setDownLoading(true);
									const response = await downloadDocumentById({
										mlsId,
										userPropertyId
									});
									const file = new Blob([response.data], {
										type: "application/pdf"
									});
									//Build a URL from the file
									const fileURL = URL.createObjectURL(file);
									//Open the URL on new Window
									window.open(fileURL, "_blank");
									setDownLoading(false);
								} catch (e) {
									setDownLoading(false);
								}
							}}
						>
							{!downloading ? (
								<CloudDownloadIcon />
							) : (
								<CircularProgress size={20} />
							)}
							<Typography
								style={{
									marginLeft: "10px"
								}}
								color="textSecondary"
							>
								{!downloading ? "Offer Form" : "Downloading..."}
							</Typography>
						</IconButton>
					</Grid>
					<Grid item xs={6} sm={6} lg={4} xl={4} md={4}>
						<IconButton color="primary">
							<ImportContactsOutlined />
							<Link
								target="_blank"
								to={`/MLSDetails/${role}/${code}/${mlsId}/${userPropertyId}`}
								style={{ overflowX: "visible", color: "black" }}
							>
								<Typography
									style={{
										marginLeft: "10px"
									}}
									color="textSecondary"
								>
									MLS Details
								</Typography>
							</Link>
						</IconButton>
					</Grid>
					{requestDisclosure ? (
						<Grid item xs={12} sm={4} lg={4} xl={4} md={4}>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={(values) => {
									onUploadDisclosure(values);
								}}
								render={({ values, errors, handleSubmit }) => (
									<Form>
										<Grid
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="flex-start"
										>
											<Grid item xs={6} sm={6} md={6}>
												<FileUpload
													disabled={loading || disclosureUploaded}
													value={values.disclosure}
													name="disclosure"
													label="Upload Disclosure"
													onFileSelectError={({ error }) => alert(error)}
												/>
											</Grid>
											<Grid item xs={6} sm={6} md={6}>
												<Box py={3} px={1}>
													<Button
														onClick={() => {
															handleSubmit();
														}}
														fullWidth={false}
														disabled={disclosureUploaded}
														loading={loading || undefined}
													>
														{"Proceed"}
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Form>
								)}
							></Formik>
						</Grid>
					) : (
						""
					)}
				</Grid>
			)}
			{status && (
				<RecipientView
					setStatus={setStatus}
					showActions={showActions}
					setShowConfirmStatusChange={setShowConfirm}
					status={status}
					revisedState={revisedState}
					signedBy={signedBy}
					showRevised={showRevised}
					setShowRevised={setShowRevised}
					onSubmitRevision={onSubmitRevision}
					loadingRecipient={loading}
					loadingSellerInfo={loadingSellerInfo}
					askSellerInfo={askSellerInfo}
					setAskSellerInfo={setAskSellerInfo}
					onSubmitSellerInfo={onSubmitSellerInfo}
					showCommentForm={showCommentForm}
					setShowCommentForm={setShowCommentForm}
					alreadyGetSellerInfo={alreadyGetSellerInfo}
					revisedFields={revisedFields}
					hideStepper={true}
				></RecipientView>
			)}
		</>
	);
}

export default RecipientForm;
