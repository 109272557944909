import React from "react";
import { useField } from "formik";
import { makeStyles, TextField, Tooltip, Box } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
    labelWithIcon: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: '5px',
        marginTop: "40px"
    }
});

function LabelAboveTextField({
    name,
    margin,
    size,
    toolTipMsg,
    label,
    tooltipRequired,
    ...otherProps
}) {
    const [field, meta] = useField(name);
    const classes = useStyles();

    const configTextfield = {
        ...field,
        ...otherProps,
        fullWidth: true,
        margin: margin || "normal",
        size: size || "medium",
        variant: "outlined",
    };

    // Error handling
    if (meta && meta.error) {
        configTextfield.error = true;
        configTextfield.helperText = meta.error;
    }

    return (
        <>
            <Box className={classes.labelWithIcon}>
                <label htmlFor={name} style={{ fontFamily: "Rubik", color: "#003A7B", fontSize: "1.125rem", fontWeight: "700" }}>
                    {label}
                </label>
                {tooltipRequired && (
                    <Tooltip title={toolTipMsg ? toolTipMsg : label}>
                        <InfoOutlined fontSize="small" style={{ marginLeft: 8, color: "#686868" }} />
                    </Tooltip>
                )}
            </Box>
            <TextField
                id={name}
                {...configTextfield}
            />
        </>
    );
}

export default LabelAboveTextField;
