import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
	Box,
	Grid,
	Container,
	makeStyles,
	Paper,
	IconButton
} from "@material-ui/core";
import { MdArrowBack as BackIcon } from "react-icons/md";

import { TextField, Button, Toast } from "../core";
import { createComment } from "../../services/comment";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
	formWrapper: {
		margin: theme.spacing(3),
		padding: theme.spacing(3)
	},
	icon: {
		fontSize: 25,
		color: theme.palette.primary.main,
		cursor: "pointer",
		float: "right"
	}
}));

const Comments = ({ comment, commentHandler, commentFormHandler }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});

	const initialValues = comment || {
		comment: ""
	};

	const validationSchema = Yup.object().shape({
		comment: Yup.string().max(1000).required("Comment is required")
	});
	const { user } = useAuth0();

	const onSubmit = async (values, { resetForm }) => {
		setLoading(true);
		let resp;

		if (comment) {
			resp = await createComment({
				userPropertyId: comment.id,
				description: values.comment,
				auth0Id: user?.sub
			});
		}
		if (resp.data.success) {
			setToast({
				state: "open",
				message: resp.data.message,
				type: "success"
			});
			if (comment) {
				setTimeout(() => {
					resetForm();
					commentFormHandler(false);
				}, 500);
			} else {
				resetForm();
			}
		} else {
			setToast({
				state: "open",
				message: resp.data.message,
				type: "error"
			});
		}

		setLoading(false);
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<Container maxWidth="md">
					<Toast {...toast} setState={setToast} />
					<IconButton
						className={classes.icon}
						onClick={() => {
							commentFormHandler(false);
						}}
					>
						<BackIcon />
					</IconButton>
					<Paper>
						<div className={classes.formWrapper}>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
							>
								{(props) => (
									<Form>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<TextField name="comment" label="Comment" required />
											</Grid>

											<Grid item xs={12}>
												<Box py={2}>
													<Button
														type="submit"
														loading={loading}
														loadingPosition="start"
													>
														{"Add Comment"}
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						</div>
					</Paper>
				</Container>
			</Grid>
		</Grid>
	);
};

export default Comments;
