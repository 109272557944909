import React, { useState } from "react";
import { sendEmailToAlokee } from "../../services/propertyForm";
import { TextField, Toast } from "../core";
import { Box, Grid, Container, Dialog, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { Button, Typography, InputLabel } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const askUsAQuestionDialogStyles = makeStyles((theme) => {
    return {
        messageDialogTitle: {
            color: "#003A7B",
            fontFamily: theme.typography.fontFamily,
            fontSize: "24px",
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '138%' /* 44.16px */
        },
        noUnderline: {
            '&:before': {
                borderBottom: 'none',
            },
            '&:after': {
                borderBottom: 'none',
            }
        },
        placeholderText: {
            paddingLeft: '8px',
            paddingRight: '8px'
        }
    };
});

const AskUsAQuestionDialog = ({ state, stateHandler }) => {
    const classes = askUsAQuestionDialogStyles();
    const [messageSending, setMessageSending] = useState(false);
    const [toast, setToast] = useState({
        state: "close",
        message: "",
        type: ""
    });

    let initialValuesQuestion = {
        firstName: "",
        lastName: "",
        email: "",
        message: ""
    };

    const schemaQuestion = Yup.object().shape({
        firstName: Yup.string().required("first name is required"),
        lastName: Yup.string().required("last name is required"),
        email: Yup.string()
            .required("email is required")
            .email("Please enter a valid email address"),
        message: Yup.string().required("message is required")
    });

    const onMessageSubmit = async (values, { resetForm }) => {
        try {
            setMessageSending(true);
            const email = await sendEmailToAlokee({
                details: {
                    from: { email: "offer@alokee.com", name: "Alokee" },
                    subject: "Question from a User"
                },
                dynamicTemplateData: {
                    name: values.firstName + " " + values.lastName,
                    email: values.email,
                    message: values.message
                }
            });
            if (email.data) {
                setToast({
                    state: "open",
                    message:
                        "Your message has been sent successfully! We will email you shortly.",
                    type: "success",
                    hideDuration: 5000
                });
                resetForm();
            }
        } catch (e) {
            console.log(e);
            setToast({
                state: "open",
                message: "There was a problem sending your message. Please try again later.",
                type: "error",
                hideDuration: 5000
            });
        } finally {
            setMessageSending(false);
            stateHandler(false);
        }
    };

    return (
        <Container>
            <Dialog
                open={state}
                onClose={stateHandler}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => stateHandler(false)}
                    style={{ position: "absolute", right: 5, top: 15 }}
                >
                    <CloseIcon />
                </IconButton>
                <Formik
                    validationSchema={schemaQuestion}
                    initialValues={initialValuesQuestion}
                    onSubmit={(values, formikBag) => onMessageSubmit(values, formikBag)}
                    render={({ handleSubmit, values, resetForm }) => {
                        return (
                            <Form style={{ padding: "20px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.messageDialogTitle}>Ask us a Question</Typography>
                                        <Typography>
                                            We are here to help you with anything and everything about Real Estate!
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="firstName"
                                            label="First Name"
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="lastName"
                                            label="Last Name"
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="email"
                                            label="Email"
                                            type="email"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel style={{ color: '#2a3d57' }} htmlFor="message">Type your message</InputLabel>
                                        <TextField
                                            name="message"
                                            type="text"
                                            maxRows={10}
                                            minRows={3}
                                            multiline
                                            required
                                            placeholder="Please let us know what we can help you with..."
                                            InputProps={{
                                                classes: {
                                                    underline: classes.noUnderline,
                                                    root: classes.placeholderText
                                                },
                                                style: {
                                                    border: '1px solid #999',
                                                    borderRadius: '4px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box py={2}>
                                            <Button
                                                className={classes.button}
                                                style={{ marginLeft: 'auto', backgroundColor: '#FFF', color: "#000", textTransform: "none" }}
                                                variant="outlined"
                                                onClick={() => {
                                                    resetForm();
                                                    stateHandler(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className={classes.button}
                                                style={{ marginLeft: "10px", textTransform: "none" }}
                                                loadingPosition="start"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    handleSubmit(values);
                                                }}
                                                disabled={messageSending}
                                            >
                                                {messageSending ? "Sending...." : "Submit"}
                                            </Button>
                                            <Typography style={{ marginTop: '10px' }}>Thanks for your interest in the movement! We'll get back to you with pro advice ASAP!</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                />
            </Dialog>
            <Toast {...toast} setState={setToast} />
        </Container>
    )
};

export default AskUsAQuestionDialog;