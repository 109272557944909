import axiosInstance from ".";

const getAllUsers = async (params) => {
	return axiosInstance.post(`/user/getAllUsers`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};
export const createUser = async (params) => {
	return axiosInstance.post(`/user/createUser`, params, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};
export const getUserProfile = async (params) => {
	return axiosInstance.post(`/user/me`, params);
};
export const updateUser = async (params) => {
	return axiosInstance.post(`/user/updateUser`, params, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};

export const remove = async (params) => {
	return axiosInstance.post(`/user/deleteUser`, {
		id: params.id,
		auth0Id: params.auth0Id
	});
};
export const createOTP = async (params) => {
	return axiosInstance.post(`/user/createOTP`, {
		auth0Id: params.auth0Id
	});
};
export const verifyOTP = async (params) => {
	return axiosInstance.post(`/user/verifyOTP`, params);
};

export default getAllUsers;
