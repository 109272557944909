import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
	Paper,
	Box,
	Typography,
	Link,
	Avatar,
	Grid,
	Container
} from "@material-ui/core";
import { MdConfirmationNumber as CodeIcon } from "react-icons/md";
import useStyles from "./styles";
import { TextField, Button, Toast } from "../core";
import AutoSubmitCode from "./AutoSubmitCode";
import { createOTP, verifyOTP } from "../../services/users";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
const cookies = new Cookies();

const initialValues = {
	code: ""
};

const validationSchema = Yup.object().shape({
	code: Yup.string().required("Code is required!")
});

const count = 120;

export default function ConfirmationCode(props) {
	const classes = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [askOTP, setAskOTP] = useState(true);
	const [counter, setCounter] = useState(0);
	const { user } = useAuth0();
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	useEffect(() => {
		if (counter > 0) {
			setTimeout(() => setCounter(counter - 1), 1000);
		} else {
			setAskOTP(true);
		}
	});

	const onSubmit = async (values) => {
		setLoading(true);
		const resp = await verifyOTP({
			auth0Id: user?.sub,
			otp: values.code
		});
		if (resp.data.success) {
			cookies.set("otpVerified", true, {
				doNotParse: true,
				path: "/"
			});
			setLoading(false);
			window.location.href = `${process.env.REACT_APP_URL}app/dashboard`;
		} else {
			setToast({
				state: "open",
				message: resp.data.errorMessage,
				type: "error"
			});
			setLoading(false);
		}
	};

	const handleResetCode = async () => {
		try {
			await createOTP({ auth0Id: user?.sub });
			setCounter(count);
			setAskOTP(false);
		} catch (e) {}
	};

	return (
		<Container maxWidth="lg">
			<Toast {...toast} setState={setToast} />
			<Paper className={classes.formContainer} elevation={3}>
				<Box mb={3}>
					<Grid container spacing={2}>
						<Grid container item direction="column" alignItems="center" xs={12}>
							<Avatar className={classes.avatar}>
								<CodeIcon />
							</Avatar>
							<Typography variant="h4">Verify your Identity</Typography>
						</Grid>
						<Grid container item direction="column" alignItems="center">
							<Typography variant="h5">Please Check your Email</Typography>
						</Grid>
					</Grid>
				</Box>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					<Form>
						<TextField
							name="code"
							type="text"
							label="Code"
							variant="outlined"
							fullWidth
							autoFocus
						/>
						<AutoSubmitCode />
						<Box>
							<Typography
								align="center"
								color="textSecondary"
								variant="subtitle2"
							>
								{counter > 0 && !askOTP ? (
									`Resend OTP in ${counter}s`
								) : (
									<Link
										component="button"
										type="button"
										variant="h4"
										onClick={handleResetCode}
									>
										SEND ME A CODE
									</Link>
								)}
							</Typography>
						</Box>

						<Box pt={3}>
							<Button
								type="submit"
								loading={loading}
								loadingPosition="start"
								fullWidth={false}
							>
								Confirm
							</Button>
						</Box>
					</Form>
				</Formik>
			</Paper>
		</Container>
	);
}
