import {
	Paper,
	Grid,
	TextField,
	InputAdornment,
	IconButton
} from "@mui/material";
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import useStyles from "../style";
import ChatList from "./ChatList";
import ChatView from "./ChatView";
import SearchIconImage from "../../../assets/searchIcon.png";
import { getAllUserChats } from "../../../services/userChat";
import socketIOClient from "socket.io-client";
import { ArrowBack } from "@mui/icons-material";
import { socket } from "../../../services/socket";

function Inbox({ setInboxVisible }) {
	const classes = useStyles();
	const [userChats, setUserChats] = useState([]);
	const [filteredUserChats, setFilteredUserChats] = useState([]);
	const [chats, setChats] = useState([]);
	const [lastMessage, setLastMessage] = useState("");

	useEffect(async () => {
		try {
			const resp = await getAllUserChats({});
			setUserChats(resp.data.data.data.rows);
			
			setFilteredUserChats(userChats);
		} catch (e) {
			
		}
	}, [chats]);

	return (
		<div className={classes.inboxContainer}>
			<IconButton
				onClick={() => {
					setInboxVisible(false);
				}}
				style={{
					position: "fixed",
					top: 79,
					left: 30
				}}
				color="primary"
			>
				<ArrowBack></ArrowBack>
			</IconButton>
			<Container maxWidth={"lg"} className={classes.chatContainer}>
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="flex-start"
					className={classes.gridContainer}
				>
					<Grid
						direction="column"
						justifyContent="center"
						alignItems="flex-start"
						item
						xs={12}
						sm={12}
						lg={4}
						xl={4}
						md={4}
					>
						<Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
							<TextField
								className={classes.searchBarStyle}
								placeholder="Search Chats..."
								margin="normal"
								variant="outlined"
								onChange={(e) => {
									if (e.target.value) {
										let filtered = userChats.filter((data) => {
											return data?.firstName?.includes(e.target.value);
										});
										setUserChats(filtered);
									} else {
										setUserChats(filteredUserChats);
									}
								}}
								InputProps={{
									className: classes.searchBar,
									startAdornment: (
										<InputAdornment position="start">
											<img
												src={SearchIconImage}
												alt="search-icon"
												style={{
													height: "25px",
													width: "25px",
													margin: "0 10px"
												}}
											/>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
							<Paper
								style={{
									width: "100%",
									minHeight: 492,
									maxHeight: 492,
									overflowY: "scroll"
								}}
							>
								<ChatList
									lastMessage={lastMessage}
									chatData={userChats}
									setChats={setChats}
								/>
							</Paper>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} lg={8} md={8} xl={8}>
						<ChatView
							uniqueKey={chats.auth0Id}
							oldChat={chats?.user_chats}
							socket={socket}
							setLastMessage={setLastMessage}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default Inbox;
