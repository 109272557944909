import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
	buttonContainer: {
		marginTop: 10,
		marginLeft: 60,
		width: "90%"
	},
	button: {
		margin: "10px !important"
	}
}));
