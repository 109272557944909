import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import faqExpandIcon from "../../assets/faqExpandIcon.svg";

const faqAccordionStyles = makeStyles((theme) => {
    return {
        faqText: {
            color: theme.palette.primary.mainLight,
            lineHeight: "132%",
            [theme.breakpoints.down('sm')]: {
                fontSize: "0.875rem"
            }
        },
        faqAnswer: {
            padding: "30px",
            [theme.breakpoints.down('sm')]: {
                padding: "5px"
            }
        }
    }
});

const FAQAccordion = ({ faqs }) => {
    const classes = faqAccordionStyles();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            {faqs.map((faq) => {
                return (
                    <Accordion key={faq.q} expanded={expanded === faq.q} onChange={handleChange(faq.q)}>
                        <AccordionSummary
                            expandIcon={<img src={faqExpandIcon} alt="Expand" aria-label="Expand FAQ" />}
                            aria-controls={`panel-${faq.q}-content`}
                            aria-expanded={expanded === faq.q}
                            id={`panel-${faq.q}-header`}
                            style={{ padding: "5px 5px 5px 12px" }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }} className={classes.faqText}>
                                {faq.q}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ background: "#F8F9FA" }}>
                            <Typography className={`${classes.faqText} ${classes.faqAnswer}`}>
                                {faq.a}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
};

export default FAQAccordion;
