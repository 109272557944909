/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@mui/icons-material/Check";
import {
	Typography,
	Paper,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	IconButton,
	Grid,
	Chip
} from "@material-ui/core";
import { MdEdit as EditIcon } from "react-icons/md";
import moment from "moment";
import Loader from "../core/Loader";
import TableHeaderCell from "./TableHeaderCell";
import Paginator from "./Paginator";
import TextField from "@material-ui/core/TextField";
import detailIcon from "./detailIcon.png";

import Toast from "../core/Toast";
import { dateTimeConverter } from "../../utils/dateTimeConverter";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(() => ({
	table: {
		minWidth: 650
	},
	icon: {
		maxHeight: "28px"
	},
	tableCell: {
		padding: "6px 6px 6px 6px"
	},
	button: {
		margin: 10,
		borderRadius: 100
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1
	},
	loaderRow: {
		height: 109
	},
	loaderPosition: {
		position: "absolute",
		right: "48%",
		top: "19%",
		zIndex: 1
	},
	loaderPositionEmpty: {
		position: "absolute",
		right: "48%",
		top: "49%",
		zIndex: 1
	},
	bodyOverlay: {
		opacity: 0.5
	},
	typo: {
		marginBottom: -13,
		marginLeft: 10
	},
	typoPickups: {
		display: "inline"
	},
	date: {
		overflow: "none",
		minWidth: 220,
		maxWidth: 220,
		marginLeft: 10
	},
	amountField: {
		marginLeft: 10,
		width: 150
	}
}));

const TableWrapper = ({
	headers,
	getData,
	dateFilters,
	onEdit,
	onDetails,
	onSuccess
}) => {
	const today = new Date();
	const { sd, ed } = dateTimeConverter(today, today, true, true);
	const classes = useStyles();
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [startDate, setStartdate] = useState(sd);
	const [endDate, setEndDate] = useState(ed);
	const [filters, setFilters] = useState({});
	const [initialFilter, setInitialFilter] = useState([]);
	const [count, setCount] = useState("0");
	const [paginator, setPaginator] = useState({
		limit: 10,
		offset: 0
	});
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const { user } = useAuth0();

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			try {
				const result = await getData({
					...paginator,
					gte: startDate,
					lte: endDate,
					filters: { ...filters },
					auth0Id: user?.sub
				});
				setData(result.data);
				setTotal(result.count);

				if (!dateFilters) {
					setTotal(result.count);
				}
			} catch (error) {
				setData([]);
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, [paginator, filters, getData, startDate, endDate]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			try {
				const result = await getData({
					limit: 0,
					offset: 0,
					getAggregations: true,
					gte: startDate,
					lte: endDate,
					filters: { ...filters },
					auth0Id: user?.sub
				});
				if (paginator.offset == 0) {
					setCount(result.count);
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, [startDate, endDate, filters]);

	return (
		<Paper elevation={3}>
			<Toast {...toast} setState={setToast} />
			<Grid container justify="space-between" alignItems="center">
				<Grid item>
					<Grid
						spacing={2}
						container
						justify="space-between"
						alignItems="center"
					>
						<Grid item>
							<Typography className={classes.typo} variant="h5">
								Total Records: {paginator.offset + 1} to{" "}
								{paginator.offset + paginator.limit < total
									? paginator.offset + paginator.limit
									: total}{" "}
								of {""}
								{total}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Paginator
						totalRecords={total}
						paginator={paginator}
						paginatorHandler={setPaginator}
					/>
				</Grid>
			</Grid>

			<form noValidate>
				<TextField
					id="startDate"
					label="Start Date"
					type="datetime-local"
					defaultValue={sd}
					className={classes.date}
					onChange={(e) => setStartdate(e.target.value)}
					InputLabelProps={{
						shrink: true
					}}
				/>
				<TextField
					id="endDate"
					label="End Date"
					type="datetime-local"
					defaultValue={ed}
					className={classes.date}
					onChange={(e) => setEndDate(e.target.value)}
					InputLabelProps={{
						shrink: true
					}}
				/>

				{
					<>
						<Typography className={classes.typo} variant="h6">
							Total :{""}
							<Chip
								key={count}
								label={count}
								style={{ margin: "0.1rem" }}
								color="primary"
								size="small"
							/>
						</Typography>
					</>
				}
			</form>

			<TableContainer>
				<PerfectScrollbar>
					<Table className={classes.table}>
						<TableHead className={classes.tableHeader}>
							<TableRow>
								{headers.map((header) => {
									return (
										<TableHeaderCell
											key={header.value}
											filter={filters}
											filterHandler={setFilters}
											className={classes.tableCell}
											filterType={header.filterType}
											getOptionsData={header.getOptionsData}
											initialOptions={header.options}
											initialFilter={initialFilter}
										>
											{header}
										</TableHeaderCell>
									);
								})}
								<TableCell className={classes.tableCell}>
									{<Typography>Action</Typography>}
								</TableCell>
							</TableRow>
						</TableHead>
						{loading && (
							<div
								className={
									data.length
										? classes.loaderPosition
										: classes.loaderPositionEmpty
								}
							>
								<Loader height={50} />
							</div>
						)}
						<TableBody className={loading ? classes.bodyOverlay : ""}>
							{loading && !data.length && (
								<TableRow>
									<TableCell
										colSpan={headers.length + 1}
										className={classes.loaderRow}
									/>
								</TableRow>
							)}

							{data &&
								data.map((row, index) => (
									<>
										<TableRow key={index}>
											{headers.map((header, headerIndex) => {
												// Transform Values
												if (header.type === "date") {
													if (row[header.value]) {
														row[header.value] = moment(
															row[header.value]
														).format("DD MMM, YYYY h:mm:ss A");
													}
												}

												return (
													<TableCell
														className={classes.tableCell}
														key={headerIndex}
													>
														{row[header.value]}
													</TableCell>
												);
											})}
											{
												<TableCell className={classes.tableCell}>
													<Box display="flex" flexDirection="row">
														{onEdit && (
															<IconButton
																color="primary"
																variant="contained"
																size="medium"
																onClick={() => onEdit(data[index])}
															>
																<EditIcon />
															</IconButton>
														)}

														{onDetails && (
															<IconButton
																color="primary"
																variant="contained"
																size="medium"
																onClick={() => onDetails(data[index])}
															>
																<img
																	src={detailIcon}
																	className={classes.icon}
																></img>
															</IconButton>
														)}
														{onSuccess && (
															<IconButton
																color="primary"
																variant="contained"
																size="medium"
																onClick={() => onSuccess(data[index])}
															>
																<CheckIcon />
															</IconButton>
														)}
													</Box>
												</TableCell>
											}
										</TableRow>
										{!loading &&
											(!!data.length || (
												<TableRow>
													<TableCell
														className={classes.tableCell}
														colSpan={headers.length + 1}
														align="center"
													>
														<Typography variant="h6">No Data Found</Typography>
													</TableCell>
												</TableRow>
											))}
									</>
								))}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</TableContainer>
			{data.length ? (
				<Paginator
					totalRecords={total}
					paginator={paginator}
					paginatorHandler={setPaginator}
					justify={"flex-end"}
				/>
			) : (
				""
			)}
		</Paper>
	);
};

export default TableWrapper;
