import {
	Typography,
	Button,
	ImageListItem,
	ImageList,
	IconButton,
	useMediaQuery
} from "@material-ui/core";
import { Container, Grid, Stack, Box, Paper } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import makeStyles from "./styles";
import Carousel from "react-material-ui-carousel";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import BedroomChildOutlinedIcon from "@mui/icons-material/BedroomChildOutlined";
import agentImage from "../../assets/defaultImage.jpeg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../core";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { getPropertyDetailsById } from "../../services/propertyForm";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";

function srcset(image, width, height, rows = 1, cols = 1) {
	return {
		src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
		srcSet: `${image}?w=${width * cols}&h=${
			height * rows
		}&fit=crop&auto=format&dpr=2 2x`
	};
}

export const MLSDetails = React.forwardRef((props, ref) => {
	const classes = makeStyles();
	const { state } = useLocation();
	const { userPropertyId } = useParams();
	const [propertyData, setPropertyData] = useState(state ? state.data : []);
	const { user } = useAuth0();
	let componentRef = useRef();
	const theme = useTheme();
	const showCarousel = useMediaQuery(theme.breakpoints.down("sm"));
	const changeColumn = useMediaQuery(theme.breakpoints.down("md"));

	useEffect(() => {
		const getProperty = async () => {
			try {
				const response = await getPropertyDetailsById({
					auth0Id: user?.sub,
					id: userPropertyId
				});

				const { MLSData } = response.data.data.data[0]["property_form"];
				setPropertyData(JSON.parse(MLSData));
			} catch (e) {}
		};
		if (!state) {
			getProperty();
		} else {
			return;
		}
	}, []);

	return (
		<div>
			<Helmet>
				{
					<title>
						{`${
							propertyData?.UnparsedAddress
								? propertyData?.UnparsedAddress
								: "Dashboard"
						}`}
						| Alokee
					</title>
				}
			</Helmet>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				className={classes.buttonContainer}
			>
				<Grid item xs={2} sm={2} lg={2} xl={2} md={2}>
					<ReactToPrint content={() => componentRef}>
						<PrintContextConsumer>
							{({ handlePrint }) => (
								<IconButton
									color="primary"
									onClick={() => {
										handlePrint();
									}}
								>
									<CloudDownloadIcon />
									<Typography
										style={{
											marginLeft: "10px"
										}}
										color="textSecondary"
									>
										Download
									</Typography>
								</IconButton>
							)}
						</PrintContextConsumer>
					</ReactToPrint>
				</Grid>
			</Grid>
			<div ref={(el) => (componentRef = el)} className={classes.mainContainer}>
				<Container maxWidth="lg" className={classes.scrollableContainer}>
					{propertyData.constructor === Object ? (
						<Grid
							container
							direction={{
								sm: "row",
								xs: "row",
								lg: "row",
								xl: "row",
								md: "row"
							}}
							justifyContent="center"
							alignItems="flex-start"
							spacing={2}
							style={{ marginBottom: 100, marginLeft: -39 }}
						>
							{showCarousel ? (
								<Grid item xs={12} sm={6} lg={6} xl={6} md={6}>
									<Carousel height={400} navButtonsAlwaysVisible={true}>
										{propertyData?.Media?.map((img, index) => {
											return (
												<img
													src={img.MediaURL}
													alt="property-images"
													style={{
														borderRadius: "8px"
													}}
													className={classes.image}
												/>
											);
										})}
									</Carousel>
								</Grid>
							) : (
								<Grid item xs={12} sm={6} lg={6} xl={6} md={6}>
									<Box>
										<ImageList rowHeight={200} gap={1}>
											{propertyData?.Media?.map((item, key) => {
												const cols = key == 0 || changeColumn ? 2 : 1;
												const rows = key == 0 || changeColumn ? 2 : 1;

												return (
													<ImageListItem
														key={item.MediaURL}
														cols={cols}
														rows={rows}
													>
														<img
															className={classes.image}
															{...srcset(item.MediaURL, 250, 200, rows, cols)}
															alt={item?.title}
															loading="lazy"
														/>
													</ImageListItem>
												);
											})}
										</ImageList>
									</Box>
								</Grid>
							)}
							<Grid item xs={12} sm={6} lg={6} xl={6} md={6}>
								<Typography className={classes.heading}>
									{propertyData.UnparsedAddress}
								</Typography>
								<Typography className={classes.subtext}>
									{propertyData.City}, {propertyData.StateOrProvince},
									{propertyData.PostalCode}
								</Typography>
								<Stack
									sx={{
										flexDirection: { sm: "row", xs: "column" },
										justifyContent: "space-between",
										marginTop: "20px",
										marginBottom: "30px"
									}}
								>
									<Stack
										sx={{
											flexDirection: "row",
											marginBottom: { sm: "0", xs: "20px" }
										}}
									>
										{propertyData.BedroomsTotal && (
											<Stack
												sx={{
													flexDirection: "row",
													marginRight: { sm: "40px", xs: "20px" }
												}}
											>
												<BedroomChildOutlinedIcon className={classes.icon} />
												<Typography className={classes.amnities}>
													{propertyData.BedroomsTotal} Bed
												</Typography>
											</Stack>
										)}
										{propertyData.BathroomsFull && (
											<Stack
												sx={{
													flexDirection: "row",
													marginRight: { sm: "40px", xs: "20px" }
												}}
											>
												<BathtubOutlinedIcon className={classes.icon} />
												<Typography className={classes.amnities}>
													{parseInt(propertyData.BathroomsFull) + " "}
													Baths
												</Typography>
											</Stack>
										)}
									</Stack>
									<Typography className={classes.price}>
										${propertyData.ListPrice.toLocaleString()}
									</Typography>
								</Stack>

								<Typography
									className={[classes.heading, classes.subheading]}
									style={{ marginTop: "10px" }}
								>
									Description
								</Typography>
								<div className={classes.description}>
									<Typography
										style={{
											color: "#000",
											textAlign: "left"
										}}
										className={[classes.heading, classes.text]}
									>
										{propertyData.PublicRemarks}
									</Typography>
								</div>

								<Typography
									style={{ marginTop: "20px" }}
									className={[classes.heading, classes.subheading]}
								>
									Home Facts
								</Typography>

								<Grid
									container
									columnSpacing={4}
									style={{ marginTop: "10px", marginBottom: "30px" }}
								>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>
												Status
											</Typography>
											<Typography
												className={classes.propDet}
												style={{
													fontWeight: 400
												}}
											>
												{propertyData.MlsStatus}
											</Typography>
										</Stack>
									</Grid>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>
												Year Built
											</Typography>
											<Typography
												style={{
													fontWeight: 400
												}}
												className={classes.propDet}
											>
												{propertyData.YearBuilt}
											</Typography>
										</Stack>
									</Grid>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>
												Property Type
											</Typography>
											<Typography
												style={{
													fontWeight: 400,
													textAlign: "end"
												}}
												className={classes.propDet}
											>
												{propertyData.PropertySubType}
											</Typography>
										</Stack>
									</Grid>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>Style</Typography>
											<Typography
												style={{
													fontWeight: 400
												}}
												className={classes.propDet}
											>
												{propertyData.ArchitecturalStyle.map((data) =>
													data.toString()
												)}
											</Typography>
										</Stack>
									</Grid>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>MLS#</Typography>
											<Typography
												style={{
													fontWeight: 400
												}}
												className={classes.propDet}
											>
												{propertyData.ListingId}
											</Typography>
										</Stack>
									</Grid>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>
												Lot Size
											</Typography>
											<Typography
												style={{
													fontWeight: 400
												}}
												className={classes.propDet}
											>
												{propertyData.LotSizeSquareFeet} Sq.Ft.
											</Typography>
										</Stack>
									</Grid>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>
												Stories
											</Typography>
											<Typography
												style={{
													fontWeight: 400
												}}
												className={classes.propDet}
											>
												{propertyData.StoriesTotal}
											</Typography>
										</Stack>
									</Grid>
									<Grid item sm={6} xs={12} marginTop="20px">
										<Stack
											sx={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between"
											}}
										>
											<Typography className={classes.propDet}>
												PropertyType
											</Typography>
											<Typography
												style={{
													fontWeight: 400
												}}
												className={classes.propDet}
											>
												{propertyData.PropertyType}
											</Typography>
										</Stack>
									</Grid>
								</Grid>

								<Typography className={[classes.heading, classes.subheading]}>
									Property Details
								</Typography>
								<Box sx={{ marginTop: "30px" }}>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>
											Homeowners Association, School Information
										</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													HOA Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														{propertyData.AssociationAmenities.length > 0
															? "Has"
															: "No"}{" "}
														Home Owners Association
													</li>
													{propertyData.AssociationAmenities.length > 0 && (
														<>
															<li className={classes.listItems}>
																Association Fee: {propertyData.AssociationFee}
															</li>
															<li className={classes.listItems}>
																Association amenities:{" "}
																<ul
																	style={{
																		marginLeft: "20px",
																		marginTop: "10px"
																	}}
																>
																	{propertyData.AssociationAmenities.map(
																		(data) => {
																			return (
																				<li className={classes.listItems}>
																					{data}
																				</li>
																			);
																		}
																	)}
																</ul>
															</li>
														</>
													)}
												</ul>
											</div>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													School Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														High School District:{" "}
														{propertyData.HighSchoolDistrict
															? propertyData.HighSchoolDistrict
															: "None"}
													</li>
													<li className={classes.listItems}>
														Elementary School:{" "}
														{propertyData.ElementarySchool
															? propertyData.ElementarySchool
															: "None"}
													</li>
													<li className={classes.listItems}>
														Middle School:{" "}
														{propertyData.MiddleOrJuniorSchool
															? propertyData.MiddleOrJuniorSchool
															: "None"}
													</li>
													<li className={classes.listItems}>
														High School:{" "}
														{propertyData.HighSchool
															? propertyData.HighSchool
															: "None"}
													</li>
												</ul>
											</div>
										</Stack>
									</Paper>
								</Box>
								<Box>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>
											Interior Features
										</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Bathroom Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														# of Full Bathrooms {propertyData.BathroomsFull}
													</li>
													<li className={classes.listItems}>
														# of Half Bathrooms {propertyData.BathroomsHalf}
													</li>
												</ul>
											</div>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Laundry Information
												</Typography>
												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Features:
														{propertyData.LaundryFeatures.length > 0 ? (
															<ul
																style={{
																	marginLeft: "20px",
																	marginTop: "10px"
																}}
															>
																{propertyData.LaundryFeatures.map((data) => (
																	<li className={classes.listItems}>{data}</li>
																))}
															</ul>
														) : (
															<li className={classes.listItems}>None</li>
														)}
													</li>
												</ul>
											</div>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Interior Features
												</Typography>
												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Features:
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData.InteriorFeatures.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
													<li className={classes.listItems}>
														Fireplaces:
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData.FireplaceFeatures.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
													<li className={classes.listItems}>
														Roof:
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData.Roof.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
													<li className={classes.listItems}>
														flooring:
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData.Flooring.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>

													<li className={classes.listItems}>
														Cooling:
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData?.Cooling?.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
													<li className={classes.listItems}>
														Heating:
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData?.Heating?.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
												</ul>
											</div>

											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Equipments
												</Typography>
												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													{propertyData.ConstructionMaterials.length > 0 ? (
														<li className={classes.listItems}>
															<ul
																style={{
																	marginLeft: "20px",
																	marginTop: "10px"
																}}
															>
																{propertyData.ConstructionMaterials.map(
																	(data) => (
																		<li className={classes.listItems}>
																			{data}
																		</li>
																	)
																)}
															</ul>
														</li>
													) : (
														<li className={classes.listItems}>None</li>
													)}
												</ul>
											</div>
										</Stack>
									</Paper>
								</Box>
								<Box>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>
											Parking / Garage
										</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Parking Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Description: {propertyData.ParkingFeatures}
													</li>
												</ul>
											</div>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Garage Information
												</Typography>
												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														{propertyData.GarageSpaces > 0 ? "Has " : "No"}{" "}
														Garage
													</li>
													{propertyData.GarageSpaces > 0 && (
														<li className={classes.listItems}>
															# of Garage Spaces{" "}
															{Math.floor(propertyData.GarageSpaces)}
														</li>
													)}
												</ul>
											</div>
										</Stack>
									</Paper>
								</Box>
								<Box>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>
											Exterior Features
										</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Pool Information:
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData.PoolFeatures.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
												</ul>
											</div>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Exterior Features
												</Typography>

												{propertyData.ExteriorFeatures.length > 0 ? (
													<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
														<li className={classes.listItems}>
															Features:{" "}
															<ul
																style={{
																	marginLeft: "20px",
																	marginTop: "10px"
																}}
															>
																{propertyData.ExteriorFeatures.map((data) => (
																	<li className={classes.listItems}>{data}</li>
																))}
															</ul>
														</li>
													</ul>
												) : (
													<li className={classes.listItems}>None</li>
												)}
											</div>
										</Stack>
									</Paper>
								</Box>
								<Box>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>Taxes</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Tax Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Annual Amount:
														{propertyData.TaxAnnualAmount
															? "$" + propertyData.TaxAnnualAmount
															: "Not Mentioned"}
													</li>
												</ul>
											</div>
										</Stack>
									</Paper>
								</Box>
								<Box>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>
											{" "}
											Property / Lot Details
										</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Lot Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Lot Size Area: {propertyData.LotSizeSquareFeet}{" "}
														Square Feet
													</li>
													<li className={classes.listItems}>
														Lot Size Description:{" "}
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData.LotFeatures.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
												</ul>
											</div>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Property Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Direction Faces: {propertyData.Directions}
													</li>
													<li className={classes.listItems}>
														Foundation Details:{" "}
														<ul
															style={{ marginLeft: "20px", marginTop: "10px" }}
														>
															{propertyData.FoundationDetails.map((data) => (
																<li className={classes.listItems}>{data}</li>
															))}
														</ul>
													</li>
												</ul>
											</div>
										</Stack>
									</Paper>
								</Box>
								<Box>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>
											{" "}
											Listing Information
										</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<Typography className={classes.amnities}>
													Listing Information
												</Typography>

												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Listing ID: {propertyData.ListingId}
													</li>
												</ul>
											</div>
										</Stack>
									</Paper>
								</Box>
								<Box>
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(228, 228, 228, 0.2)",
											border: "1px solid #d9d9d9",
											borderBottom: "none",
											paddingLeft: "15px",
											borderRadius: 0
										}}
									>
										<Typography className={classes.amnities}>
											{" "}
											Other property information
										</Typography>
									</Paper>
									<Paper
										elevation={0}
										sx={{
											border: "1px solid #d9d9d9",
											backgroundColor: "transparent",
											paddingLeft: "40px",
											borderRadius: 0
										}}
									>
										<Stack
											sx={{
												flexDirection: {
													sm: "row",
													xs: "column"
												},
												flexWrap: "wrap",
												marginTop: "12px"
											}}
										>
											<div style={{ width: "50%" }}>
												<ul style={{ marginLeft: "20px", marginTop: "10px" }}>
													<li className={classes.listItems}>
														Parcel Number: {propertyData?.ParcelNumber}
													</li>
													<li className={classes.listItems}>
														Zoning: {propertyData?.Zoning}
													</li>
												</ul>
											</div>
										</Stack>
									</Paper>
									<Stack
										flexDirection={"row"}
										margin="30px 0"
										alignItems={"center"}
									>
										<Box
											sx={{
												maxWidth: "90px",
												maxHeight: "90px",
												height: "fit-content",
												width: "fit-content",
												overflow: "hidden",
												marginRight: "15px"
											}}
										>
											<img
												src={agentImage}
												alt="seller-image"
												className={classes.agentImage}
											/>
										</Box>
										<div>
											<Typography className={classes.propDet}>
												Listed by {propertyData.ListAgentFirstName}{" "}
												{propertyData.ListAgentLastName}
											</Typography>
											{propertyData.ListOfficeKey && (
												<Typography className={classes.propDet}>
													Office ID {propertyData.ListOfficeMlsId}
												</Typography>
											)}
										</div>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					) : (
						<Stack className={classes.loader}>
							<Loader type={"Circles"}></Loader>
						</Stack>
					)}
				</Container>
			</div>
		</div>
	);
});
