import React from "react";
import { Container, makeStyles, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
	return {
		faqsTitleHeading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		},

		newsLetter: {
			background:
				"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			height: "290px",
			marginTop: 30,
			paddingTop: 30
		},

		blogPara: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem !important"
			},
			marginInlineStart: 20
		},
		blogParaHeader: {
			marginBottom: "5px",
			listStylePosition: "inside",
			textIndent: "-1em",
			paddingLeft: "1em"
		},
		divider: {
			borderTop: "3px solid #00284c"
		}
	};
});

function BlogSix() {
	const classes = useStyles();

	return (
		<div style={{ width: "100%" }}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.faqsTitleHeading}>
							How much will this house sell for?{" "}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							So, you've dound a house you want to buy. How do you know how much
							to offer?
						</p>
					</Grid>

					<Grid item xs={12}>
						<p className={classes.blogPara}>
							First, if the home is newly listed (0-5 days market time), there’s
							a good chance there will be multiple offers on the home. To see if
							there will be, you can directly call the listing agent or seller
							or have a real estate agent call the listing agent for you.
							Usually, by asking “do you expect there to be multiple offers?”
							you will get a good idea of whether or not there will be. If there
							will be, you’ll likely have to pay list price or higher. If not,
							you may be able to buy the home at list price or lower. Of course
							if the home has been listed and has “sat” on the market more than
							about five days, you may be able to get the home for under list
							price (the more market time, the better if you are the buyer. More
							market time means more likelihood you will buy the home under list
							price).
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Once you have an educated guess if there’s multiple offers or not,
							use your favorite estimator to ballpark the price you will have to
							pay. We love Zillow and Redfin price estimators; we think they’re
							really powerful for “ballparking” an offer. Note, as soon as a
							property is listed, it’s likely the estimators will “magically” be
							very close to the list price; this is to say that at the time of
							writing this blog, most estimators adjust to match the list price.
							This doesn’t really help you get precise about presenting a
							winning offer.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							So, in addition to estimators, you should produce a comparative
							market analysis or a “C.M.A” for the home you are looking to buy.
							This means finding three to six homes like the one you want (on
							your favorite real estate search site) that have sold and
							comparing them so you have a more precise idea of what the home
							you want will sell for. A real estate agent can do this for you,
							but they may ask you to sign an agency agreement that binds you to
							work with them. As an alternative, with Alokee, you can request a
							no-obligation C.M.A. Regardless of how you get a C.M.A. it may not
							help you write the winning offer.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							If a home has a reasonable list price (it seems reasonable given
							your C.M.A.) the BEST way to know what to offer is the analytic
							approach of studying list price/sold price ratio for comparable
							homes. See the image below. It’s a closed sale with list price and
							sold price data, including a PERCENTAGE OVER LIST PRICE.
						</p>
					</Grid>
					<Grid item>
						<img
							height={550}
							width={750}
							src="https://lh7-us.googleusercontent.com/zkI3CxfS2uXp-J4TZ87NA3tx2Om18LU-2SPTkizDZQSXwsTH6dd9k-MQdczsIOvQECOlKbSQVvnpEaJOwwn63hZwBeawuj_XZcDnHwInR_C83tRJgkReQxax88ldXNjPj6T8TQOwkaWKP57w4fkzs1g"
						></img>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							This percentage over list price - 11.5% in this case - can be
							found for comparable homes. This is the BEST way to know how much
							over (or under) you will have to offer/pay to get the home you
							want. You NEED to know this percentage for the neighborhood you
							are shopping in.
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							Not only does it help you understand what to offer, it helps you
							understand what neighborhoods you can actually afford. In the
							example above, even though the home was listed @ $1,299,999, it
							sold for $1,450,000. This is not a “$1.2m neighborhood” it’s a
							“$1.4m neighborhood” and you should not be fooled into thinking
							other homes listed at about $1,299,999 will sell for that… they
							will sell much higher!
						</p>
					</Grid>
					<Grid item xs={12}>
						<p className={classes.blogPara}>
							What we tried to show you in this article was three ways to
							understand what you should offer: agents, C.M.A.s and - most
							importantly - list price/sold price in the neighborhood you are
							shopping in. You can always message us at www.alokee.com if you
							want more insider tips to help you DIY real estate 🙂
						</p>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default BlogSix;
