import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Box, Grid, Avatar, Typography, makeStyles } from "@material-ui/core";
import { Button, Toast, TextField, SingleSelect } from "../core";
import userIcon from "../../assets/userIcon.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createUser, getUserProfile } from "../../services/users";
import { useAuth0 } from "@auth0/auth0-react";
import FileUpload from "../core/FileUpload";
import Footer from "../../components/footer/footer";
import ImagePicker from "../UploadAvatar/ImagePicker";
import { useUserDispatch } from "../../context/UserContext";
import { sendEmailToAlokee } from "../../services/propertyForm";

const initialValues = {
	firstName: "",
	lastName: "",
	email: "",
	address: "",
	state: "",
	city: "",
	zipCode: "",
	age: "",
	contactNumber: "",
};

const validationSchema = Yup.object().shape({
	firstName: Yup.string().required("first Name is required"),
	age: Yup.number()
		.min(18, "You must be at least 18 years old")
		.required("Age is required"),
	contactNumber: Yup.string()
		.matches(
			/^(?:\(([0-9]{3})\)|[0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
			"Phone number is not valid"
		)
		.required("Contact number is required"),
	lastName: Yup.string().required("last Name is required"),
	address: Yup.string().required("street is required"),
	state: Yup.string().required("state is required"),
	city: Yup.string().required("city is required"),
	zipCode: Yup.string()
		.matches(/^\d{5}$/, "Must be 5 digits")
		.required("ZIP code is required"),
});

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(7)
	},
	avatar: {
		backgroundColor: theme.palette.primary.main,
		minWidth: 120,
		minHeight: 120
	}
}));

const CompleteProfile = () => {
	const classes = useStyles();
	const { user, isAuthenticated, isLoading } = useAuth0();
	const userDispatch = useUserDispatch();
	let [searchParams, setSearchParams] = useSearchParams();
	const envelopeStatus = searchParams.get("envelopeStatus");
	const signerRole = searchParams.get("signerRole");
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [image, setImage] = useState(null);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const [isProfileCompleted, setIsProfileCompleted] = useState(false);

	useEffect(async () => {
		if (!isLoading && isAuthenticated) {
			try {
				const userProfileDetails = await getUserProfile({ auth0Id: user.sub });
				if (userProfileDetails?.data?.data?.data?.length) {
					setIsProfileCompleted(
						userProfileDetails.data.data.data[0].isProfileCompleted
					);
					let profileData = userProfileDetails.data.data.data[0];
					profileData.userId = userProfileDetails.data.data.data[0].id;
					delete profileData["id"];
					userDispatch({
						type: "UPDATE_USER",
						payload: { data: profileData }
					});
					navigate(
						`/app/dashboard?filter=Offers${
							envelopeStatus ? "&envelopeStatus=" : ""
						}${envelopeStatus ? envelopeStatus : ""}${
							signerRole ? "&signerRole=" : ""
						}${signerRole ? signerRole : ""}`,
						{
							replace: true
						}
					);
					setLoading(false);
				} else {
					setIsProfileCompleted(false);
					setLoading(false);
				}
			} catch (e) {
				setIsProfileCompleted(false);
				setLoading(false);
			}
		}
	}, [isLoading, isAuthenticated]);

	const onSubmit = async (values) => {
		try {
			setLoading(true);
			const data = new FormData();
			const modifiedValues = {
				...values,
				isProfileCompleted: true,
				auth0Id: user?.sub,
				email: user.email,
				age: String(values.age),
				contactNumber: cleanPhoneNumber(values.contactNumber)
			};
			if (image) {
				data.append("photo", image);
			}
			data.append("otherValues", JSON.stringify(modifiedValues));
			const resp = await createUser(data);
			if (resp.data.success && resp.data.message != "User Already Exists.") {
				setIsProfileCompleted(true);
				await sendEmailToAlokee({
					details: {
						from: {
							email: "offer@alokee.com",
							name: "Alokee"
						},
						subject: "New user Registration."
					},
					dynamicTemplateData: {
						name: values.firstName,
						email: user.email,
						message: "This new user has just signed up on Alokee."
					}
				});
				setLoading(false);
				window.location.reload();
				navigate(
					`/app/dashboard?filter=Offers${
						envelopeStatus ? "&envelopeStatus=" : ""
					}${envelopeStatus ? envelopeStatus : ""}${
						signerRole ? "&signerRole=" : ""
					}${signerRole ? signerRole : ""}`,
					{ replace: true }
				);
				return;
			} else {
				setToast({
					state: "open",
					message: resp.data.message,
					type: "error"
				});
				setLoading(false);
			}
		} catch (e) {
			setToast({
				state: "open",
				message: "Something went wrong! Check your network please.",
				type: "error"
			});
			setLoading(false);
		}
	};

	const cleanPhoneNumber = (phoneNumber) => {
		return phoneNumber.replace(/\D/g, ""); // Remove any non-digits
	};

	return (
		<>
			<Toast {...toast} setState={setToast} />
			<Box className={classes.root} maxWidth="xl" maxHeight="xl">
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					<Grid
						spacing={2}
						container
						item
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<ImagePicker setImage={setImage} imageSource={user?.picture} />
						</Grid>
						<Grid item>
							<Typography variant="h5">
								{loading
									? " Checking your Profile..."
									: "Please Complete Your Profile"}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						item
						direction="column"
						justifyContent="center"
						alignItems="center"
						xs={12}
						sm={12}
						lg={8}
						md={8}
					>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
							render={({ values, errors, handleSubmit }) => (
								<Form>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="firstName"
												label="First Name"
												type="text"
												placeholder={user?.nickname}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="lastName"
												label="Last Name"
												type="text"
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="age"
												label="Age"
												type="number"
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField
												name="contactNumber"
												label="Contact Number"
												type="text"
												placeholder="(XXX) XXX-XXXX"
												required
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={8}>
											<TextField
												name="address"
												label="Address Street"
												type="text"
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField name="city" label="City" type="text" />
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField name="state" label="State" type="text" />
										</Grid>
										<Grid item xs={12} sm={4} md={4}>
											<TextField name="zipCode" label="Zip Code" type="text" />
										</Grid>
										
									</Grid>
									<Box py={2}>
										<Button
											onClick={() => {
												handleSubmit();
											}}
											loading={loading || undefined}
										>
											{"Proceed"}
										</Button>
									</Box>
								</Form>
							)}
						></Formik>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default CompleteProfile;
