import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated } = useAuth0();
	return isAuthenticated ? (
		<Navigate to={{ pathname: "/app/dashboard" }} replace />
	) : (
		<Component {...rest} />
	);
};

export default PublicRoute;
