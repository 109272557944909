import React from "react";
import { Container, makeStyles, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
	return {
		faqsTitleHeading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		},

		newsLetter: {
			background:
				"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			height: "290px",
			marginTop: 30,
			paddingTop: 30
		},

		blogPara: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`,
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem !important"
			}
		},
		blogParaHeader: {
			marginBottom: "5px",
			listStylePosition: 'inside',
			textIndent: '-1em',
			paddingLeft: '1em'
		},
		divider: {
			borderTop: "3px solid #00284c"
		}
	};
});

function BlogTwo() {
	const classes = useStyles();

	return (
		<div style={{ width: "100%" }}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.faqsTitleHeading}>
							Is Your New Home Going to Cost More than It's Worth?
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							Home Inspection is an important part of the home-buying process.
							It saves prospective buyers money in the long run by letting them
							know of any issues that might be associated with the property.
							There are other ways to know if the home is going to cost the
							buyer more than it's worth without a home inspector!
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>
							1. Visible Foundational and Structural Issues
						</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									Visible damages can range from cracks in the foundation and
									walls, tilting/bulging walls, sagging floors, soil pulling
									away from the walls of the house to uneven gaps around windows
									and doors. The structure of the home is made up of the
									foundation and other structures such as load-bearing walls. If
									the foundation is defective, reconstruction or transfer of the
									soil-supported home to a deeper foundation is likely required.
									It can be expensive and difficult to correct.
								</Typography>
							</li>
						</ul>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>
							2. Harmful and Hazardous Chemicals
						</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									Older homes tend to contain harmful and hazardous materials in
									their construction like lead paint or asbestos(used in
									insulation and fireproofing materials). One common material
									that contained asbestos in the past are roof shingles and
									siding. A home inspector can identify this. Asbestos can be
									fatal so this is very important for the home buyer to
									consider. Removal of asbestos-containing material or
									lead-based paint can be very expensive depending on price per
									square foot, size and location.
								</Typography>
							</li>
						</ul>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>3. Old Roof/Windows</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									It is necessary to know how long the home buyer has before the
									roofs or windows of the house need replacement. Items that
									provide protection to the home from the outside often need to
									be looked into and taken care of immediately to evade more
									damage. If left in their original state, they can cause more
									damage like rot, mold, mildew and structural issues. Repairs
									such as this can easily drive costs into the six-figure range.
								</Typography>
							</li>
						</ul>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>4. Water Damage</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									Signs of water damage, dampness in walls/ceiling or presence
									of mold is indicative of a much bigger underlying issue that
									may require the home to be treated by professionals in
									addition to the repairs that would need to be done.
								</Typography>
							</li>
						</ul>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>5. Faulty Plumbing</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									Outdated or corroded plumbing systems, specifically in older
									homes, would require a complete replacement to prevent other
									problems such as leaks, water damage and other health hazards.
									In this case, costs can also vary based on accessibility to
									pipes.
								</Typography>
							</li>
						</ul>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>6. Defective Insulation</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									Whether the home has poor energy efficiency or not enough
									insulation, both these cases can be costly to fix. Homes
									without enough insulation or energy-efficient systems will
									result in higher utility bills and remediating these issues
									can be expensive.
								</Typography>
							</li>
						</ul>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>7. Infestation</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									Bug and rodent infestation can also be an expensive fix.
									Sunken areas in walls, discarded shells or wings could be
									signs of a termite infestation. Termite damage and other
									infestations should be promptly addressed to avoid further
									damage to the structure of the home and these repairs can be
									extensive.
								</Typography>
							</li>
						</ul>
					</Grid>

					<Grid item xs={12}>
						<li className={`${classes.blogPara} ${classes.blogParaHeader}`}>8. Dated Electrical System</li>
						<ul style={{ marginInlineStart: 20 }}>
							<li>
								<Typography className={classes.blogPara}>
									Often older homes with outdated and inadequate electrical
									systems may need rewiring to meet current safety standards.
									Most outdated electrical systems are easy enough to identify
									with knob and tube or cloth-covered wires.
								</Typography>
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.divider}></div>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							As you can see, there are a few ways to buy a house without using
							a traditional buyer’s agent. If you have any questions about
							buying a house - with or without a real estate agent. For more
							information, please login on our website or follow us on social
							media.
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default BlogTwo;
