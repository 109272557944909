import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export default function ChatList({ chatData, setChats, lastMessage, setLoadChats }) {
	const [selected, setSelected] = React.useState("");
	const handleListItemClick = (event, index) => {
		setSelected(index);
	};
	return chatData.map((data, key) => {
		return (
			<List>
				<ListItem
					alignItems="flex-start"
					selected={selected === key}
					button={true}
					onClick={(event) => {
						setChats(data);
						handleListItemClick(event, key);
					}}
				>
					<ListItemAvatar>
						<Avatar alt="Remy Sharp" src={data.photo} />
					</ListItemAvatar>
					<ListItemText
						primary={data.firstName + " " + data.lastName}
						secondary={
							<React.Fragment>
								<Typography
									sx={{ display: "inline" }}
									component="span"
									variant="body2"
									color="text.primary"
								>
									{data.email}
								</Typography>
								<Typography variant="body1" color="primary">
									{lastMessage?.text?.length &&
									lastMessage?.from == data.firstName
										? lastMessage?.text
										: ""}
								</Typography>
							</React.Fragment>
						}
					/>
				</ListItem>
				<Divider />
			</List>
		);
	});
}
