import React from 'react'
import { Container, makeStyles, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
	return {
		faqsTitleHeading: {
			color: "#000",
			fontSize: "2.5rem !important",
			fontFamily: theme.typography.fontFamily,
			fontWeight: "700 !important",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.75rem !important"
			}
		},

		newsLetter: {
			background:
				"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
			height: "290px",
			marginTop: 30,
			paddingTop: 30
		},

		blogPara: {
			color: "#000",
			fontSize: "1.25rem !important",
			fontFamily: `${theme.typography.fontFamily} !important`,
			fontWeight: `${theme.typography.fontWeightRegular} !important`,
		},
		blogParaListItem: {
			marginBottom: "15px",
			listStylePosition: 'inside',
			textIndent: '-1em',
			paddingLeft: '1em'
		},
		divider: {
			borderTop: "3px solid #00284c"
		}
	};
});

function BlogOne() {
	const classes = useStyles();

	return (
		<div style={{ width: "100%" }}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}
					flexDirection="column"
					alignItems={"center"}
					justifyContent={"center"}
				>
					<Grid item xs={12}>
						<Typography className={classes.faqsTitleHeading}>
							How to Buy a House Without a Real Estate Agent
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							Many prospective home buyers - at least one out of every five,
							according to recent surveys - state that they would like to be
							able to buy a house without using a real estate agent. There are
							very good reasons why some people wish to buy without using a
							traditional buyer’s agent - not the least of which is the
							opportunity to save thousands in realtor fees!
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							You may be the type of person who could buy a home without an
							agent. If you are thinking about doing that, you need to
							understand the steps of the transaction process, and the
							information and actions you need to take at each step.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.divider}></div>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							Every real estate transaction has three basic steps:
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<ul style={{ marginInlineStart: 20 }}>
							<li className={`${classes.blogParaListItem} ${classes.blogPara}`}>
								1. A deal must be reached (“Coming to terms”).
							</li>
							<li className={`${classes.blogParaListItem} ${classes.blogPara}`}>
								2. The deal must be in writing (“Committed to a Contract”).
							</li>
							<li className={`${classes.blogParaListItem} ${classes.blogPara}`}>
								3. The deal must be “Closed”.
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							So, no matter what, you will need to complete these steps.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.divider}></div>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							Let’s assume, for simplicity sake, the sale price of the home is
							$1,000,000. Below are three options for buying a home without a
							real estate agent. Each option varies in the costs for the buyer
							and in the time and effort needed to complete the transaction. :{" "}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							<span style={{ fontWeight: "bold" }}>OPTION 1:</span> Use the
							seller’s agent.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							COST - $15,000 - $30,000
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							TIME: More time intensive than using a traditional buyer's agent
							(the buyer takes on the workload of communicating with the
							seller's agent directly).
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							OW IT WORKS: You (the prospective buyer) find a home, tour it
							using open houses or the seller's agent, and decide to make an
							offer. Most seller's agents will commit your offer to a contract
							for you to deliver to the seller. The seller's agent will help you
							reach a deal, but keep in mind they are a professional negotiator
							working for the seller, and thus have a duty to represent the
							seller's interests first and foremost. Once you have come to
							terms, the seller's agent will process the closing through a Title
							and Escrow company.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							The seller's agent will have lender referrals, inspector
							referrals, and contractor referrals, but keep in mind these
							vendors all have working relationships with the seller's agent
							(who, of course, represents the seller). You will have to use the
							seller's agent to gain access to the property for any additional
							showings, inspections, or walk throughs.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							<span style={{ fontWeight: "bold" }}>
								If you are comfortable negotiating with a seller’s agent to
								determine a sale price and negotiate inspection remedies, this
								is a good option for you. The seller’s agent is motivated by the
								additional commission ($15,000 - $30,000) they will make if you
								have them commit the deal to writing.
							</span>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							<span style={{ fontWeight: "bold" }}>OPTION 2:</span> Use an
							attorney.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							COST - $2,000-$10,000
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							TIME: Usually significantly more time-intensive than using a
							traditional buyer’s agent (the buyer takes on the time commitment
							to work and communicate back and forth with both the lawyer and
							the seller’s agent).
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							HOW IT WORKS: You (the prospective buyer) find a home, tour it
							using open houses or the seller’s agent, and decide to make an
							offer. You then seek out and find a real estate attorney to assist
							you with the transaction. The attorney will commit your offer to a
							contract for you to deliver to the seller's agent. They will not
							help you reach a deal, so you must be comfortable negotiating with
							the seller’s agent. Once you have come to terms, the seller’s
							agent will process the closing through a Title and Escrow company.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							The attorney helps you ensure the contract is properly written and
							advises you on all legal considerations that are important to
							protect your interests when reaching a deal. But they will not
							have lender referrals, inspector referrals, contractor referrals,
							and will most likely not help you negotiate post-inspection
							remedies. They will not provide additional customer service (aka
							“hand-holding”). You will have to use the seller’s agent to gain
							access to the property for any additional showings, inspections,
							or walk-throughs.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							<span style={{ fontWeight: "bold" }}>
								If you do not need showings, are comfortable with the mechanics
								of a real estate transaction, are comfortable negotiating with a
								seller’s agent, and are willing to pay an attorney for
								additional hours when challenges arise, working directly with a
								real estate attorney may be an acceptable solution for you.
							</span>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							<span style={{ fontWeight: "bold" }}>OPTION 3:</span> Use Alokee.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>COST - $9,900</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							TIME: For buyers who like to search homes on their own, Alokee is
							much less time-intensive than using a traditional buyer’s agent.
							Buyers can submit offers in a matter of minutes on the app, rather
							than communicating back and forth with a buyer’s agent and then
							waiting for the agent to draft and submit the offer.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							HOW IT WORKS: You (the prospective buyer) find a home, tour it
							using open houses or Alokee and decide to make an offer. You can
							make your own offer to the seller agent directly on Alokee’s
							website. You negotiate your own deal through the Alokee’s website,
							with the ability to talk and/or chat with Alokee real estate
							attorneys when you need guidance. Once you have come to terms,
							Alokee will process the closing through a Title and Escrow company
							digitally (your interactions will happen via our site versus
							through phone calls).
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							Alokee has lender referrals, inspector referrals and selected
							contractor referrals in our portal. You can negotiate any
							post-inspection remedies, with the ability to talk and/or chat
							with Alokee real estate attorneys when you need guidance.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							<span style={{ fontWeight: "bold" }}>
								If you prefer doing business digitally, are confident in your
								ability to learn about basic real estate terminology, and don’t
								need a lot of hands-on customer service, Alokee is a great
								option for you.
							</span>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.divider}></div>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.blogPara}>
							As you can see, there are a few ways to buy a house without using
							a traditional buyer’s agent. If you have any questions about
							buying a house - with or without a real estate agent. For more
							information, please login on our website or follow us on social
							media.
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default BlogOne