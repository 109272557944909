import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import routes from "./routes";
import { Auth0ProviderWithNavigate } from "./Auth0-provider-with-navigate";
import { UserProvider } from "./context/UserContext";
import Mobile from "./pages/Mobile";

const App = () => {
	const routing = useRoutes(routes);
	const isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);

	return (
		<Auth0ProviderWithNavigate>
			<UserProvider>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					{routing}
				</ThemeProvider>
			</UserProvider>
		</Auth0ProviderWithNavigate>
	);
};

export default App;
