import axios from "axios";
import Cookies from "universal-cookie";
import axiosInstance from ".";
const cookies = new Cookies();

const googleAuth = async (params) => {
	return axiosInstance.post(`/auth/google`, { ...params });
};

export const signIn = async (params) => {
	return axiosInstance.post(`/auth/signIn`, { ...params });
};
export const signOut = async (params) => {
	return axiosInstance.post(`/auth/signOut`);
};

export const getUserRole = async (params) => {
	return axios.post(
		`https://dev-0kh2j155t64ogfrv.us.auth0.com/api/v2/users/${params.userId}/roles`,
		{},
		{
			headers: {
				Authorization: "Bearer" + " " + cookies.get("token")
			}
		}
	);
};

export default googleAuth;
