import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateUserPropertyEnvelopeSignedBy } from "../services/propertyForm";
import Cookies from "universal-cookie";
import { useAuth0 } from "@auth0/auth0-react";
import TransparentLoader from "../components/core/TransparentLoader";
const cookies = new Cookies();

function RedirectRecipient() {
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const envelopeStatus = searchParams.get("envelopeStatus");
	const userPropertyId = cookies.get("userPropertyId");
	const userPropertyEnvelopeId = cookies.get("userPropertyEnvelopeId");
	const signerRole = cookies.get("signerRole");
	const { user } = useAuth0();
	useEffect(() => {
		const updateUserProperty = async () => {
			try {
				const resp = await updateUserPropertyEnvelopeSignedBy({
					envelopeStatus,
					userPropertyId,
					userPropertyEnvelopeId,
					signerRole,
					auth0Id: user?.sub
				});
				if (resp.data.data.redirectUrl) {
					navigate(`/counterOffer/signing/${userPropertyId}`, {
						replace: true,
						state: {
							redirectUrl: resp.data.data.redirectUrl
						}
					});
					return;
				}
				if (resp.data.success && resp.data.data.envelopeCompleted) {
					window.top.location.href = `${resp.data.data.returnUrl}?envelopeStatus=Completed&signerRole=${resp.data.data.signerRole}`;
				} else {
					window.top.location.href = `${resp.data.data.returnUrl}?envelopeStatus=InComplete&signerRole=${resp.data.data.signerRole}`;
				}
			} catch (e) {
				console.log(e);
			}
		};
		updateUserProperty();
	}, []);

	return <TransparentLoader />;
}

export default RedirectRecipient;
