import React from "react";
import { Chip, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const StatusChip = ({ alreadyOffered, chipClass, status, title }) => {
	return (
		<Tooltip title={title}>
			<Chip
				label={alreadyOffered ? "Already Offered" : status}
				className={chipClass}
				icon={
					status === "accepted" ? (
						<CheckCircleIcon fontSize="small" style={{ color: "#02A689" }} />
					) : status === "rejected" ? (
						<CancelIcon fontSize="small" style={{ color: "#FF4842" }} />
					) : (
						<CircleIcon
							fontSize="small"
							style={{
								color:
									status === "Pending" ||
									status === "under-review" ||
									status === "awaiting-alokee-approval" ||
									status === "in-progress"
										? "#FFC107"
										: status === "awaiting-signatures"
										? "#003A7B"
										: "#02A689"
							}}
						/>
					)
				}
				style={{
					backgroundColor: "#FFF",
					color: "#003A7B"
				}}
				variant="outlined"
			/>
		</Tooltip>
	);
};

export default StatusChip;
