import React from "react";
import { useField } from "formik";
import { makeStyles, TextField, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
const useStyles = makeStyles({
	root: {
		"& .MuiFormLabel-root": {
			display: "flex",
			flexDirection: "row",
			alignItems: "flex-start",
			justifyContent: "space-between",
			fontFamily: "Rubik",

			"& .myIcon": {
				paddingLeft: "8px",
				paddingBottom: "10px",
				order: 999
			}
		}
	}
});
function TextFieldWrapper({
	name,
	variant,
	margin,
	size,
	toolTipMsg,
	label,
	tooltipRequired,
	...otherProps
}) {
	const [field, meta] = useField(name);
	const classes = useStyles();

	const configTextfield = {
		...field,
		...otherProps,
		fullWidth: true,
		margin: margin || "normal",
		size: size || "medium",
		className: classes.root
	};

	if (variant) {
		configTextfield.variant = variant;
	}

	if (meta && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	return (
		<>
			<Tooltip title={toolTipMsg ? toolTipMsg : label}>
				<TextField
					label={
						tooltipRequired ? (
							<>
								{label}
								<InfoOutlined className="myIcon" fontSize="large" />
							</>
						) : (
							label
						)
					}
					{...configTextfield}
				/>
			</Tooltip>
		</>
	);
}

export default TextFieldWrapper;
