import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppBar } from "../components/AppBar";
import { Container, makeStyles } from "@material-ui/core";
import BlogOne from "../components/Blogs/BlogOne";
import BlogTwo from "../components/Blogs/BlogTwo";
import FAQAccordion from "../components/FAQS/FAQAccordion";
import Footer from "../components/footer/footer";
import BlogThree from "../components/Blogs/BlogThree";
import BlogFour from "../components/Blogs/BlogFour";
import BlogFive from "../components/Blogs/BlogFive";
import BlogSix from "../components/Blogs/BlogSix";

const blogStyles = makeStyles((theme) => {
	return {
		blogContainer: {
			marginTop: "150px",
			paddingBottom: "400px",
			[theme.breakpoints.down("sm")]: {
				paddingBottom: "120px"
			}
		}
	};
});

function Blog({}) {
	const classes = blogStyles();
	const blogs = [
		{
			q: "Home Buying Options Without a Traditional Real Estate Agent",
			a: <BlogOne />
		},
		{
			q: "Alokee's Guide: How to Spot Costly Home Issues Before You Buy",
			a: <BlogTwo />
		},
		{
			q: "Navigating Home Buying: To Real Estate Agent or Not? Unraveling the Alokee Perspective",
			a: <BlogThree />
		},
		{
			q: "Unlocking Savings: The Alokee Advantage in Home Buying Bonuses",
			a: <BlogFour />
		},
		{
			q: "Mastering the Art of DIY Real Estate: Hacking Home Showings with Alokee",
			a: <BlogFive />
		},
		{
			q: "Cracking the Code: How to Determine the Right Offer for Your Dream Home with Alokee",
			a: <BlogSix />
		}
	];
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div style={{ width: "100%" }}>
			<Helmet>
				<title>Alokee | Blog</title>
			</Helmet>
			<Container maxWidth="lg" className={classes.blogContainer}>
				<AppBar whiteBackground={true} />
				<FAQAccordion faqs={blogs} />
			</Container>
			<Footer />
		</div>
	);
}

export default Blog;
