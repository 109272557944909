import React from "react";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Tooltip
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useField, useFormikContext } from "formik";

const CheckboxWrapper = ({
	name,
	label,
	legend,
	toolTipMsg,
	...otherProps
}) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);

	const handleChange = (evt) => {
		const { checked } = evt.target;
		setFieldValue(name, checked);
	};

	const configCheckbox = {
		...field,
		style: { color: "#003a7b" },
		checked: field.value,
		onChange: handleChange,
		...otherProps
	};

	const configFormControl = {};
	if (meta && meta.touched && meta.error) {
		configFormControl.error = true;
	}

	return (
		<>
			<FormControl {...configFormControl}>
				<FormLabel component="legend">{legend}</FormLabel>
				<FormGroup>
					<FormControlLabel
						control={<Checkbox {...configCheckbox} />}
						label={label}
					/>
				</FormGroup>
			</FormControl>
			{otherProps.tooltipRequired && (
				<Tooltip title={toolTipMsg ? toolTipMsg : label}>
					<InfoOutlined fontSize="small" style={{ color: "#686868", marginTop: 8 }} />
				</Tooltip>
			)}
		</>
	);
};

export default CheckboxWrapper;
