import React, { useEffect, useState } from "react";
import HomeA from "./HomeA";
import HomeB from "./HomeB";
import HomeC from "./HomeC";
import HomeD from "./HomeD";

function RenderHome() {
	const [selectedPageIndex, setSelectedPageIndex] = useState(null);

	useEffect(() => {
		const storedIndex = sessionStorage.getItem("selectedHomePage");
		if (storedIndex !== null) {
			setSelectedPageIndex(parseInt(storedIndex));
		} else {
			const randomIndex = Math.floor(Math.random() * 4);
			setSelectedPageIndex(randomIndex);
			sessionStorage.setItem("selectedHomePage", randomIndex.toString());
		}
	}, []);

	const pageComponents = [<HomeA />, <HomeB />, <HomeC />, <HomeD />];

	return selectedPageIndex == null ? null : pageComponents[selectedPageIndex];
}

export default RenderHome;
