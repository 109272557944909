import axiosInstance from ".";

export const getAllFavourites = async (params) => {
	return axiosInstance.post(`/favourite/getAllUserFavouritesById`, {
		limit: params.limit,
		offset: params.offset,
		auth0Id: params.auth0Id
	});
};

export const createFavourite = async (params) => {
	return axiosInstance.post(`/favourite/createFavourite`, params);
};

export const removeFavourite = async (params) => {
	return axiosInstance.post(`/favourite/deleteFavourite`, {
		id: params.id,
		auth0Id: params.auth0Id
	});
};
