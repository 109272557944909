import {
	Avatar,
	Container,
	Grid,
	Typography,
	IconButton,
	Button as MUIButton,
	useMediaQuery,
	Box,
	Tooltip
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import makeStyles from "../../../../components/Properties/styles";
import { useTheme } from "@material-ui/styles";
import Footer from "../../../../components/footer/footer";
import Collapsable from "../../../../components/Collapsable/Collapsable";
import SharePropertyLink from "../../../../components/SharePropertyLink/SharePropertyLink";
import { Form, Formik } from "formik";
import { Button, TextField, Toast } from "../../../../components/core";
import { useLocation } from "react-router";
import { useParams, Link } from "react-router-dom";
import {
	getProperties,
	getPropertyById
} from "../../../../services/bridgeInteractive";
import { CircularProgress, Stack } from "@mui/material";
import {
	ArrowDownwardRounded,
	FavoriteBorderOutlined
} from "@material-ui/icons";
import OfferTerms from "../../../../components/Properties/Forms/OfferTerms";
import Uploads from "../../../../components/Properties/Forms/Uploads";
import OfferDetails from "../../../../components/Properties/Forms/OfferDetails";
import OfferSummary from "../../../../components/Properties/Forms/OfferSummary";
import * as Yup from "yup";
import {
	getAllUserPropertyFormById,
	removePropertyForm,
	updateTermsAcceptedStatus,
	updateUserProperties
} from "../../../../services/propertyForm";
import {
	createPropertyForm,
	updatePropertyForm
} from "../../../../services/propertyForm";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

import FormSummary from "../../../../components/Properties/FormSummary";
import doneIcon from "../../../../assets/done.png";
import TextDialog from "../../../core/TextDialog";
import StatusChip from "../../../StatusChip/StatusChip";
import Amenities from "../../../Amenities/Amenities";
import CommentBox from "../../../Admin/comment/CommentBox";
import BuyerRevisionForm from "../../../../components/Properties/Forms/BuyerRevisionForm";
import { InfoOutlined } from "@material-ui/icons";
import {
	createFavourite,
	getAllFavourites,
	removeFavourite
} from "../../../../services/favorites";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import Chat from "../../../Chat/Chat";
import { getChatById } from "../../../../services/userPropertyChat";
import { createUserChat } from "../../../../services/userChat";
import { getUserChatById } from "../../../../services/userChat";
import { createChat } from "../../../../services/userPropertyChat";
import { useUserState } from "../../../../context/UserContext";
import NonExclusiveAgreement from "../../../../pages/NonExclusiveAgreement";
import { askLLM } from "../../../../services/llm";
import {
	PREDICTION_PROMPT,
	pricePredictorTooltip
} from "../../../../configs/siteData";
import FormSkeleton from "../../../core/FormSkeleton";
import { updateUser } from "../../../../services/users";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const cookies = new Cookies();

let initialValues = {
	purchasePrice: 0,
	deposit: 3,
	loan: 80,
	balanceDownPayment: 17,
	partners: [],
	entity: [],
	buyer: "",
	closingDate: 45,
	buyerFundNeedClose: 0,
	expiryDate: 3,
	increaseDeposit: 0,
	loanType: "Conventional",
	loanRate: "",
	loanRateType: "",
	occupancyType: "Primary",
	loanContingency: true,
	appraisalContingency: true,
	investigationContingency: true,
	sellerDocumentsReview: true,
	titleReportReview: true,
	removalOfAllContingencies: false,
	contingentOffer: false,
	liquidatedDamages: "Yes",
	arbitrationOfDisputes: "Yes",
	proofOfFunds: "",
	loanQualification: "",
	otherDocuments: ""
};

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 0
});

let initialValuesRevision = {
	purchasePriceRevised: "0"
};

let initialValuesSellerAgentInfo = {
	sellerAgentName: "",
	sellerAgentEmail: ""
};

const schemaSellerAgentInfo = Yup.object().shape({
	sellerAgentName: Yup.string(),
	sellerAgentEmail: Yup.string().required("email is required")
});

function OfferRevision({
	setPropertyFormVisible,
	revisedState,
	status,
	propertyMlsId,
	userPropertyData,
	commentsProp,
	showCommentBox,
	onAdminReviewDone,
	setStatus,
	setShowConfirmStatusChange,
	showActions,
	showRevised,
	setShowRevised,
	onSubmitRevision,
	loadingRecipient,
	showCommentForm,
	setShowCommentForm,
	sendingToBuyer,
	revisedFields,
	offerType,
	hideStepper,
	loadingSellerAgentInfo,
	askSellerAgentInfo,
	setAskSellerAgentInfo,
	onSubmitSellerAgentInfo,
	propertyRelatedChats
}) {
	const navigate = useNavigate();
	const classes = makeStyles();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const userState = useUserState();
	const [activeStep, setActiveStep] = useState(0);
	const [favourite, setFavourite] = useState(false);
	const { user, getAccessTokenSilently } = useAuth0();
	const [oldChat, setOldChat] = useState([]);
	const [isBuyer, setIsBuyer] = useState(true);
	const [comments, setComments] = useState(commentsProp || []);
	const [offerSentToSeller, setOfferSentToSeller] = useState({
		message: "",
		state: false
	});
	const [showConfirm, setShowConfirm] = useState({
		message: "Please review all details you have filled.",
		state: false
	});
	const [showConfirmOfferSent, setShowConfirmOfferSent] = useState({
		message: "",
		state: false
	});
	const [showDocuSignProceed, setShowDocuSignProceed] = useState({
		message: "",
		state: false
	});
	const [loading, setLoading] = useState(false);
	const [stepper, setStepper] = useState({
		offerTerms: true,
		uploads: false,
		offerDetails: false,
		offerSummary: false
	});
	const { state, pathname } = useLocation();
	if (state) {
		initialValues.purchasePrice = state.data.ListPrice;
	}
	const [formValues, setFormValues] = useState(
		state ? initialValues : userPropertyData
	);
	const [disclaimer, setDisclaimer] = useState({
		message: "",
		state: false
	});
	const [bonus, setBonus] = useState("0");
	const { mlsId, userId, id, userPropertyId, propertyId } = useParams();
	
	const [propertyData, setPropertyData] = useState(state ? state.data : []);
	const [favouriteData, setFavouriteData] = useState([]);
	const [toast, setToast] = useState({
		state: "close",
		message: "",
		type: ""
	});
	const [predictedPrice, setPredictedPrice] = useState("");
	const [showPrice, setShowPrice] = useState(false);
	const [predictedPriceView, setPredictedPriceView] = useState(false);
	const formRef = useRef();
	const [agreementButtonEnabled, setAgreementButtonEnabled] = useState(true);
	const userFullName = userState?.userProfile?.firstName;
	const userIdExists = !!userState?.userProfile?.identityUrl;
	const propertyLink = state?.propertyLink || "/app/dashboard?filter=Offers";
	const searchLink = state?.searchLink || "/app/dashboard?filter=Offers";
	if (!userIdExists) {
		initialValues.id = "";
	}
	useEffect(async () => {
		const token = await getAccessTokenSilently();
		const userData = jwtDecode(token);

		if (userData.permissions[0] == "read:admin-data") {
			setIsBuyer(false);
		}
	}, []);

	useEffect(() => {
		if (status == "awaiting-alokee-approval") {
			showCommentBox = true;
		}
		if (pathname == `/sellerForm/${mlsId}/${userId}/${id}/${propertyId}`) {
			if (status == "pulled-back") {
				setShowConfirmOfferSent((prevState) => {
					return {
						message: "This offer has been pulled back by Buyer.",
						state: true
					};
				});
			}
		}
	}, [status]);

	useEffect(() => {
		window.scrollTo(0, 0);

		let $filter;

		const getProperty = async () => {
			try {
				const resp = await getPropertyById({
					mlsId: mlsId || propertyMlsId,
					auth0Id: user?.sub
				});
				if (resp.data.data) {
					setPropertyData(resp.data.data);
				} else {
					try {
						const resp = await getAllUserPropertyFormById({
							id: id || userPropertyData?.userPropertyId,
							auth0Id: user?.sub
						});

						setPropertyData(
							JSON.parse(resp.data.data.data[0].property_form.MLSData)
						);
					} catch (e) {}
				}
				const PercentPrice = +resp.data.ListPrice * 0.2;
				$filter = `MlsStatus eq 'Closed' and ListPrice ge ${
					resp.data.ListPrice - PercentPrice
				} and ListPrice le ${
					+resp.data.ListPrice + PercentPrice
				} and LotSizeSquareFeet ge ${
					+resp.data.LotSizeSquareFeet - 2000
				} and LotSizeSquareFeet le ${+resp.data.LotSizeSquareFeet + 2000} ${
					resp.data.YearBuilt &&
					`and YearBuilt ge ${
						+resp.data.YearBuilt - 10
					} and YearBuilt le ${"2020"}`
				} ${
					resp.data.LivingArea &&
					`and LivingArea ge ${+resp.data.LivingArea - 500} and LivingArea le ${
						+resp.data.LivingArea + 500
					}`
				} and PostalCode eq '${resp.data.PostalCode}'`;
				const similarProperties = await getProperties(
					$filter.replace(new RegExp("null", "g"), ""),
					{
						limit: 15,
						offset: 0
					}
				);

				const predictionData = JSON.stringify(
					similarProperties.data.data.value.map((data) => {
						return {
							ListPrice: data.ListPrice,
							ClosePrice: data.ClosePrice,
							LotSizeSquareFeet: data.LotSizeSquareFeet,
							LivingAreaSquareFeet: data.LivingAreaSquareFeet,
							YearBuilt: data.YearBuilt,
							BedroomsTotal: data.BedroomsTotal,
							BathroomsFull: data.BathroomsFull
						};
					})
				);

				const payload = {
					model: "gpt-3.5-turbo",
					messages: [
						{
							role: "user",
							content: PREDICTION_PROMPT(predictionData, propertyData)
						}
					],
					temperature: 0
				};
				const prediction = await askLLM(payload);
				setPredictedPrice(prediction.data.data.choices[0].message.content);

				if (pathname == `/app/property-form/${mlsId}`) {
					setFormValues((prev) => {
						return {
							...prev,
							...initialValues,
							purchasePrice: resp.data.ListPrice
						};
					});
				}
			} catch (e) {}
		};
		if (!state) {
			getProperty();
		} else {
			return;
		}
	}, [mlsId, propertyMlsId]);
	useEffect(() => {
		const getPropertyForm = async () => {
			try {
				const resp = await getAllUserPropertyFormById({
					id: userPropertyId,
					auth0Id: user?.sub
				});
				const propertyData = resp.data.data.data[0].property_form;
				propertyData.userPropertyId = resp.data.data.data[0].id;
				propertyData.id = resp.data.data.data[0].property_form.id;
				propertyData.buyer =
					resp.data.data.data[0].user.firstName +
					" " +
					resp.data.data.data[0].user.lastName;
				if (propertyData.partners) {
					propertyData.partners = [JSON.parse(propertyData.partners)];
				}
				if (!propertyData.partners) {
					propertyData.partners = [];
				}
				if (propertyData.entity) {
					propertyData.entity = [JSON.parse(propertyData.entity)];
				}
				if (!propertyData.entity) {
					propertyData.entity = [];
				}
				if (resp.data.data.data[0].comments) {
					setComments(resp.data.data.data[0].comments);
				}
				initialValuesRevision.purchasePriceRevised = propertyData.purchasePrice;
				initialValuesRevision.loanContingencyRevised =
					propertyData.loanContingency;
				initialValuesRevision.appraisalContingencyRevised =
					propertyData.appraisalContingency;
				initialValuesRevision.investigationContingencyRevised =
					propertyData.investigationContingency;
				initialValuesRevision.sellerDocumentsReviewRevised =
					propertyData.sellerDocumentsReview;
				initialValuesRevision.titleReportReviewRevised =
					propertyData.titleReportReview;
				initialValuesRevision.removalOfAllContingenciesRevised =
					propertyData.removalOfAllContingencies;
				initialValuesRevision.contingentOfferRevised =
					propertyData.contingentOffer;

				setFormValues(propertyData);
			} catch (e) {}
		};

		getPropertyForm();
	}, [id]);

	const handleNext = () => {
		if (activeStep == 4) {
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const onFormSubmit = async (values, { resetForm }, errors) => {
		setLoading(true);
		
		try {
			values.propertyId = propertyId;
			const resp = await updateUserProperties({
				userPropertyId: values.userPropertyId,
				status: "awaiting-alokee-approval",
				revisedFields: JSON.stringify(values)
			});
			if (resp.data.data.success) {
				setLoading(false);
				setToast({
					state: "open",
					message: resp.data.message,
					type: "success"
				});
				resetForm();
				setTimeout(() => {
					navigate("/app/dashboard/admin/properties");
				}, 1000);
			} else {
				setToast({
					state: "open",
					message: resp.data.message,
					type: "error"
				});
				setLoading(false);
			}
			return;
		} catch (e) {
			
			setToast({
				state: "open",
				message: "Something went wrong!",
				type: "error"
			});
			setLoading(false);

			return;
		}
	};

	const onAccordionClick = (stepperObj) => {
		setStepper((prevState) => {
			const key = `${stepperObj}`;
			return {
				...prevState,
				[key]: !prevState[stepperObj]
			};
		});
	};

	useEffect(() => {
		if (activeStep == 0) {
			setStepper((prevState) => {
				return {
					offerTerms: true,
					uploads: false,
					offerDetails: false,
					offerSummary: false
				};
			});
		}
		if (activeStep == 1) {
			setStepper((prevState) => {
				return {
					offerTerms: false,
					uploads: true,
					offerDetails: false,
					offerSummary: false
				};
			});
		}
		if (activeStep == 2) {
			setStepper((prevState) => {
				return {
					offerTerms: false,
					uploads: false,
					offerDetails: true,
					offerSummary: false
				};
			});
		}
		if (activeStep == 3) {
			setStepper((prevState) => {
				return {
					offerTerms: false,
					uploads: false,
					offerDetails: false,
					offerSummary: true
				};
			});
		}
	}, [activeStep]);

	const handleBack = () => {
		if (activeStep == 0) {
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const onMarkFavourite = async (favourite) => {
		try {
			if (favourite) {
				const remove = await removeFavourite({
					id: favouriteData.id,
					auth0Id: user?.sub
				});
			} else {
				const createFav = await createFavourite({
					auth0Id: user?.sub,
					price: propertyData.ListPrice,
					bathrooms: propertyData.BathroomsTotalInteger,
					bedrooms: propertyData.BedroomsTotal,
					name: propertyData.UnparsedAddress,
					street: propertyData.StreetName,
					area: propertyData.MLSAreaMajor,
					images: propertyData.Media?.map((data) => data.MediaURL) || "",
					status: propertyData.MlsStatus,
					mlsId: propertyData.ListingId,
					details: propertyData
				});
			}
		} catch (e) {}
	};

	useEffect(() => {
		const getFavourite = async () => {
			try {
				const resp = await getAllFavourites({ userId, auth0Id: user?.sub });
				const { rows: favorites } = resp.data.data.data;
				if (favorites.length > 0) {
					const filtered = favorites.filter((data) => {
						if (data.mlsId == mlsId) {
							return data;
						}
					});
					if (filtered.length > 0) {
						if (filtered[0].mlsId == mlsId) {
							setFavourite(true);
						}
						setFavouriteData(filtered[0]);
					}
				}
			} catch (e) {}
		};
		getFavourite();
	}, []);

	useEffect(() => {
		const getChats = async () => {
			try {
				let resp;
				if (!isBuyer || propertyRelatedChats == true) {
					resp = await getChatById({
						userPropertyId: userPropertyData?.userPropertyId || id
					});
				} else {
					resp = await getUserChatById({
						userId: userState?.userProfile?.userId
					});
				}
				setOldChat(resp.data.data.data.rows);
			} catch (e) {}
		};
		getChats();
	}, [id]);

	const [chatForceOpen, setChatForceOpen] = useState(false);

	const commonSchemaRules = {
		purchasePrice: Yup.string().required("purchase price is required"),
		deposit: Yup.string().required("deposit is required"),
		loan: Yup.string().required("loan is required"),
		buyer: Yup.string(),
		partners: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required("Second buyer name is Required"),
				email: Yup.string()
					.email("Second Buyer email must be a valid email")
					.max(255)
					.required("Second buyer email is required")
			})
		),
		entity: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required("Entity's Name is Required"),
				email: Yup.string().email("Must be a valid email").max(255)
			})
		),
		increaseDeposit: Yup.string(),
		loanType: Yup.string(),
		loanRate: Yup.string(),
		loanRateType: Yup.string(),
		occupancyType: Yup.string(),
		loanContingency: Yup.boolean().required("required"),
		appraisalContingency: Yup.boolean().required("required"),
		investigationContingency: Yup.boolean().required("required"),
		sellerDocumentsReview: Yup.boolean().required("required"),
		titleReportReview: Yup.boolean().required("required"),
		removalOfAllContingencies: Yup.boolean().required("required"),
		contingentOffer: Yup.boolean().required("required"),
		liquidatedDamages: Yup.string().required("required"),
		arbitrationOfDisputes: Yup.string().required("required"),
		proofOfFunds: Yup.mixed().required("Please upload proof of funds"),
		loanQualification: Yup.mixed().when("loan", (loan) => {
			if (loan && loan > 0)
				return Yup.mixed().required("Please upload loan qualification");
		}),
		balanceDownPayment: Yup.string().required(
			"Balance Down Payment is required"
		),

		id: Yup.mixed().when([], {
			is: () => !!!userState?.userProfile?.identityUrl,
			then: Yup.mixed().required("Please upload your ID"),
			otherwise: Yup.mixed().notRequired()
		})
	};

	const createValidationSchema = (commonRules) => {
		if (!propertyData) {
			return Yup.object().shape({
				...commonRules
			});
		}

		const numericListPrice = parseFloat(propertyData.ListPrice);
		const updatedPurchasePriceRule = Yup.string()
			.required("purchase price is required")
			.test(
				"is-valid-price",
				"purchase price must be at least 20% of list price",
				(value) => {
					const numericValue = parseFloat(value);
					const minValue = numericListPrice * 0.2;
					return numericValue >= minValue;
				}
			);

		return Yup.object().shape({
			...commonRules,
			purchasePrice: updatedPurchasePriceRule
		});
	};

	const schema = createValidationSchema(commonSchemaRules);

	const initialSchemaRevisionRules = {
		purchasePriceRevised: Yup.string().required("purchase price is required")
	};

	const createSchemaRevision = (commonRevisionRules) => {
		if (!propertyData) {
			return Yup.object().shape({
				...commonRevisionRules
			});
		}

		const numericListPrice = parseFloat(propertyData.ListPrice);
		const updatedPurchasePriceRule = Yup.string()
			.required("purchase price is required")
			.test(
				"is-valid-price",
				"purchase price must be at least 20% of list price",
				(value) => {
					const numericValue = parseFloat(value);
					const minValue = numericListPrice * 0.2;
					return numericValue >= minValue;
				}
			);

		return Yup.object().shape({
			...commonRevisionRules,
			purchasePriceRevised: updatedPurchasePriceRule
		});
	};

	const schemaRevision = createSchemaRevision(initialSchemaRevisionRules);

	return (
		<div
			className={classes.mainContainerForm}
			style={{ paddingTop: onAdminReviewDone ? 0 : "" }}
		>
			<Container maxWidth="xl" style={{ paddingBottom: "120px" }}>
				{!isBuyer || propertyRelatedChats == true ? (
					<Chat
						uniqueKey={userPropertyData?.userPropertyId || id}
						oldChat={oldChat}
						createChat={createChat}
						chatForceOpen={chatForceOpen}
						setChatForceOpen={setChatForceOpen}
					/>
				) : (
					<Chat
						uniqueKey={userState?.userProfile?.userId}
						oldChat={oldChat}
						createChat={createUserChat}
						chatForceOpen={chatForceOpen}
						setChatForceOpen={setChatForceOpen}
					/>
				)}
				<Toast {...toast} setState={setToast} />

				{propertyData.constructor === Object &&
				formValues &&
				Object.keys(formValues).length != 0 ? (
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="stretch"
						className={classes.root}
						spacing={4}
						flexGrow
					>
						<Grid item xs={12}>
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="stretch"
								className={classes.root}
								spacing={4}
								flexGrow
							>
								<Grid
									item
									xs={12}
									sm={12}
									lg={4}
									xl={4}
									md={4}
									style={{
										position: "relative",
										padding: 0,
										margin: 3
									}}
								>
									<Typography style={{ marginBottom: "30px" }}>
										<Link
											to={searchLink}
											style={{ textDecoration: "none", color: "#686868" }}
											tabIndex={0}
											aria-label="Search"
										>
											Search {">"}
										</Link>{" "}
										<Link
											to={propertyLink}
											style={{ textDecoration: "none", color: "#686868" }}
											tabIndex={0}
											aria-label="Listing Details"
										>
											Listing Details {">"}
										</Link>{" "}
										<span style={{ color: "#003A7B" }}>Place Offer</span>
									</Typography>
								</Grid>
								{!smDown && (
									<Grid
										item
										xs={12}
										sm={12}
										lg={7}
										md={7}
										xl={7}
										style={{
											margin: 3
										}}
									></Grid>
								)}
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							lg={4}
							xl={4}
							md={4}
							style={{
								position: "relative",
								padding: 0,
								margin: 3
							}}
						>
							<Avatar
								src={
									propertyData?.Media
										? propertyData?.Media?.map((data) => data.MediaURL)[0]
										: "https://aqareyonline.com/site_new_assets/assets/images/placeholder-listing.jpeg"
								}
								variant="square"
								className={classes.img}
							></Avatar>
							<StatusChip
								alreadyOffered={false}
								chipClass={classes.formStatusChip}
								status={propertyData.MlsStatus}
							/>
							<Grid
								container
								direction="column"
								justifyContent="flex-start"
								alignItems="flex-start"
								className={classes.journeyContainer}
							>
								<Grid
									item
									container
									direction="row"
									justifyContent="space-between"
									style={{
										marginBottom: 10,
										marginLeft: 20
									}}
									xs={12}
								>
									<Grid item xs={6}>
										<MUIButton
											variant="outlined"
											className={classes.propertyFormAskQBtn}
											onClick={() => {
												setChatForceOpen(true);
											}}
										>
											Ask us a question
										</MUIButton>
									</Grid>
									{!onAdminReviewDone && (
										<Box display="flex">
											<SharePropertyLink mlsid={mlsId} />
											<IconButton
												style={{ padding: 0, marginLeft: "20px" }}
												className={classes.fav}
												aria-label="Favourite"
												onClick={() => {
													onMarkFavourite(favourite);
													setFavourite(!favourite);
												}}
											>
												{favourite ? (
													<FavoriteIcon
														style={{
															color: "#02A689",
															height: 30,
															width: 30
														}}
													/>
												) : (
													<FavoriteBorderOutlined
														style={{
															color: "#003A7B",
															height: 30,
															width: 30
														}}
													/>
												)}
											</IconButton>
										</Box>
									)}
								</Grid>
								<Grid
									item
									xs={12}
									style={{
										marginBottom: 10,
										marginLeft: 20
									}}
								>
									{smDown ? (
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
										>
											<Typography
												className={classes.price}
												style={{ marginRight: "70px" }}
											>
												${propertyData?.ListPrice?.toLocaleString()}
											</Typography>
											<img
												width="80px"
												height="80px"
												src={`https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=250&height=250&center=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]}&zoom=15.9318&marker=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]};color:%23ff0000&apiKey=${process.env.REACT_APP_TYPEAHEAD_SECRET}`}
												alt="Map of location"
											/>
										</Box>
									) : (
										<Typography className={classes.price}>
											${propertyData?.ListPrice?.toLocaleString()}
										</Typography>
									)}
								</Grid>
								<Grid
									item
									xs={12}
									lg={12}
									className={classes.address}
									style={{
										marginLeft: 20,
										marginBottom: 15
									}}
								>
									{smDown ? (
										<div>
											<Amenities
												bathrooms={propertyData.BathroomsFull}
												bedrooms={propertyData.BedroomsTotal}
												area={propertyData.LivingArea}
												clickHandler={false}
											/>
											<Typography className={classes.addressInContainer}>
												{propertyData.UnparsedAddress}
											</Typography>
										</div>
									) : (
										<Box
											display="flex"
											flexDirection="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<div style={{ marginRight: "47px" }}>
												<Amenities
													bathrooms={propertyData.BathroomsFull}
													bedrooms={propertyData.BedroomsTotal}
													area={propertyData.LivingArea}
													clickHandler={false}
												/>
												<Typography className={classes.addressInContainer}>
													{propertyData.UnparsedAddress}
												</Typography>
											</div>
											<img
												width="80px"
												height="80px"
												src={`https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=250&height=250&center=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]}&zoom=15.9318&marker=lonlat:${propertyData.Coordinates[0]},${propertyData.Coordinates[1]};color:%23ff0000&apiKey=${process.env.REACT_APP_TYPEAHEAD_SECRET}`}
												alt="Map of location"
											/>
										</Box>
									)}
								</Grid>
								<Grid
									container
									item
									xs={8}
									lg={8}
									md={8}
									className={classes.listedPrice}
									direction="row"
									justifyContent="space-between"
								>
									<Grid
										item
										xs={12}
										lg={12}
										md={12}
										style={{ marginBottom: "25px" }}
									>
										<Typography
											// style={{
											// 	cursor: "pointer",
											// }}
											variant="caption"
											className={classes.listedPriceText}
										>
											Listed Price
										</Typography>
										<Typography
											className={classes.priceNumber}
											// style={{
											// 	cursor: "pointer"
											// }}
											// onClick={() => {
											// 	formRef.current?.setFieldValue(
											// 		"purchasePrice",
											// 		propertyData.ListPrice
											// 	);
											// }}
										>
											{formatter.format(propertyData.ListPrice)}
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										lg={12}
										md={12}
										style={{ marginBottom: "25px" }}
									>
										<Typography
											className={classes.listedPriceText}
											// style={{
											// 	cursor: "pointer"
											// }}
											variant="caption"
										>
											Offer Price
										</Typography>
										<Typography
											className={classes.priceNumber}
											style={{
												// cursor: "pointer",
												overflowWrap: "break-word",
												maxWidth: "260px"
											}}
										>
											{formatter.format(
												formRef.current?.values?.purchasePrice ||
													formValues?.purchasePrice
											)}
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										lg={12}
										md={12}
										style={{ marginBottom: "25px" }}
									>
										<Typography className={classes.listedPriceText}>
											Alokee Cash Bonus
										</Typography>
										<Typography className={classes.priceNumber}>
											{pathname.includes("admin/properties")
												? formatter.format(
														formValues?.purchasePrice * 0.025 - 9999 > 0
															? formValues?.purchasePrice * 0.025 - 9999
															: 0
												  )
												: formatter.format(
														parseFloat(bonus.toString().replace(/[$,]/g, ""))
												  )}
										</Typography>
									</Grid>
									<Stack
										direction="row"
										spacing={2}
										style={{ marginBottom: "25px" }}
									>
										<MUIButton
											variant="contained"
											endIcon={
												predictedPriceView ? (
													<ExpandLess
														fontSize="large"
														style={{
															color: "#fff"
														}}
													/>
												) : (
													<ExpandMore
														fontSize="large"
														style={{
															color: "#fff"
														}}
													/>
												)
											}
											className={classes.pricePredictorButton}
											onClick={() => {
												setPredictedPriceView((prev) => {
													return !prev;
												});
												setTimeout(() => {
													setShowPrice((prev) => {
														return !prev;
													});
												}, 100);
											}}
										>
											<Typography className={classes.pricePredictorText}>
												Alokee Price Predictor{" "}
												<sup
													style={{
														fontSize: "12px"
													}}
												>
													TM
												</sup>
											</Typography>
										</MUIButton>
									</Stack>
									{predictedPriceView ? (
										<Tooltip title={pricePredictorTooltip}>
											<Grid item xs={12} lg={12} md={12}>
												<Typography
													className={classes.priceNumber}
													style={{
														transition:
															"opacity 0.5s ease, transform 0.5s ease",
														opacity: showPrice ? 1 : 0,
														transform: `translateY(${
															showPrice ? "0" : "-20px"
														})`
													}}
												>
													{predictedPrice.length > 0 ? (
														predictedPrice
													) : (
														<CircularProgress size={20} />
													)}
												</Typography>
											</Grid>
										</Tooltip>
									) : null}
									<Grid
										item
										xs={12}
										style={{ marginTop: "20px", marginBottom: "60px" }}
									>
										<MUIButton
											variant="outlined"
											className={classes.propertyFormAskQBtn}
											onClick={() => {
												window.open(
													window.location.pathname.startsWith("/app")
														? "/app/alokee-price-predictor"
														: "/alokee-price-predictor",
													"_blank"
												);
											}}
											style={{
												color: "#003A7B"
											}}
										>
											Learn more
										</MUIButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							lg={7}
							md={7}
							xl={7}
							style={{
								margin: 3,
								border: "1px solid",
								borderColor: "#cacaca"
							}}
						>
							<Formik
								initialValues={formValues}
								validationSchema={schema}
								onSubmit={onFormSubmit}
								innerRef={formRef}
								render={({
									values,
									isSubmitting,
									submitForm,
									isValid,
									dirty,
									errors,
									setErrors,
									setSubmitting
								}) => {
									if (Object.keys(errors).length > 0 && isSubmitting) {
										setSubmitting(false);
										setStepper({
											offerTerms: true,
											uploads: true,
											offerDetails: true,
											offerSummary: true
										});
									}

									return (
										<Form onChange={(e) => {}}>
											{
												<Grid
													style={{ paddingBottom: 15 }}
													item
													xs={12}
													sm={12}
													lg={12}
													xl={12}
												>
													<Collapsable
														handleBack={handleBack}
														showActions={showActions}
														isBuyer={isBuyer}
														loading={loading}
														status={status}
														handleNext={handleNext}
														activeStep={activeStep}
														revisedState={revisedState}
														onClick={() => {
															// onAccordionClick("offerTerms");
														}}
														expanded={stepper.offerTerms}
													>
														{activeStep == 0 && (
															<OfferTerms
																revisedState={revisedState}
																values={values}
																setBonus={setBonus}
															/>
														)}
														{activeStep == 1 && (
															<Uploads
																onAdminReviewDone={
																	!!onAdminReviewDone ||
																	pathname.includes("sellerForm")
																}
																disabled={revisedState}
																values={values}
																userIdExists={userIdExists}
															/>
														)}
														{activeStep == 2 && (
															<OfferDetails
																revisedState={revisedState}
																values={values}
																setDisclaimer={setDisclaimer}
																status={status}
															/>
														)}
														{activeStep == 3 && (
															<OfferSummary
																revisedState={revisedState}
																values={values}
																propertyAddress={propertyData.UnparsedAddress}
																setShowConfirmOfferSent={
																	setShowConfirmOfferSent
																}
															>
																<FormSummary
																	values={values}
																	revisedFields={revisedFields}
																	cashBonus={
																		values.purchasePrice * 0.025 - 9999 > 0
																			? values.purchasePrice * 0.025 - 9999
																			: 0
																	}
																	buyerFunds={formatter.format(
																		values.purchasePrice *
																			(values.deposit / 100) +
																			parseFloat(
																				(values.purchasePrice *
																					values.balanceDownPayment) /
																					100
																			)
																	)}
																	address={propertyData.UnparsedAddress}
																></FormSummary>
															</OfferSummary>
														)}
													</Collapsable>
												</Grid>
											}

											{showRevised && showRevised.state && (
												<TextDialog
													showActions={false}
													message={showRevised.message}
													state={showRevised.state}
													stateHandler={setShowRevised}
													submitHandler={() => {}}
													onClose={() => {}}
													showField={false}
													showAvatar={false}
												>
													<BuyerRevisionForm
														onSubmitRevision={onSubmitRevision}
														loadingRecipient={loadingRecipient}
														schemaRevision={schemaRevision}
														initialValuesRevision={initialValuesRevision}
													></BuyerRevisionForm>
												</TextDialog>
											)}
											{showConfirmOfferSent.state && (
												<TextDialog
													showActions={true}
													message={showConfirmOfferSent.message}
													state={showConfirmOfferSent.state}
													stateHandler={setShowConfirmOfferSent}
													submitHandler={() => {
														navigate("/app/dashboard?filter=Offers", {
															replace: true
														});
													}}
													onClose={() => {
														navigate("/app/dashboard?filter=Offers", {
															replace: true
														});
													}}
													showField={false}
													Avatar={
														<Avatar
															src={doneIcon}
															style={{
																top: 10,
																marginBottom: 10,
																flex: 1,
																alignContent: "center",
																alignSelf: "center"
															}}
														></Avatar>
													}
													showAvatar={true}
												></TextDialog>
											)}
											{showDocuSignProceed.state && (
												<TextDialog
													showActions={true}
													showAvatar={true}
													Avatar={
														<Avatar
															style={{
																top: 10,
																marginBottom: 10,
																flex: 1,
																alignContent: "center",
																alignSelf: "center",
																backgroundColor: "#FFFF",
																color: "#000000"
															}}
														>
															<InfoOutlined />
														</Avatar>
													}
													message={showDocuSignProceed.message}
													dialogDescription={showDocuSignProceed.description}
													state={showDocuSignProceed.state}
													hideBackdrop={true}
													stateHandler={setShowDocuSignProceed}
													submitHandler={async () => {
														let values = {};
														values.userId = userId;
														values.propertyId = cookies.get("userPropertyId");
														values.termsAcceptedByBuyer = true;
														await updateTermsAcceptedStatus(values);
														navigate("/app/document-e-signing", {
															replace: true,
															state: {
																data: mlsId
															}
														});
													}}
													onClose={async () => {
														if (status == null) {
															await removePropertyForm({
																auth0Id: user?.sub,
																id: cookies.get("userPropertyId")
															});
														}
													}}
													showField={false}
													agreementButtonEnabled={agreementButtonEnabled}
													buttonTitle={"I Agree"}
													textDialog={true}
													agreementDialog={true}
												>
													<NonExclusiveAgreement
														id="nonExclusiveAgreementRe"
														setButtonEnabled={setAgreementButtonEnabled}
														userFullName={userFullName}
													/>
												</TextDialog>
											)}
											{onAdminReviewDone && (
												<TextDialog
													showActions={false}
													message={
														"This Property Offer has been successfully sent to the Buyer Agent"
													}
													state={offerSentToSeller.state}
													stateHandler={setOfferSentToSeller}
													submitHandler={() => {}}
													onClose={() => {}}
													showField={false}
													Avatar={
														<Avatar
															src={doneIcon}
															style={{
																top: 10,
																marginBottom: 10,
																flex: 1,
																alignContent: "center",
																alignSelf: "center"
															}}
														></Avatar>
													}
													showAvatar={true}
												></TextDialog>
											)}
											<TextDialog
												showActions={true}
												hideCancel={true}
												Avatar={
													<Avatar
														style={{
															top: 10,
															marginBottom: 10,
															flex: 1,
															alignContent: "center",
															alignSelf: "center",
															backgroundColor: "#FFFF",
															color: "#000000",
															height: "40px",
															width: 40
														}}
													>
														<InfoOutlined
															style={{
																height: 40,
																width: 40
															}}
														/>
													</Avatar>
												}
												dialogDescription={disclaimer.message}
												state={disclaimer.state}
												stateHandler={setDisclaimer}
												submitHandler={() => {}}
												onClose={() => {}}
												showAvatar={true}
												showField={false}
												textDialog={true}
											></TextDialog>
											<TextDialog
												showActions={false}
												message={askSellerAgentInfo?.message}
												textDialog={askSellerAgentInfo?.textDialog}
												state={askSellerAgentInfo?.state}
												stateHandler={setAskSellerAgentInfo}
												submitHandler={() => {}}
												onClose={() => {}}
												showField={false}
												showAvatar={false}
											>
												<Formik
													validationSchema={schemaSellerAgentInfo}
													initialValues={initialValuesSellerAgentInfo}
													render={({ handleSubmit, values }) => {
														return (
															<Form>
																<Grid container spacing={2}>
																	<Grid item xs={12} md={6} sm={6}>
																		<TextField
																			name="sellerAgentName"
																			label="Seller Agent Name"
																			type="text"
																			required
																			value={formValues.sellerAgentName}
																			disabled={
																				formValues.sellerAgentName
																					? true
																					: false
																			}
																		/>
																	</Grid>
																	<Grid item xs={12} md={6} sm={6}>
																		<TextField
																			name="sellerAgentEmail"
																			label="Seller Agent Email"
																			type="email"
																			required
																		/>
																	</Grid>
																	<Grid item xs={12} md={4} sm={6}>
																		<Box py={2}>
																			<Button
																				loading={loadingSellerAgentInfo}
																				loadingPosition="start"
																				variant="contained"
																				style={{
																					textTransform: "none",
																					fontWeight: "700"
																				}}
																				onClick={() => {
																					onSubmitSellerAgentInfo({
																						...values,
																						sellerAgentName:
																							formValues.sellerAgentName ||
																							values.sellerAgentName,
																						userPropertyId:
																							formValues.userPropertyId
																					});
																				}}
																			>
																				Submit
																			</Button>
																		</Box>
																	</Grid>
																</Grid>
															</Form>
														);
													}}
												/>
											</TextDialog>
										</Form>
									);
								}}
							></Formik>
						</Grid>
					</Grid>
				) : (
					<FormSkeleton loading={true} />
				)}
			</Container>
			<Footer />
		</div>
	);
}

export default OfferRevision;
