import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

const verticalStepperStyles = makeStyles((theme) => {
	return {
		container: {
			marginTop: 50
		},
		progressbar: {
			paddingLeft: 0
		},
		step: {
			listStyleType: "none",
			position: "relative",
			display: "flex",
			alignItems: "center",
			paddingBottom: ({ lineLength }) => `${lineLength}`,
			"&:after": {
				content: "''",
				position: "absolute",
				width: 2,
				top: 20,
				bottom: -20,
				left: 20,
				borderLeft: ({ lineStyle, lineColor }) =>
					`2px ${lineStyle} ${lineColor}`,
				zIndex: -1
			},
			"&:last-child:after": {
				content: "none"
			}
		},
		stepContent: {
			position: "relative",
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
				alignItems: "flex-start"
			}
		},
		stepIcon: {
			fontSize: "1.5rem",
			height: 40,
			width: 40,
			borderRadius: "50%",
			backgroundColor: "white",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			margin: "0 auto 10px auto",
			boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
			color: ({ iconColor }) => `${iconColor}`,
			[theme.breakpoints.down("sm")]: {
				position: "absolute",
				left: 0,
				top: "50%",
				transform: "translateY(-50%)"
			}
		},
		stepLabel: {
			marginLeft: 30,
			fontSize: "1.5rem",
			[theme.breakpoints.down("sm")]: {
				fontSize: "1.125rem",
				marginLeft: 60
			}
		},
		stepNumber: {
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.secondary.mainTwo,
			fontSize: "2.5rem"
		}
	};
});

const VerticalStepper = ({
	stepperData,
	lineStyle,
	lineColor,
	iconColor,
	lineLength
}) => {
	const classes = verticalStepperStyles({
		lineStyle,
		lineColor,
		iconColor,
		lineLength
	});

	return (
		<div className={classes.container}>
			<ul className={classes.progressbar}>
				{stepperData.map((step, index) => (
					<li key={index} className={classes.step}>
						<div className={classes.stepContent}>
							<div className={classes.stepIcon}>
								<step.Icon />
							</div>
							{step.stepNumber ? (
								<Box
									display="flex"
									flexDirection="row"
									alignItems="center"
								>
									<Typography className={classes.stepLabel}>
										<span className={classes.stepNumber}>
											{step.stepNumber}
										</span>
									</Typography>
									<Typography className={classes.stepLabel}>
										{step.label}
									</Typography>
								</Box>
							) : (
								<Typography className={classes.stepLabel}>
									{step.label}
								</Typography>
							)}
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default VerticalStepper;
