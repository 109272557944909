import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
	root: {
		position: "fixed",
		bottom: "10px !important",
		right: "20px !important",
		zIndex: 20
	},
	image: {
		height: "60px !important",
		width: "60px !important",
		borderRadius: "0 !important"
	},
	closeIcon: {
		color: "#fff"
	},
	chatHeader: {
		backgroundColor: theme.palette.primary.main,
		height: "70px",
		borderTopRightRadius: "10px !important",
		borderTopLeftRadius: "10px !important"
	},
	heading: {
		color: "#fff",
		fontFamily: "Roboto Condensed"
	},
	subHeading: {
		color: "#fff",
		fontFamily: "Roboto Condensed"
	},
	messagesArea: {
		height: 430,
		overflowY: "scroll",
		background: "#fff",
		borderLeft: "solid",
		color: theme.palette.secondary.main,
		borderWidth: "thin",
		paddingBottom: 10
	},
	inboxInputArea: {
		maxHeight: "85px !important",
		borderTop: "solid",
		borderWidth: "thin",
		borderBottomLeftRadius: "6px",
		borderBottomRightRadius: "6px",
		backgroundColor: "#ADD8E6",
		borderWidth: "thin",
		marginRight: "0 !important",
		paddingRight: "0 !important"
	},
	input: {
		marginTop: 5
	},
	message: {
		backgroundColor: theme.palette.primary.main,
		borderBottomRightRadius: 10,
		borderTopRightRadius: 10,
		borderBottomLeftRadius: 10,
		padding: 10,
		marginTop: 10,
		height: "35px"
	},
	messageRight: {
		backgroundColor: "#96bc00",
		borderBottomRightRadius: 10,
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
		padding: 10,
		marginTop: 10
	},
	messageContainer: {
		marginTop: 10,
		wordWrap: "break-word" // ensure long text wraps within the container
	},
	inboxContainer: {
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(2),
		overflow: "hidden"
	},
	chatContainer: {},
	searchBarStyle: {
		height: "55px",
		width: "99%",
		borderRadius: "6px",
		backgroundColor: "#ADD8E6",
		margin: "0px !Important"
	},
	searchBar: {
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
			boxShadow: "none"
		}
	}
}));
