import { useRef } from "react";
import { Typography } from "@material-ui/core";
import { FaUpload as UploadIcon } from "react-icons/fa";
import { MdCancel as CancelIcon } from "react-icons/md";
import {
  AiFillCheckCircle as CheckIcon,
  AiFillFileExcel as InvalidFileIcon
} from "react-icons/ai";
import classNames from "classnames";
import useStyles from "./styles";

export default function ImageDrop(props) {
  const ref = useRef(null);
  const classes = useStyles();
  const {
    isInvalid,
    imageFile,
    errorMessage,
    resetState,
    handleFileDrop,
    handleFileChange
  } = props;

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileAction = () => {
    if (imageFile || isInvalid) {
      resetState();
    } else {
      ref.current.click();
    }
  };

  const getText = () => {
    if (isInvalid) {
      return errorMessage;
    }
    return imageFile ? imageFile.name : "Drop your image here, or";
  };

  const getRemoveIcon = () => {
    if (imageFile || isInvalid) {
      const cancelIconClasses = classNames(classes.removeIcon, {
        [classes.invalidFileIcon]: isInvalid
      });
      return (
        <CancelIcon className={cancelIconClasses} onClick={handleFileAction} />
      );
    }

    return (
      <Typography
        variant="subtitle2"
        display="inline"
        className={classes.browseTypography}
        onClick={handleFileAction}
      >
        browse
      </Typography>
    );
  };

  const containerClasses = classNames(classes.dropContainer, {
    [classes.dropContainerSuccess]: isInvalid === false,
    [classes.dropContainerError]: isInvalid === true
  });

  const textClasses = {
    [classes.dropTypography]: !imageFile,
    [classes.filenameText]: !!imageFile,
    [classes.errorMessage]: isInvalid
  };

  const invalidFileIconClasses = classNames(
    classes.uploadIcon,
    classes.invalidFileIcon
  );

  return (
    <div
      className={containerClasses}
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
    >
      <input
        accept="image/png, image/jpg, image/jpeg"
        type="file"
        hidden
        ref={ref}
        onChange={handleFileChange}
      />
      {!imageFile && !isInvalid && (
        <UploadIcon className={classes.uploadIcon} onClick={handleFileAction} />
      )}
      {imageFile && !isInvalid && <CheckIcon className={classes.uploadIcon} />}
      {isInvalid && <InvalidFileIcon className={invalidFileIconClasses} />}
      <Typography align="center" className={textClasses}>
        {getText()}
        {getRemoveIcon()}
      </Typography>
    </div>
  );
}
