import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import BackgroundLoader from "../core/BackgroundLoader";

const ProtectedLandingPagesLayout = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [isUser, setIsUser] = useState(true);

    useEffect(async () => {
        const token = await getAccessTokenSilently();
        const userData = jwtDecode(token);

        if (userData.permissions[0] == "read:admin-data") {
            setIsUser(false);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Dashboard | Alokee</title>
            </Helmet>
            {user && isUser ? (
                <Outlet />
            ) : (
                <BackgroundLoader />
            )}
        </>
    );
}

export default ProtectedLandingPagesLayout;
