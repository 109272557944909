import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
	formContainer: {
		marginTop: "5%",
		width: "100%",
		padding: theme.spacing(4, 3)
	},

	avatar: {
		backgroundColor: theme.palette.primary.main,
		marginBottom: theme.spacing(1)
	}
}));
