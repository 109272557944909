import React, { useEffect, useState } from "react";
import { Checkbox, SingleSelect } from "../../core";
import LabelAboveTextField from "../../core/LabelAboveTextField";
import {
	Grid,
	InputAdornment,
	Button,
	Typography,
	makeStyles,
	Box,
	Tooltip
} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { FieldArray, useFormikContext } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { InfoOutlined } from "@material-ui/icons";
import NumberPickerField from "../../core/NumberPickerField";

const warningMessage =
	"YOU ARE ADVISED AGAINST THIS OPTION.  If you remove or waive any contingencies without an adequate understanding of the Home/Property’s condition or your ability to purchase, YOU ARE ACTING AGAINST THE ADVICE OF ALOKEE AND YOUR AGENT.";
const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: "100px",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "80px"
		}
	},
	addButton: {
		textTransform: "none",
		fontSize: "1rem",
		background: "#02A689",
		color: "#FFF",
		fontWeight: "700",
		"&:hover": {
			background: "#003A7B"
		}
	},
	days: {
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightSemiBold
	},
	icon: {
		fontSize: "1.25rem",
		color: "#686868",
		marginLeft: "8px"
	}
}));
function OfferDetails({ values, revisedState, setDisclaimer, status }) {
	const classes = useStyles();
	const [buyerNotAdded, setbuyerNotAdded] = useState(true);
	const [entityNotAdded, setEntityNotAdded] = useState(true);
	
	const {
		values: {
			loanContingency,
			appraisalContingency,
			investigationContingency,
			sellerDocumentsReview,
			titleReportReview,
			removalOfAllContingencies,
			contingentOffer,
			liquidatedDamages,
			arbitrationOfDisputes
		},
		touched,
		setFieldValue,
		setTouched
	} = useFormikContext();

	useEffect(() => {
		setTouched({
			...touched,
			["loanContingency"]: true
			// ["appraisalContingency"]: true,
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (loanContingency == false)
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true
				};
			});
	}, [loanContingency]);
	useEffect(() => {
		setTouched({
			...touched,
			// ["loanContingency"]: true,
			["appraisalContingency"]: true
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (appraisalContingency == false)
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true
				};
			});
	}, [appraisalContingency]);
	useEffect(() => {
		setTouched({
			...touched
			// ["loanContingency"]: true,
			// ["appraisalContingency"]: true,
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (investigationContingency == false)
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true
				};
			});
	}, [investigationContingency]);
	useEffect(() => {
		setTouched({
			...touched
			// ["loanContingency"]: true,
			// ["appraisalContingency"]: true,
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (sellerDocumentsReview == false)
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true
				};
			});
	}, [sellerDocumentsReview]);
	useEffect(() => {
		setTouched({
			...touched
			// ["loanContingency"]: true,
			// ["appraisalContingency"]: true,
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (titleReportReview == false)
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true
				};
			});
	}, [titleReportReview]);
	useEffect(() => {
		setTouched({
			...touched
			// ["loanContingency"]: true,
			// ["appraisalContingency"]: true,
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (contingentOffer == false)
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true && touched.contingentOffer
				};
			});
	}, [contingentOffer]);
	useEffect(() => {
		setTouched({
			...touched
			// ["loanContingency"]: true,
			// ["appraisalContingency"]: true,
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (liquidatedDamages == "No")
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true
				};
			});
	}, [liquidatedDamages]);
	useEffect(() => {
		setTouched({
			...touched
			// ["loanContingency"]: true,
			// ["appraisalContingency"]: true,
			// ["investigationContingency"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["sellerDocumentsReview"]: true,
			// ["titleReportReview"]: true,
			// ["contingentOffer"]: true,
			// ["liquidatedDamages"]: true,
			// ["arbitrationOfDisputes"]: true
		});
		if (arbitrationOfDisputes == "No")
			setDisclaimer((prevState) => {
				return {
					message: warningMessage,
					state: true
				};
			});
	}, [arbitrationOfDisputes]);

	useEffect(() => {
		if (removalOfAllContingencies && !revisedState) {
			setFieldValue("loanContingency", !removalOfAllContingencies);
			setFieldValue("appraisalContingency", !removalOfAllContingencies);
			setFieldValue("investigationContingency", !removalOfAllContingencies);
			setFieldValue("sellerDocumentsReview", !removalOfAllContingencies);
			setFieldValue("titleReportReview", !removalOfAllContingencies);
			setFieldValue("loanContingency", !removalOfAllContingencies);
			setFieldValue("contingentOffer", !removalOfAllContingencies);
		}
	}, [removalOfAllContingencies]);

	useEffect(() => {
		if (
			loanContingency == true ||
			appraisalContingency == true ||
			investigationContingency == true ||
			sellerDocumentsReview == true ||
			titleReportReview == true ||
			contingentOffer == true
		) {
			setFieldValue("removalOfAllContingencies", false);
		}
	}, [
		loanContingency,
		appraisalContingency,
		investigationContingency,
		sellerDocumentsReview,
		titleReportReview
	]);
	return (
		<div className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12} style={{ marginBottom: "20px" }}>
					{revisedState ? (
						<div>
							<Box
								display="flex"
								alignItems="flex-end"
								style={{ marginTop: "40px" }}
							>
								<Typography style={{ fontSize: "1rem", coloir: "#000" }}>
									<span style={{ color: "#000", fontSize: "1rem" }}>
										Offer valid for{" "}
									</span>
									<span className={classes.days}>{values.expiryDate} days</span>
								</Typography>
								<Tooltip title="When the offer will expire if the Seller does not sign it.">
									<InfoOutlined className={classes.icon} />
								</Tooltip>
							</Box>
							<Typography
								style={{
									color: "#000",
									fontWeight: "700",
									marginTop: "6px",
									fontSize: "1rem"
								}}
							>
								Expires on:{" "}
								{new Date(
									moment(new Date(), "DD-MM-YYYY").add(
										values.expiryDate,
										"days"
									)
								).toLocaleDateString()}
							</Typography>
						</div>
					) : (
						<>
							<NumberPickerField
								name="expiryDate"
								title="Offer Expiration"
								label="Days"
								toolTipMsg={
									"When the offer will expire if the Seller does not sign it."
								}
								min={1}
								max={365}
								disabled={revisedState}
							/>
							<Typography style={{ marginTop: "25px", color: "#000" }}>
								Date:{" "}
								{new Date(
									moment(new Date(), "DD-MM-YYYY").add(
										values.expiryDate,
										"days"
									)
								).toLocaleDateString()}
							</Typography>
						</>
					)}
				</Grid>

				{/* <Grid item xs={12} md={3} sm={4}>
				<TextField
					tooltipRequired={true}
					name="increaseDeposit"
					label="Increase Deposit"
					type="text"
					defaultValue="0.00"
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>
					}}
					required
					disabled={revisedState}
				/>
			</Grid> */}
				<Grid item xs={12} md={6} sm={12}>
					<SingleSelect
						variant="outlined"
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"The type of loan as dictated by your lender.  The majority of home loans are Conventional."
						}
						name="loanType"
						label="Loan Type"
						options={{
							Conventional: "Conventional",
							FHA: "FHA",
							VA: "VA",
							"Seller Financing": "Seller Financing",
							Other: "Other"
						}}
						required
						InputProps={{
							startAdornment: <InputAdornment position="start"></InputAdornment>
						}}
						disabled={revisedState || values.loan <= 0}
						showTooltip={true}
					/>
				</Grid>
				{/* <Grid item xs={12} md={3} sm={6}>
				<TextField
					tooltipRequired={true}
					name="loanRate"
					label="Loan Rate"
					type="text"
					required
					disabled={revisedState || values.loan <= 0}
					defaultValue="0.00"
					InputProps={{
						startAdornment: <InputAdornment position="start">%</InputAdornment>,
						endAdornment: <InputAdornment position="end"></InputAdornment>
					}}
				/>
			</Grid>
			<Grid item xs={12} md={3} sm={6}>
				<SingleSelect
                variant="outlined"
					size="small"
					tooltipRequired={true}
					name="loanRateType"
					label="Loan Rate Type"
					options={{
						InitialAdjustableRate: "Initial Adjustable Rate",
						FixedRate: "Fixed Rate"
					}}
					InputProps={{
						startAdornment: <InputAdornment position="start"> </InputAdornment>
					}}
					required
					disabled={revisedState || values.loan <= 0}
				/>
			</Grid> */}
				{/* <Grid item xs={12} md={3} sm={6}>
				<TextField
					tooltipRequired={true}
					name="additionalLoanAmount"
					label="Additional Loan Amount"
					type="text"
					required
					disabled={revisedState || values.loan <= 0}
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
						endAdornment: <InputAdornment position="end"></InputAdornment>
					}}
				/>
			</Grid>
			<Grid item xs={12} md={3} sm={6}>
				<SingleSelect
                variant="outlined"
					size="small"
					tooltipRequired={true}
					name="additionalLoanType"
					label="Additional Loan Type"
					required
					disabled={revisedState || values.loan <= 0}
					InputProps={{
						startAdornment: <InputAdornment position="start"> </InputAdornment>
					}}
					options={{
						SellerFinancing: "Seller Financing",
						Other: "Other"
					}}
				/>
			</Grid> */}
				<Grid item xs={12} md={6} sm={12}>
					<SingleSelect
						variant="outlined"
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"How you will be using the house.  The majority are primary homes (where you plan to live)."
						}
						name="occupancyType"
						label="Occupancy Type"
						type="text"
						required
						disabled={revisedState}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start"> </InputAdornment>
							)
						}}
						options={{
							Primary: "Primary",
							Secondary: "Secondary",
							Investment: "Investment"
						}}
						showTooltip={true}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<SingleSelect
						variant="outlined"
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"This limits the damages to the deposit for a breach of the contract."
						}
						name="liquidatedDamages"
						label="Liquidated Damages"
						required
						disabled={revisedState}
						options={{
							Yes: "Yes",
							No: "No"
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start"> </InputAdornment>
							)
						}}
						showTooltip={true}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<SingleSelect
						variant="outlined"
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"This limits the forum for contract disputes to arbitration instead of the courts."
						}
						name="arbitrationOfDisputes"
						label="Arbitration Of Disputes"
						required
						disabled={revisedState}
						options={{
							Yes: "Yes",
							No: "No"
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start"> </InputAdornment>
							)
						}}
						showTooltip={true}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<Checkbox
						size="small"
						tooltipRequired={true}
						name="loanContingency"
						toolTipMsg={
							"Allows you to cancel the contract without penalty and receive a refund of your deposit if you are unable to secure a loan WITH certain conditions. The default is 17 days, please contact Alokee if you need more or less days."
						}
						label="Loan Contingency"
						required
						disabled={revisedState}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<Checkbox
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"Allows you to cancel the contract without penalty and receive a refund of your deposit if the appraisal does not set the value at a minimum of the Purchase Price.  The default is 17 days, please contact Alokee if you need more or less days."
						}
						name="appraisalContingency"
						label="Appraisal Contingency"
						required
						disabled={revisedState}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<Checkbox
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"Allows you to cancel the contract without penalty and receive a refund of your deposit if your investigation of the home is unacceptable.  The default is 17 days, please contact Alokee if you need more or less days."
						}
						name="investigationContingency"
						label="Investigation Contingency"
						required
						disabled={revisedState}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<Checkbox
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"Allows you to cancel the contract without penalty and receive a refund of your deposit if your review of the Seller documents of the home is unacceptable.  The default is 17 days, please contact Alokee if you need more or less days."
						}
						name="sellerDocumentsReview"
						label="Seller Documents Review"
						required
						disabled={revisedState}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<Checkbox
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"Allows you to cancel the contract without penalty and receive a refund of your deposit if your review of the title report of the home is unacceptable.  The default is 17 days, please contact Alokee if you need more or less days."
						}
						name="titleReportReview"
						label="Title Report Review"
						required
						disabled={revisedState}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<Checkbox
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"Removes of all Contingencies above (Loan, Appraisal, Investigation, Seller Documents, Title Report).  YOU ARE ADVISED AGAINST THIS OPTION.  If you remove or waive any contingencies without an adequate understanding of the Home/Property’s condition or your ability to purchase, YOU ARE ACTING AGAINST THE ADVICE OF ALOKEE AND YOUR AGENT."
						}
						name="removalOfAllContingencies"
						label="Removal Of All Contingencies"
						required
						disabled={revisedState}
					/>
				</Grid>
				<Grid item xs={12} md={6} sm={12}>
					<Checkbox
						size="small"
						tooltipRequired={true}
						toolTipMsg={
							"Allows you to cancel the contract without penalty and receive a refund of your deposit if you cannot sell your existing home/property."
						}
						name="contingentOffer"
						label="Home Sale Contingency"
						required
						disabled={revisedState}
					/>
				</Grid>
				{!values.entity.length > 0 && (
					<Grid
						flexDirection="column"
						alignItems="flex-start"
						justifyContent="flex-start"
						container
						item
						spacing={2}
					>
						<FieldArray
							tooltipRequired={true}
							name="partners"
							render={(arrayHelpers) => (
								<>
									{values.partners &&
										values.partners.length > 0 &&
										buyerNotAdded &&
										values.partners.map((partner, index) => (
											<>
												<Grid item xs={12} md={6} sm={12}>
													<LabelAboveTextField
														name={`partners[${index}].name`}
														label="Second Buyer's Name"
														tooltipRequired={false}
														disabled={revisedState}
														type="text"
														required
													/>
												</Grid>
												<Grid item xs={12} md={6} sm={12}>
													<LabelAboveTextField
														name={`partners[${index}].email`}
														label="Second Buyer's Email"
														tooltipRequired={false}
														disabled={revisedState}
														type="text"
														required
													/>
												</Grid>
												<Grid item xs={6} md={4} sm={6}>
													<Button
														disabled={revisedState}
														type="button"
														fullWidth={true}
														className={classes.addButton}
														onClick={() => {
															if (partner.name !== "" && partner.email !== "") {
																setbuyerNotAdded(false);
															}
														}}
													>
														Add
													</Button>
												</Grid>
												<Grid item xs={6} md={4} sm={6}>
													<Button
														variant="outlined"
														disabled={revisedState}
														type="button"
														style={{
															textTransform: "none",
															fontSize: "1rem",
															fontWeight: "700",
															border: "1px solid #000"
														}}
														fullWidth={true}
														onClick={() => {
															arrayHelpers.remove(index);
															setbuyerNotAdded(true);
														}}
													>
														Remove
													</Button>
												</Grid>
											</>
										))}

									{values.partners &&
										values.partners.length > 0 &&
										!buyerNotAdded &&
										values.partners.map((partner, index) => (
											<Grid item xs={12} md={8} sm={12}>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "flex-start",
														marginTop: "35px",
														marginBottom: "5px"
													}}
												>
													<AddCircleIcon
														style={{
															color: "#003A7B",
															marginRight: 5
														}}
														fontSize="large"
													/>
													<Typography
														style={{
															fontFamily: "Rubik",
															fontSize: "16px",
															color: "#003A7B"
														}}
													>
														Second Buyer Added
													</Typography>
												</div>
												<Box
													display="flex"
													flexDirection="row"
													justifyContent="space-between"
													style={{
														border: "1px solid #003A7B",
														borderRadius: "4px",
														padding: "20px"
													}}
												>
													<Typography
														style={{ color: "#000", fontSize: "1rem" }}
													>
														{partner.name}, {partner.email}
													</Typography>
													<Typography
														disabled={revisedState}
														type="button"
														style={{
															fontSize: "1rem",
															cursor: "pointer",
															color: "#003A7B"
														}}
														onClick={() => {
															arrayHelpers.remove(index);
															setbuyerNotAdded(true);
														}}
													>
														Remove
													</Typography>
												</Box>
											</Grid>
										))}

									{!values.partners ||
										(values.partners.length === 0 && buyerNotAdded && (
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "flex-start",
													marginTop: "35px"
												}}
											>
												<IconButton
													onClick={() =>
														arrayHelpers.push({ name: "", email: "" })
													}
													disabled={revisedState}
													style={{ paddingLeft: 0 }}
													aria-label="Add a Second Buyer (if any)"
												>
													<AddCircleOutlineIcon
														style={{
															color: "#003A7B"
														}}
														fontSize="large"
													/>
												</IconButton>
												<Typography
													style={{
														fontFamily: "Rubik",
														fontSize: "16px",
														color: "#003A7B"
													}}
												>
													Add a Second Buyer (if any)
												</Typography>
											</div>
										))}
								</>
							)}
						/>
					</Grid>
				)}
				{!values.partners.length > 0 && (
					<Grid
						flexDirection="column"
						alignItems="flex-start"
						justifyContent="flex-start"
						container
						item
						spacing={2}
					>
						<FieldArray
							tooltipRequired={true}
							name="entity"
							render={(arrayHelpers) => (
								<>
									{values.entity &&
										values.entity.length > 0 &&
										entityNotAdded &&
										values.entity.map((entity, index) => (
											<>
												<Grid item xs={12} md={12} sm={12}>
													<Typography
														style={{
															fontFamily: "Rubik",
															fontWeight: "bold",
															color: "#000"
														}}
													>
														You will be the primary signer.
													</Typography>
												</Grid>
												<Grid item xs={12} md={6} sm={12}>
													<LabelAboveTextField
														name={`entity[${index}].name`}
														label="Entity's Name"
														type="text"
														required
														disabled={revisedState}
														tooltipRequired={false}
													/>
												</Grid>
												{/* <Grid item xs={12} md={6} sm={12}>
												<LabelAboveTextField
													tooltipRequired={false}
													name={`entity[${index}].email`}
													label="Entity's Email"
													type="email"
													required
													disabled={revisedState}
												/>
											</Grid> */}
												<Grid item xs={12} sm={12} md={6}></Grid>
												<Grid item xs={6} md={4} sm={6}>
													<Button
														disabled={revisedState}
														type="button"
														fullWidth={true}
														className={classes.addButton}
														onClick={() => {
															if (entity.name !== "") {
																setEntityNotAdded(false);
															}
														}}
													>
														Add
													</Button>
												</Grid>
												<Grid item xs={6} md={4} sm={6}>
													<Button
														variant="outlined"
														disabled={revisedState}
														type="button"
														style={{
															textTransform: "none",
															fontSize: "1rem",
															fontWeight: "700",
															border: "1px solid #000"
														}}
														fullWidth={true}
														onClick={() => {
															arrayHelpers.remove(index);
															setbuyerNotAdded(true);
														}}
													>
														Remove
													</Button>
												</Grid>
											</>
										))}

									{values.entity &&
										values.entity.length > 0 &&
										!entityNotAdded &&
										values.entity.map((entity, index) => (
											<Grid item xs={12} md={8} sm={12}>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "flex-start",
														marginTop: "35px",
														marginBottom: "5px"
													}}
												>
													<AddCircleIcon
														style={{
															color: "#003A7B",
															marginRight: 5
														}}
														fontSize="large"
													/>
													<Typography
														style={{
															fontFamily: "Rubik",
															fontSize: "16px",
															color: "#003A7B"
														}}
													>
														Entity Added
													</Typography>
												</div>
												<Box
													display="flex"
													flexDirection="row"
													justifyContent="space-between"
													style={{
														border: "1px solid #003A7B",
														borderRadius: "4px",
														padding: "20px"
													}}
												>
													<Typography
														style={{ color: "#000", fontSize: "1rem" }}
													>
														{entity.name}
														{entity.email && "," + entity.email}
													</Typography>
													<Typography
														disabled={revisedState}
														type="button"
														style={{
															fontSize: "1rem",
															cursor: "pointer",
															color: "#003A7B"
														}}
														onClick={() => {
															arrayHelpers.remove(index);
															setEntityNotAdded(true);
														}}
													>
														Remove
													</Typography>
												</Box>
											</Grid>
										))}

									{!values.entity ||
										(values.entity.length === 0 && entityNotAdded && (
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "flex-start",
													marginTop: "35px"
												}}
											>
												<IconButton
													onClick={() => arrayHelpers.push({ name: "" })} // Email is commented out for now, so not pushing email as a part of this object
													disabled={revisedState}
													style={{ paddingLeft: 0 }}
													aria-label="Add an Entity (if any)"
												>
													<AddCircleOutlineIcon
														style={{
															color: "#003A7B"
														}}
														fontSize="large"
													/>
												</IconButton>
												<Typography
													style={{
														fontFamily: "Rubik",
														fontSize: "16px",
														color: "#003A7B"
													}}
												>
													Add an Entity (if any)
												</Typography>
											</div>
										))}
								</>
							)}
						/>
					</Grid>
				)}
			</Grid>
		</div>
	);
}

export default OfferDetails;
