import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
	root: {
		marginTop: 10
	},
	mainContainerForm: {
		paddingTop: 25,
		backgroundColor: "#fff"
	},
	formStatusChip: {
		position: "absolute",
		top: "20px",
		fontFamily: "Rubik",
		fontSize: "1rem",
		fontWeight: "400",
		color: theme.palette.primary.mainLight,
		right: "25px"
	},
	scrollableContainer: {
		flex: 1,
		overflowY: "auto",
		padding: theme.spacing(2)
	},
	propertyOfferContainer: {
		height: "150px",
		marginTop: 110,
		paddingTop: 30,
		marginBottom: 4,
		border: "1px solid",
		borderColor: "#cacaca",
		borderRadius: "4px",
		position: "sticky",
		top: 0
	},
	journeyContainer: {
		paddingTop: 20,
		flexWrap: "nowrap",
		minHeight: 305
	},
	description: {
		cursor: "pointer",
		fontFamily: "Rubik"
	},

	img: {
		height: "235px",
		width: "100%",
		maxWidth: "100%",
		objectFit: "cover"
	},
	listedPrice: {
		position: "relative",
		marginBottom: 5,
		marginLeft: 20
	},
	pricePredictorButton: {
		background:
			"linear-gradient(90deg, rgb(0, 58, 123) 27.6%, rgb(103, 229, 196) 100%)",
		textTransform: "none",
		height: 55
	},
	listedPriceText: {
		fontSize: "1.625rem",
		color: theme.palette.primary.mainLight,
		fontWeight: theme.typography.fontWeightSemiBold,
		[theme.breakpoints.down("md")]: {
			fontSize: "1.5rem"
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.375rem"
		}
	},
	pricePredictorText: {
		fontSize: "1.2rem",
		color: "#fff",
		fontWeight: theme.typography.fontWeightSemiBold,
		[theme.breakpoints.down("md")]: {
			fontSize: "1.0rem"
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.0rem"
		}
	},
	priceNumber: {
		fontSize: "2rem",
		color: theme.palette.primary.mainLight,
		[theme.breakpoints.down("md")]: {
			fontSize: "1.75rem"
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.375rem"
		},
	},
	cashBonus: {
		cursor: "pointer",
		fontSize: "16px",
		fontWeight: "600 !important",
		color: "#000",
		marginTop: 10
	},
	cashBonusText: {
		fontWeight: "bold",
		fontSize: "20px",
		width: "240px",
		overflowWrap: "break-word"
	},
	status: {
		height: "33px !important",
		width: "140px",
		borderRadius: "6px !important",
		fontFamily: "Roboto Condensed !important",
		fontSize: "15px !important",
		fontWeight: "500",
		color: "#fff !important"
	},
	bonusPriceText: {
		color: theme.palette.text.primary,
		position: "relative",
		textTransform: "uppercase",
		marginBottom: 5,
		marginLeft: 50,
		"&::before": {
			content: "''",
			left: "-16px",
			position: "absolute",
			width: "1.2px",
			height: "45px",
			backgroundColor: theme.palette.text.primary
		}
	},
	address: {
		color: theme.palette.text.primary,
		position: "relative",
		// textTransform: "uppercase",
		marginBottom: 5,
		marginLeft: 40
	},
	fav: {
		marginBottom: 5,
		marginRight: 35
	},

	heading: {
		fontSize: "1.95em",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightSemiBold,
		color: theme.palette.text.primary,
		lineHeight: 1.2,
		[theme.breakpoints.down("xs")]: {
			fontSize: "1.55em"
		}
	},
	imageList: {
		overflowY: "auto",
		overflowX: "hidden",
		height: 2380,
		[theme.breakpoints.only("xs")]: {
			height: 2380
		},
		[theme.breakpoints.only("sm")]: {
			height: 2380
		},
		[theme.breakpoints.only("md")]: {
			height: 2380
		},
		[theme.breakpoints.between("sm", "md")]: {
			height: 2680
		},
		[theme.breakpoints.up("lg")]: {
			height: 2300
		}
	},
	subheading: {
		fontSize: "1.65em",
		[theme.breakpoints.down("xs")]: {
			fontSize: "1.35em"
		}
	},
	text: {
		fontSize: "1.15em",
		lineHeight: 1,
		letterSpacing: "0.5px",
		fontWeight: theme.typography.fontWeightRegular,
		[theme.breakpoints.down("xs")]: {
			fontSize: "1em"
		},
		marginTop: "10px"
	},
	propDet: {
		fontWeight: theme.typography.fontWeightSemiBold,
		fontSize: "1.05em",
		color: theme.palette.text.primary
	},
	subtext: {
		marginTop: "20px",
		fontSize: "1.25em",
		color: "#000",
		fontWeight: theme.typography.fontWeightRegular,
		lineHeight: 1,
		[theme.breakpoints.down("xs")]: {
			fontSize: "1em"
		}
	},
	listItems: {
		minWidth: "50%",
		fontFamily: theme.typography.fontFamily,
		marginBottom: "12px",
		fontWeight: theme.typography.fontWeightLight,
		color: theme.palette.text.primary,
		fontSize: "1em"
	},
	icon: {
		color: theme.palette.text.primary,
		height: "25px",
		width: "25px",
		marginRight: "3px"
	},
	amnities: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		fontSize: "16px"
	},
	sliderCont: {
		maxWidth: "800px",
		overflow: "hidden",
		borderRadius: "8px",
		alignSelf: "center",
		[theme.breakpoints.up("lg")]: {
			maxWidth: "1000px"
		}
	},
	image: {
		height: "100%",
		width: "100%",
		objectFit: "cover",
		objectPosition: "50% 50%"
	},
	agentImage: {
		height: "110px",
		width: "110px",
		objectFit: "contain"
	},
	carousel: {
		"& button": {
			backgroundColor: "rgba(242, 242, 242, 0.5) !important",
			color: "#fff",
			height: "80px",
			width: "80px",
			borderRadius: "40px",
			top: "50% !important",
			transform: "translateY(-50%)"
		}
	},
	offerBtn: {
		borderRadius: "6px",
		backgroundColor: theme.palette.primary.mainLight,
		color: "#fff",
		fontSize: "1rem",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		textTransform: "none",
		"&:hover": {
			backgroundColor: theme.palette.secondary.mainTwo
		},
		marginRight: "20px"
	},
	inspectionBtn: {
		borderRadius: "6px",
		backgroundColor: "#FFF",
		color: theme.palette.primary.mainLight,
		fontSize: "1rem",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		textTransform: "none"
	},
	propertyFormAskQBtn: {
		borderRadius: "6px",
		border: "1px solid #000",
		backgroundColor: "#FFF",
		color: "#000",
		fontSize: "1rem",
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		textTransform: "none"
	},
	card: {
		backgroundColor: "#F2F2F2",
		maxHeight: "150px",
		minHeight: "150px",
		maxWidth: "195px",
		borderRadius: 8,
		cursor: "pointer",
		border: "2px 2px solid #fff",
		boxShadow: "2px 2px 2px #ccc",
		padding: 10
	},
	InContainer: {
		fontFamily: "Roboto !important",
		fontWeight: "700 !important",
		color: "#2a3d57",
		fontSize: "24px !important",
		cursor: "pointer"
	},
	loader: {
		position: "fixed",
		left: "50%",
		top: "50%"
	},
	price: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.primary.mainLight,
		fontSize: "2.5rem",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem"
		}
	},
	addressInContainer: {
		fontFamily: theme.typography.fontFamily,
		fontSize: "1rem",
		color: "#000",
		lineHeight: "136%"
	},
	// START NEW PropertyDetails styles
	popertyDetailsMainCont: {
		marginTop: 50,
		paddingBottom: "120px"
	},
	favouriteGrid: {
		marginTop: "40px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	largeTitle: {
		fontFamily: theme.typography.fontFamily,
		fontSize: "2.5rem",
		color: theme.palette.primary.mainLight,
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem"
		}
	},
	detailsText: {
		fontFamily: theme.typography.fontFamily,
		fontSize: "1rem",
		color: "#000",
		lineHeight: "136%"
	},
	detailsSubHeading: {
		fontFamily: theme.typography.fontFamily,
		color: "#003A7B",
		fontWeight: "bold",
		fontSize: "1.125rem",
		marginBottom: "20px"
	},
	mapGrid: {
		justifyContent: "flex-start",
		alignItems: "flex-start",
		marginTop: "20px"
	},
	detailsIconBox: {
		display: "flex",
		marginTop: "40px",
		justifyContent: "flex-start",
		gap: "100px",
		[theme.breakpoints.down("xs")]: {
			gap: "60px",
			justifyContent: "center"
		}
	},
	detailsListItem: {
		display: "list-item",
		listStyleType: "disc",
		marginLeft: "20px"
	},
	agentBox: {
		display: "flex",
		justifyContent: "flex-start",
		gap: "90px",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			gap: "20px",
			flexDirection: "column"
		}
	},
	detailsStatus: {
		position: "absolute",
		top: "15px",
		fontFamily: theme.typography.fontFamily,
		fontSize: "1rem",
		color: theme.palette.primary.mainLight,
		right: "30px",
		padding: 15
	},
	inspectionModelTitle: {
		color: "#003A7B",
		fontSize: "2rem",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.5rem"
		}
	},
	inspectionBtnText: {
		marginLeft: "8px",
		fontSize: "1.5rem",
		color: "#02A689",
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.125rem"
		}
	}
	// END NEW PropertyDetails styles
}));
