import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function GalleryModal({ images, open, onClose, currentIndex = 0, updateIndex }) {

    const handleNext = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        updateIndex(nextIndex); // update index in parent component
    };

    const handlePrevious = () => {
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        updateIndex(prevIndex); // update index in parent component
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-label='Property Image Modal'
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }}
        >
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <Button
                    aria-label='Previous Image'
                    onClick={handlePrevious}
                    disabled={images.length <= 1}
                    style={{
                        position: 'absolute',
                        left: '10px',
                        background: 'rgba(255, 255, 255, 0.6)',
                        borderRadius: '50%',
                        padding: '8px',
                        minWidth: 'unset'
                    }}
                >
                    <ArrowBackIcon style={{ color: "#FFF", fontSize: "2.5rem" }} />
                </Button>
                <img src={images[currentIndex].MediaURL} alt={images[currentIndex].title} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <Button
                    aria-label='Next Image'
                    onClick={handleNext}
                    disabled={images.length <= 1}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        background: 'rgba(255, 255, 255, 0.6)',
                        borderRadius: '50%',
                        padding: '8px',
                        minWidth: 'unset'
                    }}
                >
                    <ArrowForwardIcon style={{ color: "#FFF", fontSize: "2.5rem" }} size="large" />
                </Button>
            </div>
        </Modal>
    );
}

export default GalleryModal;
