import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Tooltip, makeStyles } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		marginBottom: theme.spacing(2),
	},
	label: {
		display: "flex",
		alignItems: "center",
	},
	select: {
		width: "200px",
		'& .MuiSelect-icon': {
			color: theme.palette.primary.main,
			fontSize: "40px",
			top: "calc(50% - 20px)"
		},
	},
}));

const SelectWrapper = ({
	name,
	variant,
	options,
	margin,
	onChange,
	label,
	toolTipMsg,
	showTooltip,
	...otherProps
}) => {
	const { setFieldValue, values } = useFormikContext();
	const [field, meta] = useField(name);
	const classes = useStyles();

	const handleChange = (evt) => {
		const { value } = evt.target;
		setFieldValue(name, value);
		if (typeof onChange === "function") {
			onChange(value);
		}
	};

	const configSelect = {
		...field,
		select: true,
		fullWidth: true,
		onChange: handleChange,
		value: values[name],
		margin: margin || "normal",
		className: classes.select,
		...otherProps,
	};

	if (variant) {
		configSelect.variant = variant;
	}

	if (meta && meta.touched && meta.error) {
		configSelect.error = true;
		configSelect.helperText = meta.error;
	}

	return (
		<div className={classes.root}>
			<div className={classes.label}>
				<InputLabel id={`${name}-select`} style={{ fontSize: "1.125rem", color: "#003A7B", fontWeight: "700", marginBottom: "10px" }}>{label}</InputLabel>
				{showTooltip && (
					<Tooltip title={toolTipMsg ? toolTipMsg : label}>
						<InfoOutlined className="myIcon" fontSize="small" style={{ marginLeft: 8, color: "#686868", marginBottom: 8 }} />
					</Tooltip>
				)}
			</div>
			<FormControl variant="outlined">
				<Select {...configSelect} className={classes.select} labelId={`${name}-select`}>
					{Object.keys(options).map((item, pos) => (
						<MenuItem key={pos} value={item}>
							{options[item]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default SelectWrapper;
