import { createContext, useContext, useReducer } from "react";
const UserStateContext = createContext();
const UserDispatchContext = createContext();

const userReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_USER":
			return { ...state, userProfile: action.payload.data };
		case "LOGOUT":
			return {
				...state,
				userProfile: null
			};
	}
	return null;
};

const UserProvider = ({ children }) => {
	const [state, dispatch] = useReducer(userReducer, { userProfile: null });
	return (
		<UserStateContext.Provider value={state}>
			<UserDispatchContext.Provider value={dispatch}>
				{children}
			</UserDispatchContext.Provider>
		</UserStateContext.Provider>
	);
};

const useUserState = () => {
	const context = useContext(UserStateContext);
	if (context === undefined) {
		throw new Error("useUserState must be used within a UserProvider");
	}
	return context;
};

const useUserDispatch = () => {
	const context = useContext(UserDispatchContext);
	if (context === undefined) {
		throw new Error("useUserDispatch must be used within a UserProvider");
	}
	return context;
};

export { UserProvider, useUserState, useUserDispatch };
